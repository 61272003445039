import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import {
  useCreateVManModelMutation,
  useUpdateVManModelMutation,
  useGetVManModelQuery,
  useGetVMansQuery,
} from "../services";
import { mergeObjects } from "../../../shared/helper";
import { FormContainer } from "../Shared";
import { useForm, useDropDownSearch } from "../../../hooks";
import { vManModelFormValidation } from "../validations";
import { getLocalizedString } from "../../../shared/translation";

export const VManModelForm = ({ idToEdit = null, onClose }) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    vehicle_manufacturer_id: "",
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: vManModelFormValidation,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const dropdownParams = { per_page: 1000, order_by: "name" };

  // Get the vehicle manufacturers list using Dropdown Search
  const {
    data: vManufacturers = { data: [] },
    setSearchKey: setVManSearch,
    isSuccess: isvManufacturersSuccess,
  } = useDropDownSearch({
    useData: useGetVMansQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_manufacturer_id,
    additionalParams: { ...dropdownParams },
  });

  const { data } = useGetVManModelQuery({ id: idToEdit }, { skip: !idToEdit });

  const { create, update } = useForm({
    createMutation: useCreateVManModelMutation,
    updateMutation: useUpdateVManModelMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  useEffect(() => {
    if (idToEdit && data) {
      setInitialValues(mergeObjects(initialValues, data));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      update({ id: idToEdit, formData });
    } else {
      create({ formData });
    }
  };

  return (
    <FormContainer
      resourceName={getLocalizedString("vehicle_manufacturer_model", "Vehicle Manufacturer Model")}
      closeForm={() => onClose(false)}
      handleFormSubmit={formik.handleSubmit}
      idToEdit={idToEdit}
    >
      <Components.QICustomSelect
        label={getLocalizedString("manufacturer", "Manufacturer")}
        {...formik.getFieldProps("vehicle_manufacturer_id")}
        onChange={(value) => {
          formik.setFieldValue("vehicle_manufacturer_id", value);
          setVManSearch("");
        }}
        onSearch={setVManSearch}
        error={formik.touched.vehicle_manufacturer_id && formik.errors.vehicle_manufacturer_id}
        labelClassName="manufacturer"
      >
        {isvManufacturersSuccess &&
          vManufacturers.data.map((vManufacturer) => (
            <li key={vManufacturer.id} value={vManufacturer.id}>
              {vManufacturer.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QIInput
        label={getLocalizedString("name", "Name")}
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />
    </FormContainer>
  );
};
