import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import {
  useCreateVManModelVariantMutation,
  useUpdateVManModelVariantMutation,
  useGetVManModelVariantQuery,
  useGetVMansQuery,
  useGetVManModelsQuery,
} from "../services";
import { FormContainer } from "../Shared";
import { useForm, useDropDownSearch } from "../../../hooks";
import { vehicleModelVariantFormSchema } from "../validations";
import { getLocalizedString } from "../../../shared/translation";

export const VManModelVariantForm = ({ idToEdit = null, onClose }) => {
  const dropdownParams = { per_page: 1000, order_by: "name" };
  const [initialValues, setInitalValues] = useState({
    name: "",
    vehicle_manufacturer_id: "",
    vehicle_manufacturer_model_id: "",
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: vehicleModelVariantFormSchema,
    onSubmit: (values) => handleFormSubmit(values),
  });

  // Get the vehicle manufacturers list using Dropdown Search
  const {
    data: vMans = { data: [] },
    setSearchKey: setVManSearch,
    isSuccess: isVmansSuccess,
  } = useDropDownSearch({
    useData: useGetVMansQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_manufacturer_id,
    additionalParams: { ...dropdownParams },
  });

  // Get the vehicle manufacturer model list using Dropdown Search
  const {
    data: vManufacturerModels = { data: [] },
    setSearchKey: setVManModelSearch,
    isSuccess: isVManufacturerModelSuccess,
  } = useDropDownSearch({
    useData: useGetVManModelsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_manufacturer_model_id,
    additionalParams: {
      ...dropdownParams,
      vehicle_manufacturer_id: formik.values.vehicle_manufacturer_id,
    },
  });

  const { data } = useGetVManModelVariantQuery({ id: idToEdit }, { skip: !idToEdit });

  const { create, update } = useForm({
    createMutation: useCreateVManModelVariantMutation,
    updateMutation: useUpdateVManModelVariantMutation,
    setError: formik.setErrors,
    closeForm: onClose,
  });

  useEffect(() => {
    if (idToEdit && data?.id) {
      setInitalValues(data);
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      update({ id: idToEdit, formData });
    } else {
      create({ formData });
    }
  };

  return (
    <FormContainer
      resourceName={getLocalizedString(
        "vehicle_manufacturer_model_variant",
        "Vehicle Manufacturer Model Variant"
      )}
      closeForm={() => onClose(false)}
      handleFormSubmit={formik.handleSubmit}
      idToEdit={idToEdit}
    >
      <Components.QICustomSelect
        label={getLocalizedString("manufacturer", "Manufacturer")}
        value={formik.values.vehicle_manufacturer_id}
        onChange={(value) => {
          formik.setValues({
            ...formik.values,
            vehicle_manufacturer_id: value,
            vehicle_manufacturer_model_id: "",
          });
          setVManSearch("");
        }}
        onSearch={setVManSearch}
        error={formik.touched.vehicle_manufacturer_id && formik.errors.vehicle_manufacturer_id}
        labelClassName="manufacturer"
      >
        {isVmansSuccess &&
          vMans.data.map((vMan) => (
            <li key={vMan.id} value={vMan.id}>
              {vMan.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QICustomSelect
        label={getLocalizedString("model", "Model")}
        value={formik.values.vehicle_manufacturer_model_id}
        onChange={(value) => {
          formik.setFieldValue("vehicle_manufacturer_model_id", value);
          setVManModelSearch("");
        }}
        onSearch={setVManModelSearch}
        error={
          formik.touched.vehicle_manufacturer_model_id &&
          formik.errors.vehicle_manufacturer_model_id
        }
        disabled={formik.values.vehicle_manufacturer_id === ""}
        labelClassName="model"
      >
        {isVmansSuccess &&
          isVManufacturerModelSuccess &&
          vManufacturerModels.data.map((vManModel) => (
            <li key={vManModel.id} value={vManModel.id}>
              {vManModel.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QIInput
        label={getLocalizedString("name", "Name")}
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />
    </FormContainer>
  );
};
