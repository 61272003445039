import { useEffect, useState } from "react";
import { useParams } from "react-router";

export const JsonRenderer = () => {
  const { client_id } = useParams();
  const [jsonData, setJsonData] = useState(null);

  //Fetch Json
  useEffect(() => {
    const fetchJsonData = async () => {
      try {
        const res = await fetch(`/${client_id}.json`);
        const data = await res.json();
        setJsonData(data);
      } catch (e) {
        const res = await fetch(`/default.json`);
        const data = await res.json();
        setJsonData(data);
      }
    };
    fetchJsonData();
  }, []);

  return (
    <>
      {jsonData && (
        <code>
          <pre className="code-format">{JSON.stringify(jsonData, null, 2)}</pre>
        </code>
      )}
    </>
  );
};
