//import { collectDataRecursively } from "../../shared/utility";
import moment from "moment";
import { useState } from "react";
import { getDuration } from "../../containers/Tracking/utils";

export const QITracksListCard = ({ data }) => {
  const [startAddress, setStartAddress] = useState(null);
  const [endAddress, setEndAddress] = useState(null);
  const { mode, ...tripData } = data;

  const {
    status,
    start_time,
    start_position,
    end_time,
    end_position,
    max_speed,
    avg_speed,
    gps_distance,
    duration,
    activityDetailsMode = ["TRIP"],
  } = tripData.data;

  const reverseGeocodeAddress = (pos) => {
    return `${pos?.city || ""},${pos?.region || ""},${pos?.country || ""}`;
  };

  const timeConversion = (time) => {
    return moment(time)?.format(" DD/MM/YYYY - hh:mm:ss A");
  };

  const fallbackLocation = (pos) => {
    if (pos?.lat && pos?.lng) {
      return `${pos?.lat},${pos?.lng}`;
    } else {
      return "No Data Recorded";
    }
  };

  const renderModes = () => {
    return (
      <section>
        <header className="activities_header">
          <strong>{mode}</strong>

          {status && <span>{status}</span>}
        </header>

        <div className="trip-card">
          <div className="start-time-and-location">
            <strong>Start Time - </strong>
            <span>{timeConversion(start_time)}</span>

            <div className="start-position">
              <strong>Start Location - </strong>
              <span>
                {start_position?.address
                  ? reverseGeocodeAddress(start_position?.address)
                  : fallbackLocation(start_position)}
              </span>
            </div>
          </div>

          {activityDetailsMode?.includes(mode) && (
            <div className="activity-details">
              {gps_distance && <div>{`Distance :  ${(gps_distance / 1000).toFixed(1)} Km`}</div>}

              {end_time && <div>Duration : {getDuration(duration)}</div>}
            </div>
          )}

          <div className="end-time-and-location">
            <strong>End Time - </strong>
            <span>{end_time ? timeConversion(end_time) : "In Progress"}</span>

            <div className="end-position">
              <span>
                <strong>End Location -</strong>{" "}
                {start_position?.address
                  ? reverseGeocodeAddress(end_position?.address)
                  : fallbackLocation(end_position)}
              </span>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return <li className="track-cardlist">{renderModes()}</li>;
};
