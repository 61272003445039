import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AdminSharedHeader, AdminSharedTable, AdminSharedDeleteModal } from "../Shared/";
import { useGetCommandsQuery, useDeleteCommandMutation } from "../services";
import { useSearchQuery, usePagination } from "../../../hooks";
import { CommandsForm } from "./form";
import { useDelete } from "../../../hooks";

export const CommandsContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, advanceSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_command_text_cont",
  });

  const {
    data = { data: [], count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetCommandsQuery({
    page,
    per_page: perPage,
    order_by: "created_at",
    order_dir: "desc",
    q,
  });

  const {
    deleteItem: deleteCommand,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteCommandMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  //Capitalize first letter
  function capitalizeFirstLetter(str) {
    return str && str[0]?.toUpperCase() + str?.slice(1);
  }

  //Capitalize all letters
  function capitalizeAllLetter(str) {
    return str?.toUpperCase();
  }

  const createNewHandler = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  const deleteClickHandler = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((datum) => datum.id === id);

    if (index > -1) {
      name = data?.data[index].name;
    }
    return name;
  };

  const headers = [
    { label: "Name", key: "name", className: "name" },
    { label: "Command", key: "command_text", className: "command" },
    {
      label: "Type",
      type: "component",
      className: "type",
      component: ({ data }) => {
        return <span>{capitalizeFirstLetter(data?.command_type)}</span>;
      },
    },
    {
      label: "Mode",
      type: "component",
      className: "mode",
      component: ({ data }) => {
        return data?.mode === "both" ? (
          <span>{capitalizeFirstLetter(data?.mode)}</span>
        ) : (
          <span>{capitalizeAllLetter(data?.mode)}</span>
        );
      },
    },
    { label: "Device Manufacturer", key: "device_manufacturer_name" },
    {
      label: "Device Model",
      className: "device-models text-center",
      nestedValue: true,
      getNestedValue: ({ id, device_model_count, name }) => {
        return (
          <>
            {device_model_count > 0 ? (
              <Link
                to={`/superadmin/devices/models?command_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {device_model_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
    },
    { label: "Created on", key: "created_at", className: "date" },
  ];

  return (
    <>
      <article className="main-container">
        <AdminSharedHeader
          createNewHandler={createNewHandler}
          groupName="other"
          heading="Configurations"
          handleSearchKey={(value) => setSimpleSearch(value)}
          handleAdvanceSearchKeys={(value) => setAdvanceSearch(null)}
          simpleSearchPlaceHolder="Search by Name, Command"
        />

        <AdminSharedTable
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          data={data.data}
          headers={headers}
          pagination={{ ...pagination, count: data.total_count }}
          onEdit={editClickHandler}
          onDelete={deleteClickHandler}
          className="commands"
          auditResource="Command"
          auditKey="name"
        />
      </article>

      {showForm && <CommandsForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}

      <AdminSharedDeleteModal
        show={showDeleteWarning}
        error={deleteErrorMsg}
        resourceName="command"
        getDeleteItemName={() => getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg("");
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteCommand({ id: idToDelete })}
      />
    </>
  );
};
