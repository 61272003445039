import React, { useState } from "react";
import PropTypes from "prop-types";
import sprite from "../../assets/icons.svg";

/**
 * This is a Accordion with custom class and icon support.
 *
 * @param {arrayOf(Object)} data - A string param must follow following Data structure
 * data: [{
      key: 1 ,
      header: 'status1',
      body: <div> Hello</div>
    },
    {
      key: 2,
      header: <div> Header</div>
      body: <div> Hello</div>
    }]
 * @param {shape}   [className={header:'',body:''}] - An object to provide different class to header and body
 * @example
 *  <QICollapsible
 *    data={data}
 *    className ={header:"headerClass",body:"bodyClass"}
 *  />
 *
 */

export const QICollapsible = ({ data, className, defaultOpenKey }) => {
  // const { header, body, icon } = className;
  const [active, setActive] = useState(defaultOpenKey);

  const handleAccordion = (key) => {
    if (active === key) {
      setActive("");
    } else {
      setActive(key);
    }
  };

  return (
    <section className="qi-accordion">
      {data.map((dataItem) => (
        <article className="qi-accordion_item" key={dataItem.key}>
          <header className={`qi-accordion_header `} onClick={() => handleAccordion(dataItem.key)}>
            <h3 className="qi-accordion_header_title">{dataItem.header}</h3>

            <span className={`icon-wrapper ${active === dataItem.key ? "active" : ""}`}>
              <svg className="icon qi-accordion_icon">
                <use href={`${sprite}#collapse`}></use>
              </svg>
            </span>
          </header>

          {active === dataItem.key && <div className="qi-accordion_body">{dataItem.body}</div>}
        </article>
      ))}
    </section>
  );
};

QICollapsible.propTypes = {
  data: PropTypes.arrayOf(Object).isRequired,
  className: PropTypes.shape({
    header: PropTypes.string,
    body: PropTypes.string,
    icon: PropTypes.string,
  }),
};
