import React, { useState } from "react";
import { MPSharedTable, MPSharedDeleteModal, MPSharedHeader, TableDropDownView } from "../Shared";
import { useSearchQuery, usePagination, useDelete, useQuery } from "../../../hooks";
import {
  useGetEventConfigurationPlansQuery,
  useDeleteEventConfigurationPlanMutation,
  useGetEventConfigurationPlanQuery,
} from "../services";
import { EventConfigurationsPlanForm } from "./form";
import { SvgIcon } from "../../Shared";
import { Link } from "react-router-dom";
import {getLocalizedString} from "../../../shared/translation";

export const EventConfigurationsPlanContainer = () => {
  //Maintains the id for delete operation
  const [idToDelete, setIdToDelete] = useState(null);

  //Maintains the id for edit operation
  const [idToEdit, setIdToEdit] = useState(null);

  //Maintains whether delete warning message needs to be shown
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  //Maintains whether add/edit form needs to be shown
  const [showForm, setShowForm] = useState(false);

  //Stores the pagination details
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_cont",
  });

  //Stores the query parameters
  const queryParams = {
    page: page,
    per_page: perPage,
    order_by: "created_at",
    order_dir: "desc",
  };

  const query = useQuery();
  let filterHeader = "";

  //Used for the filter message for the count clicks
  const filters = [
    {
      label: getLocalizedString(" event configuration_colon", " event configuration:"),
      key: "event_configuration_id",
    },
  ];

  filters.map((filter) => {
    if (query.get(filter.key)) {
      queryParams[filter.key] = query.get(filter.key);
      filterHeader = `${filter.label} ${query.get("name")}`;
    }
  });

  //Get the event comfiguration plan list
  const {
    data = {
      total_count: 0,
      data: [],
    },
    error,
    isFetching,
    isSuccess,
  } = useGetEventConfigurationPlansQuery({
    ...queryParams,
    q,
  });

  //For delete operation of a particular event configuration plan
  const {
    deleteItem: deleteEventConfigurationPlan,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteEventConfigurationPlanMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  //To maintain the header details of each column of the event configuration plan list
  const headers = [
    {
      label: getLocalizedString("name", "Name"),
      key: "name",
      className: "name",
    },
    {
      label: getLocalizedString("events", "Events"),
      type: "component",
      className: "event-configuration more",
      component: ({ data }) => {
        //eventConfigList is a list of event configuration details
        const eventConfigList = data?.event_configuration_details?.map((eventConfig) => {
          //name is the event configuration name
          const name = eventConfig.substring(eventConfig.indexOf(",") + 1, eventConfig.length);
          //type is the type of the event configuration
          const type = eventConfig.substring(0, eventConfig.indexOf(","));
          let typeName = "";
          type?.split("_").filter((word) => {
            typeName += word.charAt(0).toUpperCase() + word.substring(1, word.length) + " ";
          });
          return name + " (" + typeName?.substring(0, typeName?.length - 1) + ")";
        });
        return <TableDropDownView data={eventConfigList} />;
      },
    },
    {
      label: getLocalizedString("vehicle_groups", "Vehicle Groups"),
      nestedValue: true,
      className: "more text-center center-count-links justify-center",

      getNestedValue: ({ vehicle_groups_count, id, name }) => {
        return (
          <>
            {vehicle_groups_count > 0 ? (
              <Link
                to={`/management_portal/groups/vehicle?event_handling_plan_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {vehicle_groups_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
    },
    {
      label: getLocalizedString("device_groups", "Device Groups"),
      nestedValue: true,
      className: "more text-center center-count-links justify-center",

      getNestedValue: ({ device_groups_count, id, name }) => {
        return (
          <>
            {device_groups_count > 0 ? (
              <Link
                to={`/management_portal/groups/device?event_handling_plan_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {device_groups_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
    },
    {
      label: getLocalizedString("active", "Active"),
      key: "active",
      nestedValue: true,
      getNestedValue: ({ active }) => (
        <>
          {active ? (
            <SvgIcon name="tick" wrapperClass="status-icon active" svgClass="=icon-tick" />
          ) : (
            <SvgIcon name="cross" wrapperClass="status-icon inactive" svgClass="=icon-cross" />
          )}
        </>
      ),
      className: "w-25",
    },
    {
      label: getLocalizedString("added_on", "Added On"),
      key: "created_at",
      className: "date",
    },
    // { label: "Added By", key: "created_by" },
  ];

  //To maintain edit event
  const onEdit = (data) => {
    setShowForm(true);
    setIdToEdit(data.id);
  };

  //To maintain delete event
  const onDelete = (data) => {
    setShowDeleteWarning(true);
    setIdToDelete(data.id);
  };

  //To maintain create event
  const onCreate = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data.findIndex((item) => item.id === id);

    if (index >= 0) {
      name = data.data[index].name;
    }
    return name;
  };

  return (
    <>
      <article className="main-container">
        <MPSharedHeader
          groupName="configurations"
          resourceName="Event Handling Plans"
          createNewHandler={onCreate}
          heading={getLocalizedString("configurations", "Configurations")}
          handleSearchKey={setSimpleSearch}
          simpleSearchPlaceholder={getLocalizedString("search_by_name", "Search by Name")}
          filterText={
            filterHeader &&
            `${getLocalizedString("filtered_by", "Filtered by")} ${filterHeader}`
          }
        />

        <MPSharedTable
          resourceName="Event Handling Plans"
          data={data?.data || []}
          error={error}
          isLoading={isFetching}
          isSuccess={isSuccess}
          headers={headers}
          onEdit={onEdit}
          onDelete={onDelete}
          pagination={{ ...pagination, count: data.total_count }}
          skipClientId
          className="event-configuration-plan"
          auditResource="EventConfigurationPlan"
          auditKey="name"
        />
      </article>

      {showForm && (
        <EventConfigurationsPlanForm idToEdit={idToEdit} onClose={() => setShowForm(false)} />
      )}

      <MPSharedDeleteModal
        show={showDeleteWarning}
        entityName={
          getLocalizedString("event_ponfiguration_plan", "Event Configuration Plan")
        }
        error={deleteErrorMsg}
        itemName={getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg("");
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteEventConfigurationPlan({ id: idToDelete })}
        getApiEndpoint={useGetEventConfigurationPlanQuery}
        idToDelete={idToDelete}
      />
    </>
  );
};
