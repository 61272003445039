import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { QIDropdown } from "../../../components";
import {getLocalizedString} from "../../../shared/translation";

export const TableDropDownView = ({ data }) => {
  return (
    <>
      {data && data.length > 0 && (
        <>
          <span className="associated-devices_initial" title={data[0]}>
            {data[0]}
          </span>

          <QIDropdown
            toggleComponent={
              <div>
                {data?.length > 1 && (
                  <span className="associated-devices_more rounded">
                    + {data.length - 1} {getLocalizedString("more", "more")}
                  </span>
                )}
              </div>
            }
          >
            <Scrollbars autoHeight autoHeightMax="150px">
              <ul className="associated-devices_list">
                {data.map((label, index) => (
                  <li className="associated-devices_list_item" title={label} key={index}>
                    {label}
                  </li>
                ))}
              </ul>
            </Scrollbars>
          </QIDropdown>
        </>
      )}
    </>
  );
};
