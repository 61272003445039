import { useEffect } from "react";
import { parseServerError } from "../../shared/helper";

/**
 * This hook help to handle reset of create or update mutations and also helps to add parse and update the errors
 * occured during create/update
 * @param {Function} createMutation - The rtk mutation to create an object
 * @param {Function} updateMutation - The rtk mutation to update an object
 * @param {Function} closeForm - A function which help to close the modal when saved/updated
 * @param {Function} setError - A function which set the error object for form
 * @returns {Function} create - A function which return by createMutation to create objects
 * @returns {Function} update - A function which return by createMutation to update objects
 */
export const useForm = ({
  createMutation,
  updateMutation,
  closeForm,
  setError,
  onSuccess = () => {},
}) => {
  const [
    create,
    {
      error: createError,
      isLoading: isLoadingCreate,
      isSuccess: createSuccess,
      reset: resetCreate,
      data: responseData,
    },
  ] = createMutation();

  const [
    update,
    {
      error: updateError,
      isLoading: isLoadingUpdate,
      isSuccess: updateSuccess,
      reset: resetUpdate,
    },
  ] = updateMutation();

  // Handle server side errors
  useEffect(() => {
    if (setError) {
      if (updateError) {
        // Parse the server and set to error state
        setError(parseServerError(updateError));
      } else if (createError) {
        // Parse the server and set to error state
        setError(parseServerError(createError));
      }
    }
  }, [createError, updateError]);

  // Reset the mutation state when success
  useEffect(() => {
    if (createSuccess || updateSuccess) {
      onSuccess();
      closeForm();
      resetCreate();
      resetUpdate();
    }

    return () => {
      resetCreate();
      resetUpdate();
    };
  }, [createSuccess, updateSuccess]);

  return {
    create,
    update,
    isLoading: isLoadingCreate || isLoadingUpdate,
    updateSuccess,
    resetUpdate,
    createError,
    updateError,
    createSuccess,
    data: responseData,
  };
};
