import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { geofenceFormValidation } from "../validations";
import * as Components from "../../../components";
import { useForm } from "../../../hooks";
import { mergeObjects } from "../../../shared/helper";
import {
  useCreateGeofenceMutation,
  useGetGeofenceQuery,
  useUpdateGeofenceMutation,
} from "../services";
import { FormContainer } from "../Shared/FormContainer";
import "./style.scss";
import { getLocalizedString } from "../../../shared/translation";

export const GeofenceForm = ({ idToEdit = null, closeForm, isNew }) => {
  const [showModal, setShowModal] = useState(false);
  const dropdownParams = { per_page: 1000 };
  const [coordinates, setCoordinates] = useState({});
  const [mapEroor, setMapError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const [initialValues, setInitialValues] = useState({
    name: "",
    json_data: "{}",
  });

  const formik = useFormik({
    initialValues,
    validationSchema: geofenceFormValidation,
    enableReinitialize: true,
    onSubmit: (value) => handleFormSubmit(value),
  });

  const {
    create: createGeofence,
    update: updateGeofence,
    createError,
    updateError,
  } = useForm({
    createMutation: useCreateGeofenceMutation,
    updateMutation: useUpdateGeofenceMutation,
    closeForm,
    setError: formik.setErrors,
  });

  useEffect(() => {
    if (coordinates) {
      formik.setFieldValue("json_data", JSON.stringify(coordinates));
    }
  }, [coordinates]);

  // Fetch the data if edit form
  const { data } = useGetGeofenceQuery({ id: idToEdit }, { skip: !idToEdit });

  // Set the values for edit form
  useEffect(() => {
    if (idToEdit && data) {
      let tempData = { ...data };
      if (tempData.json_data) {
        try {
          let parsed = JSON.parse(tempData.json_data);
          if (!parsed) {
            tempData.json_data = "{}";
          }
          setCoordinates(parsed);
        } catch {
          tempData.json_data = "{}";
        }
      }
      setInitialValues(mergeObjects(formik.initialValues, tempData));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    // const error = createError || updateError;
    if (idToEdit) {
      updateGeofence({ id: idToEdit, formData });
      if (!Object.keys(coordinates).length) {
        setMapError((prev) =>
          getLocalizedString(
            "coordinates_and_types_are_missing",
            "Coordinates and Types are missing"
          )
        );
      }
    } else {
      createGeofence({ formData });
      if (!Object.keys(coordinates).length) {
        setMapError((prev) =>
          getLocalizedString(
            "coordinates_and_types_are_missing",
            "Coordinates and Types are missing"
          )
        );
      }
    }
  };

  return (
    <FormContainer
      resourceName={getLocalizedString("geofence", "Geofence")}
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
      isEditing={isEditing}
      size="xl-large"
    >
      <Components.QIInput
        label={getLocalizedString("name", "Name")}
        placeholder={getLocalizedString("geofence_name", "Geofence name")}
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />

      {/* Geofence Map */}

      <div className="geofence-container">
        <Components.QIGeofenceMap
          setCoordinates={setCoordinates}
          coordinates={coordinates}
          error={mapEroor}
          setError={setMapError}
          isNew={isNew}
          setIsEditing={setIsEditing}
        />
      </div>

      {/* <Components.QIInput
        label="Json Data"
        textarea={true}
        {...formik.getFieldProps("json_data")}
        value={JSON.stringify(coordinates, null, 2)} // Convert to JSON format
        error={formik.touched.json_data && formik.errors.json_data}
        className="json-data min-h-[350px]"
      /> */}
    </FormContainer>
  );
};
