import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { useForm, useDropDownSearch } from "../../../hooks";
import { FormContainer } from "../Shared/FormContainer";
import {
  useCreateVClassMutation,
  useUpdateVClassMutation,
  useGetVClassQuery,
  useGetClientsQuery,
} from "../services";
import { vClassesFormValidation } from "../validations";
import { mergeObjects } from "../../../shared/helper";

export const VClassForm = ({ idToEdit = null, closeForm }) => {
  const dropDownParams = { per_page: 1000, order_by: "name" };
  const [initialValues, setInitialValues] = useState({ name: "", client_id: "" });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: vClassesFormValidation,
    onSubmit: (values) => handleFormSubmit(values),
  });

  // Get the clients list using Dropdown Search
  const {
    data: clients,
    setSearchKey: setClientSearch,
    isSuccess: isClientsSuccess,
  } = useDropDownSearch({
    useData: useGetClientsQuery,
    simpleSearchKey: "name_or_email_cont",
    selectedIds: formik.values.client_id,
    additionalParams: { ...dropDownParams },
  });

  const { data } = useGetVClassQuery({ id: idToEdit }, { skip: !idToEdit });

  const { create: createVClass, update: updateVClass } = useForm({
    createMutation: useCreateVClassMutation,
    updateMutation: useUpdateVClassMutation,
    closeForm,
    setError: formik.setErrors,
  });

  useEffect(() => {
    if (idToEdit && data) {
      setInitialValues(mergeObjects(initialValues, data));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      updateVClass({ id: idToEdit, formData });
    } else {
      createVClass({ formData });
    }
  };

  return (
    <FormContainer
      resourceName="Vehicle Class"
      closeForm={closeForm}
      handleFormSubmit={formik.handleSubmit}
      idToEdit={idToEdit}
    >
      <Components.QICustomSelect
        label="Client"
        {...formik.getFieldProps("client_id")}
        onChange={(value) => {
          formik.setFieldValue("client_id", value);
          setClientSearch("");
        }}
        onSearch={setClientSearch}
        error={formik.touched.client_id && formik.errors.client_id}
        labelClassName="client"
      >
        <li value="">All</li>
        {isClientsSuccess &&
          clients.data.map((client) => {
            return (
              <li key={client.id} value={client.id}>
                {client.name}
              </li>
            );
          })}
      </Components.QICustomSelect>
      <Components.QIInput
        label="Name"
        placeholder="GPV/HGV/LGV"
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />
    </FormContainer>
  );
};
