import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import sprite from "../../assets/icons.svg";

export const QICalendar = ({
  value,
  onChange,
  className = "",
  labelClass = "",
  label,
  inputWrapperClass = "",
  inputClass,
  dateFormat = "dd/MM/yyyy",
  maxDate,
  minDate,
  id,
  showTimeSelect,
  timeIntervals,
  maxTime,
  minTime,
  readOnly,
  error,
  disabled,
  ...props
}) => {
  const CustomInput = forwardRef(({ value, onClick, className }, ref) => (
    <>
      <button className={className} onClick={onClick} ref={ref}>
        {value || "Select"}
      </button>
    </>
  ));

  return (
    <div
      className={`qi-input qi-form-element ${error && !disabled ? "error" : ""} ${
        disabled && "disabled"
      } ${className}`}
    >
      {label && (
        <label className={`qi-input_label ${labelClass}`} data-testid="input-label">
          {label}
        </label>
      )}

      <div className={`qi-input_wrapper date-range-picker ${inputWrapperClass}`}>
        <DatePicker
          className={`${readOnly ? "form-control-plaintext" : "date-range "} ${inputClass}`}
          showMonthDropdown
          showYearDropdown
          dateFormat={dateFormat}
          maxDate={maxDate}
          minDate={minDate}
          id={id}
          showTimeSelect={showTimeSelect}
          timeIntervals={timeIntervals}
          maxTime={maxTime}
          minTime={minTime}
          selected={value}
          onChange={onChange}
          customInput={<CustomInput className="example-custom-input" />}
          {...props}
        ></DatePicker>
        {!readOnly && error && !disabled && (
          <div className="qi-input_error">
            <span className="icon-wrapper">
              <svg className="icon">
                <use href={`${sprite}#info`}></use>
              </svg>
            </span>
            <p
              className="qi-input_error_message "
              dangerouslySetInnerHTML={{ __html: `${error}` }}
            ></p>
          </div>
        )}
      </div>
    </div>
  );
};
