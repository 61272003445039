import React from "react";
import * as Components from "../../../components";
import sprite from "../../../assets/icons.svg";
import { loader } from "../../../assets";
import { getLocalizedString } from "../../../shared/translation";

export const FormContainer = ({
  resourceName = "",
  customResourceName = "",
  closeForm,
  handleFormSubmit,
  idToEdit = null,
  error,
  children,
  isLoading,
  headerButton,
  size = "large",
  ifTrackingApp,
  isEditing,
  headerButtonOnclick = () => {},
}) => {
  // To scroll up to the first field which has error
  async function handleSubmit() {
    handleFormSubmit();

    // Delay for a short time to ensure handleFormSubmit() execution
    setTimeout(() => {
      // Scroll to the first field with an error
      const firstErrorField = document.querySelector(".error");

      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 500);
  }

  return (
    <Components.QIModal show onHide={closeForm} size={size} backdrop={false}>
      <Components.QIModalHeader onHide={closeForm}>
        {customResourceName ? (
          <h5>{customResourceName}</h5>
        ) : (
          <h5>
            {idToEdit
              ? `${getLocalizedString("edit", "Edit")} ${resourceName}`
              : `${getLocalizedString("create", "Add")} ${resourceName}`}
          </h5>
        )}
        {headerButton && (
          <Components.QIButton
            // icon={{
            //   src: isLoading ? <img src={loader} height="10px" width="20px" /> : `${sprite}#save`,
            // }}
            className="qi-btn secondary sm"
          >
            {headerButton}
          </Components.QIButton>
        )}
      </Components.QIModalHeader>
      <Components.QIModalBody>{children}</Components.QIModalBody>
      <Components.QIModalFooter>
        <div>
          {error?.serverError ? <p className="text-danger text-center">{error.serverError}</p> : ""}
        </div>
        <div className="flex justify-center">
          <Components.QIButton
            variant="secondary"
            className="qi-btn secondary md"
            onClick={closeForm}
          >
            {getLocalizedString("cancel", "Cancel")}
          </Components.QIButton>

          <Components.QIButton
            onClick={() => handleSubmit()}
            icon={{
              src: isLoading ? <img src={loader} height="10px" width="20px" /> : `${sprite}#save`,
            }}
            className="qi-btn primary md"
            disabled={isEditing}
          >
            {idToEdit
              ? getLocalizedString("update", "Update")
              : !ifTrackingApp
              ? getLocalizedString("save", "Save")
              : getLocalizedString("generate", "Generate")}
          </Components.QIButton>
        </div>
      </Components.QIModalFooter>
    </Components.QIModal>
  );
};
