import { useState, useCallback } from "react";
import { useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes";

export const Container = ({ isItemsDragging, children, handleDragChange }) => {
  const moveBox = useCallback((id, left, top) => {
    handleDragChange(id, { top, left });
  });

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.WINDOW,
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        moveBox(item.id, left, top);
        return undefined;
      },
    }),
    [moveBox]
  );

  return (
    <div className={`doppable-container ${isItemsDragging ? "draggable-only" : ""}`} ref={drop}>
      {children}
    </div>
  );
};
