import { set } from "date-fns";
import React, { useRef, useState } from "react";
import { useOutsideAlerter } from "../../hooks";

export const QIDropdown = ({ toggleComponent, children, className = "", onHide = () => {} }) => {
  const [show, setShow] = useState(false);
  const menuRef = useRef(null);

  useOutsideAlerter(menuRef, () => {
    onHide();
    setShow(false);
  });

  const handleSwitch = () => {
    if (show) {
      onHide();
      setShow(false);
    } else {
      setShow(true);
    }
  };

  return (
    <div className={`qi-dropdown qi-form-element ${className} `} ref={menuRef}>
      <div className="qi-dropdown_toggle" onClick={handleSwitch}>
        {toggleComponent}
      </div>
      {show && (
        <div className={`qi-dropdown_menu ${show ? "visible" : "invisible"}`}>{children}</div>
      )}
    </div>
  );
};
