import React, { useState } from "react";
import { useSearchQuery, usePagination } from "../../../hooks";
import { AdminSharedHeader, AdminSharedTable, AdminSharedDeleteModal } from "../Shared/";
import { useQuery, useDelete } from "../../../hooks";
import { useGetGeolocationsQuery, useDeleteGeolocationMutation } from "../services";
import { GeolocationForm } from "./Form";

export const GeolocationContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_cont",
  });

  const queryParams = {
    page: page,
    per_page: perPage,
    order_by: "created_at",
    order_dir: "desc",
  };
  const query = useQuery();
  let filterHeader = "";
  const filters = [{ label: " geocoder:", key: "geocoder_id" }];

  filters.map((filter) => {
    if (query.get(filter.key)) {
      queryParams[filter.key] = query.get(filter.key);
      filterHeader = `${filter.label} ${query.get("name")}`;
    }
  });

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetGeolocationsQuery({
    ...queryParams,
    q,
  });

  const {
    deleteItem: deleteGeolocation,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteGeolocationMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  const createNewHandler = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  const deleteClickHandler = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const headers = [{ label: "Name", key: "name", className: "name" }];

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((datum) => datum.id === id);

    if (index > -1) {
      name = data?.data[index].name;
    }
    return name;
  };

  return (
    <>
      <article className="main-container">
        <AdminSharedHeader
          createNewHandler={createNewHandler}
          groupName="other"
          heading="Configurations"
          filterText={filterHeader && `Filtered by ${filterHeader}`}
          handleSearchKey={setSimpleSearch}
          simpleSearchPlaceHolder="Search by Name"
        />
        <AdminSharedTable
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          data={data.data}
          headers={headers}
          pagination={{ ...pagination, count: data.total_count }}
          className="geolocation-plans"
          onEdit={editClickHandler}
          onDelete={deleteClickHandler}
          auditResource="GeolocationPlan"
          auditKey="name"
        />
      </article>

      {showForm && <GeolocationForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}

      <AdminSharedDeleteModal
        show={showDeleteWarning}
        error={deleteErrorMsg}
        resourceName="geolocation plan"
        getDeleteItemName={() => getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg("");
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteGeolocation({ id: idToDelete })}
      />
    </>
  );
};
