import React, { useState } from "react";
import { MPSharedTable, MPSharedDeleteModal, MPSharedHeader, DetailsDropDownView } from "../Shared";
import { useSearchQuery, usePagination, useDelete } from "../../../hooks";
import { useGetApiKeysQuery, useDeleteApiKeyMutation } from "../services";
import { SvgIcon } from "../../Shared";
import { ApiKeyForm } from "./Form";
import {getLocalizedString} from "../../../shared/translation";

export const ApiListContainer = () => {
  const [idToDelete, setIdToDelete] = useState(null);
  const [idToEdit, setIdToEdit] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, q } = useSearchQuery({
    page,
    setPage,
    // simpleSearchKey: "name_cont",
  });

  const queryParams = {
    page: page,
    per_page: perPage,
    order_by: "created_at",
    order_dir: "desc",
  };

  const {
    data = {
      total_count: 0,
      data: [],
    },
    error,
    isFetching,
    isSuccess,
  } = useGetApiKeysQuery({
    ...queryParams,
    q,
  });

  const headers = [
    {
      label: getLocalizedString("name", "Name"),
      key: "name",
      className: "name",
    },
    {
      label: getLocalizedString("description", "Description"),
      key: "note",
      className: "note",
    },
    {
      label: getLocalizedString("role", "Role"),
      key: "client_app_role_id",
      className: "client-app-role",
    },
    {
      label: getLocalizedString("api_key", "API Key"),
      type: "component",
      className: "api-key more",
      component: ({ data }) => {
        return <DetailsDropDownView data={data?.api_key_string} />;
      },
    },
    {
      label: getLocalizedString("active", "Active"),
      nestedValue: true,
      getNestedValue: ({ is_enabled }) => (
        <>
          {is_enabled ? (
            <SvgIcon name="tick" wrapperClass="status-icon active" svgClass="=icon-tick" />
          ) : (
            <SvgIcon name="cross" wrapperClass="status-icon inactive" svgClass="=icon-cross" />
          )}
        </>
      ),
      className: "enabled",
    },
    {
      label: getLocalizedString("added_on", "Added On"),
      key: "created_at",
      className: "date",
    },
  ];

  const onEdit = (data) => {
    setShowForm(true);
    setIdToEdit(data.id);
  };

  const onDelete = (data) => {
    setShowDeleteWarning(true);
    setIdToDelete(data.id);
  };

  const onCreate = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const {
    deleteItem: deleteApiKey,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteApiKeyMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data.findIndex((item) => item.id === id);

    if (index >= 0) {
      name = data.data[index].name;
    }
    return name;
  };

  return (
    <>
      <article className="main-container">
        <MPSharedHeader
          groupName="client_config"
          resourceName="Api_key"
          createNewHandler={onCreate}
          heading={getLocalizedString("settings", "Settings")}
          skipCanCreate={true}
          // handleSearchKey={setSimpleSearch}
          // simpleSearchPlaceholder="Search by name"
        />

        <MPSharedTable
          resourceName="Api_key"
          data={data?.data || []}
          error={error}
          isLoading={isFetching}
          isSuccess={isSuccess}
          headers={headers}
          onDelete={onDelete}
          onEdit={onEdit}
          pagination={{ ...pagination, count: data.total_count }}
          skipCanDelete={true}
          skipCanEdit={true}
          skipClientId={true}
          className="api-key"
          auditKey=""
        />
      </article>

      {showForm && <ApiKeyForm onClose={() => setShowForm(false)} idToEdit={idToEdit} />}

      <MPSharedDeleteModal
        show={showDeleteWarning}
        entityName={getLocalizedString("api_key", "API key")}
        error={deleteErrorMsg}
        itemName={getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg("");
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteApiKey({ id: idToDelete })}
      />
    </>
  );
};
