import { useEffect, useState } from "react";
import { loader } from "../../../assets";
import { useCreateAuthMutation } from "../service";
import { FMDPStorage } from "../../../shared/helper";
import { QIButton } from "../../../components";

export const ClientLogin = ({ username, callbackUrl, token, clientName, appId }) => {
  const [tryAgain, setTryAgain] = useState(false);
  const [login, { isSuccess: loginSuccess, data, error: loginError }] = useCreateAuthMutation({
    fixedCacheKey: "shared-sso-data",
  });

  !callbackUrl && sessionStorage.setItem("fmdp-access-token", token);

  const queryParams = callbackUrl
    ? {
        username: username,
        callback_url: callbackUrl,
        token: token,
        client_name: clientName,
      }
    : {};

  //Call login Api when received the queryparams
  useEffect(() => {
    login(queryParams);
  }, []);

  //Call login Api when clicked on Try Again
  useEffect(() => {
    login(queryParams);
  }, [tryAgain]);

  // For successful login, set the selected app id
  useEffect(() => {
    if (loginSuccess) {
      if (callbackUrl) {
        FMDPStorage.set("selected-app-id", data?.selected_app_id);
      } else {
        FMDPStorage.set("selected-app-id", appId);
      }
      FMDPStorage.set("live-view", true);
    }
  }, [loginSuccess]);

  return (
    <main className="login-layout authentication">
      <div className="login-form-container">
        {!loginSuccess && !loginError && (
          <div>
            <h1 className="page-title">Authenticating...</h1>

            <img className="happenings" src={loader} />
          </div>
        )}

        {loginError && (
          <p className="login-form-container_error text-red-500 text-sm mb-4">
            Authentication Failed!
            <QIButton
              onClick={() => setTryAgain((prevState) => !prevState)}
              className="qi-btn primary sm "
            >
              Try Again
            </QIButton>
          </p>
        )}
      </div>
    </main>
  );
};
