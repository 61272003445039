import * as Yup from "yup";

export const reportFormValidation = Yup.object({
  name: Yup.string().required("Required"),
  report_sub_type: Yup.mixed().required("Required"),
  metadata: Yup.object().shape({
    selection_criteria: Yup.string().required("Selection Criteria is Required"),
    asset_ids: Yup.array()
      .of(Yup.string().required("Asset ID is Required"))
      .min(1, "At least one Asset ID is required"),
    asset_type: Yup.string().required("Asset Type is Required"),
  }),
  report_configuration_id: Yup.string().when("report_type", {
    is: (report_type) => report_type === "trackpoint",
    then: Yup.string().required("Report Configuration is Required"),
  }),
  summary_interval: Yup.mixed().when("report_sub_type", {
    is: (report_sub_type) => report_sub_type === "summary",
    then: Yup.mixed().required("Required"),
  }),
  event_type: Yup.mixed().when("report_type", {
    is: "event",
    then: Yup.mixed().required("Required"),
    otherwise: Yup.mixed(),
  }),
});
