import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { useForm, useDropDownSearch } from "../../../hooks";
import { mergeObjects } from "../../../shared/helper";
import {
  useUpdateDModelMutation,
  useGetDModelQuery,
  useGetEventConfigurationsQuery,
  useGetVariableMappingsQuery,
} from "../services";
import { FormContainer } from "../Shared/FormContainer";
import { getLocalizedString } from "../../../shared/translation";

export const DModelForm = ({ idToEdit = null, deviceManufacturer, onClose }) => {
  const [initialValues, setInitialValues] = useState({
    trip_event_configuration_id: "",
    variable_mapping_id: "",
  });

  const formik = useFormik({
    initialValues,
    // validationSchema: ,
    enableReinitialize: true,
    onSubmit: (value) => handleFormSubmit(value),
  });

  const queryParamsForTrips = {
    q: JSON.stringify({ event_type_id_eq: 2 }),
  };

  //Get trip event configurations list
  const { data: tripEventsList } = useGetEventConfigurationsQuery({
    q: encodeURIComponent(queryParamsForTrips.q),
    per_page: 600,
  });

  // Get the variable mapping list using Dropdown Search
  const {
    data: variableMappings,
    setSearchKey: setVariableMappingSearch,
    isSuccess: isVariableMappingSuccess,
  } = useDropDownSearch({
    useData: useGetVariableMappingsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.variable_mapping_id,
    additionalParams: { device_model_id: idToEdit },
  });

  const { create: createDModel, update: updateDModel } = useForm({
    updateMutation: useUpdateDModelMutation,
    createMutation: useUpdateDModelMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  // Fetch the data if edit form
  const { data } = useGetDModelQuery({ id: idToEdit }, { skip: !idToEdit });

  // Set the values for edit form
  useEffect(() => {
    if (idToEdit && data) {
      setInitialValues(mergeObjects(formik.initialValues, data));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    updateDModel({ id: idToEdit, formData });
  };

  return (
    <FormContainer
      customResourceName={`
       ${getLocalizedString(
         "set_default_configurations_for",
         "Set Default Configurations For"
       )} ${deviceManufacturer} ${data?.name}
      `}
      closeForm={() => onClose(false)}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
    >
      <Components.QICustomSelect
        label={getLocalizedString("trip_configuration", "Trip Configuration")}
        value={formik.values.trip_event_configuration_id}
        onChange={(value) => {
          formik.setFieldValue("trip_event_configuration_id", value);
        }}
        error={
          formik.touched.trip_event_configuration_id && formik.errors.trip_event_configuration_id
        }
        labelClassName="trip-configuration"
      >
        <li value="">{getLocalizedString("select", "Select")}</li>
        {tripEventsList?.data?.map((model, index) => (
          <li value={model.id} key={index}>
            {model.name}
          </li>
        ))}
      </Components.QICustomSelect>
      <Components.QICustomSelect
        label={getLocalizedString("variable_mapping", "Variable Mapping")}
        value={formik.values.variable_mapping_id}
        onChange={(value) => {
          formik.setFieldValue("variable_mapping_id", value);
        }}
        error={formik.touched.variable_mapping_id && formik.errors.variable_mapping_id}
        labelClassName="variable-mapping"
      >
        <li value="">{getLocalizedString("select", "Select")}</li>
        {variableMappings?.data?.map((model, index) => (
          <li value={model.id} key={index}>
            {model.name}
          </li>
        ))}
      </Components.QICustomSelect>
    </FormContainer>
  );
};
