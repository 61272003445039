export class FMDPWebSocket {
  constructor(userId) {
    this.ws = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}?user_id=${userId}`);
    this.isConnected = false; //Keep the track of connection status
    this.channelCallbacks = {}; // keeps the registered Channels and callback as key,value pair
    /**
     * This queue will make websocket in sync.
     * User can try subscription even before connection stablized
     */
    this.queue = [];

    this.ws.onmessage = ({ data }) => {
      const parsedData = JSON.parse(data);

      if (parsedData.identifier) {
        let identifier = JSON.parse(parsedData.identifier);

        /**
         * if channel and callback is registered pass call the callback function and pass the data recieved to callback.
         */
        if (identifier && identifier.channel && this.channelCallbacks[identifier.channel]) {
          this.channelCallbacks[identifier.channel](parsedData);
        }
      }
    };

    this.ws.onopen = () => {
      this.isConnected = true;
      /**
       * As connected check if and subscription is pending to subscribe
       * Subscribe all pending subscriptions
       */
      while (this.queue.length > 0) {
        this.ws.send(this.queue.pop());
      }
    };

    this.ws.onclose = () => {
      this.isConnected = false;
    };
  }

  subscribe(channel, callback) {
    const identifier = `{"channel": "${channel}"}`;
    const msg = JSON.stringify({
      command: "subscribe",
      identifier,
    });

    // If websocket in connected directly send the message to ws else add the subscription to queue
    if (this.isConnected) {
      this.ws.send(msg);
    } else {
      this.queue.push(msg);
    }

    this.channelCallbacks[channel] = callback;
  }
}
