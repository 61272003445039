import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { QIInput, QIButton } from "../../../components";
import { useForgotPasswordMutation } from "../service";
import { forgetPasswordSchema } from "../validations";
import { ErrorModal } from "../Shared/ErrorModal";

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    email: "",
  });

  const [errorModal, setShowErrorModal] = useState({
    msg: "",
    show: false,
    type: "",
  });

  const formik = useFormik({
    initialValues,
    validationSchema: forgetPasswordSchema,
    onSubmit: (values) => handleForgetPassword(values),
  });

  const [create, { isSuccess, isError }] = useForgotPasswordMutation();

  const handleForgetPassword = (formData) => {
    create(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      setShowErrorModal({
        msg: "We have sent you reset link. Please check your mail.",
        show: true,
        type: "success",
      });
    }
    if (isError) {
      setShowErrorModal({
        msg: "There is no account with given e-mail",
        show: true,
        type: "warning",
      });
    }
  }, [isSuccess, isError]);

  const closeWarningModal = () => {
    if (errorModal.type === "success") {
      navigate("/");
    }
    setShowErrorModal({
      msg: "",
      show: false,
      type: "",
    });
  };

  return (
    <div className="login-layout">
      <div className="login-form-container">
        <h1>Find your account</h1>
        <QIInput
          label="Email"
          type="text"
          {...formik.getFieldProps("email")}
          error={formik.touched.email && formik.errors.email}
        />
        <QIButton
          className="w-100 btn sm primary text-uppercase mx-auto"
          onClick={formik.handleSubmit}
        >
          send
        </QIButton>

        <p className="text-center">
          <a href="/login">Back to login</a>
        </p>
      </div>
      {errorModal.show && <ErrorModal msg={errorModal.msg} onClose={closeWarningModal} />}
    </div>
  );
};
