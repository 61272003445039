import { QIPagination, QISelect } from "../../../components";

export const PaginationHeader = ({ pagination, parentName }) => {
  let fromCount = Math.max(pagination.page - 1, 0) * pagination.perPage;
  let toCount = Math.min(pagination.page * pagination.perPage, pagination.count);

  return (
    <>
      {pagination.count > 0 && (
        <p className="showing-out-of text-sm">
          Showing {fromCount + 1} - {toCount}/{pagination.count}
          {parentName && (
            <>
              &nbsp;of&nbsp;
              <strong>{`${parentName}`}</strong>
            </>
          )}
        </p>
      )}
    </>
  );
};

export const PaginationFooter = ({ pagination }) => {
  let pageCount = Math.ceil(pagination.count / (pagination.perPage || 10));

  return (
    <nav className="pagination-nav">
      {pagination.count > 20 && (
        <>
          <QISelect
            label="Per Page"
            value={pagination.perPage}
            onChange={(e) => {
              pagination.onPerPageChange(e.target.value);
              pagination.onPageChange(1);
            }}
            className="page-count-select"
          >
            {[20, 50, 100].map((count) => (
              <option value={count} key={count}>
                {count}
              </option>
            ))}
          </QISelect>
          <QIPagination
            pageCount={pageCount}
            onPageChange={pagination.onPageChange}
            activePage={pagination.page}
          />
        </>
      )}
    </nav>
  );
};
