import { useState, useEffect } from "react";
import TripsHeader from "./TripsHeader";
import TripsListWrapper from "./TripsListWrapper";
import { useGetTripsTrackQuery } from "../../services";
import moment from "moment";
import { NoData } from "../../../Shared";
import { QIErrorBoundary, QISpinner } from "../../../../components";

const Activities = ({ deviceId, selectedRange, clientId, selectedVehiclePlate, type }) => {
  const per_page = 200;
  const [page, setPage] = useState(0);
  const [tracksData, setTracksData] = useState([]);
  useEffect(() => {
    setPage(0);
    setTracksData([]);
  }, [selectedRange, deviceId]);

  const {
    data: response,
    error,
    isFetching,
    isSuccess,
  } = useGetTripsTrackQuery(
    deviceId && {
      deviceId: deviceId,
      startTime: moment(selectedRange.startDate).valueOf(),
      endTime: moment(selectedRange.endDate).valueOf(),
      clientId: clientId,
      page: page,
      per_page: per_page,
      plate_number: [selectedVehiclePlate],
      source_id: [deviceId],
      type: type,
      search: false,
    }
  );

  useEffect(() => {
    if (response && deviceId) {
      try {
        if (response?.activities.length) {
          setTracksData((prevdata) => [...prevdata, ...response?.activities]);
          setPage((prevPage) => prevPage + 1);
        }
      } catch (e) {}
    }
  }, [response, deviceId, selectedRange]);

  const message = {
    header: error?.data?.message,
  };

  return (
    <div className="trips">
      <TripsHeader />
      {error && <QIErrorBoundary message={message} />}
      {isFetching && (
        <div className="no-data">
          <QISpinner size="50px" />
        </div>
      )}
      {!isFetching && isSuccess && tracksData?.length ? (
        <TripsListWrapper tracksData={tracksData || []} />
      ) : (
        isSuccess && !error && !isFetching && <NoData />
      )}
    </div>
  );
};

export default Activities;
