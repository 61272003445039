import { useSelector, useDispatch } from "react-redux";
import { addToLayout } from "../../../reduxStore/features";

export const MinimisedTab = ({ layoutName }) => {
  const dispatch = useDispatch();
  const options = useSelector((state) => state.common.layouts[`${layoutName}Minimised`]);

  return (
    <div className="tab">
      <div className="actions">
        {Object.keys(options).map((key, index) => (
          <div
            key={index}
            className="tab-item"
            onClick={() => dispatch(addToLayout({ key, layoutName }))}
          >
            {options[key].title}
          </div>
        ))}
      </div>
    </div>
  );
};
