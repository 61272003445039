import { useEffect, useState } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";

export const useWebSocket = (source_id, type) => {
  const [connection, setConnection] = useState(null);
  const [realTimedata, setRealTimeData] = useState({});
  const [sourceId, setSourceId] = useState(null);

  //Socket Connection
  useEffect(() => {
    const newSocketConnection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_PUBLISHER_URL)
      .build();
    setConnection(newSocketConnection);
    setSourceId((p) => source_id);
  }, [setSourceId, source_id]);
  //Setting Real Time Data
  useEffect(() => {
    let group = "All";
    let imeis = sourceId;
    if (connection) {
      async function start() {
        try {
          if (imeis === source_id && imeis.length) {
            await connection.start();
            await connection.invoke("UnSubscribeByClientId", imeis, group);
            await connection.invoke("SubscribeByClientId", imeis, group);
            connection.serverTimeoutInMilliseconds = 5 * 60 * 1000;
            connection.on(type, function (allData) {
              const { Data } = JSON.parse(allData);
              setRealTimeData((d) => ({ ...d, [Data?.source_id]: Data }));
            });
          }
        } catch (e) {
          //console.log(e);
        }
      }
      start();
      //Restart Connection if State is not Connected
      connection["_connectionState"] !== "Connected" &&
        connection.onclose(async () => {
          await start();
        });
    }
    return () => {
      //Cleanup after url change
      try {
        connection.stop();
        //connection && connection.invoke("UnSubscribe", imeis, group);
      } catch (e) {}
    };
  }, [connection, sourceId, source_id, type]);
  //End

  return { realTimedata };
};
