import React, { useState } from "react";
import { useSearchQuery, usePagination } from "../../../hooks";
import { AdminSharedHeader, AdminSharedTable, AdminSharedDeleteModal } from "../Shared/";
import { useQuery, useDelete } from "../../../hooks";
import { useGetGeoCodersQuery, useDeleteGeoCoderMutation } from "../services";
import { GeocodersForm } from "./Form";
import { Link } from "react-router-dom";

export const GeocodersContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_description_cont",
  });

  const queryParams = {
    page: page,
    per_page: perPage,
    order_by: "created_at",
    order_dir: "desc",
  };

  let query = useQuery();
  let filterLabel = "";
  const filters = [{ label: "Client", key: "client_id" }];

  filters.map((filter) => {
    if (query.get(filter.key)) {
      queryParams[filter.key] = query.get(filter.key);
      filterLabel = `${filter.label} : ${query.get("name")}`;
    }
  });

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetGeoCodersQuery({
    ...queryParams,
    q,
  });

  const {
    deleteItem: deleteGeoCoder,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteGeoCoderMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  const headers = [
    { label: "Name", key: "name", className: "name" },
    { label: "Description", key: "description", className: "description" },
    { label: "Alias Name", key: "client_alias_name", className: "client-alias-name" },
    {
      label: "Geolocation Plans",
      nestedValue: true,
      className: "devices text-center",
      getNestedValue: ({ id, geolocation_plans_count, name }) => {
        return (
          <>
            {geolocation_plans_count > 0 ? (
              <Link
                to={`/superadmin/others/geolocation_plan?geocoder_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {geolocation_plans_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
    },
    { label: "Created on", key: "created_at", className: "date" },
  ];

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((datum) => datum.id === id);

    if (index > -1) {
      name = data?.data[index].name;
    }
    return name;
  };

  return (
    <>
      <article className="main-container">
        <AdminSharedHeader
          groupName="other"
          heading="Configurations"
          handleSearchKey={(value) => setSimpleSearch(value)}
          simpleSearchPlaceHolder="Search by Name, Description"
          filterText={filterLabel && `Filtered by ${filterLabel}`}
        />
        <AdminSharedTable
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          data={data.data}
          headers={headers}
          pagination={{ ...pagination, count: data.total_count }}
          className="geocoders"
        />
      </article>

      {showForm && <GeocodersForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}

      <AdminSharedDeleteModal
        show={showDeleteWarning}
        error={deleteErrorMsg}
        resourceName="geocoder"
        getDeleteItemName={() => getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg("");
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteGeoCoder({ id: idToDelete })}
      />
    </>
  );
};
