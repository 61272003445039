import React, { useEffect, useState } from "react";
import QIMap from "@qinvent/map";

export const QIMapLoader = ({
  containerId,
  className = "",
  countryConfig = {
    center: [0, 0],
    minZoom: 1,
    zoom: 1,
    maxZoom: 25,
  },
  dataFormat = {
    lat: "lat",
    lon: "lng",
    time: "recorded_on",
  },
  setMapRef = () => {},
  children,
  mapExpand,
  dockAlign,
  selectedRowId,
  maximizeMinimize,
}) => {
  const [map, setMap] = useState(null);

  useEffect(() => {
    if ((maximizeMinimize || dockAlign || mapExpand || selectedRowId || maximizeMinimize) && map) {
      map.getMapReference().updateSize();
    }
  }, [mapExpand, map, dockAlign, selectedRowId, maximizeMinimize]);

  useEffect(() => {
    if (countryConfig) {
      const qiMap = new QIMap({
        containerId,
        baseLayer: ["OSM"],
        dataFormat,
        currentCountry: {
          center: [0, 0],
          minZoom: 1,
          zoom: 1,
          maxZoom: 19,
        },
        defaultBaseLayer: "OSM",
      });
      qiMap.createMap();

      /* qiMap.removeLayer = (layerName) => {
        try {
          qiMap._hideOverlay();
        } finally {
          qiMap.removeLayerSource(layerName);
          qiMap.resetZoomLevel();
        }
      }; */

      qiMap.removeLayerSource = (layerName) => {
        let layer_arr = Object.keys(qiMap.layer);
        let strVal = layerName.toString();
        let eleIndex = layer_arr.indexOf(strVal);
        let l = qiMap.layer[layer_arr[eleIndex]];
        qiMap.resetZoomLevel();
        l?.getSource()?.clear();

        /* try {
            qiMap._hideOverlay();
          } catch (e) {
            console.log(e);
          } finally {
            if (layer) {
              layer.getSource().clear();
            }
          } */
      };

      qiMap.resetZoomLevel = () => {
        const initialSize = qiMap.getMapReference().getSize();
        const map = qiMap.getMapReference();
        var view = map.getView();
        view.setZoom(countryConfig.zoom);
        view.centerOn(countryConfig.center, initialSize, [500, 400]);
      };

      qiMap.showTooltip = (coordinate) => {
        try {
          qiMap._hideOverlay();
        } finally {
          const evt = {};
          evt.type = "singleclick";
          evt.coordinate = qiMap.getGeometry(coordinate);
          evt.pixel = qiMap.getMapReference().getPixelFromCoordinate(evt.coordinate);
          qiMap._showTooltip(evt);
        }
      };
      setMap(qiMap);
      setMapRef(qiMap);
    }
  }, []);

  return (
    <main className="relative">
      <aside className="collapsable-side-panel">{children}</aside>

      <article className={`qiMap ${className}`}>
        <div id={containerId}> </div>
      </article>
    </main>
  );
};
