import { useEffect, useState } from "react";
import { useGetRawDataQuery } from "../../services";
import moment from "moment";
import { MPSharedTable } from "../../../ManagementPortal/Shared";
import Scrollbars from "react-custom-scrollbars";
import { toast } from "react-toastify";
import { getLocalizedString } from "../../../../shared/translation";
import "./style.scss";

export const RawData = ({ deviceId, selectedRange, decode, order }) => {
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [clipBoard, setClipBoard] = useState(getLocalizedString("copy", "Copy"));

  const pagination = { page, perPage, onPageChange: setPage, onPerPageChange: setPerPage };

  //Fetch Raw Data
  const {
    data: response,
    isFetching,
    isSuccess,
    error,
  } = useGetRawDataQuery(
    {
      deviceId,
      start_time: moment(selectedRange.startDate).valueOf(),
      end_time: moment(selectedRange.endDate).valueOf(),
      perPage: perPage,
      page: page - 1,
      decode: decode,
      sort: `server_time:${order ? "ASC" : "DESC"}`,
    },
    { enabled: true }
  );

  //store response
  useEffect(() => {
    if (response) {
      setData([]);
      response?.map((data) => {
        setData(data);
      });
    }
  }, [response, deviceId]);

  const handleCopy = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success("Copied");
      })
      .catch((err) => {
        toast.error("Failed to Copy");
        console.error("Failed to copy text: ", err);
      });
  };

  const headers = [
    {
      label: getLocalizedString("server_time", "Server Time"),
      nestedValue: true,
      getNestedValue: ({ server_time }) => {
        return moment(server_time).format(" DD/MM/YYYY - hh:mm:ss A");
      },
      className: "server-time",
    },

    {
      label: getLocalizedString("packet_id", "Packet Id"),
      id: 2,
      key: "packet_id",
      className: "packet-id",
    },
    {
      label: getLocalizedString("data", "Data"),
      id: 3,
      nestedValue: true,
      getNestedValue: ({ data }) => {
        //const value = decode ? data : getLocalizedString("binary_data", "Binary Data");
        return (
          <code>
            <pre className="code-format">
              <div onClick={() => handleCopy(data)} className="clip">
                {clipBoard}
              </div>
              <Scrollbars>
                <div className="content">{data}</div>
              </Scrollbars>
            </pre>
          </code>
        );
      },
      className: "data",
    },
  ];

  return (
    <>
      <MPSharedTable
        resourceName="Raw Data"
        isLoading={isFetching}
        error={error}
        isSuccess={isSuccess}
        headers={headers}
        data={data?.raw_data || []}
        pagination={{ ...pagination, count: data?.total_count }}
        className="raw-data"
        auditKey=""
        skipCanDelete={true}
        skipClientId
      />
    </>
  );
};
