import React, { useState, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { mergeObjects } from "../../../shared/helper";
import {
  useCreateUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useGetClientsQuery,
  useGetAppsQuery,
} from "../services";
import { userFormSchema, userEditFormSchema } from "../validations";
import { FormContainer } from "../Shared/FormContainer";
import { user400 } from "../../../assets";
import { useForm, useStaticDropDownSearch, useDropDownSearch } from "../../../hooks";
import { SvgIcon } from "../../Shared";
import { parseServerError, FMDPStorage } from "../../../shared/helper";
import Countrycode from "../../../shared/countryCode.json";
import { ConfirmationModal } from "../Shared/ConfirmationModal";

const ClientRoles = ({ clientId, roles, onChange, clients, reset, idToEdit }) => {
  const { data: apps, isSuccess: isAppsSuccess } = useGetAppsQuery(
    {
      client_id: clientId,
      include: "roles",
    },
    { skip: !clientId }
  );

  const getClientName = () => {
    let client = clients.find((client) => client.id == clientId);

    if (client) {
      return client.name;
    }
    return "";
  };

  const adminRole = useMemo(() => {
    let mPortal = apps?.data?.find((app) => app.name == "Management Portal");

    if (mPortal) {
      let adminRole = mPortal.client_app_roles.find((role) => role.name == "Admin");

      if (adminRole) {
        onChange(adminRole, true);
        return adminRole;
      }
      return null;
    }

    return null;
  }, [apps]);

  useEffect(() => {
    return () => {
      reset(roles);
    };
  }, []);

  const nonAdminRolesCount = useMemo(() => {
    let count = 0;

    apps?.data?.forEach((app) => {
      app.client_app_roles.forEach((role) => {
        if (!(role.name == "Admin" && app.name == "Management Portal") && roles.includes(role.id)) {
          count++;
        }
      });
    });

    return count;
  }, [apps]);

  return (
    <div className="border p-2 w-1/2">
      <strong>
        {getClientName()} {"->"} Management Portal
      </strong>
      {adminRole && (
        <div>
          {adminRole && (
            <div className="mx-2 flex">
              <input
                type="checkbox"
                className="mx-2"
                onChange={(e) => onChange(adminRole, e.target.checked)}
                defaultChecked
                disabled
              />
              {adminRole.name}
            </div>
          )}
        </div>
      )}
      {idToEdit && (
        <div className="mx-2 bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
          + {nonAdminRolesCount} Roles (Added by client)
        </div>
      )}
    </div>
  );
};

export const UserForm = ({ idToEdit = null, closeForm }) => {
  const dropmenuQueryParams = { per_page: 1000, order_by: "name" };
  const [initialValues, setInitialValues] = useState({
    client_ids: [],
    app_ids: [],
    roles: [],
    first_name: "",
    last_name: "",
    email: "",
    superadmin: false,
    password: "",
    is_enabled: true,
    picture: {
      image_extension: "",
      image_data: "",
    },
    country_code: "",
    phone_no: "",
  });

  // To handle confirmation modal
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // To handle skipping of the confirmatiion modal if no client admin is chosen
  const [skipConfirmationModal, setSkipConfirmationModal] = useState(false);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: idToEdit ? userEditFormSchema : userFormSchema,
    onSuccess: () => {
      const currentUser = FMDPStorage.get("current-user");
      if (currentUser.id == idToEdit) {
        window.location.reload();
      }
    },
    onSubmit: (values) => handleFormSubmit(values),
  });

  // Get the clients list using Dropdown Search
  const {
    data: clients,
    setSearchKey: setClientSearch,
    isSuccess: clientsSuccess,
  } = useDropDownSearch({
    useData: useGetClientsQuery,
    simpleSearchKey: "name_or_email_cont",
    additionalParams: { ...dropmenuQueryParams },
    selectedIds: formik.values.client_ids,
  });

  // Get the country code list using Dropdown Search for static data
  const { data: countryCode, setSearchKey: setCountryCodeSearchKey } = useStaticDropDownSearch({
    dataSet: Countrycode,
    selectedIds: formik.values.country_code,
  });

  const {
    create: createUser,
    update: updateUser,
    error: updateError,
  } = useForm({
    createMutation: useCreateUserMutation,
    updateMutation: useUpdateUserMutation,
    closeForm,
    onSuccess: () => {
      const currentUser = FMDPStorage.get("current-user");
      if (currentUser.id == idToEdit) {
        window.location.reload();
      }
    },
    setError: formik.setErrors,
  });

  const { data } = useGetUserQuery({ id: idToEdit }, { skip: !idToEdit });

  // Set form data in case of edit
  useEffect(() => {
    if (idToEdit && data) {
      setInitialValues(mergeObjects(initialValues, data));
    }
  }, [data]);

  useEffect(() => {
    if (updateError) {
      formik.setErrors(parseServerError(updateError));
    }
  }, [updateError]);

  const handleRoleSelection = (role, value) => {
    if (value === false) {
      // Remove role.id from formik.values.roles
      const updatedRoles = formik.values.roles.filter((id) => id !== role.id);
      formik.setFieldValue("roles", updatedRoles);
    } else {
      // If role.id is not present in formik.values.roles then add role.id to formik.values.roles
      if (!formik.values.roles.includes(role.id)) {
        formik.setFieldValue("roles", [...formik.values.roles, role.id]);
      }
    }
  };

  const handleFormSubmit = (formData) => {
    if (skipConfirmationModal || showConfirmationModal) {
      let finalFormData = {
        ...formData,
      };
      if (!finalFormData.picture || !finalFormData.picture.image_extension) {
        finalFormData.picture = { image_extension: "", image_data: "", _destroy: true };
      }

      if (idToEdit) {
        delete finalFormData.password;

        if (!finalFormData.roles || finalFormData.roles.length == 0) {
          delete finalFormData.client_id;
        }
        updateUser({ formData: finalFormData, id: idToEdit });
      } else {
        createUser({ formData: finalFormData });
      }
    } else {
      setShowConfirmationModal(true);
    }
  };

  const getConfirmationMessage = () => {
    return (
      <div className="confirmation-message">
        <span>
          <SvgIcon name="alert" className="confirmation-message_icon" />
        </span>
        <p>This user is being granted the </p>
        <p>
          <strong>Client Admin</strong> access to each of the selected client(s).
        </p>
        {!idToEdit && (
          <p>Please use the respective Client Management Portal to add non-admin users.</p>
        )}
        <p>Are you sure?</p>
      </div>
    );
  };

  useEffect(() => {
    if (formik?.values?.roles?.length === 0) {
      setSkipConfirmationModal(true);
    } else {
      setSkipConfirmationModal(false);
    }
  }, [formik.values.roles]);

  return (
    <FormContainer
      resourceName="User"
      closeForm={closeForm}
      handleFormSubmit={formik.handleSubmit}
      idToEdit={idToEdit}
    >
      <Components.QIPhotoUploader
        label="Upload Photo"
        src={formik.values.picture}
        onChange={(value) => formik.setFieldValue("picture", value)}
        placeHolderImg={user400}
        photoGuide={`
          <h4 className="font-semibold">Image Upload guide - </h4>
          <div className="ml-2">
            <p>Max size 40kb</p>
            <p>File format .jpg/ .jpeg/ .png/ .gif</p>
            <p>Preferred image aspect ratio 1:1</p>
          </div>
        `}
        error={formik.touched.picture && formik.errors.picture}
      />
      <Components.QIInput
        label="First Name"
        placeholder="First Name"
        {...formik.getFieldProps("first_name")}
        error={formik.touched.first_name && formik.errors.first_name}
      />
      <Components.QIInput
        label="Last Name"
        placeholder="Last Name"
        {...formik.getFieldProps("last_name")}
        error={formik.touched.last_name && formik.errors.last_name}
      />
      <div className="flex justify-between country-code-and-number">
        <Components.QICustomSelect
          label="Country Code"
          className="country-code-and-number_item1"
          {...formik.getFieldProps("country_code")}
          onChange={(value) => formik.setFieldValue("country_code", value)}
          onSearch={setCountryCodeSearchKey}
          error={formik.touched.country_code && formik.errors.country_code}
          labelClassName="country-code"
        >
          <li value="">Select...</li>
          {countryCode?.map((code, index) => (
            <li key={index} value={code.dial_code} title={`${code.dial_code} (${code.name})`}>
              {code.dial_code} ({code.name})
            </li>
          ))}
        </Components.QICustomSelect>

        <Components.QIInput
          label="Phone No."
          placeholder="Phone No."
          className="country-code-and-number_item2"
          customLabelClass="phone-number"
          customWrapperClass="phone-number-wrapper"
          {...formik.getFieldProps("phone_no")}
          error={formik.touched.phone_no && formik.errors.phone_no}
        />
      </div>
      <Components.QIInput
        label="Email"
        placeholder="user@email.com"
        {...formik.getFieldProps("email")}
        error={formik.touched.email && formik.errors.email}
        disabled={idToEdit}
      />
      {!idToEdit && (
        <Components.QIInput
          label="Password"
          placeholder="example@1234"
          {...formik.getFieldProps("password")}
          error={formik.touched.password && formik.errors.password}
        />
      )}
      <Components.QISwitch
        className="active-status"
        label="Active"
        value={formik.values.is_enabled}
        readOnly={JSON.parse(localStorage.getItem("current-user"))?.email == formik?.values?.email}
        disabled={JSON.parse(localStorage.getItem("current-user"))?.email == formik?.values?.email}
        onChange={() => {
          formik.setFieldValue("is_enabled", !formik.values.is_enabled);
        }}
      />
      <Components.QIMultiSelectDropDown
        label="Clients"
        selected={formik.values.client_ids}
        data={clients?.data || []}
        onChange={(value) => {
          formik.setFieldValue("client_ids", value);
          setClientSearch("");
        }}
        onSearch={(value) => setClientSearch(value)}
        error={formik.touched.client_ids && formik.errors.client_ids}
      />
      <div className={`qi-input ${formik.errors.roles && "error"}`}>
        <label className="qi-input_label">Clients & Roles</label>
        <div className="client-roles">
          {formik.values.client_ids.map((clientId) => (
            <ClientRoles
              clientId={clientId}
              roles={formik.values.roles}
              onChange={handleRoleSelection}
              clients={clients?.data || []}
              key={clientId}
              reset={(value) => formik.setFieldValue("roles", value)}
              idToEdit={idToEdit}
            />
          ))}
          {formik.errors.roles && (
            <div className="qi-input_error">
              <SvgIcon name="info" />
              <p className="qi-input_error_message ">{formik.errors.roles}</p>
            </div>
          )}
        </div>
      </div>
      <Components.QISwitch
        label="Super Admin"
        value={formik.values.superadmin}
        onChange={() => formik.setFieldValue("superadmin", !formik.values.superadmin)}
      />

      {showConfirmationModal && (
        <ConfirmationModal
          onCancel={() => {
            setShowConfirmationModal(false);
          }}
          onConfirm={() => {
            formik.handleSubmit();
          }}
          show={showConfirmationModal}
          headerText="Confirmation Alert!"
        >
          {getConfirmationMessage()}
        </ConfirmationModal>
      )}
    </FormContainer>
  );
};
