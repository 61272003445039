import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { mergeObjects } from "../../../shared/helper";
import {
  useCreateVModelMutation,
  useUpdateVModelMutation,
  useGetVModelQuery,
  useGetVTypesQuery,
  useGetVClassesQuery,
  useGetVManModelVariantsQuery,
  useGetVMansQuery,
  useGetVManModelsQuery,
} from "../services";
import { FormContainer } from "../Shared";
import { vehicleModelFormShema } from "../validations";
import { useForm, useDropDownSearch } from "../../../hooks";
import { getLocalizedString } from "../../../shared/translation";

const dropmenuParams = { per_page: 1000, order_by: "name" };

export const VModelForm = ({ idToEdit = null, onClose }) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    vehicle_manufacturer_id: "",
    vehicle_manufacturer_model_id: "",
    vehicle_manufacturer_model_variant_id: "",
    vehicle_class_id: "",
    vehicle_type_id: "",
    capacity: "",
    engine_capacity: "",
    year: "",
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: vehicleModelFormShema,
    onSubmit: (value) => handleFormSubmit(value),
  });

  // Get the vehicle types list using Dropdown Search
  const { data: vTypes, setSearchKey: setVTypesSearch } = useDropDownSearch({
    useData: useGetVTypesQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_type_id,
  });

  // Get the vehicle classes list using Dropdown Search
  const { data: vClasses, setSearchKey: setVClassesSearch } = useDropDownSearch({
    useData: useGetVClassesQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_class_id,
  });

  // Get the vehicle manufacturers list using Dropdown Search
  const {
    data: vMans = { data: [] },
    setSearchKey: setVManSearch,
    isSuccess: isVmansSuccess,
  } = useDropDownSearch({
    useData: useGetVMansQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_manufacturer_id,
    additionalParams: { ...dropmenuParams },
  });

  // Get the vehicle manufacturer model list using Dropdown Search
  const {
    data: vManufacturerModels = { data: [] },
    setSearchKey: setVManModelSearch,
    isSuccess: isVManufacturerModelSuccess,
  } = useDropDownSearch({
    useData: useGetVManModelsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_manufacturer_model_id,
    additionalParams: {
      ...dropmenuParams,
      vehicle_manufacturer_id: formik.values.vehicle_manufacturer_id,
    },
  });

  // Get Vehicle Manufacturer model variants list using Dropdown Search
  const {
    data: variants = { data: [] },
    setSearchKey: setVManModelVariantsSearch,
    isSuccess: isVariantsSuccess,
  } = useDropDownSearch({
    useData: useGetVManModelVariantsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_manufacturer_model_variant_id,
    additionalParams: {
      ...dropmenuParams,
      vehicle_manufacturer_model_id: formik.values.vehicle_manufacturer_model_id,
    },
  });

  const { data } = useGetVModelQuery({ id: idToEdit }, { skip: !idToEdit });

  const { create: createVModel, update: updateVModel } = useForm({
    createMutation: useCreateVModelMutation,
    updateMutation: useUpdateVModelMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  useEffect(() => {
    if (idToEdit && data?.id) {
      setInitialValues(mergeObjects(initialValues, data));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      updateVModel({ id: idToEdit, formData });
    } else {
      createVModel({ formData });
    }
  };

  return (
    <FormContainer
      resourceName={getLocalizedString("specification", "Specification")}
      closeForm={() => onClose(false)}
      handleFormSubmit={formik.handleSubmit}
      idToEdit={idToEdit}
    >
      <Components.QICustomSelect
        label={getLocalizedString("manufacturer", "Manufacturer")}
        value={formik.values.vehicle_manufacturer_id}
        onChange={(value) => {
          formik.setValues({
            ...formik.values,
            vehicle_manufacturer_id: value,
            vehicle_manufacturer_model_id: "",
            vehicle_manufacturer_model_variant_id: "",
          });
          setVManSearch("");
        }}
        onSearch={setVManSearch}
        error={formik.touched.vehicle_manufacturer_id && formik.errors.vehicle_manufacturer_id}
        labelClassName="manufacturer"
      >
        {isVmansSuccess &&
          vMans?.data?.map((vMan) => (
            <li key={vMan.id} value={vMan.id}>
              {vMan.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QICustomSelect
        label={getLocalizedString("model", "Model")}
        value={formik.values.vehicle_manufacturer_model_id}
        onChange={(value) => {
          formik.setValues({
            ...formik.values,
            vehicle_manufacturer_model_id: value,
            vehicle_manufacturer_model_variant_id: "",
          });
          setVManModelSearch("");
        }}
        onSearch={setVManModelSearch}
        disabled={formik.values.vehicle_manufacturer_id === ""}
        error={
          formik.touched.vehicle_manufacturer_model_id &&
          formik.errors.vehicle_manufacturer_model_id
        }
        labelClassName="model"
      >
        {isVManufacturerModelSuccess &&
          vManufacturerModels.data.map((vManModel) => (
            <li key={vManModel.id} value={vManModel.id}>
              {vManModel.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QICustomSelect
        label={getLocalizedString("variant", "Variant")}
        value={formik.values.vehicle_manufacturer_model_variant_id}
        onChange={(value) => {
          formik.setFieldValue("vehicle_manufacturer_model_variant_id", value);
          setVManModelVariantsSearch("");
        }}
        onSearch={setVManModelVariantsSearch}
        disabled={formik.values.vehicle_manufacturer_model_id === ""}
        error={
          formik.touched.vehicle_manufacturer_model_variant_id &&
          formik.errors.vehicle_manufacturer_model_variant_id
        }
        labelClassName="variant"
      >
        {isVariantsSuccess &&
          variants.data.map((variant) => (
            <li key={variant.id} value={variant.id}>
              {variant.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QIInput
        label={getLocalizedString("name", "Name")}
        placeholder=""
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />
      <Components.QIInput
        label={getLocalizedString("capacity", "Capacity")}
        {...formik.getFieldProps("capacity")}
        error={formik.touched.capacity && formik.errors.capacity}
      />
      <Components.QIInput
        label={getLocalizedString("engine_capacity", "Engine Capacity")}
        {...formik.getFieldProps("engine_capacity")}
        error={formik.touched.engine_capacity && formik.errors.engine_capacity}
      />
      <Components.QIInput
        label={getLocalizedString("year", "Year")}
        {...formik.getFieldProps("year")}
        error={formik.touched.year && formik.errors.year}
      />

      <Components.QICustomSelect
        label={getLocalizedString("vehicle_type", "Vehicle Type")}
        onChange={(updatedList) => {
          formik.setFieldValue("vehicle_type_id", updatedList);
          setVTypesSearch("");
        }}
        value={formik.values.vehicle_type_id}
        onSearch={setVTypesSearch}
        labelClassName="vehicle-type"
      >
        <p className="dropdown-heading">System Default</p>
        {vTypes?.data?.map((type, index) => {
          return (
            type.client_id === null && (
              <li value={type.id} key={index}>
                {type.name}
              </li>
            )
          );
        })}
        <p className="dropdown-heading">Client Created</p>
        {vTypes?.data?.map((type, index) => {
          return (
            type.client_id && (
              <li value={type.id} key={index}>
                {type.name}
              </li>
            )
          );
        })}
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("vehicle_class", "Vehicle Class")}
        onChange={(updatedList) => {
          formik.setFieldValue("vehicle_class_id", updatedList);
          setVClassesSearch("");
        }}
        value={formik.values.vehicle_class_id}
        onSearch={setVClassesSearch}
        labelClassName="vehicle-class"
      >
        <p className="dropdown-heading">System Default</p>
        {vClasses?.data?.map((classes, index) => {
          return (
            classes.client_id === null && (
              <li value={classes.id} key={index}>
                {classes.name}
              </li>
            )
          );
        })}
        <p className="dropdown-heading">Client Created</p>
        {vClasses?.data?.map((classes, index) => {
          return (
            classes.client_id && (
              <li value={classes.id} key={index}>
                {classes.name}
              </li>
            )
          );
        })}
      </Components.QICustomSelect>
    </FormContainer>
  );
};
