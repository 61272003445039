import { FMDPStorage } from "../../../shared/helper";
import {
  getIgnitionStatus,
  getTimeGap,
  valueFormatter,
  spreadDeviceDataObject,
  displayKeyName,
  getValueByNestedKey,
} from "../utils";
import moment from "moment";
import * as dataConfigJson from "../data-config.json";
import { useSelector } from "react-redux";
import { getLocalizedString } from "../../../shared/translation";

export const HeaderDetails = ({ realTimedata, deviceId, deviceData, type }) => {
  let speed = realTimedata[deviceId]?.velocity?.speed || deviceData?.velocity?.v?.speed;
  let direction = realTimedata[deviceId]?.velocity?.direction || deviceData?.velocity?.v?.direction;
  let serverTime =
    realTimedata[deviceId]?.server_time || deviceData?.server_time?.v || deviceData?.server_time;

  const { status, activeWithin } = getTimeGap(serverTime);

  return (
    <>
      <li className="details-block_summary_list_item">
        <strong>{getLocalizedString("ignition", "Ignition")} :</strong>{" "}
        {getIgnitionStatus(realTimedata[deviceId]?.ignition || deviceData?.ignition?.v)}
      </li>
      <li className="details-block_summary_list_item">
        <strong>{getLocalizedString("speed", "Speed")} :</strong>{" "}
        {speed == 0 || speed ? `${(speed * 3.6).toFixed(1)} km/h` : "Not Available"}
      </li>
      <li className="details-block_summary_list_item">
        <strong>{getLocalizedString("direction", "Direction")} :</strong>{" "}
        {direction == 0 || direction ? `${direction} °` : "Not Available"}
      </li>
      <li
        className="details-block_summary_list_item details-block_device-imei"
        title={deviceData?.source_id}
      >
        <strong>{getLocalizedString("device_imei", "Device IMEI")} :</strong>{" "}
        {deviceData?.source_id}
      </li>
      {type === "Devices" && (
        <li
          className="details-block_summary_list_item details-block_device-imei"
          title={deviceData?.plate_number?.v}
        >
          <strong>
            {getLocalizedString("plate_vehicle_plate_number", "Vehicle Plate Number")} :
          </strong>{" "}
          {deviceData?.plate_number?.v || "NA"}
        </li>
      )}
    </>
  );
};

export const ListDetails = ({ clientConfig, realTimedata, deviceData, deviceId }) => {
  const variableData = useSelector((state) => state.common.variableDatas);

  const clientsData = JSON.parse(JSON.stringify(dataConfigJson))?.clients;

  const selectedClientName = FMDPStorage.get("selected-client");

  // If view all is false then make it false otherwise make it true
  let view_all = true;

  // Check if clientConfig exists and has the necessary nested structure
  if (clientConfig?.tracking?.web?.views?.health?.view_all != null) {
    view_all = clientConfig?.tracking?.web?.views?.health?.view_all;
  }

  let selectedFields = [];
  if (!view_all) {
    selectedFields =
      Object.keys(clientConfig).length &&
      Object.values(clientConfig?.tracking?.mobile?.views?.health?.fields);
  }

  return view_all ? (
    <section className="details-block_data-container">
      <ul className="details-block_data details-block_other-data">
        {Object.entries(spreadDeviceDataObject(deviceData))?.map(([key, value]) => {
          if (value !== null && key !== "cell_info" && !key.startsWith("_")) {
            let ItemValues = valueFormatter(
              key,
              getValueByNestedKey(realTimedata[deviceId], key) || value,
              variableData
            );
            return (
              <li className="details-block_other-data_item">
                <strong
                  title={displayKeyName(variableData, key)}
                  className="details-block_other-data_item_label"
                >
                  {getLocalizedString(key, displayKeyName(variableData, key))}
                </strong>
                <span title={ItemValues} className="details-block_other-data_item_value">
                  {ItemValues}
                </span>
              </li>
            );
          }
        })}
      </ul>
    </section>
  ) : (
    <>
      {/* Custom Config Generated */}
      <section className="details-block_data-container">
        <ul className="details-block_data details-block_other-data">
          {Object.entries(spreadDeviceDataObject(deviceData))?.map(([key, value]) => {
            if (
              selectedFields.includes(key) &&
              value !== null &&
              key !== "cell_info" &&
              !key.startsWith("_")
            ) {
              let ItemValues = valueFormatter(
                key,
                getValueByNestedKey(realTimedata[deviceId], key) || value,
                variableData
              );
              return (
                <li className="details-block_other-data_item">
                  <strong
                    title={displayKeyName(variableData, key)}
                    className="details-block_other-data_item_label"
                  >
                    {displayKeyName(variableData, key)}
                  </strong>
                  <span title={ItemValues} className="details-block_other-data_item_value">
                    {ItemValues}
                  </span>
                </li>
              );
            }
          })}
        </ul>
      </section>
    </>
  );
};
