import { useState } from "react";
import { QIMultiSelectDropDown } from "../../../../components";
import { removeDuplicates, uniqueArray } from "../../utils";
import {getLocalizedString} from "../../../../shared/translation";

const ColumnConfig = ({
  setSelectedColumns,
  selectedColumns,
  columnData,
  setSelectedColumnItems,
  variableData,
}) => {
  const handleChange = (value) => {
    setSelectedColumnItems([]);
    uniqueArray(columnData)?.map((item) => {
      if (value?.includes(item?.id)) {
        setSelectedColumnItems((prev) => [...prev, item?.name]);
      }
    });
    setSelectedColumns(value);
  };

  return (
    <>
      <QIMultiSelectDropDown
        label={getLocalizedString("column_config", "Column Config")}
        selected={[...new Set(selectedColumns)]}
        data={uniqueArray(columnData)}
        onChange={(value) => handleChange(value)}
        variableData={variableData}
      />
    </>
  );
};

const TrackPointHeader = ({
  setSelectedColumns,
  selectedColumns,
  columnData,
  configDivRef,
  setSelectedColumnItems,
  variableData,
}) => {
  return (
    <>
      <div ref={configDivRef} className="qi-list-view-column-config">
        <ColumnConfig
          columnData={removeDuplicates(columnData || [])}
          setSelectedColumns={setSelectedColumns}
          selectedColumns={selectedColumns}
          setSelectedColumnItems={setSelectedColumnItems}
          variableData={variableData}
        />
      </div>
    </>
  );
};

export default TrackPointHeader;
