import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MPSharedHeader, MPSharedTable, MPSharedDeleteModal, TableDropDownView } from "../Shared/";
import { TickOrCross } from "../../Shared";
import { useGetClientsQuery } from "../services";
import { useSearchQuery, usePagination } from "../../../hooks";
import { ClientForm } from "./Form";
import { getLocalizedString } from "../../../shared/translation";

export const ClientsContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_cont",
  });

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetClientsQuery({ page, per_page: perPage, order_by: "created_at", order_dir: "desc", q });

  const [idToEdit, setIdToEdit] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const createNewHandler = () => {
    setIdToEdit(null);
    setShowForm(true);
  };

  const editClickHandler = (datum) => {
    setIdToEdit(datum.id);
    setShowForm(true);
  };

  const headers = [
    { label: getLocalizedString("id", "Id"), key: "id", className: "id" },
    {
      label: getLocalizedString("name", "Name"),
      key: "name",
      className: "name",
      nestedValue: true,
      getNestedValue: ({ name }) => {
        name = name.replace(/\s\s+/g, " ");
        return name;
      },
    },
    {
      label: getLocalizedString("applications", "Applications"),
      className: "apps applications more",
      type: "component",
      component: ({ data }) => {
        const appNames = data?.app_names || [""];
        return <TableDropDownView data={appNames} />;
      },
    },
    {
      label: getLocalizedString("devices", "Devices"),
      nestedValue: true,
      getNestedValue: ({ id, devices_count, name }) => {
        return (
          <>
            {devices_count > 0 ? (
              <Link
                to={`/management_portal/devices/list?client_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {devices_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
      className: "text-center device",
    },
    {
      label: getLocalizedString("vehicles", "Vehicles"),
      key: "vehicles_count",
      className: "text-center vehicle",
    },
    {
      label: getLocalizedString("retention_period_days", "Retention Period (Days)"),
      key: "retention_period",
      className: "text-center",
    },
    {
      label: getLocalizedString("co_branding_screen", "Co-Branding Screen"),
      nestedValue: true,
      getNestedValue: ({ co_branding_screen }) => <TickOrCross flag={co_branding_screen} />,
      className: "text-center",
    },
    { label: getLocalizedString("added_on", "Added On"), key: "created_at", className: "date" },
    {
      label: getLocalizedString("added_by", "Added By"),
      key: "created_by",
      className: "added-by",
    },
  ];

  return (
    <>
      <MPSharedHeader
        createNewHandler={createNewHandler}
        heading={getLocalizedString("clients", "Clients")}
        count={data.total_count}
        handleSearchKey={(value) => setSimpleSearch(value)}
        simpleSearchPlaceholder={getLocalizedString("search_by_name", "Search by Name")}
        resourceName="Reseller Clients"
      />
      <MPSharedTable
        resourceName="Reseller Clients"
        isLoading={isFetching}
        error={error}
        isSuccess={isSuccess}
        data={data.data}
        headers={headers}
        pagination={{ ...pagination, count: data.total_count }}
        onEdit={editClickHandler}
        skipClientId={true}
        className="clients no-tabs"
        auditResource="Client"
        auditKey="name"
      />
      {showForm && <ClientForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}
    </>
  );
};
