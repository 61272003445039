import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { variableMappingFormSchema } from "../validations";
import * as Components from "../../../components";
import { mergeObjects } from "../../../shared/helper";
import {
  useCreateVariableMappingMutation,
  useUpdateVariableMappingMutation,
  useGetVariableMappingQuery,
  useGetVariablesQuery,
  useGetDModelsQuery,
  useGetDMansQuery,
  useGetClientsQuery,
} from "../services";
import { FormContainer } from "../Shared/FormContainer";
import { useForm, useDropDownSearch } from "../../../hooks";

export const VariableMappingForm = ({ idToEdit = null, closeForm }) => {
  const dropmenuQueryParams = { per_page: 1000, order_by: "name" };

  const [initialValues, setInitialValues] = useState({
    client_id: "",
    device_model_id: "",
    device_manufacturer_id: "",
    name: "",
    mapping_details: [],
  });

  const formik = useFormik({
    initialValues,
    validationSchema: variableMappingFormSchema,
    onSubmit: (values) => handleFormSubmit(values),
    enableReinitialize: true,
  });

  const [variableMappingValues, setVariableMappingValues] = useState({
    target_variable_id: "",
    source_variable_id: "",
    multiplication_factor: "",
    offset: "",
    remove_source_variable: true,
  });

  const formikVariableMapping = useFormik({
    initialValues: variableMappingValues,
    // onSubmit: (values) => handleVariableMappingSubmit(values),
    enableReinitialize: true,
  });

  // Get the clients list using Dropdown Search
  const {
    data: clients,
    setSearchKey: setClientSearch,
    isSuccess: isClientsSuccess,
  } = useDropDownSearch({
    useData: useGetClientsQuery,
    simpleSearchKey: "name_or_email_cont",
    selectedIds: formik.values.client_id,
  });

  // Get the device manufacturers list using Dropdown Search
  const {
    data: dmans,
    setSearchKey: setDManSearch,
    isSuccess: dmansSuccess,
  } = useDropDownSearch({
    useData: useGetDMansQuery,
    simpleSearchKey: "name_or_clients_name_cont",
    selectedIds: formik.values.device_manufacturer_id,
    additionalParams: { ...dropmenuQueryParams },
  });

  // Get the device models list using Dropdown Search, against selected devices manufacturers
  const {
    data: deviceModels,
    setSearchKey: setDeviceModelSearch,
    isSuccess: deviceModelsSuccess,
  } = useDropDownSearch({
    useData: useGetDModelsQuery,
    simpleSearchKey: "name_or_device_manufacturer_name_cont",
    additionalParams: { device_manufacturer_id: formik.values.device_manufacturer_id },
    selectedIds: formik.values.device_model_id,
  });

  const [filteredClients, setFilteredClients] = useState([]);

  // Get data for target variables list
  const { data: variables = { data: [] } } = useGetVariablesQuery({
    per_page: 2000,
    order_by: "variables.display_name",
    classification_type: ["tracking_data_device", "tracking_data_custom"],
  });

  const getClassificationType = () => {
    let classification_id = "";
    dmans?.data?.map((dman) => {
      if (formik?.values?.device_manufacturer_id == dman?.id) {
        if (dman?.name === "Mobile") {
          classification_id = "tracking_data_api";
        } else {
          classification_id = "tracking_data_device";
        }
      }
    });
    return classification_id;
  };

  // Get data for source variables list
  const { data: deviceModelVariables = { data: [] } } = useGetVariablesQuery(
    {
      per_page: 2000,
      device_model_id: formik.values.device_model_id,
      order_by: "variables.display_name",
      classification_type: getClassificationType(),
    },
    { skip: !formik.values.device_model_id }
  );

  const { create: createMapping, update: updateMapping } = useForm({
    createMutation: useCreateVariableMappingMutation,
    updateMutation: useUpdateVariableMappingMutation,
    closeForm,
    setError: formik.setErrors,
  });

  const { data } = useGetVariableMappingQuery({ id: idToEdit }, { skip: !idToEdit });
  const [reorderedMappingData, setReorderedMappingData] = useState([]);

  // This object holds the mapping between the ID and the display name of fields in the
  // input form

  const [displayNameMap, setDisplayNameMap] = useState({});
  const mapColumnNames = ["target_variable_id", "source_variable_id"];

  // For mapping data in case of edit form
  useEffect(() => {
    if (idToEdit && variables && data) {
      const updatedFormData = mergeObjects(formik.values, data);
      setInitialValues(updatedFormData);

      //To reorder the mapping data in correct order to display in the edit form
      const tempData = updatedFormData?.mapping_details?.map((item) => {
        // Check if both target and source variable types are not "number"
        if (item?.target_variable_type !== "number" || item?.source_variable_type !== "number") {
          return {
            target_variable_id: item.target_variable_id,
            source_variable_id: item.source_variable_id,
            multiplication_factor: "NA",
            offset: "NA",
            remove_source_variable: item.remove_source_variable,
          };
        }

        // Otherwise, use the original values
        return {
          target_variable_id: item.target_variable_id,
          source_variable_id: item.source_variable_id,
          multiplication_factor: item.multiplication_factor,
          offset: item.offset,
          remove_source_variable: item.remove_source_variable,
        };
      });

      setReorderedMappingData(tempData);
    }
  }, [data, variables]);

  // For edit form, if any particular client is chosen then the client dropdown will have only ALL option
  useEffect(() => {
    if (idToEdit && data?.client_id && clients?.data) {
      let tempClient = [];
      clients?.data?.map((client) => {
        if (client?.id === data?.client_id) {
          tempClient.push(client);
        }
      });

      setFilteredClients(tempClient);
    }
  }, [data, clients?.data]);

  const handleFormSubmit = (formData) => {
    const { device_manufacturer_id, ...newFormData } = formData;
    if (idToEdit) {
      updateMapping({ id: idToEdit, formData: newFormData });
    } else {
      createMapping({ formData: newFormData });
    }
  };

  useEffect(() => {
    if (variables) {
      let tempObj = {};
      variables.data.forEach((data) => {
        tempObj[data.id] = data.display_name;
      });
      setDisplayNameMap({ target_variable_id: tempObj, source_variable_id: tempObj });
    }
  }, [variables]);

  // To maintain the label names and errors for each of the mappings
  const [headers, setHeaders] = useState([
    {
      label: "Target Variables",
      key: "target_variable_id",
      error: "",
      dataType: "",
      className: "target_variable_id",
    },
    {
      label: "Source Variables",
      key: "source_variable_id",
      error: "",
      dataType: "",
      className: "source_variable_id",
    },
    {
      label: "Multiplication Factor",
      key: "multiplication_factor",
      error: "",
      className: "multiplication_factor",
    },
    {
      label: "Offset",
      key: "offset",
      error: "",
      className: "offset",
    },
    {
      label: "Remove Source Variable",
      key: "remove_source_variable",
      error: "",
      className: "remove_source_variable",
    },
    {
      className: "action",
    },
  ]);

  const handleChange = (name, value) => {
    let setDataType = "";
    const parsedValue = value === "" ? "" : JSON?.parse(value);

    if (name === "target_variable_id" || name === "source_variable_id") {
      setDataType = parsedValue?.variable_type;
      formikVariableMapping.setFieldValue(name, parsedValue?.id);
    } else {
      formikVariableMapping.setFieldValue(name, value);
    }

    // Find the index of the header based on the given name
    const headerIndex = headers.findIndex((header) => header.key === name);

    if (headerIndex !== -1) {
      // Update the display_value of the found header
      setHeaders((prevHeaders) => {
        const newHeaders = [...prevHeaders];
        newHeaders[headerIndex] = {
          ...newHeaders[headerIndex],
          dataType: setDataType,
        };
        return newHeaders;
      });
    }
  };

  // Reset the mapping values to default
  const resetValues = () => {
    formikVariableMapping.setFieldValue("target_variable_id", "");
    formikVariableMapping.setFieldValue("source_variable_id", "");
    formikVariableMapping.setFieldValue("source_variable_id", "");
    formikVariableMapping.setFieldValue("offset", "NA");
    formikVariableMapping.setFieldValue("multiplication_factor", "NA");
    setHeaders((prevHeaders) => [
      {
        ...prevHeaders[0],
        dataType: "",
      },
      {
        ...prevHeaders[1],
        dataType: "",
      },
      ...prevHeaders.slice(2), // Keep the rest of the headers unchanged
    ]);
  };

  const handleSubmit = (values) => {
    formik.setFieldValue("mapping_details", values);
    resetValues();
  };

  // Reset the values when a field is cancelled
  const handleOnCancel = () => {
    resetValues();
  };

  const validateAndSetError = (fieldName, errorValue, updatedHeaders) => {
    //To update the error message for every mapping data
    const tempHeaders = updatedHeaders.map((header) => {
      if (header.key === fieldName) {
        return {
          ...header,
          error: errorValue,
        };
      }
      return header;
    });

    return tempHeaders;
  };

  const validateVariableMapping = (objectItem, allData) => {
    // Adding validations for mapping
    let updatedHeaders = structuredClone(headers);
    let errorPresent = false;
    let required = false;

    if (!objectItem?.target_variable_id) {
      updatedHeaders = validateAndSetError("target_variable_id", "Required", updatedHeaders);
      required = errorPresent = true;
    } else {
      updatedHeaders = validateAndSetError("target_variable_id", "", updatedHeaders);
    }
    if (!objectItem?.source_variable_id) {
      updatedHeaders = validateAndSetError("source_variable_id", "Required", updatedHeaders);
      required = errorPresent = true;
    } else {
      updatedHeaders = validateAndSetError("source_variable_id", "", updatedHeaders);
    }
    if (!objectItem?.multiplication_factor) {
      updatedHeaders = validateAndSetError("multiplication_factor", "Required", updatedHeaders);
      required = errorPresent = true;
    } else {
      updatedHeaders = validateAndSetError("multiplication_factor", "", updatedHeaders);
    }
    if (!objectItem?.offset) {
      updatedHeaders = validateAndSetError("offset", "Required", updatedHeaders);
      required = errorPresent = true;
    } else {
      updatedHeaders = validateAndSetError("offset", "", updatedHeaders);
    }

    if (!required) {
      if (headers[0]?.dataType !== headers[1]?.dataType) {
        updatedHeaders = validateAndSetError(
          "target_variable_id",
          "Data-type mismatch",
          updatedHeaders
        );
        updatedHeaders = validateAndSetError(
          "source_variable_id",
          "Data-type mismatch",
          updatedHeaders
        );
        errorPresent = true;
      } else {
        updatedHeaders = validateAndSetError("target_variable_id", "", updatedHeaders);
        updatedHeaders = validateAndSetError("source_variable_id", "", updatedHeaders);
      }

      if (!errorPresent) {
        allData?.forEach((datum) => {
          if (
            datum?.target_variable_id === objectItem?.target_variable_id &&
            datum?.source_variable_id === objectItem?.source_variable_id
          ) {
            updatedHeaders = validateAndSetError(
              "target_variable_id",
              "Already exists",
              updatedHeaders
            );
            updatedHeaders = validateAndSetError(
              "source_variable_id",
              "Already exists",
              updatedHeaders
            );
            errorPresent = true;
          } else {
            updatedHeaders = validateAndSetError("target_variable_id", "", updatedHeaders);
            updatedHeaders = validateAndSetError("source_variable_id", "", updatedHeaders);
          }
        });
      }
    }

    if (!errorPresent) {
      if (objectItem?.target_variable_id === objectItem?.source_variable_id) {
        updatedHeaders = validateAndSetError(
          "target_variable_id",
          "Target & Source cannot be same",
          updatedHeaders
        );
        updatedHeaders = validateAndSetError(
          "source_variable_id",
          "Target & Source cannot be same",
          updatedHeaders
        );
        errorPresent = true;
      } else {
        updatedHeaders = validateAndSetError("target_variable_id", "", updatedHeaders);
        updatedHeaders = validateAndSetError("source_variable_id", "", updatedHeaders);
      }
    }

    setHeaders(updatedHeaders);
    if (!errorPresent) {
      formikVariableMapping.setValues(variableMappingValues);
    }

    return errorPresent;
  };

  useEffect(() => {
    if (headers[0]?.dataType === "Number" && headers[1]?.dataType === "Number") {
      formikVariableMapping.setFieldValue("multiplication_factor", "1");
      formikVariableMapping.setFieldValue("offset", "0");
    } else {
      formikVariableMapping.setFieldValue("multiplication_factor", "NA");
      formikVariableMapping.setFieldValue("offset", "NA");
    }
  }, [
    formikVariableMapping?.values?.target_variable_id,
    formikVariableMapping?.values?.source_variable_id,
  ]);

  // Checking if the client needs to be disabled
  const isClientDisabled = () => {
    // For edit form if the client is chosen as all then disable client field
    if (idToEdit && data?.client_id === null) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <FormContainer
      resourceName="Variable Mapping"
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
    >
      <Components.QICustomSelect
        label="Client"
        value={formik.values.client_id}
        onChange={(value) => {
          formik.setFieldValue("client_id", value);
          value && formik.setErrors((prevState) => ({ ...prevState, client_id: "" }));
          setClientSearch("");
        }}
        onSearch={setClientSearch}
        error={formik.touched.client_id && formik.errors.client_id}
        labelClassName="client"
        disabled={isClientDisabled()}
      >
        <li value="">All</li>

        {filteredClients.length > 0
          ? filteredClients.map((client) => (
              <li key={client.id} value={client.id}>
                {client.name}
              </li>
            ))
          : isClientsSuccess &&
            clients.data.map((client) => (
              <li key={client.id} value={client.id}>
                {client.name}
              </li>
            ))}
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label="Device Manufacturer"
        {...formik.getFieldProps("device_manufacturer_id")}
        error={formik.touched.device_manufacturer_id && formik.errors.device_manufacturer_id}
        onChange={(value) => {
          formik.setValues({
            ...formik.values,
            device_manufacturer_id: value,
            device_model_id: "",
          });
          setDManSearch("");
        }}
        onSearch={setDManSearch}
        labelClassName="device-manufacturer"
      >
        {dmans &&
          dmans.data.map((dman) => (
            <li key={dman.id} value={dman.id}>
              {dman.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QICustomSelect
        label="Device Model"
        {...formik.getFieldProps("device_model_id")}
        error={formik.touched.device_manufacturer_id && formik.errors.device_manufacturer_id}
        onChange={(value) => {
          formik.setFieldValue("device_model_id", value);
          setDeviceModelSearch("");
        }}
        disabled={formik.values.device_manufacturer_id === ""}
        onSearch={setDeviceModelSearch}
        labelClassName="device-model"
      >
        {dmansSuccess &&
          deviceModelsSuccess &&
          deviceModels.data.map((deviceModel) => (
            <li key={deviceModel.id} value={deviceModel.id}>
              {deviceModel.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QIInput
        label="Name"
        placeholder="Mapping Name"
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />

      <Components.QITableCustom
        headers={headers}
        handleSubmit={handleSubmit}
        objectItem={formikVariableMapping.values}
        handleOnCancel={handleOnCancel}
        validator={validateVariableMapping}
        initialValues={reorderedMappingData}
        displayNameMap={displayNameMap}
        mapColumnNames={mapColumnNames}
        error={formik.touched.mapping_details && formik.errors.mapping_details}
      >
        <div className="qi-list-view_column edit">
          <select
            className="qi-select_text"
            onChange={(e) => handleChange("target_variable_id", e.target.value)}
          >
            <>
              <option value="">select</option>
              {variables?.data?.map((data, index) => (
                <option value={JSON.stringify(data)} key={index}>
                  {data?.display_name} ({data?.section_name}
                  {data?.unit_name ? ` - ${data.unit_name}` : ""})
                </option>
              ))}
            </>
          </select>

          <small className="error">{headers[0]?.error}</small>
        </div>
        <div className="qi-list-view_column edit">
          <select
            className="qi-select_text"
            onChange={(e) => handleChange("source_variable_id", e.target.value)}
          >
            <>
              <option value="">select</option>
              {deviceModelVariables?.data?.map((data, index) => (
                <option value={JSON.stringify(data)} key={index}>
                  {data?.display_name} ({data?.section_name}
                  {data?.unit_name ? ` - ${data.unit_name}` : ""})
                </option>
              ))}
            </>
          </select>

          <small className="error">{headers[1]?.error}</small>
        </div>
        <div className="qi-list-view_column">
          <input
            className="qi-table-custom_form_input"
            placeholder="Multiplication Factor"
            defaultValue="1"
            type="number"
            value={formikVariableMapping?.values?.multiplication_factor}
            onChange={(e) => handleChange("multiplication_factor", e.target.value)}
            disabled={
              headers[0]?.dataType === "Number" && headers[1]?.dataType === "Number" ? false : true
            }
          />
          <small className="error">{headers[2]?.error}</small>
        </div>
        <div className="qi-list-view_column">
          <input
            className="qi-table-custom_form_input"
            placeholder="Offset"
            defaultValue="0"
            type="number"
            value={formikVariableMapping?.values?.offset}
            onChange={(e) => handleChange("offset", e.target.value)}
            disabled={
              headers[0]?.dataType === "Number" && headers[1]?.dataType === "Number" ? false : true
            }
          />
          <small className="error">{headers[3]?.error}</small>
        </div>
        <div className="qi-list-view_column">
          <input
            type="checkbox"
            defaultChecked
            onChange={(e) => handleChange("remove_source_variable", e.target.checked)}
          />
          <small className="error">{headers[4]?.error}</small>
        </div>
      </Components.QITableCustom>
    </FormContainer>
  );
};
