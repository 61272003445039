import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSearchQuery, usePagination } from "../../../hooks";
import { AdminSharedHeader, AdminSharedTable, AdminSharedDeleteModal } from "../Shared/";
import { useGetVMansQuery, useDeleteVManMutation } from "../services";
import { VManForm } from "./Form";
import { useDelete } from "../../../hooks";

export const VMansContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_client_name_cont",
  });

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetVMansQuery({
    page,
    per_page: perPage,
    order_by: "vehicle_manufacturers.created_at",
    order_dir: "desc",
    q,
  });

  const {
    deleteItem: deleteVMan,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteVManMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  const createNewHandler = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  const deleteClickHandler = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const headers = [
    { label: "Client", key: "client_name", alternate: "All", className: "clients" },
    {
      label: "Name",
      nestedValue: true,
      getNestedValue: ({ id, name }) => {
        return (
          <Link to={`${id}`} style={{ textDecoration: "none" }}>
            {name}
          </Link>
        );
      },
      className: "name",
    },
    {
      label: "Models",
      key: "vehicle_manufacturer_models_count",
      alternate: "0",
      className: "models text-center",
    },
    {
      label: "Variants",
      key: "vehicle_manufacturer_model_variants_count",
      alternate: "0",
      className: "variants text-center",
    },
    {
      label: "Specifications",
      key: "vehicle_models_count",
      alternate: "0",
      className: "specifications text-center",
    },
    {
      label: "Vehicles",
      type: "component",
      component: ({ data }) => {
        const { id, vehicles_count, name } = data;
        return (
          <>
            {vehicles_count > 0 ? (
              <Link
                to={`/superadmin/vehicles/list?vehicle_manufacturer_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {vehicles_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
      className: "vehicles text-center",
    },
    { label: "Created on", key: "created_at", className: "date" },
  ];

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((datum) => datum.id === id);

    if (index > -1) {
      name = data?.data[index].name;
    }
    return name;
  };

  return (
    <>
      <article className="main-container">
        <AdminSharedHeader
          createNewHandler={createNewHandler}
          groupName="vehicle"
          heading="Vehicles"
          handleSearchKey={(value) => setSimpleSearch(value)}
          simpleSearchPlaceHolder="Search by Name, Client"
        />

        <AdminSharedTable
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          data={data.data}
          headers={headers}
          pagination={{ ...pagination, count: data.total_count }}
          onEdit={editClickHandler}
          onDelete={deleteClickHandler}
          className="vehicle-manufacturers"
          auditResource="VehicleManufacturer"
          auditKey="name"
        />
      </article>

      <AdminSharedDeleteModal
        show={showDeleteWarning}
        error={deleteErrorMsg}
        resourceName="vehicle manufacturer"
        getDeleteItemName={() => getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg("");
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteVMan({ id: idToDelete })}
      />
      {showForm && <VManForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}
    </>
  );
};
