import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { LocalizationFormSchema } from "../validations";
import * as Components from "../../../components";
import { useForm, useDropDownSearch } from "../../../hooks";
import { mergeObjects } from "../../../shared/helper";
import {
  useCreateLocalizationMutation,
  useGetLocalizationQuery,
  useUpdateLocalizationMutation,
  useGetClientsQuery,
  useGetLanguagesQuery,
  useGetApplicationVariantsQuery,
} from "../services";
import { FormContainer } from "../Shared/FormContainer";

export const LocalizationForm = ({ idToEdit = null, closeForm }) => {
  const dropdownParams = { per_page: 1000, order_by: "name" };
  const [initialValues, setInitialValues] = useState({
    // client_id: "",
    locale_id: "",
    application_variant_id: "",
    key: "",
    value: "",
  });

  const formik = useFormik({
    initialValues,
    validationSchema: LocalizationFormSchema,
    enableReinitialize: true,
    onSubmit: (value) => handleFormSubmit(value),
  });

  const {
    create: createLocalization,
    update: updateLocalization,
    isLoading,
  } = useForm({
    createMutation: useCreateLocalizationMutation,
    updateMutation: useUpdateLocalizationMutation,
    closeForm,
    setError: formik.setErrors,
  });

  // Fetch the data if edit form
  const { data } = useGetLocalizationQuery({ id: idToEdit }, { skip: !idToEdit });

  // Get the application variants list using Dropdown Search
  const {
    data: applicationVariants,
    setSearchKey: setApplicationVariantsSearch,
    isSuccess: isApplicationVariantsSuccess,
  } = useDropDownSearch({
    useData: useGetApplicationVariantsQuery,
    simpleSearchKey: "name",
    selectedIds: formik.values.application_variant_id,
  });

  // Get the langauges list using Dropdown Search
  const {
    data: languages,
    setSearchKey: setLanguagesSearch,
    isSuccess: isLanguagesSuccess,
  } = useDropDownSearch({
    useData: useGetLanguagesQuery,
    simpleSearchKey: "name",
    selectedIds: formik.values.locale_id,
    additionalParams: { ...dropdownParams },
  });

  // Set the values for edit form
  useEffect(() => {
    if (idToEdit && data) {
      let tempData = { ...data };
      setInitialValues(mergeObjects(formik.initialValues, tempData));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      updateLocalization({ id: idToEdit, formData: formData });
    } else {
      createLocalization({ formData: formData });
    }
  };

  return (
    <FormContainer
      resourceName="Localization"
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
      error={formik.errors}
      isLoading={isLoading}
    >
      {/* <Components.QICustomSelect
        label="Client"
        {...formik.getFieldProps("client_id")}
        onChange={(value) => {
          formik.setFieldValue("client_id", value);
          setClientSearch("");
        }}
        onSearch={setClientSearch}
        error={formik.touched.client_id && formik.errors.client_id}
        labelClassName="client"
        disabled={true}
      >
        <li value="">All</li>
        {isClientsSuccess &&
          clients.data.map((client) => (
            <li key={client.id} value={client.id}>
              {client.name}
            </li>
          ))}
      </Components.QICustomSelect> */}

      <Components.QICustomSelect
        label="Language"
        value={formik.values.locale_id}
        onChange={(value) => {
          formik.setFieldValue("locale_id", value);
        }}
        onSearch={setLanguagesSearch}
        error={formik.touched.locale_id && formik.errors.locale_id}
      >
        <li value="">Select</li>
        {isLanguagesSuccess &&
          languages?.data?.map((language) => (
            <li key={language?.id} value={language?.id} title={language?.name}>
              {language?.name}
            </li>
          ))}
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label="Profile"
        value={formik.values.application_variant_id}
        onChange={(value) => {
          formik.setFieldValue("application_variant_id", value);
        }}
        error={formik.touched.application_variant_id && formik.errors.application_variant_id}
      >
        <li value="">Select</li>
        {isApplicationVariantsSuccess &&
          applicationVariants?.data?.map((app) => (
            <li key={app?.id} value={app?.id} title={app?.name}>
              {app?.name}
            </li>
          ))}
      </Components.QICustomSelect>

      <Components.QIInput
        label="Key"
        placeholder="Key"
        {...formik.getFieldProps("key")}
        error={formik.touched.key && formik.errors.key}
      />
      <Components.QIInput
        label="Value"
        placeholder="Value"
        {...formik.getFieldProps("value")}
        error={formik.touched.value && formik.errors.value}
      />
    </FormContainer>
  );
};
