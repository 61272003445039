import { useEffect, useState } from "react";
import moment from "moment";
import { AdvanceSearchWrapper } from "../Shared/";
import { QIDateRangePicker, QIInput } from "../../../components";
import {getLocalizedString} from "../../../shared/translation";

export const VehicleModelAdvanceSearch = ({ onSearch, activeSearch }) => {
  const initialValues = {
    name_cont: "",
    vehicle_manufacturer_model_variant_vehicle_manufacturer_model_vehicle_manufacturer_name_cont:
      "",
    vehicle_manufacturer_model_variant_vehicle_manufacturer_model_name_cont: "",
    vehicle_manufacturer_model_variant_name_cont: "",
    capacity_cont: "",
    engine_capacity_cont: "",
    year_eq: "",
    filter_vehicle_class_ids: [],
    filter_vehicle_type_ids: [],
    created_at_gteq: null,
    created_at_lteq: null,
  };

  const [formData, setFormData] = useState(initialValues);
  const [showCalender, setShowCalender] = useState(false);
  const [resetCount, setResetCount] = useState(0);

  useEffect(() => {
    if (activeSearch) {
      setFormData(activeSearch);
    }
  }, []);

  const handleFormChange = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const clearAdvanceSearch = () => {
    setFormData(initialValues);
    onSearch(null);
    //New Calendar id on clear
    setResetCount((i) => i + 1);
    setShowCalender(false);
  };

  const handleDateRange = (startTime, endTime) => {
    setFormData((prevState) => ({
      ...prevState,
      created_at_gteq: startTime,
      created_at_lteq: endTime,
    }));
    setResetCount((i) => i + 1);
  };

  return (
    <AdvanceSearchWrapper
      activeFilters={activeSearch}
      onClear={clearAdvanceSearch}
      onSearch={() => onSearch(formData)}
      showCalender={showCalender}
      setShowCalender={setShowCalender}
    >
      <QIInput
        label={getLocalizedString("name", "Name")}
        value={formData.name_cont}
        onChange={(e) => handleFormChange("name_cont", e.target.value)}
      />
      <QIInput
        label={getLocalizedString("manufacturer", "Manufacturer")}
        value={
          formData.vehicle_manufacturer_model_variant_vehicle_manufacturer_model_vehicle_manufacturer_name_cont
        }
        onChange={(e) =>
          handleFormChange(
            "vehicle_manufacturer_model_variant_vehicle_manufacturer_model_vehicle_manufacturer_name_cont",
            e.target.value
          )
        }
      />
      <QIInput
        label={getLocalizedString("model", "Model")}
        value={formData.vehicle_manufacturer_model_variant_vehicle_manufacturer_model_name_cont}
        onChange={(e) =>
          handleFormChange(
            "vehicle_manufacturer_model_variant_vehicle_manufacturer_model_name_cont",
            e.target.value
          )
        }
      />
      <QIInput
        label={getLocalizedString("variant", "Variant")}
        value={formData.vehicle_manufacturer_model_variant_name_cont}
        onChange={(e) =>
          handleFormChange("vehicle_manufacturer_model_variant_name_cont", e.target.value)
        }
      />
      <QIInput
        label={getLocalizedString("capacity", "Capacity")}
        value={formData.capacity_cont}
        type="number"
        onChange={(e) => handleFormChange("capacity_cont", e.target.value)}
      />
      <QIInput
        label={getLocalizedString("engine_capacity", "Engine Capacity")}
        value={formData.engine_capacity_cont}
        onChange={(e) => handleFormChange("engine_capacity_cont", e.target.value)}
      />
      <QIInput
        label={getLocalizedString("year", "Year")}
        value={formData.year_eq}
        onChange={(e) => handleFormChange("year_eq", e.target.value)}
      />

      <QIDateRangePicker
        start={formData.created_at_gteq}
        end={formData.created_at_lteq}
        label={getLocalizedString("created_between", "Created b/w")}
        maxDate={moment()}
        onChange={handleDateRange}
        showCustomRangeLabel
        setShowCalender={setShowCalender}
        resetCount={resetCount}
      />
    </AdvanceSearchWrapper>
  );
};
