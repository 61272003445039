import React from "react";
import PropTypes, { any } from "prop-types";
import { QICollapsible } from "../../../components";
import { StatusBody } from "./StatusBody";

export const CollapsibleSection = ({ vehicleData, ...props }) => {
  // TO-DO this is for ref
  // const list = [
  //   { label: "Fuel", value: vehicleData.fuel || "N/A", icon: { src: gasStationIcon }, headerType: "element" },
  //   { label: "Speed", value: vehicleData.speed || "N/A", icon: { src: speedometerIcon } },
  //   { label: "Altitude", value: vehicleData.altitude || "N/A", icon: { src: mountainIcon } },
  //   { label: "Rpm", value: vehicleData.rpm || "N/A", icon: { src: roadIcon } },
  // ];

  const list = vehicleData.otherData;
  const data = [
    {
      key: 1,
      header: "Status",
      body: <StatusBody data={list} />,
    },
  ];
  return <QICollapsible data={data} defaultOpenKey={1} />;
};

CollapsibleSection.propTypes = {
  vehicleData: PropTypes.objectOf(any).isRequired,
};
