import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { filterTrackingData } from "./helper";
import { useQuery } from "../../../hooks";
import { useGetTrackingDataQuery } from "../services";
import { TrackingTable } from "../Shared/";
import { QIDateRangePicker, QIExpand, QICheckBox, QIMapLoader } from "../../../components";
import { SvgIcon } from "../../Shared";
import endPointMarker from "../assets/mapIcons/endPointMarker.png";
import startPointMarker from "../assets/mapIcons/startPointMarker.png";

const startPoint = {
  image: {
    src: startPointMarker,
    anchor: [0.5, 1.0],
    imgSize: [50, 40],
    zIndex: 100,
  },
};

const endPoint = {
  image: {
    src: endPointMarker,
    anchor: [0.5, 1.0],
    imgSize: [50, 40],
    zIndex: 1000,
  },
};

const tooltip = {
  showOn: "click",
  content: (feature) => {
    const data = feature.get("data");
    let { speed, time, lat, lon } = data;
    // Format of data is different in case of line type
    if (data.coordinate) {
      speed = data.coordinate.speed;
      time = data.coordinate.time;
    }
    return `
      <div class="d-flex text-primary"} ">
        <div>
          <div>Speed : ${speed}</div>
          <div>Timestamp: ${time}</div>
          <div>Location: lat: ${lat}, lon: ${lon}</div>
        </div>
      <div>`;
  },
};

export const RouteDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const query = useQuery();
  const { imei } = useParams();
  const plateNumber = query.get("plate_no");
  const layerName = "trackingDataLayer";

  const [formData, setFormData] = useState({
    startDate: moment().subtract(1, "hour"),
    endDate: moment(),
    pageNumber: 1,
  });

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [filteredData, setFilteredData] = useState(null);
  const [map, setMap] = useState(null);
  const pagination = { page, perPage, onPageChange: setPage, onPerPageChange: setPerPage };

  const {
    data: exactTrackingData,
    isFetching,
    error,
    isLoading,
    isSuccess,
  } = useGetTrackingDataQuery({
    start_time: moment(formData.startDate).valueOf(),
    end_time: moment(formData.endDate).valueOf(),
    imei: imei,
    page_size: perPage,
    fields: "gps_location,velocity,position_time",
    page_number: page,
  });

  const [trackType, setTrackType] = useState({ point: false, line: true });

  const headers = [
    { label: "Date and Time", key: "positionTime" },
    { label: "Speed", key: "speed", alternate: "0" },
  ];

  useEffect(() => {
    if (filteredData && map) {
      map.removeLayerSource(layerName);

      if (trackType.line) {
        map.drawTrack({
          layerName: layerName,
          trackType: "line",
          data: filteredData.linecoordinates,
          fitWithinView: true,
          startPoint: true,
          endPoint: true,
          style: { startPoint, endPoint },
          tooltip,
        });
      }
      if (trackType.point) {
        map.drawTrack({
          layerName: layerName,
          trackType: "point",
          data: filteredData.coordinates,
          fitWithinView: true,
          startPoint: true,
          endPoint: true,
          style: {
            startPoint,
            endPoint,
          },
          tooltip,
        });
      }
    }
  }, [filteredData, map, trackType]);

  useEffect(() => {
    if (exactTrackingData) {
      const parsedData = filterTrackingData(exactTrackingData.tracking_data);
      setFilteredData(parsedData);
    }
  }, [exactTrackingData]);

  const handleDates = (start, end) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      startDate: start,
      endDate: end,
    }));
  };

  const gotoPreviousPage = () => {
    navigate(-1);
  };

  const openPopUp = (data) => {
    const { lat, lon } = data;
    // dispatch(routesActions.toolTip.show({ coordinates: [lon, lat] }));
  };

  const handleTrackType = (type) => {
    setTrackType((prevState) => ({ ...prevState, [type]: !prevState[type] }));
  };

  return (
    <QIMapLoader containerId="route-details" setMapRef={setMap}>
      <QIExpand title={`TRIP ${plateNumber}`}>
        <div className="">
          <div
            className="flex items-start border p-2"
            style={{ width: "500px", backgroundColor: "#f7feff" }}
          >
            <div className="mr-2 clickable" onClick={gotoPreviousPage}>
              <SvgIcon name="back" />
            </div>

            <div className="w-full">
              <div className="flex">
                <h5>
                  <strong className="mr-2 uppercase">Vehicle Plate Number :</strong>
                </h5>
                <h5>{plateNumber}</h5>
              </div>

              <div className="w-full">
                <label className="mr-2">Date and Time Range :</label>
                <QIDateRangePicker
                  start={formData.startDate}
                  end={formData.endDate}
                  maxDate={moment()}
                  minDate={moment().subtract(2, "year")}
                  onChange={handleDates}
                  timePicker={true}
                  showCustomRangeLabel={true}
                />
              </div>
              <div className="flex justify-around">
                <QICheckBox
                  label="Show Points"
                  checked={trackType.point}
                  onChange={() => handleTrackType("point")}
                  id="tracking_show_point"
                />
                <QICheckBox
                  label="Show Tracks"
                  checked={trackType.line}
                  onChange={() => handleTrackType("line")}
                  id="tracking_show_line"
                />
              </div>
            </div>
          </div>
          {filteredData && (
            <TrackingTable
              resourceName=""
              pagination={{ ...pagination, count: exactTrackingData.TotalRecords }}
              isLoading={isLoading}
              isFetching={isFetching}
              error={error}
              isSuccess={isSuccess}
              data={filteredData.data || []}
              headers={headers}
              tableHandlers={{
                rowHandler: openPopUp,
              }}
            />
          )}
        </div>
      </QIExpand>
    </QIMapLoader>
  );
};
