import React, { useState } from "react";
import { usePagination, useSearchQuery } from "../../../hooks";
import { AdminSharedHeader, AdminSharedTable, AdminSharedDeleteModal } from "../Shared/";
import { MSPForm } from "./Form";
import { useDelete } from "../../../hooks";
import { useGetMSPsQuery, useDeleteMSPMutation } from "../services";

export const MSPContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();
  const { setSimpleSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_cont",
  });

  const queryParams = {
    page: page,
    per_page: perPage,
    order_by: "mobile_service_providers.created_at",
    order_dir: "desc",
  };

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetMSPsQuery({
    ...queryParams,
    q,
  });

  const {
    deleteItem: deleteMSP,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteMSPMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const [idToDelete, setIdToDelete] = useState(null);
  const [idToEdit, setIdToEdit] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  const createNewHandler = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const deleteClickHandler = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  const headers = [
    { label: "Client", key: "client_name", alternate: "All", className: "clients" },
    { label: "Name", key: "name", className: "name" },
    { label: "Country", key: "country_name", className: "country" },
    { label: "Sim Cards", key: "sim_card_count", className: "sim-cards" },

    { label: "Created on", key: "created_at", className: "date" },
  ];

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((datum) => datum.id === id);

    if (index > -1) {
      name = data?.data[index].name;
    }

    return name;
  };

  return (
    <>
      <article className="main-container">
        <AdminSharedHeader
          createNewHandler={createNewHandler}
          groupName="other"
          heading="Configurations"
          handleSearchKey={setSimpleSearch}
          simpleSearchPlaceHolder="Search by Name"
        />
        <AdminSharedTable
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          headers={headers}
          data={data.data}
          pagination={{ ...pagination, count: data.total_count }}
          onDelete={deleteClickHandler}
          onEdit={editClickHandler}
          className="msp"
          auditResource="MobileServiceProvider"
          auditKey="name"
        />
      </article>

      {showForm && <MSPForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}

      <AdminSharedDeleteModal
        show={showDeleteWarning}
        resourceName="mobile service provider"
        error={deleteErrorMsg}
        getDeleteItemName={() => getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg();
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteMSP({ id: idToDelete })}
      />
    </>
  );
};
