import React, { useState, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import {
  useCreateRoleMutation,
  useGetRoleQuery,
  useUpdateRoleMutation,
  useGetAppFeaturesQuery,
  useGetAppsQuery,
} from "../services";
import { rolesFormValidation } from "../validations";
import { mergeObjects } from "../../../shared/helper";
import { FormContainer } from "../Shared";
import { useForm } from "../../../hooks";
import { getLocalizedString } from "../../../shared/translation";

export const RoleForm = ({ idToEdit = null, onClose }) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    app_id: "",
    enabled_app_feature_ids: [],
  });

  const formik = useFormik({
    initialValues,
    validationSchema: rolesFormValidation,
    enableReinitialize: true,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const [actionIds, setActionIds] = useState({ create: [], delete: [], update: [] });

  const { create, update } = useForm({
    createMutation: useCreateRoleMutation,
    updateMutation: useUpdateRoleMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  // Get existing role data if form is in edit mode
  const { data } = useGetRoleQuery({ id: idToEdit }, { skip: !idToEdit });

  const { data: apps = {} } = useGetAppsQuery({ page: 1, per_page: 1000 });

  const { data: appFeatures = {}, isLoading: isAppFeaturesLoading } = useGetAppFeaturesQuery({
    app_id: formik.values.app_id,
    per_page: 1000,
    page: 0,
    order_by: "name",
    order_dir: "asc",
  });

  /**
   *
   * @param {int} id : App feature id to be included/removed
   * This method will help to add or remove app features from role
   */
  const handleRoleSelection = (id) => {
    let enabledAppFeatures = [...formik.values.enabled_app_feature_ids];

    if (enabledAppFeatures.includes(id)) {
      enabledAppFeatures = enabledAppFeatures.filter((item) => item !== id);
    } else {
      enabledAppFeatures.push(id);
    }

    formik.setFieldValue("enabled_app_feature_ids", enabledAppFeatures);
  };

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      update({ formData, id: idToEdit });
    } else {
      create({ formData });
    }
  };

  /**
   *
   * @param {String} action : one of available actions in ["create","update","delete"]
   * This methods helps to detect whether actions of one type is selected or not
   * @returns {boolean}
   */
  const isAllActionsSelected = (action) => {
    for (let i = 0; i < actionIds[action].length; i++) {
      if (!formik.values.enabled_app_feature_ids.includes(actionIds[action][i])) {
        return false;
      }
    }
    return true;
  };

  const handleSelectAll = (action) => {
    if (isAllActionsSelected(action)) {
      let tempSelectedAction = formik.values.enabled_app_feature_ids.filter(
        (id) => !actionIds[action].includes(id)
      );
      formik.setFieldValue("enabled_app_feature_ids", tempSelectedAction);
    } else {
      let tempSelectedAction = [...formik.values.enabled_app_feature_ids];

      actionIds[action].forEach((actionId) => {
        if (!tempSelectedAction.includes(actionId)) {
          tempSelectedAction.push(actionId);
        }
      });
      formik.setFieldValue("enabled_app_feature_ids", tempSelectedAction);
    }
  };

  /**
   *
   * This Effect will group the action ids by action type
   *
   */
  useEffect(() => {
    if (appFeatures) {
      const tempActionIds = { create: [], update: [], delete: [] };

      Object.keys(appFeatures).forEach((key) => {
        if (appFeatures[key].create) {
          tempActionIds.create.push(appFeatures[key].create);
        }
        if (appFeatures[key].update) {
          tempActionIds.update.push(appFeatures[key].update);
        }
        if (appFeatures[key].delete) {
          tempActionIds.delete.push(appFeatures[key].delete);
        }
      });
      setActionIds(tempActionIds);
    }
  }, [appFeatures]);

  // Set form data in case of edit
  useEffect(() => {
    if (idToEdit && data) {
      setInitialValues(mergeObjects(formik.values, data));
    }
  }, [data]);

  return (
    <FormContainer
      resourceName={getLocalizedString("role", "Role")}
      closeForm={() => onClose(false)}
      handleFormSubmit={formik.handleSubmit}
      idToEdit={idToEdit}
    >
      <div className="w-50">
        <Components.QIInput
          label={getLocalizedString("name", "Name")}
          {...formik.getFieldProps("name")}
          error={formik.touched.name && formik.errors.name}
        />
        <Components.QICustomSelect
          label={getLocalizedString("applications", "Applications")}
          {...formik.getFieldProps("app_id")}
          onChange={(value) => formik.setFieldValue("app_id", value)}
          error={formik.touched.app_id && formik.errors.app_id}
          disabled={idToEdit}
        >
          <>
            {apps?.data?.map((app, index) => (
              <li value={app.id} key={index}>
                {app.name}
              </li>
            ))}
          </>
        </Components.QICustomSelect>
      </div>
      <div className="text-center">
        <small className="text-danger">{formik.errors.enabled_app_feature_ids}</small>
      </div>
      {appFeatures && Object.keys(appFeatures).length ? (
        <div className="roles-form-list">
          <div className="qi-list-view ">
            <div className="qi-list-view_header">
              <div className="qi-list-view_column feature-name-column"></div>
              <div className="qi-list-view_column read-column read-column">
                {getLocalizedString("read", "Read")}
              </div>
              {[
                {
                  label: getLocalizedString("create", "Create"),
                  key: "create",
                  className: "create-column create-column",
                },
                {
                  label: getLocalizedString("update", "Update"),
                  key: "update",
                  className: "update-column update-column",
                },
                {
                  label: getLocalizedString("delete", "Delete"),
                  key: "delete",
                  className: "delete-column delete-column",
                },
              ].map((header, index) => (
                <div className={`qi-list-view_column ${header.className}`} key={index}>
                  <input
                    type="checkbox"
                    checked={
                      formik?.values?.name == "Admin" ? "true" : isAllActionsSelected(header.key)
                    }
                    disabled={formik?.values?.name == "Admin" && true}
                    onChange={() => handleSelectAll(header.key)}
                  />
                  {header.label}
                </div>
              ))}
            </div>
            <ul className="qi-list-view_list">
              {appFeatures &&
                Object.keys(appFeatures).map((key, index) => (
                  <li className="qi-list-view_list_item" key={index}>
                    <div className="qi-list-view_column feature-name-column" title={key}>
                      {key}
                    </div>
                    <div className="qi-list-view_column read-column">
                      <input type="checkbox" checked readOnly />
                    </div>
                    <div className="qi-list-view_column create-column">
                      {appFeatures[key].create && (
                        <input
                          type="checkbox"
                          onChange={() => handleRoleSelection(appFeatures[key].create)}
                          checked={
                            formik?.values?.name == "Admin"
                              ? "true"
                              : formik.values.enabled_app_feature_ids.includes(
                                  appFeatures[key].create
                                )
                          }
                          readOnly={formik?.values?.name == "Admin" && true}
                        />
                      )}
                    </div>
                    <div className="qi-list-view_column update-column">
                      {appFeatures[key].update && (
                        <input
                          type="checkbox"
                          onChange={() => handleRoleSelection(appFeatures[key].update)}
                          checked={
                            formik?.values?.name == "Admin"
                              ? "true"
                              : formik.values.enabled_app_feature_ids.includes(
                                  appFeatures[key].update
                                )
                          }
                          readOnly={formik?.values?.name == "Admin" && true}
                        />
                      )}
                    </div>
                    <div className="qi-list-view_column delete-column">
                      {appFeatures[key].delete && (
                        <input
                          type="checkbox"
                          onChange={() => handleRoleSelection(appFeatures[key].delete)}
                          checked={
                            formik?.values?.name == "Admin"
                              ? "true"
                              : formik.values.enabled_app_feature_ids.includes(
                                  appFeatures[key].delete
                                )
                          }
                          readOnly={formik?.values?.name == "Admin" && true}
                        />
                      )}
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      ) : (
        formik.values.app_id == 2 && (
          <div className="text-center text-red-600">
            {(!isAppFeaturesLoading &&
              formik.values.app_id &&
              getLocalizedString("no_features_are_associated_with_given_application", "")) ||
              "No features are associated with given application"}
          </div>
        )
      )}
    </FormContainer>
  );
};
