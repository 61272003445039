import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Components from "../../../components";
import { FormContainer } from "../Shared";
import { useDropDownSearch, useForm } from "../../../hooks";
import {
  useGetAppsQuery,
  useCreateApiKeyMutation,
  useUpdateApiKeyMutation,
  useGetApiKeyQuery,
} from "../services";
import { ApiKeyValidation } from "../validations";
import { mergeObjects } from "../../../shared/helper";
import {getLocalizedString} from "../../../shared/translation";

export const ApiKeyForm = ({ onClose, idToEdit }) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    note: "",
    client_app_role_id: null,
    is_enabled: true,
  });

  const formik = useFormik({
    initialValues,
    validationSchema: !idToEdit && ApiKeyValidation,
    enableReinitialize: true,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const { data: datumApiKey = {} } = useGetApiKeyQuery({ id: idToEdit });

  //Dropdown Search for Roles
  const { data: apps, setSearchKey: appIdSearch } = useDropDownSearch({
    useData: useGetAppsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.client_app_role_id,
    additionalParams: { include: "roles" },
  });

  const { create: createApiKeys, update: updateApiKeys } = useForm({
    createMutation: useCreateApiKeyMutation,
    updateMutation: useUpdateApiKeyMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  useEffect(() => {
    if (idToEdit && datumApiKey) {
      setInitialValues(mergeObjects(initialValues, datumApiKey));
    }
  }, [datumApiKey]);

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      updateApiKeys({ id: idToEdit, formData });
    } else {
      createApiKeys({ formData });
    }
  };

  return (
    <>
      <FormContainer
        resourceName={getLocalizedString("api_key", "API Key")}
        handleFormSubmit={formik.handleSubmit}
        closeForm={() => onClose(false)}
        idToEdit={idToEdit}
      >
        <Components.QIInput
          label={getLocalizedString("name", "Name")}
          placeholder={getLocalizedString("enter_name", "Enter Name")}
          {...formik.getFieldProps("name")}
          error={formik?.touched?.name && formik?.errors?.name}
        />
        <Components.QIInput
          label={getLocalizedString("description", "Description")}
          placeholder={getLocalizedString("enter_description", "Enter Description")}
          {...formik.getFieldProps("note")}
          error={formik?.touched?.note && formik?.errors?.note}
        />
        <Components.QICustomSelect
          label={getLocalizedString("role", "Role")}
          value={formik.values.client_app_role_id}
          onChange={(value) => {
            formik.setFieldValue("client_app_role_id", Number(value));
            appIdSearch("");
          }}
          onSearch={appIdSearch}
          error={formik?.touched?.client_app_role_id && formik?.errors?.client_app_role_id}
          labelClassName="role"
        >
          {apps?.data?.map((app) => {
            const name = app.name;
            return app?.client_app_roles?.map((val, id) => {
              return (
                <li value={`${val?.id}`} key={id}>
                  {`${name}-${val?.name}`}
                </li>
              );
            });
          })}
        </Components.QICustomSelect>
        {idToEdit && (
          <Components.QISwitch
            label={getLocalizedString("active", "Active")}
            {...formik.getFieldProps("is_enabled")}
            error={formik.touched.is_enabled && formik.errors.is_enabled}
            onChange={() => {
              formik.setFieldValue("is_enabled", !formik.values.is_enabled);
            }}
          />
        )}
      </FormContainer>
    </>
  );
};
