import moment from "moment";
import { eventsType } from "../utils";
import { getLocalizedString } from "../../../shared/translation";

const getEventTypeStatus = (events) => {
  if (events?.status && events?.sub_type && events?.status !== events?.sub_type) {
    return eventsType(events?.sub_type) + "-" + eventsType(events?.status);
  } else if (events?.status) {
    return eventsType(events?.status);
  } else if (events?.sub_type) {
    return eventsType(events?.sub_type);
  } else return "";
};

export const header = [
  {
    label: getLocalizedString("time", "Time"),
    id: 1,
    nestedValue: true,
    getNestedValue: ({ source_time }) => {
      const sourceTime = source_time ? formatTime(source_time) : "";
      return <h1 title={sourceTime}>{sourceTime}</h1>;
    },
  },
  {
    label: getLocalizedString("type", "Type"),
    id: 2,
    nestedValue: true,
    getNestedValue: ({ events }) => {
      return <h1 title={eventsType(events?.type)}>{eventsType(events?.type)}</h1>;
    },
  },
  {
    label: getLocalizedString("name", "Name"),
    id: 3,
    nestedValue: true,
    getNestedValue: ({ events, activities }) => {
      const name =
        events?.config?.name ||
        events?.config?.Config?.name ||
        activities?.data?.config?.Config?.name ||
        activities?.data?.config?.name;
      return <h1 title={name}>{name}</h1>;
    },
  },
  {
    label: "Location",
    id: 4,
    nestedValue: true,
    getNestedValue: ({ gps }) => {
      const lat = gps?.position?.lat;
      const lng = gps?.position?.lng;
      return (
        <h1
          title={`${lat ? Number(lat).toFixed(5) : "NA"}, ${lng ? Number(lng).toFixed(5) : "NA"}`}
        >
          {lat ? Number(lat).toFixed(5) : "NA"}, {lng ? Number(lng).toFixed(5) : "NA"}
        </h1>
      );
    },
  },
  // {
  //   label: "Lat",
  //   id: 5,
  //   nestedValue: true,
  //   getNestedValue: ({ gps }) => {
  //     const lat = gps?.position?.lat;
  //     return <h1>{lat ? Number(lat).toFixed(5) : "NA"}</h1>;
  //   },
  // },
  // {
  //   label: "Lng",
  //   id: 6,
  //   nestedValue: true,
  //   getNestedValue: ({ gps }) => {
  //     const lng = gps?.position?.lng;
  //     return <h1>{lng ? Number(lng).toFixed(5) : "NA"}</h1>;
  //   },
  // },
  {
    label: getLocalizedString("status", "Status"),
    id: 5,
    nestedValue: true,
    getNestedValue: ({ events }) => {
      return <h1 title={getEventTypeStatus(events)}>{getEventTypeStatus(events)}</h1>;
    },
  },
];

function formatTime(time) {
  return moment(time).format("DD/MM/YY - HH:mm:ss A");
}
