import { useState } from "react";
import { QITracksListCard, QISpinner, QIErrorBoundary } from "../../../components";
import { header } from "./tableHeader";
import { TableWrapper } from "./TableWrapper";
import { NoData } from "../../Shared";
import { useNavigate } from "react-router-dom";

const TripsList = ({
  data,
  resetCount,
  selectedRange,
  setSelectedRange,
  error,
  listData,
  setDeviceId,
  setHighlight,
  highlight,
  setTripTime,
  listType,
  setResetCount,
  page,
  perPage,
  setPage,
  setPerPage,
  tripSearch,
  setTripSearch,
  setShowEventsDetails,
  showEventsDetails,
  setDockAlign,
  setMapExpand,
  isFetching,
  isSuccess,
}) => {
  const navigate = useNavigate();
  const [view, setView] = useState("Table");

  //Pagination
  const pagination = { page, perPage, onPageChange: setPage, onPerPageChange: setPerPage };

  //Handle Row Selection
  const handleRowClick = (data) => {
    navigate("/tracking/trips", { state: { ...data } });
  };

  const message = {
    header: error?.data?.message,
  };

  return (
    <>
      {error && <QIErrorBoundary message={message} />}
      {isFetching && (
        <div className="no-data">
          <QISpinner size="50px" />
        </div>
      )}
      {!isFetching && isSuccess && data?.trips?.length ? (
        <div className="map-view-trips">
          <TableWrapper
            data={data?.trips || []}
            header={header}
            pagination={{ ...pagination, count: data?.total_count }}
            error={error}
            isSuccess={true}
            tableHandlers={{
              rowHandler: handleRowClick,
            }}
            highlight={highlight}
            setHighlight={setHighlight}
            listType={listType}
          />
        </div>
      ) : (
        isSuccess && !error && !isFetching && <NoData />
      )}
    </>
  );
};

export default TripsList;
