import React, { useState, useEffect } from "react";
import { useSearchQuery, usePagination, useQuery } from "../../../hooks";
import { MPSharedHeader, MPSharedTable, MPSharedDeleteModal } from "../Shared/";
import { UserForm } from "./form";
import { useGetUsersQuery, useDeleteUserMutation } from "../services";
import { SvgIcon } from "../../Shared";
import { UserGroupCsvUploadForm } from "../UserGroups/csvUploadForm";
import { getLocalizedString } from "../../../shared/translation";

export const UsersContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "filter_full_name_or_email_or_filter_ph_with_country_code_cont",
  });

  const queryParams = {
    page: page,
    per_page: perPage,
    order_by: "users.created_at",
    order_dir: "desc",
  };

  const query = useQuery();
  let filterHeader = "";
  const filters = [
    {
      label: " " + getLocalizedString("user_group_lowercase", " user group") + ": ",
      key: "user_group_id",
    },
  ];

  filters.map((filter) => {
    if (query.get(filter.key)) {
      queryParams[filter.key] = query.get(filter.key);
      filterHeader = `${filter.label} ${query.get("name")}`;
    }
  });

  const {
    data = { total_count: 0, data: [] },
    error,
    isFetching,
    isSuccess,
  } = useGetUsersQuery({
    ...queryParams,
    q,
  });

  const [deleteUser, { isSuccess: deleteSuccess, reset: resetDeleteDevice }] =
    useDeleteUserMutation();

  const [idToDelete, setIdToDelete] = useState(null);
  const [idToEdit, setIdToEdit] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showCsvUploadForm, setShowCsvUploadForm] = useState(false);

  const onEdit = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  const onDelete = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const onCreate = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const uploadCsvHandler = () => {
    setShowCsvUploadForm(true);
  };

  const getAppName = (appName) => {
    return appName?.split("_").map((appNamePart) => {
      return appNamePart[0].toUpperCase() + appNamePart?.substring(1, appNamePart.length) + " ";
    });
  };

  const headers = [
    {
      label: getLocalizedString("name", "Name"),
      key: "name",
      nestedValue: true,
      getNestedValue: ({ first_name, last_name }) => `${first_name || ""} ${last_name || ""}`,
      className: "name",
    },
    { label: getLocalizedString("email", "Email"), key: "email", className: "email" },
    {
      label: getLocalizedString("phone_no", "Phone No."),
      nestedValue: true,
      getNestedValue: (element) =>
        `${element.country_code ? `${element.country_code}-` : ""}${element.phone_no || ""}`,
      className: "phone-number",
    },
    {
      label: getLocalizedString("active", "Active"),
      key: "is_enabled",
      nestedValue: true,
      getNestedValue: ({ is_enabled }) => (
        <>
          {is_enabled ? (
            <SvgIcon name="tick" wrapperClass="status-icon active" svgClass="=icon-tick" />
          ) : (
            <SvgIcon name="cross" wrapperClass="status-icon inactive" svgClass="=icon-cross" />
          )}
        </>
      ),
      className: "w-25",
    },
    {
      label: getLocalizedString("applications_and_roles", "Applications and Roles"),
      type: "component",
      className: "applications-and-role",
      component: ({ data }) => {
        const { client_app_roles: clientAppRoles } = data;

        return (
          <div>
            {clientAppRoles &&
              Object?.keys(clientAppRoles).map((key, index) => (
                <>
                  {key && (
                    <div
                      className="application-role"
                      key={index}
                      title={`${key}: ${clientAppRoles[key]?.join(",")}` || ""}
                    >
                      <strong className="application-role_application" title={key}>
                        <span className="application-role_application_name">{getAppName(key)}</span>
                      </strong>
                      <div className="application-role_application_roles">
                        {clientAppRoles[key]?.map((role, index) => (
                          <span
                            className="application-role_application_roles_item"
                            key={index}
                            title={role}
                          >
                            <span className="application-role_application_roles_item_name">
                              {role}
                            </span>
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              ))}
          </div>
        );
      },
    },
    {
      label: getLocalizedString("added_on", "Added On"),
      key: "created_at",
      className: "date",
    },
  ];

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((item) => item.id === id);
    if (index >= 0) {
      name = `${data?.data[index]?.first_name} ${data?.data[index]?.last_name}`;
    }
    return name;
  };

  useEffect(() => {
    if (deleteSuccess) {
      setShowDeleteWarning(false);
      resetDeleteDevice();
    }
    return () => resetDeleteDevice();
  }, [deleteSuccess]);

  return (
    <>
      <article className="main-container">
        <MPSharedHeader
          groupName="users_role"
          createNewHandler={onCreate}
          heading={getLocalizedString("users_and_roles", "Users And Roles")}
          resourceName="Users"
          handleSearchKey={(value) => setSimpleSearch(value)}
          simpleSearchPlaceholder={getLocalizedString(
            "search_by_name_email_phone_no",
            "Search by Name, Email, Phone No."
          )}
          filterText={
            filterHeader && `${getLocalizedString("filtered_by", "Filtered by")} ${filterHeader}`
          }
          uploadCsvHandler={uploadCsvHandler}
        />

        <MPSharedTable
          resourceName="Users"
          isLoading={isFetching}
          isSuccess={isSuccess}
          error={error}
          data={data.data}
          headers={headers}
          onEdit={onEdit}
          pagination={{ ...pagination, count: data.total_count }}
          skipClientId
          className="users"
          // onDelete={onDelete}
          auditResource="User"
          auditKey="email"
        />
      </article>

      {showForm && <UserForm idToEdit={idToEdit} onClose={() => setShowForm(false)} />}

      {showCsvUploadForm && <UserGroupCsvUploadForm onClose={() => setShowCsvUploadForm(false)} />}

      <MPSharedDeleteModal
        show={showDeleteWarning}
        entityName={getLocalizedString("user", "User")}
        itemName={getDeleteItemName(idToDelete)}
        onHide={() => setShowDeleteWarning(false)}
        onDelete={() => deleteUser({ id: idToDelete })}
      />
    </>
  );
};
