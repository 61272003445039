import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { FormContainer } from "../Shared";
import {
  useCreateSimCardMutation,
  useUpdateSimCardMutation,
  useGetSimCardQuery,
  useGetMSPsQuery,
} from "../services";
import { useForm, useStaticDropDownSearch, useDropDownSearch } from "../../../hooks";
import { SimCardFormValidation } from "../validations";
import { mergeObjects } from "../../../shared/helper";
import { simCardTypes, simCapabilities } from "../../../shared/dropdownLists";
import Countrycode from "../../../shared/countryCode.json";
import {getLocalizedString} from "../../../shared/translation";

export const SimCardsForm = ({ idToEdit = null, onClose }) => {
  const [initialValues, setInitialValues] = useState({
    display_name: "",
    sim_type: "",
    imsi: "",
    phone_no: "",
    domestic_roaming_enabled: false,
    international_roaming_enabled: false,
    mobile_service_provider_id: "",
    capabilities: [],
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: SimCardFormValidation,
    onSubmit: (value) => handleFormSubmit(value),
  });

  const {
    create: createSimCard,
    update: updateSimCard,
    updateSuccess,
    updateError,
    createSuccess,
  } = useForm({
    createMutation: useCreateSimCardMutation,
    updateMutation: useUpdateSimCardMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  // Get the country code list using Dropdown Search for static data
  const { data: countryCode, setSearchKey: setCountryCodeSearchKey } = useStaticDropDownSearch({
    dataSet: Countrycode,
    selectedIds: formik.values.country_code,
  });

  // Get sim card data for edit form
  const { data = {} } = useGetSimCardQuery({ id: idToEdit }, { skip: !idToEdit });

  //Get the mobile service provider list to check if only 1 data is present then that should be auto-selected
  const { data: mobileData = {} } = useGetMSPsQuery();

  // Get the mobile service provider list using Dropdown Search
  const {
    data: mobileServiceProviders,
    setSearchKey: setMobileServiceProvidersSearch,
    isSuccess: isMobileServiceProvidersSuccess,
  } = useDropDownSearch({
    useData: useGetMSPsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.mobile_service_provider_id,
  });

  // Set form data in case of edit
  useEffect(() => {
    if (idToEdit && data) {
      setInitialValues(mergeObjects(initialValues, data));
    }
  }, [data]);

  // Set physical SIM by default
  useEffect(() => {
    formik.setFieldValue("sim_type", "physical");
  }, []);

  // Set the mobile service provider automatically if there is only one
  useEffect(() => {
    if (!idToEdit && mobileData) {
      if (mobileData?.data?.length === 1) {
        formik.setFieldValue("mobile_service_provider_id", mobileData?.data[0]?.id);
        handleDialCodeChange(mobileData?.data[0]?.id);
      }
    }
  }, [mobileData]);

  // Set the dial code when a mobile service provider is selected
  const handleDialCodeChange = (value) => {
    if (value) {
      mobileServiceProviders?.data?.map((mobileServiceProvider) => {
        if (value == mobileServiceProvider?.id) {
          formik.setFieldValue("country_code", mobileServiceProvider?.dial_code);
        }
      });
    } else {
      formik.setFieldValue("country_code", "");
    }
  };

  // Handle change for the mobile service provider
  const handleMobileServiceProviderChange = (value) => {
    handleDialCodeChange(value);
    formik.setFieldValue("mobile_service_provider_id", value);
    setMobileServiceProvidersSearch("");
  };

  const handleFormSubmit = async (formData) => {
    if (idToEdit) {
      updateSimCard({ id: idToEdit, formData });
    } else {
      createSimCard({ formData });
    }
  };

  return (
    <FormContainer
      resourceName={getLocalizedString("sim_card", "Sim Card")}
      closeForm={() => onClose(false)}
      handleFormSubmit={formik.handleSubmit}
      idToEdit={idToEdit}
    >
      <Components.QIInput
        label={getLocalizedString("name", "Name")}
        placeholder={getLocalizedString("sim_card_name", "SIM card name")}
        {...formik.getFieldProps("display_name")}
        error={formik.touched.display_name && formik.errors.display_name}
      />

      <Components.QICustomSelect
        label={getLocalizedString("type", "Type")}
        {...formik.getFieldProps("sim_type")}
        onChange={(value) => formik.setFieldValue("sim_type", value)}
        error={formik.touched.sim_type && formik.errors.sim_type}
      >
        {simCardTypes?.map((simCard, index) => (
          <li value={simCard.id} key={index}>
            {simCard.name}
          </li>
        ))}
      </Components.QICustomSelect>

      <Components.QIMultiSelectDropDown
        label={getLocalizedString("capabilities", "Capabilities")}
        selected={formik.values.capabilities}
        onChange={(value) => formik.setFieldValue("capabilities", value)}
        data={simCapabilities || []}
        className="narrow-multiselect-dropdown"
      />

      <Components.QIInput
        label={getLocalizedString("imsi", "IMSI")}
        placeholder={getLocalizedString("sim_card_id", "SIM card ID")}
        {...formik.getFieldProps("imsi")}
        error={formik.touched.imsi && formik.errors.imsi}
      />

      <Components.QICustomSelect
        label={getLocalizedString("mobile_service_provider", "Mobile Service Provider")}
        {...formik.getFieldProps("mobile_service_provider_id")}
        onChange={(value) => handleMobileServiceProviderChange(value)}
        error={
          formik.touched.mobile_service_provider_id && formik.errors.mobile_service_provider_id
        }
        onSearch={setMobileServiceProvidersSearch}
        labelClassName="mobile-service-provider"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {mobileServiceProviders?.data?.map((mobileServiceProvider, index) => (
            <li value={mobileServiceProvider.id} key={index}>
              {mobileServiceProvider.name}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <div className="flex justify-between country-code-and-number">
        <Components.QICustomSelect
          label={getLocalizedString("country_code", "Country Code")}
          placeholder={getLocalizedString("country_code", "Country Code")}
          className="country-code-and-number_item1"
          {...formik.getFieldProps("country_code")}
          onChange={(value) => formik.setFieldValue("country_code", value)}
          onSearch={setCountryCodeSearchKey}
          error={formik.touched.country_code && formik.errors.country_code}
          labelClassName="country-code"
          disabled={true}
        >
          <li value="">{getLocalizedString("select", "Select")}</li>
          {countryCode?.map((code, index) => (
            <li key={index} value={code.dial_code} title={`${code.dial_code} (${code.name})`}>
              {code.dial_code} ({code.name})
            </li>
          ))}
        </Components.QICustomSelect>
        <Components.QIInput
          label={getLocalizedString("phone_no", "Phone No.")}
          placeholder={getLocalizedString("phone_no", "Phone No.")}
          className="country-code-and-number_item2"
          customLabelClass="phone-number"
          customWrapperClass="phone-number-wrapper"
          {...formik.getFieldProps("phone_no")}
          error={formik.touched.phone_no && formik.errors.phone_no}
        />
      </div>

      <Components.QISwitch
        label={getLocalizedString("domestic_roaming", "Domestic Roaming")}
        value={formik.values.domestic_roaming_enabled}
        onChange={() =>
          formik.setFieldValue("domestic_roaming_enabled", !formik.values.domestic_roaming_enabled)
        }
      />
      <Components.QISwitch
        label={getLocalizedString("international_roaming", "International Roaming")}
        value={formik.values.international_roaming_enabled}
        onChange={() =>
          formik.setFieldValue(
            "international_roaming_enabled",
            !formik.values.international_roaming_enabled
          )
        }
      />
    </FormContainer>
  );
};
