import React from "react";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import { QIModal, QIModalBody, QIModalHeader } from "../../../components";
import { useGetAuditableEventQuery } from "../services";

export const ChangeLog = ({
  idToAudit,
  onClose,
  auditForm = true,
  auditTrailModalView = false,
}) => {
  // Fetch the data for a particular id
  const { data } = useGetAuditableEventQuery({ id: idToAudit });
  const changes = data?.data;

  const makeRepresentable = (key, value) => {
    let before = value[0];
    let after = value[1];

    if (before === null) {
      before = "";
    }
    if (after === null) {
      after = "";
    }
    if (["created_at", "updated_at", "activated_at", "discarded_at", "changed_at"].includes(key)) {
      if (before) {
        before = moment(before).format("MMM Do, YYYY - h:mm:ss A");
      }
      if (after) {
        after = moment(after).format("MMM Do, YYYY - h:mm:ss A");
      }
    }
    if (["password", "encrypted_password"].includes(key)) {
      before = "*****";
      after = "******";
    }
    if (key === "credentials" || key === "backend_credentials") {
      if (before) {
        before = JSON.parse(before);
        if (before.password) {
          before.password = "***";
        }
      }
      if (after) {
        after = JSON.parse(after);
        if (after.password) {
          after.password = "*****";
        }
      }
    }
    if (before === true || before === false) {
      before = before.toString();
    }
    if (after === false || after === true) {
      after = after.toString();
    }
    if (key === "created_by_id" || key === "updated_by_id") {
      after = changes.user_email;
    }
    if (typeof before == "object") {
      before = <pre>{JSON.stringify(before, null, 2)}</pre>;
    }
    if (typeof after == "object") {
      after = <pre>{JSON.stringify(after, null, 2)}</pre>;
    }

    const splitter = (val) => {
      let arr = val.split("_");
      return arr.join(" ");
    };

    return (
      <React.Fragment key={key}>
        {before !== after && (
          <li className="flex">
            <div className="change-log-view_item field">{splitter(key)}</div>
            <div className="change-log-view_item before">{before}</div>
            <div className="change-log-view_item after">{after}</div>
          </li>
        )}
      </React.Fragment>
    );
  };

  const ListAuditDetails = () => {
    return (
      <>
        <section className="change-log-view-wrapper">
          <header className="change-log-view">
            <strong className="change-log-view_item field">Field</strong>
            <strong className="change-log-view_item before">Before</strong>
            <strong className="change-log-view_item after">After</strong>
          </header>

          {auditTrailModalView ? (
            <ul className="change-log-view_list">
              {Object.keys(changes?.object_changes || {}).map((key, index) =>
                makeRepresentable(key, changes.object_changes[key])
              )}
            </ul>
          ) : (
            <Scrollbars autoHeight autoHeightMax="calc(100vh - 430px)">
              <ul className="change-log-view_list">
                {Object.keys(changes?.object_changes || {}).map((key, index) =>
                  makeRepresentable(key, changes.object_changes[key])
                )}
              </ul>
            </Scrollbars>
          )}
        </section>
      </>
    );
  };

  const ListAuditHeaderDetails = () => {
    return (
      <ul className="change-log-for">
        <li className="change-log-for_item">
          <strong className="change-log-for_item_label">Entity:</strong>
          <span className="change-log-for_item_value ellipsis">{changes?.resource_value}</span>
        </li>
        <li className="change-log-for_item">
          <strong className="change-log-for_item_label">Changed By:</strong>
          <span className="change-log-for_item_value ellipsis">
            {changes?.user_email === null || changes?.user_email === ""
              ? "System"
              : changes?.user_email}
          </span>
        </li>
        <li className="change-log-for_item">
          <strong className="change-log-for_item_label">Event:</strong>
          <span className="change-log-for_item_value ellipsis">{changes?.event_type}</span>
        </li>
        <li className="change-log-for_item">
          <strong className="change-log-for_item_label">Resource Type:</strong>
          <span className="change-log-for_item_value ellipsis">{changes?.resource_type}</span>
        </li>
        <li className="change-log-for_item">
          <strong className="change-log-for_item_label">Changed At:</strong>
          <span className="change-log-for_item_value ellipsis">
            {changes?.created_at
              ? moment.unix(changes.created_at).format("MMM Do, YYYY - h:mm:ss A")
              : ""}
          </span>
        </li>
      </ul>
    );
  };

  if (auditForm) {
    return (
      <QIModal show={true} size="xl-large">
        <QIModalHeader onHide={onClose}>
          <h5>Change Log</h5>
        </QIModalHeader>

        <QIModalBody className="qi-modal_body">
          {ListAuditHeaderDetails()}
          {ListAuditDetails()}
        </QIModalBody>

        <footer className="qi-modal_footer no-buttons"></footer>
      </QIModal>
    );
  } else return ListAuditDetails();
};
