import React, { useState } from "react";
import { useSearchQuery, usePagination, useDelete } from "../../../hooks";
import { MPSharedHeader, MPSharedTable, MPSharedDeleteModal } from "../Shared";
import { ReportConfigurationsForm } from "./Form";
import { useGetReportConfigurationsQuery, useDeleteGeofenceMutation } from "../services";
import {getLocalizedString} from "../../../shared/translation";

export const ReportConfigurationsContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_cont",
  });

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetReportConfigurationsQuery({
    page,
    per_page: perPage,
    order_by: "created_at",
    order_dir: "desc",
    q,
  });

  const {
    deleteItem: deleteBackend,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteGeofenceMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  const createNewHandler = () => {
    setShowForm(true);
    setIsNew(true);
    setIdToEdit(null);
  };

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIsNew(false);
    setIdToEdit(datum.id);
  };

  const headers = [
    { label: getLocalizedString("name", "Name"), key: "name", className: "name" },
    {
      label: getLocalizedString("type", "Type"),
      type: "component",
      className: "report-type more",
      component: ({ data }) => {
        //Underscore is replaced by space and first letter of each word is made in upper case
        const name = data?.report_type?.split("_").map((word) => {
          // Display Activity instead of Activities
          return word === "activities"
            ? "Activity"
            : word.charAt(0).toUpperCase() + word.substring(1, word.length) + " ";
        });

        return <span>{name}</span>;
      },
    },

    // { label: "Added by", key: "created_by", className: "added-by" },
    {
      label: getLocalizedString("added_on", "Added on"),
      key: "created_at",
      className: "date",
    },
  ];

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((datum) => datum.id === id);

    if (index > -1) {
      name = data?.data[index].name;
    }
    return name;
  };

  return (
    <>
      <article className="main-container">
        <MPSharedHeader
          groupName="configurations"
          resourceName="Report Configurations"
          createNewHandler={createNewHandler}
          heading={getLocalizedString("configurations", "Configurations")}
          handleSearchKey={setSimpleSearch}
          simpleSearchPlaceholder={getLocalizedString("search_by_name", "Search by Name")}
          onEdit={editClickHandler}
        />
        <MPSharedTable
          resourceName="Report Configurations"
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          headers={headers}
          data={data.data}
          pagination={{ ...pagination, count: data.total_count }}
          onEdit={editClickHandler}
          // onDelete={deleteClickHandler}
          className="geofence"
          auditResource="ReportConfiguration"
          auditKey="name"
          skipClientId
        />
      </article>

      {showForm && (
        <ReportConfigurationsForm idToEdit={idToEdit} onClose={() => setShowForm(false)} />
      )}

      <MPSharedDeleteModal
        show={showDeleteWarning}
        entityName={getLocalizedString("report_configurations", "report configuration")}
        error={deleteErrorMsg}
        itemName={getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg();
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteBackend({ id: idToDelete })}
      />
    </>
  );
};
