import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showWarningModal: false,
  addModal: false,
  auditTrailParams: null,
};

const adminFeatures = createSlice({
  name: "adminFeatures",
  initialState,
  reducers: {
    toggleAuditModal: (state, action) => {
      state.auditTrailParams = action.payload;
    },
  },
});

export const { toggleAuditModal } = adminFeatures.actions;
export const adminFeaturesReducer = adminFeatures.reducer;
