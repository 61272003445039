import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * This method will help to detect  the change in simple search and
 * Advance search and generate a single query string 'q'. Where higher prefrence is to advance search
 * @params page: An state variable which help to maintain the current page
 * @params setPage: A change state function to update the value of page variable
 * @params simpleSearchKey: It's an key matching to ransack(In BE) searchingx
 *
 * @return simpleSearch : An state variable which holds value to search
 * @return setSimpleSearch: An function to update the simpleSearchValue
 * @return advanceSearch: An object which can be passed to advance search forms
 * @return setAdvanceSearch: An function which can be passed to update the advance search
 * @return q: a search string which can be directly passed to query
 */
export const useSearchQuery = ({
  page,
  setPage,
  simpleSearchKey,
  additionalSimpleSearchKeys = [],
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsSearch = searchParams.get("q");
  const [simpleSearch, setSimpleSearch] = useState(paramsSearch || "");
  const [advanceSearch, setAdvanceSearch] = useState(null);

  // Update the query string whenever there is change in simpleSearch or advanceSearch

  useEffect(() => {
    const queryParams = {};

    // Find all the query params from url and prepare queryParams object
    for (let entry of searchParams.entries()) {
      queryParams[entry[0]] = entry[1];
    }

    // Update the query param of url whenever there is a change in simpleSearch
    if (simpleSearch) {
      queryParams.q = simpleSearch;
      if (queryParams.page != 1 && simpleSearch !== paramsSearch) {
        delete queryParams.page;
        delete queryParams.perPage;
      }
      setSearchParams(queryParams);
    } else if (queryParams.q) {
      delete queryParams.q; // Delete the query when there is no searchKeyword
      setSearchParams(queryParams);
    }
  }, [simpleSearch]);

  useEffect(() => {
    let query = searchParams.get("q");
    if (query != q) {
      setSimpleSearch(query ? query : "");
    }
  }, [searchParams]);

  // Generate/Update the query object whenever
  const q = useMemo(() => {
    let tempQ = "";

    if (simpleSearch) {
      let searchObj = {
        [simpleSearchKey]: simpleSearch,
      };
      if (additionalSimpleSearchKeys) {
        for (let i = 0; i < additionalSimpleSearchKeys.length; i++) {
          searchObj[additionalSimpleSearchKeys[i]] = simpleSearch;
        }
        searchObj["m"] = "or";
      }
      tempQ = encodeURIComponent(JSON.stringify(searchObj));
    } else if (advanceSearch) {
      tempQ = encodeURIComponent(JSON.stringify(advanceSearch));
    }

    // Auto change the page number to 1 in case of search
    if (page !== 1 && tempQ) {
      setPage(1);
    }

    return tempQ;
  }, [simpleSearch, advanceSearch]);

  return { simpleSearch, setSimpleSearch, advanceSearch, setAdvanceSearch, q };
};
