import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { backendsFormSchema } from "../validations";
import * as Components from "../../../components";
import { useForm } from "../../../hooks";
import { mergeObjects } from "../../../shared/helper";
import {
  useCreateBackendMutation,
  useGetBackendQuery,
  useUpdateBackendMutation,
  useGetBackendTypesQuery,
} from "../services";
import { FormContainer } from "../Shared/FormContainer";
import { SvgIcon } from "../../Shared";
import { getLocalizedString } from "../../../shared/translation";
import { eventTypes } from "../../../shared/dropdownLists";

export const BackendForm = ({ idToEdit = null, closeForm }) => {
  const dropdownParams = { per_page: 1000 };

  const [initialValues, setInitialValues] = useState({
    name: "",
    backend_type: "",
    protocol: "HTTP",
    host: "",
    path: "",
    port: "",
    timeout: 30,
    maximum_retry_count: "",
    enabled: false,
    additional_text: "{}",
    http_headers: "{}",
    default: false,
    clean_session: false,
    keep_alive_interval: "",
    username: "",
    password: "",
    ssl_certificate: null,
    forwarding_option: "raw_data",
    remove_ssl_certificate: false,
    port_track_synq: "",
    event_ids: [],
  });

  const formik = useFormik({
    initialValues,
    validationSchema: backendsFormSchema,
    enableReinitialize: true,
    onSubmit: (value) => handleFormSubmit(value),
  });

  const { create: createBackend, update: updateBackend } = useForm({
    createMutation: useCreateBackendMutation,
    updateMutation: useUpdateBackendMutation,
    closeForm,
    setError: formik.setErrors,
  });

  // Get Backend types
  const { data: backendTypes } = useGetBackendTypesQuery(
    {
      ...dropdownParams,
      protocol: formik.values.protocol,
    },
    {
      skip: !formik.values.protocol,
    }
  );

  // Fetch the data if edit form
  const { data } = useGetBackendQuery({ id: idToEdit }, { skip: !idToEdit });

  // Set the values for edit form
  useEffect(() => {
    if (idToEdit && data) {
      let tempData = { ...data };
      if (tempData?.http_headers) {
        try {
          let parsed = JSON.parse(tempData.http_headers);
          if (!parsed) {
            tempData.http_headers = "{}";
          }
        } catch {
          tempData.http_headers = "{}";
        }
      }
      if (tempData?.event_ids?.length > 0) {
        // Convert event_ids array of integers to array of strings
        const tempEventIdsStrings = tempData?.event_ids?.map((id) => id?.toString());
        tempData.event_ids = tempEventIdsStrings;
      }

      setInitialValues(mergeObjects(formik.initialValues, tempData));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    let fObj = new FormData();

    Object.keys(formData).forEach((key) => {
      // Remove `ssl_certificate_url` in edit mode
      if (key !== "ssl_certificate_url" && formData[key] != null) {
        if (Array.isArray(formData[key])) {
          // Convert array of strings to array of integers
          const arrayOfIntegers = formData[key].map((value) => parseInt(value));
          // Append the array of integers
          fObj.append(key, JSON.stringify(arrayOfIntegers));
        } else {
          fObj.append(key, formData[key]);
        }
      }
    });

    if (idToEdit) {
      updateBackend({ id: idToEdit, formData: fObj });
    } else {
      createBackend({ formData: fObj });
    }
  };

  const getFileName = ({ id, url }) => {
    let paths = url?.split("/") || [];

    if (paths.length >= 1) {
      return paths[paths.length - 1];
    }
    return "";
  };

  const validateHttpHeader = (currentObj, existingObj = {}) => {
    let error = {};
    let key = currentObj?.key?.trim();
    let value = currentObj?.value?.trim();

    const lowerCaseExistingObj = Object?.keys(existingObj)?.map((key) => key?.toLowerCase());

    if (lowerCaseExistingObj.includes(key?.toLowerCase())) {
      error.key = "Already exists";
    }
    if (/^[A-Za-z0-9_-]*$/.test(key) === false) {
      error.key = "Supported special characters are: - and _";
    }
    if (key?.length === 0) {
      error.key = "Required";
    } else if (key?.length > 32) {
      error.key = "Maximum length 32";
    }
    if (value?.length === 0) {
      error.value = "Required";
    } else if (value?.length > 4096) {
      error.value = "Maximum length 4096";
    }
    return error;
  };

  const handleProtocolChange = (value) => {
    if (value === "MQTT" || value === "HTTPS") {
      formik.setFieldValue("keep_alive_interval", 60);
      formik.setFieldValue("clean_session", true);
    } else {
      formik.setFieldValue("keep_alive_interval", "");
      formik.setFieldValue("clean_session", false);
    }
    if (value === "TCP" || value === "UDP") {
      formik.setFieldValue("path", "");
    }
    if (value === "UDP") {
      formik.setFieldValue("maximum_retry_count", "");
    }

    formik.setFieldValue("protocol", value);
    formik.setFieldValue("backend_type", "");
  };

  const handleForwardingChange = (value) => {
    formik.setFieldValue("forwarding_option", value);
    // If anything else apart from event is chosen then the events are reset
    if (
      value === "raw_data_base64" ||
      value === "raw_data" ||
      value === "processed_data" ||
      value === "trips"
    ) {
      formik.setFieldValue("event_ids", []);
    }
  };

  return (
    <FormContainer
      resourceName={getLocalizedString("backend", "Backend")}
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
      error={formik.errors}
    >
      <Components.QIInput
        label={getLocalizedString("name", "Name")}
        placeholder={getLocalizedString("backend_name", "Backend name")}
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />
      <Components.QICustomSelect
        label={getLocalizedString("forwarding", "Forwarding")}
        onChange={(value) => handleForwardingChange(value)}
        value={formik.values.forwarding_option}
      >
        <li value="raw_data_base64">Raw Data (Listener)</li>
        <li value="raw_data">Raw Data (Device)</li>
        <li value="processed_data">Processed Data</li>
        <li value="events">Events</li>
        <li value="trips">Trips</li>
      </Components.QICustomSelect>

      {formik?.values?.forwarding_option === "events" && (
        <Components.QIMultiSelectDropDown
          label={getLocalizedString("events", "Events")}
          selected={formik.values.event_ids}
          onChange={(value) => formik.setFieldValue("event_ids", value)}
          data={eventTypes || []}
          className="events"
          error={formik.touched.event_ids && formik.errors.event_ids}
        />
      )}

      <Components.QICustomSelect
        label={getLocalizedString("protocol", "Protocol")}
        placeholder="http/https"
        {...formik.getFieldProps("protocol")}
        onChange={(value) => handleProtocolChange(value)}
        error={formik.touched.protocol && formik.errors.protocol}
      >
        <li value="TCP">TCP</li>
        <li value="HTTP">HTTP</li>
        <li value="HTTPS">HTTPS</li>
        <li value="MQTT">MQTT</li>
        <li value="UDP">UDP</li>
      </Components.QICustomSelect>
      <Components.QICustomSelect
        label={getLocalizedString("type", "Type")}
        {...formik.getFieldProps("backend_type")}
        onChange={(value) => formik.setFieldValue("backend_type", value)}
        error={formik.touched.backend_type && formik.errors.backend_type}
        disabled={!formik.values.protocol}
      >
        {backendTypes?.data?.map((value, index) => (
          <li value={value} key={index}>
            {value}
          </li>
        ))}
      </Components.QICustomSelect>
      <Components.QIInput
        label={getLocalizedString("host", "Host")}
        placeholder="dev.tracksynq.com"
        {...formik.getFieldProps("host")}
        error={formik.touched.host && formik.errors.host}
      />
      <Components.QIInput
        label={getLocalizedString("port", "Port")}
        placeholder="3000"
        {...formik.getFieldProps("port")}
        error={formik.touched.port && formik.errors.port}
      />

      {["HTTPS", "HTTP"].includes(formik.values.protocol) && (
        <>
          <Components.QICustomField
            label={getLocalizedString("http_headers", "HTTP Headers")}
            {...formik.getFieldProps("http_headers")}
            validator={validateHttpHeader}
            presentObjects={data ? formik.initialValues.http_headers : {}}
            idToEdit={idToEdit}
            onChange={(value) => formik.setFieldValue("http_headers", value)}
            resetOnUnmount
          />
        </>
      )}

      {["HTTPS", "HTTP", "MQTT"].includes(formik.values.protocol) && (
        <>
          <Components.QIInput
            label={getLocalizedString("username", "Username")}
            {...formik.getFieldProps("username")}
          />
          <Components.QIInput
            label={getLocalizedString("password", "Password")}
            {...formik.getFieldProps("password")}
          />
        </>
      )}

      {(formik.values.protocol == "MQTT" ||
        formik.values.protocol == "HTTPS" ||
        formik.values.protocol == "TCP") && (
        <Components.QIInput
          label={getLocalizedString("keep_alive_interval", "Keep Alive Interval(Second)")}
          {...formik.getFieldProps("keep_alive_interval")}
        />
      )}

      {(formik.values.protocol == "MQTT" || formik.values.protocol == "HTTPS") && (
        <>
          <Components.QISwitch
            label={getLocalizedString("clean_session", "Clean Session")}
            value={formik.values.clean_session}
            onChange={() => formik.setFieldValue("clean_session", !formik.values.clean_session)}
          />
          {idToEdit && (
            <div className="qi-input">
              <label className="qi-input_label">
                {getLocalizedString("available_ssl", "Available SSL")}
              </label>
              <div className="qi-input_wrapper">
                {formik.values.ssl_certificate_url && !formik.values.remove_ssl_certificate ? (
                  <div className="chip-container available-ssl-chip">
                    <span className="qi-chip">
                      <a
                        href={`${formik.values.ssl_certificate_url.url}`}
                        className="flex items-center"
                      >
                        <SvgIcon
                          name="download"
                          wrapperClass="icon-download"
                          svgClass="icon"
                          onClick={() => formik.setFieldValue("remove_ssl_certificate", true)}
                        />

                        {getFileName(formik.values.ssl_certificate_url)}
                      </a>
                      <SvgIcon
                        name="cross"
                        svgClass="icon"
                        onClick={() => formik.setFieldValue("remove_ssl_certificate", true)}
                      />
                    </span>
                  </div>
                ) : (
                  getLocalizedString("no_file_selected", "No file selected")
                )}
              </div>
            </div>
          )}
          <Components.QIFileUploader
            label={getLocalizedString("ssl", "SSL")}
            placeholder={getLocalizedString("only_pem_and_crt_files", "Only .pem and .crt files")}
            value={formik.values.ssl_certificate}
            onChange={(files) => {
              formik.setFieldValue("ssl_certificate", files?.length > 0 ? files[0] : null);
            }}
            error={formik.touched.ssl_certificate && formik.errors.ssl_certificate}
          />
        </>
      )}

      {(formik.values.protocol == "MQTT" ||
        formik.values.protocol == "HTTP" ||
        formik.values.protocol == "HTTPS") && (
        <Components.QIInput
          label={
            formik.values.protocol == "MQTT"
              ? getLocalizedString("topic", "Topic")
              : getLocalizedString("path", "Path")
          }
          placeholder="qigeneric"
          {...formik.getFieldProps("path")}
          error={formik.touched.path && formik.errors.path}
        />
      )}
      <Components.QIInput
        label={getLocalizedString("timeout", "Timeout")}
        placeholder={getLocalizedString("timeout", "Timeout")}
        {...formik.getFieldProps("timeout")}
        error={formik.touched.timeout && formik.errors.timeout}
      />
      {formik.values.protocol !== "UDP" && (
        <Components.QIInput
          label={getLocalizedString("max_retry", "Max Retry")}
          placeholder={getLocalizedString("maximum_retry_count", "Maximum retry count")}
          {...formik.getFieldProps("maximum_retry_count")}
          error={formik.touched.maximum_retry_count && formik.errors.maximum_retry_count}
        />
      )}
      <Components.QICustomField
        label={getLocalizedString("additional_field", "Additional Field")}
        {...formik.getFieldProps("additional_text")}
        onChange={(value) => formik.setFieldValue("additional_text", value)}
      />
      <Components.QISwitch
        label={getLocalizedString("default", "Default")}
        {...formik.getFieldProps("default")}
        error={formik.touched.default && formik.errors.default}
        onChange={() => formik.setFieldValue("default", !formik.values.default)}
      />
      <Components.QISwitch
        label={getLocalizedString("enabled", "Enabled")}
        {...formik.getFieldProps("enabled")}
        error={formik.touched.enabled && formik.errors.enabled}
        onChange={() => formik.setFieldValue("enabled", !formik.values.enabled)}
      />
    </FormContainer>
  );
};
