import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AdminSharedHeader, AdminSharedTable, TableDropDownView } from "../Shared/";
import { useSearchQuery, usePagination } from "../../../hooks";
import { useGetDMansQuery } from "../services";
import { DManForm } from "./Form";

export const DMansContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_clients_name_cont",
  });

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetDMansQuery({
    page,
    per_page: perPage,
    order_by: "created_at",
    order_dir: "desc",
    q,
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [showForm, setShowForm] = useState(false);

  // const createNewHandler = () => {
  //   setShowForm(true);
  //   setIdToEdit(null);
  // };

  // const editClickHandler = (datum) => {
  //   setShowForm(true);
  //   setIdToEdit(datum.id);
  // };

  const headers = [
    { label: "Name", key: "name", className: "name" },
    {
      label: "Device Models",
      className: "device-models text-center",
      nestedValue: true,
      alternate: "See associated device models",
      getNestedValue: ({ id, device_models_count, name }) => {
        return (
          <>
            {device_models_count > 0 ? (
              <Link
                to={`/superadmin/devices/models?device_manufacturer_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {device_models_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
    },
    {
      label: "Devices",
      nestedValue: true,
      className: "devices text-center",
      alternate: "See associated devices",
      getNestedValue: ({ id, devices_count, name }) => {
        return (
          <>
            {devices_count > 0 ? (
              <Link
                to={`/superadmin/devices/list?device_manufacturer_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {devices_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
    },
    {
      label: "Clients",
      type: "component",
      className: "clients more",
      component: ({ data }) => {
        const clients = data?.clients?.split(",") || [""];
        return <TableDropDownView data={clients} />;
      },
    },
    { label: "Created on", key: "created_at", className: "date" },
  ];

  return (
    <>
      <article className="main-container">
        <AdminSharedHeader
          groupName="device"
          heading="Devices"
          handleSearchKey={(value) => setSimpleSearch(value)}
          simpleSearchPlaceHolder="Search by Name, Client Name"
        />
        <AdminSharedTable
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          data={data.data}
          headers={headers}
          pagination={{ ...pagination, count: data.total_count }}
          // onEdit={editClickHandler}
          className="device-manufacturers"
          // auditResource="DeviceManufacturer"
          // auditKey="name"
        />
      </article>
      {showForm && <DManForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}
    </>
  );
};
