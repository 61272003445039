import { FMDPStorage } from "./helper";
import selectedLanguage from "./selectedLanguage.json";

const defaultLanguage = "en";

export class TranslationHelper {
  constructor() {
    this.supportedLanguages = [];
    this.translationObj = {};
    this.localizations = {};

    const currentUser = JSON.parse(localStorage.getItem("current-user"));
    const language = currentUser?.locale?.code || FMDPStorage.get("user-language") || "";

    if (language) {
      this.currentLanguage = language;
    } else {
      FMDPStorage.set("user-language", defaultLanguage);
      this.currentLanguage = defaultLanguage;
    }
  }

  setSupportedLanguage(languageList) {
    this.supportedLanguages = languageList;
  }

  setTranslationObj(translationObj) {
    this.translationObj = translationObj || {};
  }

  setLocalizations(localizations) {
    this.localizations = localizations || {};
  }

  isLanSupported(language) {
    return this.supportedLanguages.findIndex((lan) => lan?.code === language) >= 0;
  }

  changeLanguage(newLan) {
    if (this.isLanSupported(newLan) && this.currentLanguage !== newLan) {
      FMDPStorage.set("user-language", newLan);
      return true;
    }
    return false;
  }

  get(key) {
    return this.translationObj[key] || {};
  }

  getLocalization(key) {
    return this.localizations[key] || {};
  }
}

export const tHelper = new TranslationHelper();
export const translator = {};

export function getLocalizedString(id, defaultValue) {
  try {
    return JSON?.parse(tHelper?.localizations)[id] || defaultValue;
  } catch {
    return defaultValue;
  }
}
