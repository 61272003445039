import { SvgIcon } from "../../Shared";
import {getLocalizedString} from "../../../shared/translation";

export const NoData = () => {
  return (
    <div className="no-data">
      <SvgIcon name="app-icon" wrapperClass="no-data-icon" />

      <div className="no-data_message">
        <h3>{getLocalizedString("no_data_found", "No Data Found")}</h3>
      </div>
    </div>
  );
};
