import { useState } from "react";
import { useGetReportQuery, useDeleteReportMutation } from "../../ManagementPortal/services";
import "./styles.scss";
import { useSearchQuery, usePagination, useDelete } from "../../../hooks";
import { MPSharedHeader, MPSharedTable, MPSharedDeleteModal } from "../../ManagementPortal/Shared/";
import { capitalize, capitalizeAllFirstLetters } from "../utils";
import { SvgIcon } from "../../Shared";
import { utcToLocalGlobal } from "../../../shared/utility";
import { ReportsForm } from "./Form";
import { FMDPStorage } from "../../../shared/helper";
import sprite from "../../../assets/icons.svg";
import { getLocalizedString } from "../../../shared/translation";

export const ReportsContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_cont",
  });

  const currentUser = FMDPStorage.get("current-user");
  const selectedAppId = FMDPStorage.get("selected-app-id");

  const getUserId = () => {
    const currentAppRoles = currentUser?.client_app_roles;
    const currentUserId = currentUser?.id;
    let hasAdminRole = false;
    currentAppRoles?.filter((clientData) => {
      if (clientData?.client_app_id === selectedAppId) {
        clientData?.roles?.filter((role) => {
          if (role?.name === "Admin" || role?.name === "Fleet Admin") {
            hasAdminRole = true;
          }
        });
      }
    });
    if (hasAdminRole === false) {
      return currentUserId;
    } else {
      return "";
    }
  };

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetReportQuery({
    page,
    per_page: perPage,
    order_by: "created_at",
    order_dir: "desc",
    q,
    filter_user_id: getUserId(),
  });

  const {
    deleteItem: deleteReport,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteReportMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [setIsNew] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  const createNewHandler = () => {
    setShowForm(true);
    setIsNew(true);
    setIdToEdit(null);
  };

  const deleteClickHandler = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const headers = [
    {
      label: getLocalizedString("name", "Name"),
      key: "name",
      className: "name",
    },
    {
      label: getLocalizedString("report_type", "Report Type"),
      id: 2,
      className: "report-type",
      type: "component",
      component: ({ data }) => {
        //Underscore is replaced by space and first letter of each word is made in upper case
        let name = data?.report_type?.split("_").map((word) => {
          // Display Activity instead of Activities
          return word === "Activities"
            ? "Activity"
            : word.charAt(0).toUpperCase() + word.substring(1, word.length) + " ";
        });

        name = name?.map((s) => s?.trim())?.join(" ");

        return (
          <span
            title={`${name} ${
              data?.report_type?.toLowerCase() === "trackpoint"
                ? "(" + data?.report_configuration_name + ")"
                : ""
            }`}
          >
            {`${name} ${
              data?.report_type?.toLowerCase() === "trackpoint"
                ? "(" + data?.report_configuration_name + ")"
                : ""
            }`}
          </span>
        );
      },
    },
    {
      label: getLocalizedString("subtype", "Sub-Type"),
      className: "report-sub-type",
      type: "component",
      component: ({ data }) => {
        const subType = data?.report_sub_type?.split("_").map((word) => {
          return word.charAt(0).toUpperCase() + word.substring(1, word.length) + " ";
        });

        const summaryInterval = data?.summary_interval?.split("_").map((word) => {
          // Display NA instead of na
          return word === "na"
            ? "NA"
            : word.charAt(0).toUpperCase() + word.substring(1, word.length) + " ";
        });

        return (
          <span title={`${subType} ${summaryInterval ? "(" + summaryInterval + ")" : ""}`}>
            {`${subType} ${summaryInterval ? "(" + summaryInterval + ")" : ""}`}
          </span>
        );
      },
    },
    {
      label: getLocalizedString("selected_criteria", "Selected Criteria"),
      id: 4,
      className: "selection-criteria",
      type: "component",
      component: ({ data }) => {
        return (
          <span
            title={`${data?.selection_criteria} ${
              data?.asset_type ? "(" + data?.asset_type + ")" : ""
            }`}
          >
            {`${data?.selection_criteria} ${data?.asset_type ? "(" + data?.asset_type + ")" : ""}`}
          </span>
        );
      },
    },
    {
      label: getLocalizedString("group_by_asset", "Group By Asset"),
      key: "group_by_asset",
      nestedValue: true,
      getNestedValue: ({ group_by_asset }) => (
        <>
          {group_by_asset ? (
            <SvgIcon name="tick" wrapperClass="status-icon active" svgClass="=icon-tick" />
          ) : (
            <SvgIcon name="cross" wrapperClass="status-icon inactive" svgClass="=icon-cross" />
          )}
        </>
      ),
      className: "w-25",
    },
    {
      label: getLocalizedString("time_range", "Time Range"),
      id: 6,
      nestedValue: true,
      getNestedValue: ({ start_time, end_time }) => {
        return (
          <>
            <h1 title={utcToLocalGlobal(start_time) + " to " + utcToLocalGlobal(end_time)}>
              {utcToLocalGlobal(start_time)}to {utcToLocalGlobal(end_time)}
            </h1>
          </>
        );
      },
    },
    {
      label: getLocalizedString("status", "Status"),
      id: 7,
      nestedValue: true,
      getNestedValue: ({ status }) => {
        switch (status) {
          case "in_progress":
            status = "In Progress";
            break;
          case "pending":
            status = "Scheduled";
            break;
          case "failure":
            status = "Failed";
            break;
          default:
            break;
        }
        status = status.replace("_", " ");
        return <h1 title={status}>{capitalize(status)}</h1>;
      },
    },
    {
      label: getLocalizedString("created_by", "Created By"),
      id: 8,
      key: "created_by",
      className: "added-by",
    },
    {
      label: getLocalizedString("created_at", "Created At"),
      id: 9,
      nestedValue: true,
      getNestedValue: ({ created_at }) => {
        return <h1 title={utcToLocalGlobal(created_at)}>{utcToLocalGlobal(created_at)}</h1>;
      },
    },
    {
      label: getLocalizedString("download", "Download"),
      id: 10,
      className: "text-center",
      nestedValue: true,
      getNestedValue: ({ report_file_url, status }) => {
        let statusIcon;
        switch (status) {
          case "in_progress":
            statusIcon = "time";
            break;
          case "pending":
            statusIcon = "time";
            break;
          case "failure":
            statusIcon = "cross";
            break;
          case "completed":
            statusIcon = "tick";
            break;
          default:
            break;
        }
        return (
          <>
            {report_file_url ? (
              <a href={report_file_url} className="download-status">
                <SvgIcon name="download" wrapperClass="icon-download" svgClass="icon" />
              </a>
            ) : (
              <span
                className="download-status status-in-progress disabled-button"
                title={capitalizeAllFirstLetters(status)}
              >
                <svg className="icon">
                  <use href={`${sprite}#${statusIcon}`}></use>
                </svg>
              </span>
            )}
          </>
        );
      },
    },
  ];

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((datum) => datum.id === id);

    if (index > -1) {
      name = data?.data[index].name;
    }
    return name;
  };

  return (
    <>
      <article className="main-container report-container">
        <MPSharedHeader
          resourceName="Report"
          createNewHandler={createNewHandler}
          groupName="Reports"
          addNewLabel={getLocalizedString("generate_report", "Generate Report")}
          heading={getLocalizedString("reports", "Reports")}
          handleSearchKey={setSimpleSearch}
          simpleSearchPlaceholder={getLocalizedString("search_by_name", "Search by Name")}
          skipCanCreate={true}
        />
        <MPSharedTable
          resourceName="Report Configuration"
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          headers={headers}
          data={data.data}
          pagination={{ ...pagination, count: data.total_count }}
          onDelete={deleteClickHandler}
          className="report"
          auditKey=""
          skipCanDelete={true}
          skipClientId
        />
      </article>

      {showForm && <ReportsForm idToEdit={idToEdit} onClose={() => setShowForm(false)} />}

      <MPSharedDeleteModal
        show={showDeleteWarning}
        entityName={getLocalizedString("report", "Report")}
        error={deleteErrorMsg}
        itemName={getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg();
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteReport({ id: idToDelete })}
      />
    </>
  );
};
