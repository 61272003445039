/**
 * Removes duplicate objects from an array and sorts it based on datetimetext property.
 *
 * @param {Array} arr - The input array containing objects with datetimetext property.
 * @returns {Array} A new array with duplicates removed and sorted by datetimetext in descending order.
 */
export const removeDuplicatesAndSort = (arr) => {
  /**
   * An object to track unique objects based on their string representation.
   * @type {Object}
   */
  const uniqueObject = {};

  /**
   * An array to store the final unique and sorted objects.
   * @type {Array}
   */
  const uniqueArray = [];

  arr.forEach((obj) => {
    if (obj.headerText === "Connection Failed") {
      uniqueArray.push(obj); // Always add "Connection Failed" objects
    } else {
      const strObj = JSON.stringify(obj);
      if (!uniqueObject[strObj]) {
        uniqueObject[strObj] = true;
        uniqueArray.push(obj);
      }
    }
  });

  // Sort the uniqueArray by datetimetext
  uniqueArray.sort((a, b) => {
    const timeA = new Date(a.datetimetext).getTime();
    const timeB = new Date(b.datetimetext).getTime();
    return timeB - timeA; // Sorting in descending order (newest first)
  });

  return uniqueArray;
};
