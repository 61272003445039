import { useMemo, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import sprite from "../../assets/icons.svg";

export const QIMultiObjectSelect = ({
  label,
  headers = [],
  value,
  validator,
  error,
  readOnly,
  onChange,
  disabled = false,
  className
}) => {
  const intialFormObj = useMemo(() => {
    let formObj = {};

    headers.forEach((header) => {
      formObj[header.key] = "";
    });

    return formObj;
  }, headers);

  const [formData, setFormData] = useState(intialFormObj);
  const [showNewInput, setShowNewInput] = useState(false);
  const [errors, setErrors] = useState({});

  const handleFormChange = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const onCancel = () => {
    setFormData(intialFormObj);
    setShowNewInput(false);
  };

  const addNewPair = () => {
    let tempErrors = validator ? validator(formData) : {};

    if (Object.keys(tempErrors).length == 0) {
      const tempArr = [...value, formData];
      onChange(tempArr);
      setFormData(intialFormObj);
      setShowNewInput(false);
      setErrors(tempErrors);
    } else {
      setErrors(tempErrors);
    }
  };

  const removeItem = (index) => {
    let tempArr = [...value];
    tempArr.splice(index, 1);
    onChange(tempArr);
  };

  return (
    <section className={`qi-input qi-form-element items-center ${error ? "error": ""}  ${className}`}>
      <label className="qi-input_label">{label}</label>
      <div className="qi-input_wrapper">
        <header className="qi-list-view_header">
          {headers.map((header, index) => (
            <span
              className={`qi-list-view_column ${header.className}`}
              key={index}
              title={header.label}
            >
              {header.label}
            </span>
          ))}
          <span className={`qi-list-view_column`}></span>
        </header>
        <Scrollbars autoHeight autoHeightMax="170px">
          <ul className="qi-list-view_list custom-field_list">
            {value.map((data, index) => (
              <li className="qi-list-view_list_item" key={index}>
                {headers.map((header, index) => (
                  <span className="qi-list-view_column" key={index}>
                    {data[header.key]}
                  </span>
                ))}
                <span className="qi-list-view_column">
                  {!disabled && (
                    <span className="icon-wrapper tick-icon" onClick={() => removeItem(index)}>
                      <svg className="icon icon-delete">
                        <use href={`${sprite}#delete`}></use>
                      </svg>
                    </span>
                  )}
                </span>
              </li>
            ))}
          </ul>
        </Scrollbars>
        {showNewInput && (
          <div className="qi-list-view add-admin-user">
            {showNewInput && (
              <form className="qi-list-view_list_item add-admin-user_form">
                {headers.map((header, index) => (
                  <div className="qi-list-view_column" key={index}>
                    {!header.uiType && (
                      <input
                        className="w-full"
                        placeholder="Key"
                        value={formData[header.key]}
                        onChange={(e) => handleFormChange(header.key, e.target.value)}
                      />
                    )}
                    {header.uiType == "select" && (
                      <select
                        value={formData[header.key]}
                        onChange={(e) => handleFormChange(header.key, e.target.value)}
                      >
                        <option>Select</option>
                        {header.options?.map(({ label, value }) => (
                          <option value={value} key={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    )}
                    <small className="text-red-600">{errors[header.key]}</small>
                  </div>
                ))}
                <div className="qi-list-view_column action add-admin-user_button-set">
                  <span className="icon-wrapper tick-icon" onClick={addNewPair}>
                    <svg className="icon icon-tick">
                      <use href={`${sprite}#tick`}></use>
                    </svg>
                  </span>
                  <span className="icon-wrapper cross-icon" onClick={onCancel}>
                    <svg className="icon icon-cross">
                      <use href={`${sprite}#cross`}></use>
                    </svg>
                  </span>
                </div>
              </form>
            )}
          </div>
        )}
        {!readOnly && error && (
          <div className="qi-input_error">
            <span className="icon-wrapper" onClick={addNewPair}>
              <svg className="icon icon-error">
                <use href={`${sprite}#info`}></use>
              </svg>
            </span>
            <p className="qi-input_error_message ">{error}</p>
          </div>
        )}
      </div>

      {!showNewInput && !disabled && (
        <span className="icon-wrapper add-new" onClick={() => setShowNewInput(true)}>
          <svg className="icon icon-add">
            <use href={`${sprite}#add`}></use>
          </svg>
        </span>
      )}
    </section>
  );
};
