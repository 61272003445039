import { useEffect, useState } from "react";
import { QIMapLoader } from "../../../components";
import { Icon, Style, Stroke } from "ol/style.js";
import { locationImg, endPointMarker, startPointMarker } from "../assets";
import { removeDuplicates } from "../utils";
import { useGetVehicleRoutingQuery } from "../services";
import moment from "moment";

/**
 * Component for displaying vehicle trips on a map.
 * @param {Object} props - Component props.
 * @param {string} props.deviceId - ID of the device/vehicle.
 * @param {Object} props.selectedRange - Selected time range for data retrieval.
 */

const TripsMap = ({ deviceId, selectedRange, mapExpand, dockAlign, maximizeMinimize }) => {
  const [tripMapRef, setTripMapRef] = useState(null);
  //Sets new map everytime
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [mapTrackData, setmapTrackData] = useState([]);
  const [totalCount, setTotalCount] = useState(200);

  // Reset map data when selectedRange or deviceId changes
  useEffect(() => {
    setPage(0);
    setmapTrackData([]);
  }, [selectedRange, deviceId]);

  // Fetch trip map data using the custom hook
  const {
    data: resData,
    error,
    isLoading,
  } = useGetVehicleRoutingQuery(
    deviceId && {
      deviceId,
      per_page: totalCount,
      page,
      start_time: moment(selectedRange?.start_time).valueOf(),
      end_time: moment(selectedRange?.end_time).valueOf(),
      isTime: true,
    },
    { refetchOnMountOrArgChange: true }
  );

  const showAddress = (device_data) => {
    if (device_data?.gps?.position?.address) {
      return `${device_data?.gps?.position?.address?.city},${device_data?.gps?.position?.address?.region},${device_data?.gps?.position?.address?.country}`;
    } else {
      return "No Recorded Data";
    }
  };

  // Collect and process map data
  useEffect(() => {
    try {
      if (deviceId) {
        if (resData?.total_count && page <= Math.round(resData?.total_count / totalCount)) {
          resData?.data.forEach((device_data) => {
            if (device_data?.gps) {
              let h = {
                source_id: device_data?.source_id,
                lat: device_data?.gps?.position?.lat,
                lng: device_data?.gps?.position?.lng,
                direction: device_data?.velocity?.direction,
                speed: device_data?.velocity?.speed,
                "010d_vehicle_speed": device_data?.obd?.["010d_vehicle_speed"],
                address: showAddress(device_data),
              };
              setmapTrackData((prev) => [...prev, h]);
            }
          });
          setPage((c) => c + 1);
        }
      }
    } catch (e) {}
  }, [resData, selectedRange]);

  useEffect(() => {
    try {
      if (tripMapRef && mapTrackData.length && deviceId) {
        deviceId && tripMapRef.removeLayerSource(`track_${count - 1}`);
        let layerName = `track_${count}`;

        tripMapRef.addLayer({ name: layerName });
        // Draw the last and first points of the track with specific styles
        tripMapRef.drawTrack({
          layerName: layerName,
          fitWithinView: true,
          trackType: "point",
          data: [{ coordinates: [mapTrackData[0]] }],
          style: {
            point: {
              style: (feature) => {
                return new Style({
                  image: new Icon({
                    color: "#eaff00",
                    crossOrigin: "anonymous",
                    src: endPointMarker,
                    imgSize: [44, 44],
                    /* rotation: feature.direction, */
                  }),
                });
              },
            },
          },
        });
        tripMapRef.drawTrack({
          layerName: layerName,
          fitWithinView: true,
          trackType: "point",
          data: [{ coordinates: [mapTrackData[mapTrackData.length - 1]] }],
          style: {
            point: {
              style: (feature) => {
                return new Style({
                  image: new Icon({
                    color: "#0767D0",
                    crossOrigin: "anonymous",
                    src: startPointMarker,
                    imgSize: [36, 36],
                    /* rotation: feature.direction, */
                  }),
                });
              },
            },
          },
        });
        // Draw the track line connecting all points
        tripMapRef.drawTrack({
          layerName: layerName,
          fitWithinView: true,
          trackType: "line",
          data: [{ coordinates: removeDuplicates(mapTrackData) }],
          style: {
            line: {
              style: (feature) => {
                return new Style({
                  stroke: new Stroke({
                    color: "#0f53ff",
                    width: 5,
                  }),
                });
              },
            },
          },
          tooltip: {
            showOn: "hover",
            content: (feature) => {
              let { coordinate } = feature.get("data");
              return `
              <div>Address: ${coordinate?.address}</div>
              <div>Latitude: ${Number(coordinate?.lat).toFixed(5)}</div>
              <div> Longitude: ${Number(coordinate?.lng).toFixed(5)} </div>
              <div>Direction: ${coordinate?.direction}</div>
              <div>Speed: ${Number(coordinate?.speed * 3.6).toFixed(1)} km/h</div>
              `;
            },
          },
        });
        tripMapRef.performFit(layerName);
        setCount((c) => c + 1);
      } else {
        tripMapRef?.removeLayer(`track_${count - 1}`);
        tripMapRef?.resetZoomLevel();
      }
    } catch (e) {}
  }, [tripMapRef, mapTrackData, deviceId, dockAlign]);

  return (
    <div className={`map-container`}>
      <QIMapLoader
        mapExpand={mapExpand}
        dockAlign={dockAlign}
        setMapRef={setTripMapRef}
        containerId="device-map-container"
        maximizeMinimize={maximizeMinimize}
      ></QIMapLoader>
    </div>
  );
};

export default TripsMap;
