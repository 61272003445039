import { smallLogo } from "../../../assets";
import { Scrollbars } from "react-custom-scrollbars";
import { getTimeGap, getIgnitionStatus } from "../utils";

export const VehicleDetailsWindowView = ({
  realTimedata,
  deviceId,
  deviceData,
  summaryDetails,
  listDetails,
}) => {
  const { status, description } = getTimeGap(
    realTimedata[deviceId]?.source_time || deviceData?.source_time
  );

  return (
    <article className="device-details-window">
      <section className="details-block_section">
        <aside className="details-block_summary">
          <div className="details-block_summary_item-info">
            <div className="item-photo">
              <img src={smallLogo} alt="logo" />
              <span className={`status ${status}`} title={description}></span>
            </div>
          </div>
          <ul className="details-block_summary_list">{summaryDetails}</ul>
        </aside>
        <div className="details-block_data-container-wrapper">
          <Scrollbars autoHeight>{listDetails}</Scrollbars>
        </div>
      </section>
    </article>
  );
};
