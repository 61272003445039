import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Components from "../../../components";
import {
  useCreateDModelControlSignalMutation,
  useUpdateDModelControlSignalMutation,
  useGetDModelControlSignalQuery,
} from "../services";
import { useForm } from "../../../hooks";
import { FormContainer } from "../Shared/FormContainer";

export const DModelControlSignalForm = ({ idToEdit = null, closeForm }) => {
  // new form state
  const [formData, setFormData] = useState({
    device_model_id: useParams().deviceModelId,
    name: "",
    mapping: "",
  });

  // error handling
  const [error, setError] = useState({});

  const { create: createControlSignal, update: updateControlSignal } = useForm({
    createMutation: useCreateDModelControlSignalMutation,
    updateMutation: useUpdateDModelControlSignalMutation,
    closeForm,
    setError,
  });

  // edit form
  const { data = {} } = useGetDModelControlSignalQuery({ id: idToEdit }, { skip: !idToEdit });

  useEffect(() => {
    if (idToEdit && data.id) {
      setFormData({
        name: data.name || "",
        device_manufacturer_id: data.device_manufacturer_id || "",
        device_model_id: data.device_model_id || "",
        mapping: JSON.stringify(data.mapping) || "",
      });
    }
  }, [data]);

  const handleFormChange = (field, value) => {
    setFormData((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleFormSubmit = () => {
    let mapping = "";
    try {
      mapping = JSON.parse(formData.mapping);
      const tempFormData = { ...formData, mapping };
      if (idToEdit) {
        updateControlSignal({ id: idToEdit, formData: tempFormData });
      } else {
        createControlSignal({ formData: tempFormData });
      }
    } catch (e) {
      setError((prevState) => ({ ...prevState, mapping: "Not a valid JSON" }));
    }
  };

  return (
    <FormContainer
      resourceName="Control Signal"
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={handleFormSubmit}
    >
      <Components.QIInput
        label="Name"
        value={formData.name}
        onChange={(e) => handleFormChange("name", e.target.value)}
        error={error.name}
      />
      <Components.QIInput
        textarea
        label="Mapping (JSON)"
        value={formData.mapping}
        onChange={(e) => handleFormChange("mapping", e.target.value)}
        error={error.mapping}
      />

      {error?.serverError ? <p className="text-danger text-center">{error.serverError}</p> : ""}
    </FormContainer>
  );
};
