import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { useForm, useDropDownSearch } from "../../../hooks";
import { FormContainer } from "../Shared/FormContainer";
import { mergeObjects } from "../../../shared/helper";
import {
  useCreateVehicleMutation,
  useUpdateVehicleMutation,
  useGetVehicleQuery,
  useGetClientsQuery,
  useGetDevicesQuery,
  useGetVManModelsQuery,
  useGetVManModelVariantsQuery,
  useGetObdCodesQuery,
  useGetVMansQuery,
  useGetUsersQuery,
} from "../services";
import { vehicleFormSchema } from "../validations";

export const VehicleForm = ({ idToEdit = null, closeForm }) => {
  const [touched, setTouched] = useState(false);
  const [initialValues, setInitialValues] = useState({
    client_id: "",
    vehicle_manufacturer_id: "",
    vehicle_manufacturer_model_id: "",
    vehicle_model_id: "",
    name: "",
    plate_number: "",
    engine_number: "",
    chasis_number: "",
    driver_id: "",
    device_ids: [],
    obd_code_ids: [],
    additional_text: "{}",
    assigned_user_id: "",
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: vehicleFormSchema,
    onSubmit: (vlaues) => handleFormSubmit(vlaues),
  });

  // Get the clients list using Dropdown Search
  const {
    data: clients,
    setSearchKey: setClientSearch,
    isSuccess: isClientsSuccess,
  } = useDropDownSearch({
    useData: useGetClientsQuery,
    simpleSearchKey: "name_or_email_cont",
    selectedIds: formik.values.client_id,
  });

  // Get the vehicle manufacturers list using Dropdown Search
  const {
    data: vehicleMans,
    setSearchKey: setVManSearch,
    isSuccess: isVehicleManSuccess,
  } = useDropDownSearch({
    useData: useGetVMansQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_manufacturer_id,
    additionalParams: { client_id: formik.values.client_id },
  });

  // Get Vehicle Manufacturers Model list, if manufacture is selected using Dropdown Search
  const {
    data: vehicleManModels,
    setSearchKey: setVManModelSearch,
    isSuccess: isVehicleManModelSuccess,
  } = useDropDownSearch({
    useData: useGetVManModelsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_manufacturer_model_id,
    additionalParams: {
      per_page: 1000,
      client_id: formik.values.client_id,
      vehicle_manufacturer_id: formik.values.vehicle_manufacturer_id,
    },
    additionalParamsToHook: { skip: !formik.values.vehicle_manufacturer_id },
  });

  //Get User List to link with vehicle using Dropdown Search
  const {
    data: users = { data: [] },
    setSearchKey: setUsersSearch,
    isSuccess: isUsersSuccess,
  } = useDropDownSearch({
    useData: useGetUsersQuery,
    simpleSearchKey: "filter_full_name_or_email_cont",
    selectedIds: formik.values.assigned_user_id,
  });

  // Get the  Obd codes list using Dropdown Search
  const {
    data: obdCodes,
    setSearchKey: setObdCodeSearchKey,
    isSuccess: obdCodeSuccess,
  } = useDropDownSearch({
    useData: useGetObdCodesQuery,
    simpleSearchKey: "code_or_name_cont",
    selectedIds: formik.values.obd_code_ids,
    // additionalParams: { ...dropmenuQueryParams },
  });

  const deviceParams = {
    not_assigned: true,
  };

  if (idToEdit) {
    deviceParams.vehicle_id = idToEdit;
  }

  const { data: devices, isSuccess: isDevicesSuccess } = useGetDevicesQuery(
    {
      per_page: 1000,
      ...deviceParams,
      client_id: formik.values.client_id,
    },
    { skip: !formik.values.client_id }
  );

  // Get Vehicle Manufacturer Model Variant list, if manufacture model is selected
  const { data: vehicleManModVar, isSuccess: isVehicleManModVarSuccess } =
    useGetVManModelVariantsQuery(
      {
        per_page: 1000,
        client_id: formik.values.client_id,
        vehicle_manufacturer_model_id: formik.values.vehicle_manufacturer_model_id,
        merge_model: true,
      },
      { skip: !formik.values.vehicle_manufacturer_model_id }
    );

  const { create: createVehicle, update: updateVehicle } = useForm({
    createMutation: useCreateVehicleMutation,
    updateMutation: useUpdateVehicleMutation,
    closeForm,
    setError: formik.setErrors,
  });

  const { data = {} } = useGetVehicleQuery({ id: idToEdit }, { skip: !idToEdit });

  useEffect(() => {
    if (idToEdit && data) {
      setInitialValues(mergeObjects(initialValues, data));
    }
  }, [data]);

  useEffect(() => {
    const selectedModelObject = vehicleManModVar?.data?.find(
      (variant) => variant.vehicle_model_id == formik.values.vehicle_model_id
    );

    if (selectedModelObject) {
      if (idToEdit && !touched && data?.obd_code_ids?.length > 0) {
        setTouched(true);
      } else {
        formik.setFieldValue("obd_code_ids", selectedModelObject.obd_code_ids);
      }
    }
  }, [vehicleManModVar, formik.values.vehicle_model_id]);

  const handleFormSubmit = (formData) => {
    let finalFormData = { ...formData };
    const modelObject = vehicleManModVar?.data?.find(
      (variant) => variant.vehicle_model_id == formik.values.vehicle_model_id
    );

    if (modelObject) {
      const selectedObdcodes = [...formik.values.obd_code_ids];
      const modelObdcodes = [...modelObject.obd_code_ids];

      if (selectedObdcodes.sort().toString() === modelObdcodes.sort().toString()) {
        finalFormData.obd_code_ids = [];
      }
    }
    if (idToEdit) {
      updateVehicle({ id: idToEdit, formData: finalFormData });
    } else {
      createVehicle({ formData: finalFormData });
    }
  };

  return (
    <FormContainer
      resourceName="Vehicle"
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
    >
      <Components.QICustomSelect
        label="Client"
        value={formik.values.client_id}
        disabled={true}
        onChange={(value) => {
          formik.setValues({
            ...formik.values,
            client_id: value,
            vehicle_manufacturer_id: "",
            vehicle_manufacturer_model_id: "",
            vehicle_model_id: "",
            device_ids: [],
          });
          setClientSearch("");
        }}
        onSearch={setClientSearch}
        error={formik.touched.client_id && formik.errors.client_id}
        labelClassName="client"
      >
        {isClientsSuccess &&
          clients.data.map((client) => (
            <li key={client.id} value={client.id}>
              {client.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QICustomSelect
        label="Manufacturer"
        value={formik.values.vehicle_manufacturer_id}
        disabled={true}
        onChange={(value) => {
          formik.setValues({
            ...formik.values,
            vehicle_manufacturer_id: value,
            vehicle_manufacturer_model_id: "",
            vehicle_model_id: "",
          });
          setVManSearch("");
        }}
        onSearch={setVManSearch}
        error={formik.touched.vehicle_manufacturer_id && formik.errors.vehicle_manufacturer_id}
        labelClassName="manufacturer"
      >
        {isVehicleManSuccess &&
          vehicleMans.data.map((vMan) => (
            <li key={vMan.id} value={vMan.id}>
              {vMan.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QICustomSelect
        label="Model"
        value={formik.values.vehicle_manufacturer_model_id}
        disabled={true}
        onChange={(value) => {
          formik.setValues({
            ...formik.values,
            vehicle_manufacturer_model_id: value,
            vehicle_model_id: "",
          });
          setVManModelSearch("");
        }}
        onSearch={setVManModelSearch}
        error={
          formik.touched.vehicle_manufacturer_model_id &&
          formik.errors.vehicle_manufacturer_model_id
        }
        labelClassName="model"
      >
        {isVehicleManModelSuccess &&
          vehicleManModels.data.map((vMan) => (
            <li key={vMan.id} value={vMan.id}>
              {vMan.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QICustomSelect
        label="Specifications"
        value={formik.values.vehicle_model_id}
        onChange={(value) => {
          formik.setFieldValue("vehicle_model_id", value);
        }}
        error={formik.touched.vehicle_model_id && formik.errors.vehicle_model_id}
        disabled={true}
        labelClassName="specifications"
      >
        {isVehicleManModVarSuccess &&
          vehicleManModVar.data.map((vManVar) => (
            <li key={vManVar.vehicle_model_id} value={vManVar.vehicle_model_id}>
              Name: {vManVar.name}, Engine Capacity: {vManVar.engine_capacity}, Capacity:
              {vManVar.capacity}, Year: {vManVar.year}
            </li>
          ))}
      </Components.QICustomSelect>

      <Components.QIInput
        label="Name"
        placeholder="Vehicle name"
        {...formik.getFieldProps("name")}
        disabled={true}
        error={formik.touched.name && formik.errors.name}
      />
      <Components.QIInput
        label="Plate Number"
        placeholder="Vehicle plate number"
        {...formik.getFieldProps("plate_number")}
        disabled={true}
        error={formik.touched.plate_number && formik.errors.plate_number}
      />
      <Components.QIInput
        label="Engine Number"
        placeholder="Engine Number"
        {...formik.getFieldProps("engine_number")}
        disabled={true}
        error={formik.touched.engine_number && formik.errors.engine_number}
      />
      <Components.QIInput
        label="Chassis Number"
        placeholder="Chasiss Number"
        {...formik.getFieldProps("chasis_number")}
        disabled={true}
        error={formik.touched.chasis_number && formik.errors.chasis_number}
      />
      <Components.QIMultiSelectDropDown
        label="Devices"
        labelKey="device_id"
        selected={formik.values.device_ids}
        data={devices?.data || []}
        onChange={(data) => formik.setFieldValue("device_ids", data)}
        readOnly={true}
      />
      <Components.QICustomField
        label="Custom Field"
        readOnly={true}
        {...formik.getFieldProps("additional_text")}
        onChange={(value) => formik.setFieldValue("additional_text", value)}
      />
      <Components.QIMultiSelectDropDown
        label="Obd Codes"
        data={obdCodes.data}
        selected={formik.values.obd_code_ids}
        onChange={(data) => {
          formik.setFieldValue("obd_code_ids", data);
          setObdCodeSearchKey("");
        }}
        onSearch={(value) => setObdCodeSearchKey(value)}
        error={formik.touched.obd_code_ids && formik.errors.obd_code_ids}
      />
      <Components.QICustomSelect
        label="User"
        value={formik.values.assigned_user_id}
        disabled={true}
        onChange={(value) => {
          formik.setFieldValue("assigned_user_id", value);
          setUsersSearch("");
        }}
        onSearch={setUsersSearch}
        error={formik.touched.assigned_user_id && formik.errors.assigned_user_id}
        labelClassName="user"
      >
        <li value="">Select...</li>
        {users.data.map((user) => (
          <li value={user.id} key={user.id}>
            {user.first_name + " " + user.last_name + " (" + user.email + ")"}
          </li>
        ))}
      </Components.QICustomSelect>
    </FormContainer>
  );
};
