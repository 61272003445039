import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryAuth } from "../../reduxStore/helper";
import { FMDPStorage } from "../../shared/helper";

export const authenticationApi = createApi({
  name: "authApi",
  baseQuery: baseQueryAuth(process.env.REACT_APP_AUTHENTICATION_API_URL),
  prepareHeaders: (headers, { getState }) => {
    headers.set("Access-Control-Allow-Origin", "*");
    headers.set("Content-Type", "application/json");
    return headers;
  },
  endpoints: (build) => ({
    createAuth: build.mutation({
      query: ({ email, password, callback_url, token, client_name, username }) => ({
        url: "sso",
        method: "post",
        body: { email, password, callback_url, token, client_name, username },
      }),
      transformResponse: (response, meta, arg) => {
        if (response?.access_token) {
          sessionStorage.setItem("fmdp-access-token", response.access_token);
        }
        if (response) {
          // Update the local storage
          FMDPStorage.set("current-user", response);
        }
        return response;
      },
    }),
    selectClientApp: build.mutation({
      query: (body) => ({
        url: "sso/select_client_app",
        method: "put",
        body,
      }),
    }),
    selectSuperAdmin: build.mutation({
      query: () => ({
        url: "sso/select_superadmin",
        method: "put",
      }),
    }),
    forgotPassword: build.mutation({
      query: (formData) => ({
        url: "sso/forget_password",
        method: "post",
        body: formData,
      }),
    }),
    resetPassword: build.mutation({
      query: (q) => ({
        url: `sso/reset_password`,
        method: "put",
        body: q.formData,
      }),
    }),
    logoutApp: build.mutation({
      query: () => ({
        url: "sso",
        method: "delete",
      }),
    }),
    createClientAuth: build.mutation({
      query: (queryParams) => {
        return {
          url: `login`,
          method: "post",
          body: queryParams,
        };
      },
    }),
  }),
});

export const {
  useCreateAuthMutation,
  useCreateClientAuthMutation,
  useSelectClientAppMutation,
  useSelectSuperAdminMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useLogoutAppMutation,
} = authenticationApi;
