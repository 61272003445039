import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Scrollbars } from "react-custom-scrollbars";
import * as Components from "../../../components";
import {
  useCreateClientMutation,
  useGetClientQuery,
  useUpdateClientMutation,
  useGetAppsQuery,
  useGetBackendsQuery,
  useGetGeolocationsQuery,
  useGetUsersQuery,
} from "../services";
import { ConfirmationModal } from "../Shared";
import { SvgIcon } from "../../Shared";
import { useForm, useDropDownSearch } from "../../../hooks";
import { FormContainer } from "../Shared/FormContainer";
import { mergeObjects } from "../../../shared/helper";
import { clientFormSchema, editClientFormSchema, clientAdminUserValidation } from "../validations";
import { FMDPStorage } from "../../../shared/helper";
import { userRoles } from "../../../shared/dropdownLists";
import { getLocalizedString } from "../../../shared/translation";

const AdminUserForm = ({ onCancel, userList, setUserList }) => {
  const [initialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role: "Fleet Admin",
    // admin_backend_ids: [],
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: clientAdminUserValidation,
    onSubmit: (values) => addUser(values),
  });

  const isValidUserData = (user) => {
    let isValid = true;

    if (userList.find((currentUser) => currentUser.email === user.email)) {
      formik.setFieldError("email", "Email is already added");
      isValid = false;
    }
    return isValid;
  };

  const addUser = (newUser) => {
    if (isValidUserData(newUser)) {
      setUserList([...userList, newUser]);
      onCancel();
    }
  };

  return (
    <form className="qi-list-view_list_item add-admin-user_form http-header">
      <div className="qi-list-view_column">
        <select
          className="qi-select_text"
          onChange={(e) => formik.setFieldValue("role", e.target.value)}
        >
          <>
            {userRoles?.map((data, id) => (
              <option value={data.id} key={id}>
                {data.name}
              </option>
            ))}
          </>
        </select>
      </div>

      <div className="qi-list-view_column">
        <Components.QIInput
          className="client-admin"
          placeholder="Email"
          {...formik.getFieldProps("email")}
          error={formik.errors.email}
        />
      </div>

      <div className="qi-list-view_column">
        <Components.QIInput
          className="client-admin"
          placeholder="First Name"
          {...formik.getFieldProps("first_name")}
          error={formik.errors.first_name}
        />
      </div>

      <div className="qi-list-view_column">
        <Components.QIInput
          className="client-admin"
          placeholder="Last Name"
          {...formik.getFieldProps("last_name")}
          error={formik.errors.last_name}
        />
      </div>

      <div className="qi-list-view_column action add-admin-user_button-set">
        <SvgIcon
          wrapperClass="icon-wrapper tick-icon"
          svgClass="icon icon-tick"
          name="tick"
          onClick={formik.handleSubmit}
        />
        {userList?.length > 0 && (
          <SvgIcon
            wrapperClass="icon-wrapper cross-icon"
            svgClass="icon icon-cross"
            name="cross"
            onClick={onCancel}
          />
        )}
      </div>
    </form>
  );
};

const AdminUser = ({
  userList,
  setUserList,
  error,
  readOnly = "",
  userData,
  adminUserIds,
  setAdminUserIds,
  onSearch,
  onSearchPlaceHolder,
  allUserData,
  setUserSearchkey,
}) => {
  const [showNewUserFields, setShowNewUserFields] = useState(true);

  const onCancel = () => {
    setShowNewUserFields(false);
  };

  const removeUser = (email) => {
    setUserList(userList.filter((currentUser) => currentUser.email !== email));
    if (userList?.length === 1) {
      setShowNewUserFields(true);
    }
  };

  const headers = [
    { label: getLocalizedString("role", "Role"), className: "" },
    { label: getLocalizedString("email", "Email"), className: "" },
    { label: getLocalizedString("first_name", "First Name"), className: "" },
    { label: getLocalizedString("last_name", "Last Name"), className: "" },
    { label: "", className: "action" },
  ];

  return (
    <section className={`qi-input items-center ${error && "error"}`}>
      <label className="qi-input_label">{getLocalizedString("admin_users", "Admin Users")} </label>
      <div className="qi-input_wrapper add-admin-user-wrapper">
        {(userList?.length > 0 || showNewUserFields) && (
          <div className="qi-list-view add-admin-user">
            <Components.QIMultiSelectDropDown
              label={getLocalizedString("user_list", "User List")}
              data={userData?.data || []}
              labelKey={(data) => (
                <>
                  <span className="available-in-list_item_primary-data">
                    {data.first_name} {data.last_name}
                  </span>
                  <small className="available-in-list_item_secondary-data">{data.email}</small>
                </>
              )}
              selected={adminUserIds}
              onChange={(updatedList) => {
                setAdminUserIds(updatedList);
                setUserSearchkey("");
              }}
              allData={allUserData?.data || []}
              dataApi={useGetUsersQuery}
              onSearch={onSearch}
              onSearchPlaceHolder={onSearchPlaceHolder}
            />
            <header className="qi-list-view_header">
              {headers.map((header, index) => (
                <span
                  className={`qi-list-view_column ${header.className}`}
                  key={index}
                  title={header.label}
                >
                  {header.label}
                </span>
              ))}
            </header>

            <Scrollbars autoHeight autoHeightMax="170px">
              <ul className="qi-list-view_list add-admin-user_list">
                {userList.map((user, index) => {
                  if (typeof user !== "number") {
                    return (
                      <li className="qi-list-view_list_item" key={index}>
                        {["role", "email", "first_name", "last_name"].map((label, i) => {
                          return (
                            <span className="qi-list-view_column" title={user[label]} key={i}>
                              {user[label]}
                            </span>
                          );
                        })}
                        <span className="qi-list-view_column action">
                          <SvgIcon
                            wrapperClass="icon-wrapper"
                            svgClass="icon-delete"
                            name="delete"
                            onClick={() => removeUser(user.email)}
                          />
                        </span>
                      </li>
                    );
                  }
                })}
              </ul>
            </Scrollbars>

            {showNewUserFields && (
              <AdminUserForm onCancel={onCancel} userList={userList} setUserList={setUserList} />
            )}
          </div>
        )}
        {!readOnly && error && (
          <div className="qi-input_error">
            <SvgIcon wrapperClass="icon-wrapper" svgClass="icon icon-error" name="info" />
            <p className="qi-input_error_message ">{error}</p>
          </div>
        )}
      </div>

      {!showNewUserFields && (
        <SvgIcon
          wrapperClass="icon-wrapper add-new"
          svgClass="icon-add"
          name="add"
          onClick={() => setShowNewUserFields(true)}
          title="Add New"
        />
      )}
    </section>
  );
};

export const ClientForm = ({ idToEdit = null, closeForm = () => {} }) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    alternate_email: "",
    address: "",
    app_ids: [],
    // additional_feature_ids: [],
    admin_users: [],
    admin_user_ids: [],
    overwrite_geolocation_plan: false,
    retention_period: "180",
    // geolocation_plan_id: "",
    // config_json: "{}",
    co_branding_screen: false,
  });

  const formik = useFormik({
    initialValues,
    validationSchema: idToEdit ? editClientFormSchema : clientFormSchema,
    enableReinitialize: true,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const [confirmation, setConfirmation] = useState(false);
  const [agree, setAgree] = useState(false);
  const [formData, setFormData] = useState({});

  const { create: createClient, update: updateClient } = useForm({
    createMutation: useCreateClientMutation,
    updateMutation: useUpdateClientMutation,
    closeForm,
    setError: formik.setErrors,
  });

  const { data: apps = { data: [] } } = useGetAppsQuery({
    per_page: 1000,
  });

  const { data } = useGetClientQuery({ id: idToEdit }, { skip: !idToEdit });

  const { data: constantUserData } = useGetUsersQuery();

  // useEffect(() => {
  //   if (!idToEdit) {
  //     const currentUserId = FMDPStorage?.get("current-user")?.id;
  //     formik.setFieldValue("admin_user_ids", [currentUserId]);
  //   }
  // }, []);

  //Get User List using Dropdown Search
  const { data: userData, setSearchKey: setUserSearchkey } = useDropDownSearch({
    useData: useGetUsersQuery,
    simpleSearchKey: "filter_full_name_or_email_cont",
    selectedIds: formik.values.admin_users,
  });

  //Get Geolocation plan List using Dropdown Search
  // const {
  //   data: geolocation_plans,
  //   setSearchKey: setGeolocationPlanSearchKey,
  //   // isSuccess: isGeolocationPlansSuccess,
  // } = useDropDownSearch({
  //   useData: useGetGeolocationsQuery,
  //   simpleSearchKey: "name_cont",
  //   selectedIds: formik.values.geolocation_plan_id,
  // });

  useEffect(() => {
    // Auto populate Management portal in app list when it's create New form
    if (!idToEdit && apps.data.length > 0) {
      const mpId = apps.data.find((app) => app.name === "Management Portal")?.id;
      if (!formik.values.app_ids.includes(mpId)) {
        formik.setFieldValue("app_ids", [...formik.values.app_ids, mpId]);
      }
    }
  }, [apps.data]);

  // Set form data in case of edit
  useEffect(() => {
    if (idToEdit && data) {
      let tempData = { ...data };
      // if (tempData.config_json) {
      //   try {
      //     let parsed = JSON.parse(tempData.config_json);
      //     if (!parsed) {
      //       tempData.config_json = "{}";
      //     }
      //   } catch {
      //     tempData.config_json = "{}";
      //   }
      // }
      setInitialValues(mergeObjects(formik.initialValues, tempData));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    const finalFormData = {
      ...formData,
      app_ids: formData.app_ids.join(","),
      name: formData.name.replace(/\s+/g, " ").trim(),
    };

    if (idToEdit) {
      const currentUser = FMDPStorage.get("current-user");
      currentUser.client_apps.filter((client) => {
        if (client.client.id === formData.id) {
          client.name = `${formData.name}`;
        }
      });
      FMDPStorage.set("current-user", currentUser);
      updateClient({ formData: finalFormData, id: idToEdit });
      //   window.location.reload();
    } else {
      setAgree(false);
      if (formik?.values?.admin_user_ids?.length === 0) {
        createClient({ formData: finalFormData });
      } else {
        setFormData(finalFormData);
        setConfirmation(true);
      }
    }
  };

  // For add form if added some admin user then have to agree to agreement for form submission
  useEffect(() => {
    if (agree) {
      createClient({ formData: formData });
    }
  }, [agree, formData]);

  // Get the backends list using Dropdown Search
  // const {
  //   data: backends,
  //   setSearchKey: setBackendSearchKey,
  //   isSuccess: isBackendSuccess,
  // } = useDropDownSearch({
  //   useData: useGetBackendsQuery,
  //   simpleSearchKey: "name_or_backend_type_cont",
  //   selectedIds: formik.values.admin_backend_ids,
  // });

  // const [showSuccessModal, setShowSuccessModal] = useState(false);
  // const [oldGeolocationPlanId, setOldGeolocationPlanId] = useState("");

  // const handleGeolocationChange = (value) => {
  //   setShowSuccessModal(true);
  //   setOldGeolocationPlanId(formik.values.geolocation_plan_id);
  //   formik.setFieldValue("geolocation_plan_id", value);
  // };

  return (
    <FormContainer
      resourceName={getLocalizedString("client", "Client")}
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
    >
      <Components.QIInput
        label={getLocalizedString("Name", "Name")}
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />
      <Components.QIInput
        label={getLocalizedString("email", "Email")}
        {...formik.getFieldProps("email")}
        error={formik.touched.email && formik.errors.email}
      />
      <Components.QIInput
        label={getLocalizedString("alt_email", "Alt Email")}
        {...formik.getFieldProps("alternate_email")}
        error={formik.touched.alternate_email && formik.errors.alternate_email}
      />
      <Components.QIInput
        label={getLocalizedString("address", "Address")}
        textarea
        {...formik.getFieldProps("address")}
        error={formik.touched.address && formik.errors.address}
      />
      {!idToEdit && (
        <AdminUser
          userList={formik.values.admin_users}
          setUserList={(value) => formik.setFieldValue("admin_users", value)}
          adminUserIds={formik.values.admin_user_ids}
          setAdminUserIds={(value) => formik.setFieldValue("admin_user_ids", value)}
          error={formik.touched.admin_users && formik.errors.admin_users}
          userData={userData}
          allUserData={constantUserData}
          onSearch={setUserSearchkey}
          onSearchPlaceHolder="Search by Name, Email"
          setUserSearchkey={setUserSearchkey}
        />
      )}
      <Components.QIMultiSelectDropDown
        label={getLocalizedString("applications", "Applications")}
        selected={formik.values.app_ids}
        data={apps?.data || []}
        onChange={(updatedList) => formik.setFieldValue("app_ids", updatedList)}
        error={formik.touched.app_ids && formik.errors.app_ids}
      />
      {/* <Components.QIMultiSelectDropDown
        label="Backends"
        data={backends?.data || []}
        labelKey={(data) => `${data.name} ${!data.enabled ? "(Disabled)" : ""}`}
        selected={formik.values.admin_backend_ids}
        onChange={(updatedList) => {
          formik.setFieldValue("admin_backend_ids", updatedList);
          setBackendSearchKey("");
        }}
        onSearch={(value) => setBackendSearchKey(value)}
        hasAction={({ default: isDefault }) => !isDefault}
        disabled={!isBackendSuccess}
      /> */}
      {/* <Components.QICustomSelect
        label="Geolocation Plan"
        value={formik.values.geolocation_plan_id}
        onChange={handleGeolocationChange}
        error={formik.touched.geolocation_plan_id && formik.errors.geolocation_plan_id}
        onSearch={(value) => setGeolocationPlanSearchKey(value)}
        labelClassName="geolocation-plan"
      >
        <li value="">Select...</li>
        {(geolocation_plans?.data || []).map((g) => (
          <li value={g.id} key={g.id}>
            {g.name}
          </li>
        ))}
      </Components.QICustomSelect> */}

      {confirmation && (
        <ConfirmationModal
          onConfirm={() => {
            setConfirmation(false);
            setAgree(true);
          }}
          onCancel={() => {
            setAgree(false);
            setConfirmation(false);
          }}
          show={confirmation}
          headerText={getLocalizedString("warning", "Warning!")}
          yesLabel={getLocalizedString("agree", "Agree")}
          noLabel={getLocalizedString("disagree", "Disagree")}
          headerClassName="warning-popup-title"
        >
          <p className="text-center">
            <strong>
              You must not add anyone from your company as an admin to a client to protect privacy
              of your client.
            </strong>
          </p>
          <p className="mt-3">
            You must have a formal and legal agreement signed by the authorized signatories from
            your company and your client before granting access to the client portal and/or data.
          </p>
          <p className="mt-3">
            Do you agree to act responsibly and with caution and take the complete responsibility of
            any consequences due to mistakes committed and/or negligence while using the portal?
          </p>
        </ConfirmationModal>
      )}
      <Components.QIInput
        label={getLocalizedString("retention_period_days", "Retention Period (Days)")}
        {...formik.getFieldProps("retention_period")}
        error={formik.touched.retention_period && formik.errors.retention_period}
      />
      {/* <Components.QIInput
        label="Additional Features (JSON)"
        textarea={true}
        {...formik.getFieldProps("config_json")}
        error={formik.touched.config_json && formik.errors.config_json}
      /> */}
      <Components.QISwitch
        label={getLocalizedString("co_branding_screen", "Co-Branding Screen")}
        {...formik.getFieldProps("co_branding_screen")}
        onChange={() =>
          formik.setFieldValue("co_branding_screen", !formik.values.co_branding_screen)
        }
      />
    </FormContainer>
  );
};
