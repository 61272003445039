(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("ol"));
	else if(typeof define === 'function' && define.amd)
		define(["ol"], factory);
	else if(typeof exports === 'object')
		exports["QIMap"] = factory(require("ol"));
	else
		root["QIMap"] = factory(root["ol"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__47__) {
return 