import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { QITable, QISpinner, QIDropdown } from "../../../components";
import { utcToLocal, setUpdatedBy, utcToLocalGlobal } from "../../../shared/utility";
import { SvgIcon } from "../../Shared";
import { AdminEditButton, AdminDeleteButton, MPUploadCsvButton } from "./Button";
import { PaginationHeader, PaginationFooter } from "./pagination";
import { AuditTrailModal } from ".";

export const AdminSharedTable = ({
  data,
  error,
  isLoading,
  isSuccess,
  headers,
  pagination,
  onEdit,
  onDelete,
  onUploadCsv,
  editDeleteCheck,
  auditResource = "",
  auditKey,
  className = "",
  additionalActions = [],
  detailedView = false,
  detailedData,
  labelName = "Actions",
  systemDefault = true,
}) => {
  const [showAudit, setShowAudit] = useState(false);
  const [auditId, setAuditId] = useState("");

  const navigate = useNavigate();
  // update created at
  let updatedHeaders = headers.map((header) => {
    if (header.key === "created_at" || header.utc) {
      return {
        label: "Added on",
        nestedValue: true,
        getNestedValue: ({ created_at }) => utcToLocalGlobal(created_at),
        className: "date",
      };
    }

    if (header.key === "updated_at" || header.utc) {
      return {
        label: "Updated on",
        nestedValue: true,
        getNestedValue: ({ updated_at }) => utcToLocalGlobal(updated_at),
        className: "date",
      };
    }

    if (header.key === "user_email") {
      return {
        ...header,
        label: header.label,
        nestedValue: true,
        getNestedValue: ({ user_email }) => setUpdatedBy(user_email),
      };
    }

    return header;
  });

  const Actions = ({ data }) => {
    return (
      <QIDropdown
        className="more-options-group"
        toggleComponent={
          !detailedView && (
            <SvgIcon wrapperClass="more-options" name="more-options" title="More Options" />
          )
        }
      >
        <>
          {additionalActions.map((action, index) => (
            <React.Fragment key={index}>{action.component(data)}</React.Fragment>
          ))}

          {!data[editDeleteCheck] && (
            <>
              {onEdit && (
                <AdminEditButton title="Edit" onClick={() => onEdit(data)} className="me-2" />
              )}
              {onDelete && (
                <AdminDeleteButton
                  title="Delete"
                  onClick={() => onDelete(data)}
                  removeDelete={true}
                />
              )}
              {onUploadCsv && <MPUploadCsvButton onClick={() => onUploadCsv(data)} />}
            </>
          )}
          {auditKey && !data[editDeleteCheck] ? (
            <SvgIcon
              wrapperClass="clickable"
              name="audit-log"
              onClick={() => {
                setShowAudit(true);
                setAuditId(data.id);
              }}
              title="Audit log"
            />
          ) : (
            systemDefault && (
              <p className="actions-information">
                Its a System Default item. No actions available.
              </p>
            )
          )}
        </>
      </QIDropdown>
    );
  };

  if (onEdit || onDelete || additionalActions.length > 0) {
    updatedHeaders.push({
      label: labelName,
      type: "component",
      component: Actions,
      className: "action",
    });
  }

  return (
    <>
      {isLoading && (
        <div className="no-data">
          <QISpinner size="50px" />
          <p>Loading...</p>
        </div>
      )}
      {!isLoading && error && <h2>Something went wrong! Try again ...</h2>}

      {!isLoading && isSuccess && data.length > 0 ? (
        <QITable
          detailedView={detailedView}
          detailedData={detailedData}
          headers={updatedHeaders}
          data={data}
          className={className}
        />
      ) : (
        <>
          {!isLoading && (
            <div className="no-data">
              <SvgIcon wrapperClass="no-data-icon" svgClass="icon-noData" name="app-icon" />
              <p>No data available</p>
            </div>
          )}
        </>
      )}

      {pagination && (
        <aside className="qi-pagination-wrapper">
          <PaginationHeader pagination={pagination} />
          {isSuccess && pagination && <PaginationFooter pagination={pagination} />}
        </aside>
      )}

      {showAudit && (
        <AuditTrailModal
          resourceType={auditResource}
          onClose={() => setShowAudit(false)}
          auditId={auditId}
          data={data || []}
        />
      )}
    </>
  );
};
