import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useForm, useDropDownSearch, useStaticDropDownSearch } from "../../../hooks";
import * as Components from "../../../components";
import { mergeObjects } from "../../../shared/helper";
import Countrycode from "../../../shared/countryCode.json";
import {
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
  useGetDeviceQuery,
  useGetDModelsQuery,
  useGetDMansQuery,
  useGetBackendDropdownQuery,
  useGetClientsQuery,
  useGetVariableMappingsQuery,
  useGetAppsQuery,
  useGetGeolocationsQuery,
} from "../services";
import { deviceFormSchemaAdmin } from "../validations";
import { FormContainer } from "../Shared/FormContainer";

/**
* This is an add/edit form of Devices
*
* @param {number} [idToEdit:"null"]- A number for the id of the selected edit form and null for the add form
* @param {func} [closeForm] - A function to close the add/edit form

* @example
* <DeviceForm
    idToEdit=45
    closeForm={() => setShowForm(false)}
  />
 */

export const DeviceForm = ({ idToEdit = null, closeForm }) => {
  //To maintain the values of all the fields in the form
  const [initialValues, setinitialValues] = useState({
    client_id: "",
    device_manufacturer_id: "",
    device_model_id: "",
    name: "",
    device_id: "",
    primary_sim_card_id: "",
    country_code: "",
    simcard_phone_no: "",
    firmware_version: "",
    enabled: true,
    credentials: { username: "", password: "" },
    app_ids: [],
    variable_mapping_id: "",
    backend_ids: [],
    geolocation_plan_id: "",
  });

  //If the value of manufacturer or model is changed then the state is made true, otherwise it is false
  const [manufacturerModelChanged, setManufacturerModelChanged] = useState(false);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => handleFormSubmit(values),
    validationSchema: deviceFormSchemaAdmin,
  });

  const { create: createBackend, update: updateBackend } = useForm({
    createMutation: useCreateDeviceMutation,
    updateMutation: useUpdateDeviceMutation,
    closeForm,
    setError: formik.setErrors,
  });

  // Get the clients list using Dropdown Search
  const {
    data: clients,
    setSearchKey: setClientSearch,
    isSuccess: isClientsSuccess,
  } = useDropDownSearch({
    useData: useGetClientsQuery,
    simpleSearchKey: "name_or_email_cont",
    selectedIds: formik.values.client_id,
  });

  // Get the device manufacturers list using Dropdown Search
  const {
    data: dmans,
    setSearchKey: setDManSearch,
    isSuccess: dmansSuccess,
  } = useDropDownSearch({
    useData: useGetDMansQuery,
    simpleSearchKey: "name_or_clients_name_cont",
    selectedIds: formik.values.device_manufacturer_id,
  });

  // Get the device models list using Dropdown Search, against selected devices manufacturers
  const {
    data: deviceModels,
    setSearchKey: setDeviceModelSearch,
    isSuccess: deviceModelsSuccess,
  } = useDropDownSearch({
    useData: useGetDModelsQuery,
    simpleSearchKey: "name_or_device_manufacturer_name_cont",
    additionalParams: { device_manufacturer_id: formik.values.device_manufacturer_id },
    selectedIds: formik.values.device_model_id,
  });

  // Get the variable mappings list using Dropdown Search, against selected devices models
  const {
    data: variableMappings,
    setSearchKey: setVariableMappingSearch,
    // isSuccess: isVariableMappingSuccess,
  } = useDropDownSearch({
    useData: useGetVariableMappingsQuery,
    additionalParams: { device_model_id: formik.values.device_model_id },
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.variable_mapping_id,
  });

  // Get the backends list using Dropdown Search
  const {
    data: backends,
    setSearchKey: setBackendSearchKey,
    isSuccess: isBackendSuccess,
  } = useDropDownSearch({
    useData: useGetBackendDropdownQuery,
    simpleSearchKey: "name_or_backend_type_cont",
    additionalParamsToHook: { skip: !formik.values.client_id },
    additionalParams: { device_id: formik.values.id },
    selectedIds: formik.values.backend_ids,
  });

  // Get the geolocation plans list using dropdown search
  const {
    data: geolocation_plans,
    setSearchKey: setGeolocationPlanSearchKey,
    // isSuccess: isGeolocationPlansSuccess,
  } = useDropDownSearch({
    useData: useGetGeolocationsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.geolocation_plan_id,
  });

  // Get the country code list using Dropdown Search for static data
  const { data: countryCode, setSearchKey: setCountryCodeSearchKey } = useStaticDropDownSearch({
    dataSet: Countrycode,
    selectedIds: formik.values.country_code,
  });

  // Get List of apps subscribed by client
  const { data: clientApp = { data: [] }, isSuccess: clientAppSuccess } = useGetAppsQuery({
    client_id: formik.values.client_id,
  });

  // Data of selected device in edit mode
  const { data = {} } = useGetDeviceQuery({ id: idToEdit }, { skip: !idToEdit });

  // This method help to set the device data response in formData
  useEffect(() => {
    if (idToEdit && data.id) {
      const updatedFormData = mergeObjects(formik.values, data);
      setinitialValues(updatedFormData);
    }
  }, [data]);

  //To set the default variable mapping
  useEffect(() => {
    if (formik.values.device_model_id && !formik.values.variable_mapping_id) {
      variableMappings?.data.map((variableMapping) => {
        if (variableMapping.default && manufacturerModelChanged) {
          formik.setFieldValue("variable_mapping_id", variableMapping.id);
        }
      });
    }
  }, [variableMappings]);

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      updateBackend({ id: idToEdit, formData });
    } else {
      createBackend({ formData });
    }
  };

  const clientName = localStorage.getItem("selected-client") || "Client";

  const handleBackendSelection = ({ client_id, forSelect, enabled, name, default: isDefault }) => {
    if (client_id == null) {
      return `${name}-${enabled ? "Enabled" : "Disabled"} (Super Admin added backends)`;
    } else if (client_id != null) {
      return `${name}-${enabled ? "Enabled" : "Disabled"}-${
        isDefault ? "Default" : "Not Default"
      }  (${clientName} Admin backends can't be removed or selected)`;
    }
    if (forSelect && !enabled) {
      return `${name}-Disabled backend can't be selected`;
    }

    return "";
  };

  const isBackendSelectable = ({ client_id, enabled }) => {
    if (client_id) {
      return false;
    }
    return true;
  };

  const isBackendDeletable = ({ client_id }) => {
    if (client_id) {
      return false;
    }
    return true;
  };

  const getClassName = ({ client_id, forSelect, enabled, default: isDefault }) => {
    if (client_id == null) {
      return `by-superadmin-${enabled ? "enabled" : "disabled"}`;
    } else {
      return `by-client-${enabled ? "enabled" : "disabled"}-${
        isDefault ? "default" : "not-default"
      }`;
    }
  };

  // To get default variable mapping from the variable mapping list api response
  const getDefaultVariableMapping = () => {
    variableMappings?.data.map((variableMapping) => {
      if (variableMapping.default) {
        return variableMapping.id;
      }
    });
  };

  return (
    <FormContainer
      resourceName="Device"
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
    >
      <Components.QICustomSelect
        label="Client"
        value={formik.values.client_id}
        onChange={(value) => {
          formik.setFieldValue("client_id", value);
          value && formik.setErrors((prevState) => ({ ...prevState, client_id: "" }));
          setClientSearch("");
        }}
        onSearch={setClientSearch}
        error={formik.touched.client_id && formik.errors.client_id}
        disabled={true}
      >
        {isClientsSuccess &&
          clients.data.map((client) => (
            <li key={client.id} value={client.id}>
              {client.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QICustomSelect
        label="Manufacturer"
        value={formik.values.device_manufacturer_id}
        onChange={(value) => {
          formik.setValues({
            ...formik.values,
            device_manufacturer_id: value,
            device_model_id: "",
            variable_mapping_id: "",
          });
          setDManSearch("");
          setManufacturerModelChanged(true);
        }}
        edit={true}
        onSearch={setDManSearch}
        error={formik.touched.device_manufacturer_id && formik.errors.device_manufacturer_id}
        labelClassName="manufacturer"
      >
        {dmans &&
          dmans.data.map((dman) => (
            <li key={dman.id} value={dman.id}>
              {dman.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QICustomSelect
        label="Model"
        value={formik.values.device_model_id}
        onChange={(value) => {
          formik.setValues({
            ...formik.values,
            device_model_id: value,
            variable_mapping_id: "",
          });
          value &&
            formik.setErrors((prevState) => ({
              ...prevState,
              device_model_id: "",
              variable_mapping_id: "",
            }));
          setDeviceModelSearch("");
          setManufacturerModelChanged(true);
        }}
        error={formik.touched.device_model_id && formik.errors.device_model_id}
        edit={true}
        onSearch={setDeviceModelSearch}
        labelClassName="model"
      >
        {dmansSuccess && deviceModelsSuccess && deviceModels?.data.length == 0 ? (
          <li className="no-item-availlable">No Item Available.</li>
        ) : (
          deviceModels?.data?.map((deviceModel) => (
            <li key={deviceModel?.id} value={deviceModel?.id}>
              {deviceModel?.name}
            </li>
          ))
        )}
      </Components.QICustomSelect>
      <Components.QICustomSelect
        label="Variable Mapping"
        value={
          formik.values.variable_mapping_id
            ? formik.values.variable_mapping_id
            : getDefaultVariableMapping()
        }
        onChange={(value) => {
          formik.setFieldValue("variable_mapping_id", value);
          setVariableMappingSearch("");
        }}
        disabled={!formik.values.device_model_id}
        onSearch={setVariableMappingSearch}
        labelClassName="variable-mapping"
      >
        <li value="">Select ...</li>
        {variableMappings?.data.map((variableMapping) => (
          <li value={variableMapping.id} key={variableMapping.id}>
            {variableMapping.name}
          </li>
        ))}
      </Components.QICustomSelect>
      <Components.QIInput
        label="Name"
        placeholder="Device Name"
        {...formik.getFieldProps("name")}
        disabled={true}
        error={formik.touched.name && formik.errors.name}
      />
      <Components.QIInput
        label="ID/IMEI"
        placeholder="Unique device ID or IMEI"
        {...formik.getFieldProps("device_id")}
        disabled={true}
        error={formik.touched.device_id && formik.errors.device_id}
      />
      <Components.QIInput
        label="Primary SIM Card"
        placeholder="Primary Sim card No."
        {...formik.getFieldProps("primary_sim_card_id")}
        disabled={true}
        error={formik.touched.primary_sim_card_id && formik.errors.primary_sim_card_id}
      />

      <div className="flex justify-between country-code-and-number">
        <Components.QICustomSelect
          label="Country Code"
          placeholder="Country code"
          disabled={true}
          className="country-code-and-number_item1"
          {...formik.getFieldProps("country_code")}
          onChange={(value) => formik.setFieldValue("country_code", value)}
          onSearch={setCountryCodeSearchKey}
          error={formik.touched.country_code && formik.errors.country_code}
          labelClassName="country-code"
        >
          <li value="">Select</li>
          {countryCode.map((code, index) => (
            <li value={code.dial_code} title={`${code.dial_code} (${code.name})`} key={index}>
              {code.dial_code} ({code.name})
            </li>
          ))}
        </Components.QICustomSelect>

        <Components.QIInput
          label="Phone No."
          placeholder="Phone No"
          disabled={true}
          className="country-code-and-number_item2"
          customLabelClass="phone-number"
          customWrapperClass="phone-number-wrapper"
          {...formik.getFieldProps("simcard_phone_no")}
          error={formik.touched.simcard_phone_no && formik.errors.simcard_phone_no}
        />
      </div>

      <div className="qi-input credentials">
        <label className="qi-input_label">SMS Credentials</label>
        <div className="w-3/4">
          <Components.QIInput
            label="Username"
            {...formik.getFieldProps("credentials.username")}
            disabled={true}
            error={formik.errors["credentials.username"]}
          />
          <Components.QIInput
            label="Password"
            type="password"
            {...formik.getFieldProps("credentials.password")}
            disabled={true}
            error={formik.errors["credentials.password"]}
          />
        </div>
      </div>

      <Components.QISwitch
        label="Enabled"
        value={formik.values.enabled}
        disabled={true}
        readOnly={true}
        onChange={() => formik.setFieldValue("enabled", !formik.values.enabled)}
      />

      {/* <Components.QIMultiSelectDropDown
        label="Applications"
        data={clientApp?.data || []}
        selected={formik.values.app_ids}
        onChange={(updatedList) => formik.setFieldValue("app_ids", updatedList)}
        readOnly={true}
      /> */}

      <Components.QIMultiSelectDropDown
        label="Backends"
        data={backends?.data || []}
        className="backends-owner"
        labelKey={(data) => `${data.name} ${!data.enabled ? "(Disabled)" : ""}`}
        selected={formik.values.backend_ids}
        onChange={(updatedList) => {
          formik.setFieldValue("backend_ids", updatedList);
          setBackendSearchKey("");
        }}
        onSearch={(value) => setBackendSearchKey(value)}
        hasAction={({ default: isDefault }) => !isDefault}
        disabled={!isBackendSuccess}
        actionError={handleBackendSelection}
        getRowClass={getClassName}
        isSelectable={isBackendSelectable}
        isDeletable={isBackendDeletable}
      />

      <Components.QICustomSelect
        label="Geolocation Plan"
        value={formik.values.geolocation_plan_id}
        onChange={(value) => {
          formik.setFieldValue("geolocation_plan_id", value);
          setGeolocationPlanSearchKey("");
        }}
        error={formik.touched.geolocation_plan_id && formik.errors.geolocation_plan_id}
        onSearch={(value) => setGeolocationPlanSearchKey(value)}
        labelClassName="geolocation-plan"
      >
        <li value="">Select...</li>
        {(geolocation_plans?.data || []).map((g) => (
          <li value={g.id} key={g.id}>
            {g.name}
          </li>
        ))}
      </Components.QICustomSelect>

      <Components.QIInput
        label="Firmware Version"
        placeholder="Firmware Version"
        disabled={true}
        {...formik.getFieldProps("firmware_version")}
        error={formik.touched.firmware_version && formik.errors.firmware_version}
      />
    </FormContainer>
  );
};
