import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { useForm, useDropDownSearch } from "../../../hooks";
import { FormContainer } from "../Shared/FormContainer";
import { mergeObjects } from "../../../shared/helper";
import {
  useCreateVTypeMutation,
  useUpdateVTypeMutation,
  useGetVTypeQuery,
  useGetClientsQuery,
} from "../services";
import { vTypesFormValidation } from "../validations";

export const VTypeForm = ({ idToEdit = null, closeForm }) => {
  const dropDownParams = { per_page: 1000, order_by: "name" };

  const [initialValues, setInitialValues] = useState({
    name: "",
    client_id: "",
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: vTypesFormValidation,
    onSubmit: (values) => handleFormSubmit(values),
  });

  // Get the clients list using Dropdown Search
  const {
    data: clients,
    setSearchKey: setClientSearch,
    isSuccess: isClientsSuccess,
  } = useDropDownSearch({
    useData: useGetClientsQuery,
    simpleSearchKey: "name_or_email_cont",
    selectedIds: formik.values.client_id,
    additionalParams: { ...dropDownParams },
  });

  const { data } = useGetVTypeQuery({ id: idToEdit }, { skip: !idToEdit });

  const { create: createVType, update: updateVType } = useForm({
    createMutation: useCreateVTypeMutation,
    updateMutation: useUpdateVTypeMutation,
    closeForm,
    setError: formik.setErrors,
  });

  useEffect(() => {
    if (idToEdit && data) {
      setInitialValues(mergeObjects(initialValues, data));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      updateVType({ id: idToEdit, formData });
    } else {
      createVType({ formData });
    }
  };

  return (
    <FormContainer
      resourceName="Vehicle Type"
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
    >
      <Components.QICustomSelect
        label="Client"
        {...formik.getFieldProps("client_id")}
        onChange={(value) => {
          formik.setFieldValue("client_id", value);
          setClientSearch("");
        }}
        error={formik.touched.client_id && formik.errors.client_id}
        onSearch={setClientSearch}
        labelClassName="client"
      >
        <li value="">Select</li>
        {isClientsSuccess &&
          clients.data.map((client) => (
            <li key={client.id} value={client.id}>
              {client.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QIInput
        label="Name"
        placeholder="Hatchback/SUV/MUV"
        {...formik.getFieldProps("name")}
        error={formik.touched && formik.errors.name}
      />
    </FormContainer>
  );
};
