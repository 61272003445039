import sprite from "../assets/nativeIcons/icons.svg";

export const NativeSvgIcon = ({
  wrapperClass = "",
  svgClass = "",
  name,
  onClick = () => {},
  title = "",
}) => {
  return (
    <span className={`icon-wrapper ${wrapperClass || " "}`} onClick={onClick} title={title}>
      <svg className={`icon ${svgClass || " "}`}>
        <use href={`${sprite}#${name}`}></use>
      </svg>
    </span>
  );
};
