import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useForm, useDropDownSearch } from "../../../hooks";
import * as Components from "../../../components";
import { FormContainer } from "../Shared/FormContainer";
import {
  useCreateVModelMutation,
  useUpdateVModelMutation,
  useGetVModelQuery,
  useGetClientsQuery,
  useGetVTypesQuery,
  useGetVClassesQuery,
  useGetVManModelVariantsQuery,
  useGetVMansQuery,
  useGetVManModelsQuery,
  useGetObdCodesQuery,
} from "../services";
import { mergeObjects } from "../../../shared/helper";
import { vehicleModelFormShema } from "../validations";

const dropmenuParams = { per_page: 1000, order_by: "name" };

export const VModelForm = ({ idToEdit = null, closeForm }) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    client_id: "",
    vehicle_manufacturer_id: "",
    vehicle_manufacturer_model_id: "",
    vehicle_manufacturer_model_variant_id: "",
    vehicle_class_id: "",
    vehicle_type_id: "",
    capacity: "",
    engine_capacity: "",
    year: "",
    obd_code_ids: [],
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: vehicleModelFormShema,
    onSubmit: (values) => handleFormSubmit(values),
  });

  // Get the clients list using Dropdown Search
  const {
    data: clients,
    setSearchKey: setClientSearch,
    isSuccess: isClientsSuccess,
  } = useDropDownSearch({
    useData: useGetClientsQuery,
    simpleSearchKey: "name_or_email_cont",
    selectedIds: formik.values.client_id,
    additionalParams: { ...dropmenuParams },
  });

  // Get Vehicle Types using Dropdown Search
  const {
    data: vTypes = { data: [] },
    setSearchKey: setVTypesSearch,
    isSuccess: isVtypesSuccess,
  } = useDropDownSearch({
    useData: useGetVTypesQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_type_id,
    additionalParams: formik.values.client_id
      ? { ...dropmenuParams, client_id: formik.values.client_id }
      : { ...dropmenuParams, only_master: true },
  });

  // Get Vehicle classes using Dropdown Search
  const {
    data: vClasses = { data: [] },
    setSearchKey: setVClassesSearch,
    isSuccess: isVClassesSuccess,
  } = useDropDownSearch({
    useData: useGetVClassesQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_class_id,
    additionalParams: formik.values.client_id
      ? { ...dropmenuParams, client_id: formik.values.client_id }
      : { ...dropmenuParams, only_master: true },
  });

  // Get the vehicle manufacturers list using Dropdown Search
  const {
    data: vMans = { data: [] },
    setSearchKey: setVManSearch,
    isSuccess: isVmansSuccess,
  } = useDropDownSearch({
    useData: useGetVMansQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_manufacturer_id,
    additionalParams: formik.values.client_id
      ? { ...dropmenuParams, client_id: formik.values.client_id }
      : { ...dropmenuParams, only_master: true },
  });

  // Get the vehicle manufacturer model list using Dropdown Search
  const {
    data: vManufacturerModels = { data: [] },
    setSearchKey: setVManModelSearch,
    isSuccess: isVManufacturerModelSuccess,
  } = useDropDownSearch({
    useData: useGetVManModelsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_manufacturer_model_id,
    additionalParams: {
      ...dropmenuParams,
      client_id: formik.values.client_id,
      vehicle_manufacturer_id: formik.values.vehicle_manufacturer_id,
    },
    additionalParamsToHook: { skip: !formik.values.vehicle_manufacturer_id },
  });

  // Get the  Obd codes list using Dropdown Search
  const {
    data: obdCodes,
    setSearchKey: setObdCodeSearchKey,
    isSuccess: obdCodeSuccess,
  } = useDropDownSearch({
    useData: useGetObdCodesQuery,
    simpleSearchKey: "code_or_name_cont",
    selectedIds: formik.values.obd_code_ids,
    // additionalParams: { ...dropmenuQueryParams },
  });

  // Get Vehicle Manufacturer model variants list using Dropdown Search
  const {
    data: variants = { data: [] },
    setSearchKey: setVManModelVariantsSearch,
    isSuccess: isVariantsSuccess,
  } = useDropDownSearch({
    useData: useGetVManModelVariantsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_manufacturer_model_variant_id,
    additionalParams: {
      ...dropmenuParams,
      vehicle_manufacturer_model_id: formik.values.vehicle_manufacturer_model_id,
    },
  });

  const { create: createVModel, update: updateVModel } = useForm({
    createMutation: useCreateVModelMutation,
    updateMutation: useUpdateVModelMutation,
    closeForm,
    setError: formik.setErrors,
  });

  // Fetch selected model data in edit mode
  const { data } = useGetVModelQuery({ id: idToEdit }, { skip: !idToEdit });

  useEffect(() => {
    if (idToEdit && data) {
      setInitialValues(mergeObjects(initialValues, data));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      updateVModel({ id: idToEdit, formData });
    } else {
      createVModel({ formData });
    }
  };

  // const handleObdCodes = (updatedIds) => {
  //   // If user try to add new obd code
  //   if (updatedIds.length > formik.values.obd_code_ids.length) {
  //     const lastInsertedObdCode = updatedIds.pop();
  //     const selectedObdCode = obdCodes.data.find((obdCode) => obdCode.id === lastInsertedObdCode);
  //     const selectedType = selectedObdCode?.code_type;
  //     const idsOfcodeOfSameType = [];

  //     for (let i = 0; i < obdCodes?.data?.length; i++) {
  //       const record = obdCodes?.data[i];

  //       if (record && record.code_type === selectedType) {
  //         idsOfcodeOfSameType.push(record.id);
  //       }
  //     }

  //     updatedIds = updatedIds.filter((id) => !idsOfcodeOfSameType.includes(id));
  //     updatedIds.push(lastInsertedObdCode);
  //     formik.setFieldValue("obd_code_ids", updatedIds);
  //   }
  //   // If user try to remove obd code
  //   else {
  //     formik.setFieldValue("obd_code_ids", updatedIds);
  //   }
  // };

  return (
    <FormContainer
      resourceName="Specification"
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
    >
      <Components.QICustomSelect
        label="Client"
        {...formik.getFieldProps("client_id")}
        onChange={(value) => {
          formik.setValues({
            ...formik.values,
            client_id: value,
            vehicle_manufacturer_id: "",
            vehicle_manufacturer_model_id: "",
            vehicle_manufacturer_model_variant_id: "",
          });
          setClientSearch("");
        }}
        onSearch={setClientSearch}
        error={formik.touched.client_id && formik.errors.client_id}
        labelClassName="client"
      >
        <li value="">Select</li>
        {isClientsSuccess &&
          clients.data.map((client) => (
            <li key={client.id} value={client.id}>
              {client.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QICustomSelect
        label="Manufacturer"
        {...formik.getFieldProps("vehicle_manufacturer_id")}
        onChange={(value) => {
          formik.setValues({
            ...formik.values,
            vehicle_manufacturer_id: value,
            vehicle_manufacturer_model_id: "",
            vehicle_manufacturer_model_variant_id: "",
          });
          setVManSearch("");
        }}
        onSearch={setVManSearch}
        error={formik.touched.vehicle_manufacturer_id && formik.errors.vehicle_manufacturer_id}
        labelClassName="manufacturer"
      >
        <li value="">Select</li>
        {isVmansSuccess &&
          vMans.data.map((vMan) => (
            <li key={vMan.id} value={vMan.id}>
              {vMan.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QICustomSelect
        label="Model"
        {...formik.getFieldProps("vehicle_manufacturer_model_id")}
        onChange={(value) => {
          formik.setValues({
            ...formik.values,
            vehicle_manufacturer_model_id: value,
            vehicle_manufacturer_model_variant_id: "",
          });
          setVManModelSearch("");
        }}
        onSearch={setVManModelSearch}
        error={
          formik.touched.vehicle_manufacturer_model_id &&
          formik.errors.vehicle_manufacturer_model_id
        }
        disabled={formik.values.vehicle_manufacturer_id === ""}
        labelClassName="model"
      >
        <li value="">Select</li>
        {isVManufacturerModelSuccess &&
          vManufacturerModels.data.map((vManModel) => (
            <li key={vManModel.id} value={vManModel.id}>
              {vManModel.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QICustomSelect
        label="Variant"
        {...formik.getFieldProps("vehicle_manufacturer_model_variant_id")}
        onChange={(value) => {
          formik.setFieldValue("vehicle_manufacturer_model_variant_id", value);
          setVManModelVariantsSearch("");
        }}
        onSearch={setVManModelVariantsSearch}
        disabled={formik.values.vehicle_manufacturer_model_id === ""}
        error={
          formik.touched.vehicle_manufacturer_model_variant_id &&
          formik.errors.vehicle_manufacturer_model_variant_id
        }
        labelClassName="variant"
      >
        <li value="">Select</li>
        {isVariantsSuccess &&
          variants.data.map((variant) => (
            <li key={variant.id} value={variant.id}>
              {variant.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QIInput
        label="Name"
        placeholder=""
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />
      <Components.QIInput
        label="Capacity"
        placeholder="4/6/10"
        {...formik.getFieldProps("capacity")}
        error={formik.touched.capacity && formik.errors.capacity}
      />
      <Components.QIInput
        label="Engine Capacity"
        placeholder="2000CC"
        {...formik.getFieldProps("engine_capacity")}
        error={formik.touched.engine_capacity && formik.errors.engine_capacity}
      />
      <Components.QIInput
        label="Year"
        placeholder={new Date().getFullYear()}
        {...formik.getFieldProps("year")}
        error={formik.touched.year && formik.errors.year}
      />

      <Components.QICustomSelect
        label="Types"
        onChange={(updatedList) => {
          formik.setFieldValue("vehicle_type_id", updatedList);
          setVTypesSearch("");
        }}
        value={formik.values.vehicle_type_id}
        onSearch={setVTypesSearch}
        labelClassName="types"
      >
        <p className="dropdown-heading">System Default</p>
        {isVtypesSuccess &&
          vTypes?.data?.map((type, index) => {
            return (
              type.client_name === null && (
                <li value={type.id} key={index}>
                  {type.name}
                </li>
              )
            );
          })}
        {formik.values.client_id && <p className="dropdown-heading">Client Created</p>}
        {isVtypesSuccess &&
          vTypes?.data?.map((type, index) => {
            return (
              type.client_name && (
                <li value={type.id} key={index}>
                  {type.name}
                </li>
              )
            );
          })}
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label="Classes"
        onChange={(updatedList) => {
          formik.setFieldValue("vehicle_class_id", updatedList);
          setVClassesSearch("");
        }}
        value={formik.values.vehicle_class_id}
        onSearch={setVClassesSearch}
        labelClassName="classes"
      >
        <p className="dropdown-heading">System Default</p>
        {isVClassesSuccess &&
          vClasses?.data?.map((classes, index) => {
            return (
              classes.client_name === null && (
                <li value={classes.id} key={index}>
                  {classes.name}
                </li>
              )
            );
          })}
        {formik.values.client_id && <p className="dropdown-heading">Client Created</p>}
        {isVClassesSuccess &&
          vClasses?.data?.map((classes, index) => {
            return (
              classes.client_name && (
                <li value={classes.id} key={index}>
                  {classes.name}
                </li>
              )
            );
          })}
      </Components.QICustomSelect>
      <Components.QIMultiSelectDropDown
        label="Obd Codes"
        data={obdCodes.data}
        selected={formik.values.obd_code_ids}
        onChange={(updatedList) => {
          formik.setFieldValue("obd_code_ids", updatedList);
          setObdCodeSearchKey("");
        }}
        onSearch={(value) => setObdCodeSearchKey(value)}
        error={formik.touched.obd_code_ids && formik.errors.obd_code_ids}
      />
    </FormContainer>
  );
};
