import { fullLogo } from "../../assets/";

import { banner } from "../../assets/";
import { dashboard } from "../../assets/";
import "./documentation.scss";

export const Documentation = () => {
  return <>
    <div className="static">
      <header className="site-header">
          <span className="brand"><img src={fullLogo} /></span>
          <h3>Fleet Mobility Data Platform</h3>
      </header>

      <main className="main-container-wrapper">
        <aside className="site-banner">
          <img className="site-banner_image" src={banner} />
          <h2 className="site-banner_title">FMDP user Documentation</h2>
        </aside>

        <div className="main-container">
          <h1 className="main-container_title">FMDP Documentation</h1>
          <p className="information-section_content">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni.</p>

          <p className="information-section_content">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni. Sed ut perspiciatis unde omnis.</p>

          <section className="information-section">
            <div className="information-section_graphics"><img src={dashboard} /></div>

            <div className="information-section_data">
              <h2 className="information-section_title">Information Title 1</h2>

              <p className="information-section_content">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni.</p>
            </div>
          </section>

          <section className="information-section reverse">
            <div className="information-section_graphics"><img src={dashboard} /></div>

            <div className="information-section_data">
              <h2 className="information-section_title">Information Title 2</h2>

              <p className="information-section_content">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni.</p>
            </div>
          </section>

          <section className="information-section">
            <div className="information-section_graphics"><img src={dashboard} /></div>

            <div className="information-section_data">
              <h2 className="information-section_title">Information Title 3</h2>

              <p className="information-section_content">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni.</p>
            </div>
          </section>
        </div>
      </main>

      <footer className="site-footer">
        <p>Copyright © 2005 - 2022, Quantum Inventions Pte Ltd. All rights reserved.</p>
      </footer>
    </div>
  </>;
};
