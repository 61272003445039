import React from "react";
import { QIModal, QIModalBody, QIButton, QIModalFooter, QIModalHeader } from "../../../components";
import { getLocalizedString } from "../../../shared/translation";

export const ConfirmationModal = ({
  onConfirm,
  onCancel,
  onOkay,
  children,
  show,
  headerText,
  yesLabel,
  noLabel,
  headerClassName = "",
}) => {
  return (
    <QIModal show={show} className="confirm" backdropView={false}>
      <QIModalBody>
        <h2 className={`page-title mb-4 text-center ${headerClassName}`}>{headerText}</h2>
        {children}
      </QIModalBody>
      <QIModalFooter>
        {onOkay ? (
          <QIButton className="qi-btn primary sm" onClick={onOkay}>
            {getLocalizedString("ok", "OK")}
          </QIButton>
        ) : (
          <>
            <QIButton className="qi-btn primary sm" onClick={onConfirm}>
              {yesLabel || getLocalizedString("yes", "Yes")}
            </QIButton>
            <QIButton className="qi-btn primary sm" onClick={onCancel}>
              {noLabel || getLocalizedString("no", "No")}
            </QIButton>
          </>
        )}
      </QIModalFooter>
    </QIModal>
  );
};
