import React from "react";
import { QIModal, QIModalBody, QIModalFooter, QIButton } from "../../../components";
import { SvgIcon } from "../../Shared";
import { getLocalizedString } from "../../../shared/translation";

export const MPSharedDeleteModal = ({
  entityName,
  itemName,
  onHide,
  show,
  onDelete,
  error,
  idToDelete,
  getApiEndpoint,
}) => {
  let idData = {};
  if (idToDelete) {
    idData = getApiEndpoint({ id: idToDelete });
  }

  let confirmationMessage =
    getLocalizedString("the_association_with", "The association with") + " ";
  if (idData?.data?.vehicle_group_ids?.length || idData?.data?.device_group_ids?.length) {
    if (idData?.data?.device_group_ids?.length) {
      confirmationMessage += getLocalizedString("device_group", "Device Group") + "(s) ";
    }
    if (idData?.data?.vehicle_group_ids?.length && idData?.data?.device_group_ids?.length) {
      confirmationMessage += getLocalizedString("and", "and") + " ";
    }
    if (idData?.data?.vehicle_group_ids?.length) {
      confirmationMessage += getLocalizedString("vehicle_group", "Vehicle Group") + "(s) ";
    }
    confirmationMessage += getLocalizedString("will_also_be_deleted", "will also be deleted.");
  } else {
    confirmationMessage = "";
  }

  return (
    <QIModal show={show} scrollable={true} className="confirm">
      <QIModalBody className={`${error && "association-error"}`}>
        {error ? (
          <div className="association-error_container">
            <SvgIcon wrapperClass="cross-icon" svgClass="icon-cross" name="cross" />
            <p className="association-error_message">{error}.</p>
          </div>
        ) : (
          <>
            <h5 className="page-title mb-4 text-center">
              <strong>
                {getLocalizedString("confirmation_alert", "Confirmation Alert ") + "!"}
              </strong>
            </h5>
            <div className="confirmation-message">
              <span>
                <SvgIcon name="alert" className="confirmation-message_icon" />
              </span>
              <p>
                {getLocalizedString("you_are_about_to_delete_the", "You are about to delete the")}{" "}
                {entityName} <strong>{itemName}</strong>.
              </p>
              {idToDelete && (
                <p>
                  <strong>{confirmationMessage}</strong>
                </p>
              )}
              <p>{getLocalizedString("are_you_sure", "Are you sure") + "?"}</p>
            </div>
          </>
        )}
      </QIModalBody>
      <QIModalFooter>
        <QIButton onClick={onHide} className="qi-btn secondary md" variant="secondary" size="sm">
          {error ? "Close" : getLocalizedString("no", "No")}
        </QIButton>
        {!error && (
          <QIButton onClick={onDelete} size="sm" variant="danger" className="qi-btn primary md">
            {getLocalizedString("yes", "Yes")}
          </QIButton>
        )}
      </QIModalFooter>
    </QIModal>
  );
};
