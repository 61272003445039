import React from "react";
import { QIModal, QIModalBody, QIButton, QIModalFooter, QIModalHeader } from "../../../components";

export const ConfirmationModal = ({ onConfirm, onCancel, onOkay, children, show, headerText }) => {
  return (
    <QIModal show={show} className="confirm">
      <QIModalBody>
        <h2 className="page-title mb-4 text-center">{headerText}</h2>
        {children}
      </QIModalBody>
      <QIModalFooter>
        {onOkay ? (
          <QIButton className="qi-btn primary sm" onClick={onOkay}>
            OK
          </QIButton>
        ) : (
          <>
            <QIButton className="qi-btn primary sm" onClick={onConfirm}>
              Yes
            </QIButton>
            <QIButton className="qi-btn primary sm" onClick={onCancel}>
              No
            </QIButton>
          </>
        )}
      </QIModalFooter>
    </QIModal>
  );
};
