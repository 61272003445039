import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { FormContainer } from "../Shared";
import { useCreateUserGroupCsvMutation, useUpdateUserGroupCsvMutation } from "../services";
import { useForm } from "../../../hooks";
import { UserGroupCsvUploadFormSchema } from "../validations";
import { getCsvDetails } from "../../../shared/helper";
import { useGetUserGroupsQuery } from "../services";
import {getLocalizedString} from "../../../shared/translation";

export const UserGroupCsvUploadForm = ({ idToEditOrCsvUpload = null, onClose }) => {
  const dropdownParams = { per_page: 1000, order_by: "name" };
  const [initialValues, setInitialValues] = useState({
    csv_file: null,
    update_if_exist: false,
    csv_column_mappings: {
      first_name: "",
      last_name: "",
      country_code: "",
      phone_number: "",
      email: "",
      active: "",
      password: "",
      management_portal_roles: "",
      tracking_portal_roles: "",
    },
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: UserGroupCsvUploadFormSchema,
    onSubmit: (value) => {
      handleFormSubmit(value);
    },
  });

  const [headers, setHeaders] = useState([]);
  const [selectedHeaders, setSelectedHeaders] = useState([]);
  const [isCsvUpload, setIsCsvUpload] = useState(false);

  useEffect(() => {
    let tempArray = [];
    Object.keys(formik.values.csv_column_mappings).map((key) => {
      if (formik.values.csv_column_mappings[key]) {
        tempArray.push(formik.values.csv_column_mappings[key]);
      }
    });
    setSelectedHeaders(tempArray);
  }, [formik.values.csv_column_mappings]);

  useEffect(() => {
    if (formik.values.csv_file) {
      getCsvDetails(formik.values.csv_file, updateCsvDetails);
    }
  }, [formik.values.csv_file]);

  //To set the user group when asset is imported for a particular user group
  useEffect(() => {
    if (idToEditOrCsvUpload) {
      formik.setFieldValue("group_id", idToEditOrCsvUpload);
    }
  }, [idToEditOrCsvUpload]);

  const { create: createUserGroupCsv } = useForm({
    createMutation: useCreateUserGroupCsvMutation,
    updateMutation: useUpdateUserGroupCsvMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  const { data: userGroups, isSuccess: isUserGroupsSuccess } =
    useGetUserGroupsQuery(dropdownParams);

  const handleFormSubmit = (formData) => {
    const formObj = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === "csv_column_mappings") {
        formObj.append(key, JSON.stringify(formData[key]));
      } else {
        if (formData[key] !== null) {
          formObj.append(key, formData[key]);
        }
      }
    });
    createUserGroupCsv({ id: idToEditOrCsvUpload, formData: formObj });
  };

  const updateCsvDetails = (headers, csvSize) => {
    setHeaders(headers);
    setIsCsvUpload(true);
  };

  const showWarningMessage = (value) => {
    let countValueOccurence = 0;
    selectedHeaders.map((header) => {
      if (header === value) {
        countValueOccurence++;
      }
    });
    if (countValueOccurence > 1) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <FormContainer
      closeForm={onClose}
      handleFormSubmit={formik.handleSubmit}
      customResourceName="Import Users"
    >
      <Components.QICustomSelect
        label={getLocalizedString("group", "Group")}
        {...formik.getFieldProps("group_id")}
        onChange={(value) => {
          formik.setFieldValue("group_id", value);
        }}
        error={formik?.touched?.group_id && formik?.errors?.group_id}
        disabled={idToEditOrCsvUpload ? true : false}
        labelClassName="group"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {userGroups?.data?.map((group, index) => (
            <li value={group?.id} key={index}>
              {group?.name}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QIFileUploader
        label={getLocalizedString("import_users", "Import Users")}
        placeholder={getLocalizedString("only_csv_file", "Only .csv files")}
        value={formik.values.csv_file}
        onChange={(files) => formik.setFieldValue("csv_file", files[0])}
        error={formik.touched.csv_file && formik.errors.csv_file}
      />

      <Components.QISwitch
        label={getLocalizedString("update_if_exists", "Update if exists")}
        {...formik.getFieldProps("update_if_exist")}
        error={formik.touched.update_if_exist && formik.errors.update_if_exist}
        onChange={() => formik.setFieldValue("update_if_exist", !formik.values.update_if_exist)}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        readOnly={!formik.values.csv_file || formik.errors.csv_file}
      />

      <Components.QICustomSelect
        label={getLocalizedString("first_name", "First Name")}
        {...formik.getFieldProps("csv_column_mappings.first_name")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.first_name", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.first_name &&
          formik?.errors?.csv_column_mappings?.first_name
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.first_name)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="first-name"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("last_name", "Last Name")}
        {...formik.getFieldProps("csv_column_mappings.last_name")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.last_name", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.last_name &&
          formik?.errors?.csv_column_mappings?.last_name
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.last_name)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="last-name"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("country_code", "Country Code")}
        {...formik.getFieldProps("csv_column_mappings.country_code")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.country_code", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.country_code &&
          formik?.errors?.csv_column_mappings?.country_code
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.country_code)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="country-code"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("phone_no", "Phone No")}
        {...formik.getFieldProps("csv_column_mappings.phone_number")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.phone_number", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.phone_number &&
          formik?.errors?.csv_column_mappings?.phone_number
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.phone_number)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="phn-no"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("email", "Email")}
        {...formik.getFieldProps("csv_column_mappings.email")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.email", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.email && formik?.errors?.csv_column_mappings?.email
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.email)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="email"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("active", "Active")}
        {...formik.getFieldProps("csv_column_mappings.active")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.active", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.active &&
          formik?.errors?.csv_column_mappings?.active
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.active)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="email"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={getLocalizedString("password", "Password")}
        {...formik.getFieldProps("csv_column_mappings.password")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.password", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.password &&
          formik?.errors?.csv_column_mappings?.password
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.password)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="password"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={
          getLocalizedString("management_portal_applications", "Management Portal Applications")
        }
        {...formik.getFieldProps("csv_column_mappings.management_portal_roles")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.management_portal_roles", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.management_portal_roles &&
          formik?.errors?.csv_column_mappings?.management_portal_roles
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.management_portal_roles)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="applications"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label={
          getLocalizedString("tracking_portal_applications", "Tracking Portal Applications")
        }
        {...formik.getFieldProps("csv_column_mappings.tracking_portal_roles")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.tracking_portal_roles", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.tracking_portal_roles &&
          formik?.errors?.csv_column_mappings?.tracking_portal_roles
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.tracking_portal_roles)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={!formik.values.csv_file || formik.errors.csv_file}
        labelClassName="applications"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>
    </FormContainer>
  );
};
