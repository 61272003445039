import React from "react";
import PropTypes, { element, string } from "prop-types";

/**
* This is button which support icons.
*
* @param {string} [type:"button"]- A string param for type of button
* @param {boolean} [disabled:false] - boolean value for disable/enable btn
* @param {string} [size:"sm"] - A string param for size of button anyof("lg","md","sm")
* @param {string} [className: ''] - A string for custom class of button
* @param {text}  [iconAlign:"left"] - Any of ['right','left'] to align icon right/left
* @param {text} [variant:"primary"] - A string for ReactBootstrap button Type
* @param {object} [icon: {
* @param {string}  [height:'30px'],
* @param {string}  [width:'30px'],
* @param {string}  [src: ''],
* @param {oneOf['right','left']} [align:  'left'}] - A object to define props of icon
* @example
* <QIButton
    type="button"
    size="md"
    className="fleet-button"
    icon={{src:img,height:'20px',width:'20px',align:'right'}}
    onClick={() => handleButtonClick()}
    variant="link"
  >
    Click Me
  </QIButton>
 */

export const QIButton = ({ className, type, disabled, icon = {}, onClick, children, ...props }) => {
  const { className: iconClass, wrapperClass, src } = icon;

  return (
    <button
      data-testid="button"
      onClick={onClick}
      type={type}
      className={`qi-btn ${disabled ? "disabled" : ""} ${className || ""}`}
      disabled={disabled}
    >
      <span className="qi-btn_label">{children}</span>
      {src && typeof src === "string" ? (
        <span className={`icon-wrapper ${wrapperClass}`}>
          <svg className={`icon ${iconClass || ""}`}>
            <use href={src}></use>
          </svg>
        </span>
      ) : (
        src
      )}
    </button>
  );
};

QIButton.defaultProps = {
  type: "button",
  disabled: false,
  className: "",
  onClick: () => {},
};

QIButton.propTypes = {
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.shape({
    src: PropTypes.oneOfType([string, element]),
    className: PropTypes.any,
  }),
  children: PropTypes.any,
  onClick: PropTypes.func,
};
