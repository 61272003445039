import React, { useState, useEffect } from "react";
import * as Components from "../../../components";
import { useCreateDManMutation, useGetDManQuery, useUpdateDManMutation } from "../services";
import { FormContainer } from "../Shared/FormContainer";
import { useForm } from "../../../hooks";

export const DManForm = ({ idToEdit = null, closeForm }) => {
  const [formData, setFormData] = useState({
    name: "",
  });

  // error handling
  const [error, setError] = useState({});

  const { create, update } = useForm({
    createMutation: useCreateDManMutation,
    updateMutation: useUpdateDManMutation,
    closeForm,
    setError,
  });

  const { data } = useGetDManQuery({ id: idToEdit }, { skip: !idToEdit });

  useEffect(() => {
    if (idToEdit && data) {
      setFormData(data);
    }
  }, [data]);

  const handleFormChange = (field, value) => {
    setFormData((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleFormSubmit = () => {
    if (idToEdit) {
      update({ id: idToEdit, formData });
    } else {
      create({ formData });
    }
  };

  return (
    <FormContainer
      resourceName="Manufacturer"
      idToEdit={idToEdit}
      handleFormSubmit={handleFormSubmit}
      closeForm={closeForm}
      error={error}
    >
      <Components.QIInput
        label="Name"
        placeholder="Concox"
        value={formData.name}
        onChange={(e) => handleFormChange("name", e.target.value)}
        error={error.name}
      />
    </FormContainer>
  );
};
