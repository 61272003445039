import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  QIDropdown,
  QIModal,
  QIModalBody,
  QIModalFooter,
  QIButton,
  QINotification,
} from "../../../components";
import { SvgIcon } from "../../Shared";
import { FMDPStorage } from "../../../shared/helper";
import { toggleAppInfo } from "../../Authentication/helper";
import { tHelper } from "../../../shared/translation";
import { smallLogo, userImg } from "../../../assets";
import {
  useCreateAuthMutation,
  useSelectClientAppMutation,
  useSelectSuperAdminMutation,
  useLogoutAppMutation,
} from "../../Authentication/service";
import {
  useGetLanguagesQuery,
  useGetNotificationsQuery,
  useGetNotificationsCountQuery,
  useUpdateNotificationReadMutation,
} from "../services";
import { useSearchQuery, usePagination } from "../../../hooks";
import { Scrollbars } from "react-custom-scrollbars";
import { setFlashScreen, setWebSocket } from "../../../reduxStore/features";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "../../../shared/translation";

export const CommonHeader = ({ appName, appToToggle, setLayoutSelectedAppId }) => {
  const navigate = useNavigate();
  const [currentLanguage] = useState(tHelper.currentLanguage);
  const [selectedAppId, setSelectedAppId] = useState(FMDPStorage.get("selected-app-id"));
  const [appToBeSelected, setAppToBeSelected] = useState("");
  const [dropDownSection, setDropDownSection] = useState("");
  const [quickDashboard, setQuickDashBoard] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [gridSelectedApp, setGridSelectedApp] = useState("");
  const [showConfirmationModal, setshowConfirmationModal] = useState();

  const { page, setPage, perPage, pagination } = usePagination();

  const dashboardRef = useRef(null);

  const { q } = useSearchQuery({
    page,
    setPage,
  });

  const dispatch = useDispatch();

  const queryParams = {
    page: page,
    per_page: perPage,
    order_by: "id",
    order_dir: "desc",
  };

  const [
    selectClientApp,
    {
      isSuccess: selectClientAppSuccess,
      data: clientAppData,
      reset: resetSelectClientApp,
      error: selectClientAppError,
    },
  ] = useSelectClientAppMutation({
    fixedCacheKey: "shared-client-app-data",
  });

  const [
    selectSuperAdmin,
    {
      isSuccess: selectSuperAdminSuccess,
      reset: resetSelectSuperAdmin,
      error: selectSuperAdminError,
    },
  ] = useSelectSuperAdminMutation({
    fixedCacheKey: "shared-superadmin-app-data",
  });

  const [logoutApp, { isSuccess: logoutAppSuccess, reset: resetlogoutApp, error: logoutAppError }] =
    useLogoutAppMutation();

  const currentUser = FMDPStorage.get("current-user");
  const selectedClientId = FMDPStorage.get("selected-client-id");

  const [, { data: user }] = useCreateAuthMutation({
    fixedCacheKey: "shared-sso-data",
  });

  const { data: languages } = useGetLanguagesQuery({ per_page: 1000, order_by: "name" });

  //On load Notification Count
  const { data: initialNotificationCount } = useGetNotificationsCountQuery();

  const clientName = localStorage.getItem("selected-client") || "";

  const getCurrentLaguage = useMemo(() => {
    if (languages && languages.data) {
      let currentLangObj = languages.data.find((lan) => lan?.code === currentLanguage);
      if (currentLangObj) {
        return currentLangObj.name;
      }
    }
    return "";
  }, [languages, currentLanguage]);

  const logOut = () => {
    logoutApp();
  };

  useEffect(() => {
    if (logoutAppSuccess) {
      FMDPStorage.clear();
      window.location.reload();
    }
  }, [logoutAppSuccess]);

  const switchLanguage = (language) => {
    if (currentLanguage !== language) {
      tHelper.changeLanguage(language);
      window.location.reload();
    }
  };

  const switchApplication = () => {
    if (user.superadmin && selectedAppId !== appToBeSelected && appToBeSelected == "superadmin") {
      selectSuperAdmin();
    } else if (appToBeSelected !== selectedAppId) {
      selectClientApp({ client_app_id: appToBeSelected });
    }
    dispatch(setWebSocket(null));
  };

  // Navigate to app and toggle app info once app is changed
  useEffect(() => {
    if (selectClientAppSuccess) {
      if (toggleAppInfo(user, appToBeSelected || appToToggle, clientAppData)) {
        setSelectedAppId(appToBeSelected || appToToggle);
        navigate("/");
      }
      resetSelectClientApp();
    }
  }, [selectClientAppSuccess]);

  // Navigate to admin dashboard if selected as super admin
  useEffect(() => {
    if (selectSuperAdminSuccess) {
      FMDPStorage.set("selected-app", "SuperAdmin");
      FMDPStorage.set("selected-app-id", "superadmin");
      FMDPStorage.set("selected-client", "");
      navigate("/");
      resetSelectSuperAdmin();
    }
  }, [selectSuperAdminSuccess, selectSuperAdminError]);

  const initiateAppChange = (appId) => {
    if (appId !== selectedAppId) {
      setAppToBeSelected(appId);
      setLayoutSelectedAppId(appId);
    }
  };

  const extractAppName = (app) => {
    const currentAppName =
      app?.name?.substring(0, app?.name?.lastIndexOf("-"))?.trim() +
        " " +
        app?.name?.substring(app?.name?.lastIndexOf("-") + 1, app?.name?.length)?.trim() || "";
    return currentAppName;
  };

  const getCustomName = () => {
    let app = user?.client_apps?.find((app) => app.id === selectedAppId);
    if (app) {
      return app.custom_name?.trim() ? app.custom_name : extractAppName(app);
    }
    return "";
  };
  /*
  const getAppIcon = () => {
    let app = currentUser?.client_apps?.find((app) => extractAppName(app) == appName);

    if (app && user-dropdown_link ) {
      return app.picture;
    }
    return null;
  };
*/
  const getClientAppIcon = () => {
    let clientApp = user?.client_apps?.find((app) => app.id == selectedAppId);
    let client = clientApp?.client;

    if (clientApp && clientApp?.image_url) {
      return clientApp;
    }
    return client;
  };

  const getAppName = (app) => {
    let clientName = app?.name?.substring(0, app?.name?.lastIndexOf("-"))?.trim() || "";

    let appCustomName = app.custom_name === null ? null : app.custom_name.trim();
    if (appCustomName) {
      return `${clientName} - ${appCustomName}`;
    }
    return app.name;
  };

  //set co_branding screen
  useEffect(() => {
    if (
      clientAppData?.user_sessions?.client_app?.client?.co_branding_screen === true ||
      clientAppData?.user_sessions?.client_app?.client?.co_branding_screen === false
    ) {
      dispatch(
        setFlashScreen(clientAppData?.user_sessions?.client_app?.client?.co_branding_screen)
      );
    }
  }, [dispatch, clientAppData]);

  const goToDashboard = () => {
    FMDPStorage.set("app_features", null);
    FMDPStorage.set("selected-client", null);
    FMDPStorage.set("selected-app", null);
    FMDPStorage.set("selected-app-id", null);
    FMDPStorage.set("created-by-client-id", null);
    FMDPStorage.set("created-by-reseller", null);
    FMDPStorage.set("reseller", null);
    navigate("/app-selection");
  };

  const handleClickOutside = (event) => {
    if (dashboardRef.current && !dashboardRef.current.contains(event.target)) {
      // Clicked outside the config div, set showConfig to false
      setQuickDashBoard(false);
    }
  };

  useEffect(() => {
    // Add event listener on mount
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Empty dependency array ensures the effect runs only on mount and unmount

  const handleGridSelection = (client) => {
    setGridSelectedApp(client);
    setShowModal(true);
  };

  useEffect(() => {
    if (appToBeSelected) {
      switchApplication();
    }
  }, [appToBeSelected]);

  useEffect(() => {
    if (showModal && !showConfirmationModal) {
      initiateAppChange(gridSelectedApp);
    }
  }, [showModal, showConfirmationModal]);

  return (
    <header className="qi-header">
      <span className="branding">
        {getClientAppIcon()?.image_url ? (
          <img alt="" src={getClientAppIcon()?.image_url ? getClientAppIcon().image_url : ""} />
        ) : (
          <img alt="" src={smallLogo} />
        )}
      </span>
      <h3 className="qi-header_title text-center">
        <strong className="qi-header_title_text">
          <span onClick={() => navigate("/")} className="capitalize mx-2">
            {getCustomName()}
          </span>
        </strong>
      </h3>

      <div ref={dashboardRef} className="quick-dashboard">
        {(user?.client_apps?.length > 1 ||
          (user?.client_apps?.length === 1 && user?.superadmin)) && (
          <button onClick={() => setQuickDashBoard((prev) => !prev)}>
            <SvgIcon name={"grid"} />
          </button>
        )}
        {quickDashboard && (
          <div className="switch-apps">
            <Scrollbars autoHeight autoHeightMax="600px">
              <ul className="switch-apps_list">
                <li className="switch-apps_list_item" onClick={goToDashboard}>
                  <SvgIcon name="back" wrapperClass="p-2" />
                  <strong>
                    {getLocalizedString("go_to_dashboard", "Clients and Applications")}
                  </strong>
                </li>
                {user.superadmin && (
                  <li
                    className={`switch-apps_list_item ${
                      selectedAppId === "superadmin" ? "active" : ""
                    }`}
                    onClick={() => {
                      handleGridSelection("superadmin");
                      setQuickDashBoard(false);
                    }}
                  >
                    <div className="application-icon-wrapper">
                      <SvgIcon name="crown" wrapperClass="crown-icon" svgClass="icon-crown" />
                    </div>
                    <span>{getLocalizedString("superadmin", "Superadmin")}</span>
                  </li>
                )}
                {user?.client_apps?.map((app, index) => (
                  <li
                    className={`switch-apps_list_item ${selectedAppId === app.id ? "active" : ""}`}
                    key={index}
                    onClick={() => {
                      handleGridSelection(app?.id);
                      setQuickDashBoard(false);
                      if (app?.client?.id === selectedClientId) {
                        setshowConfirmationModal(false);
                      } else {
                        setshowConfirmationModal(true);
                      }
                    }}
                  >
                    <div className="application-icon-wrapper">
                      {app.image_url ? (
                        <span className="application-icon_wrapper">
                          <img src={app?.image_url} className="application-icon" />
                        </span>
                      ) : app?.client?.image_url ? (
                        <span className="application-icon_wrapper">
                          <img src={app?.client?.image_url} className="application-icon" />
                        </span>
                      ) : (
                        <SvgIcon name="app-icon" wrapperClass="application-icon defult" />
                      )}
                    </div>
                    <span>{getAppName(app)}</span>
                  </li>
                ))}
              </ul>
            </Scrollbars>
          </div>
        )}
      </div>
      <QINotification OnNotification={OnNotification} initialValue={initialNotificationCount} />

      <div className="qi-header_nav">
        <QIDropdown
          onHide={() => setDropDownSection("")}
          className="user-action-dropdown"
          toggleComponent={
            <div className="user-profile-name">
              <div className="user-profile-image-container">
                <img
                  className="user-profile-image"
                  alt="user-profile"
                  src={
                    user?.picture?.image_extension && user?.picture?.image_data
                      ? `data:image/${user.picture.image_extension};base64,${user.picture.image_data}`
                      : userImg
                  }
                />
              </div>
              <strong>
                {user.first_name || getLocalizedString("unknown", "Unknown")} {user.last_name || ""}
              </strong>
            </div>
          }
        >
          {dropDownSection === "" && (
            <ul className="user-dropdown">
              <li className="user-dropdown_user-info" onClick={() => setDropDownSection("")}>
                <Link to="/management_portal/user-profile">
                  <strong>{`${user.first_name} ${user.last_name}`}</strong>
                </Link>
                <p>{user.email || ""}</p>
              </li>

              {/* <li className="user-dropdown_link">
                <a
                  className="user-dropdown_link_item clickable"
                  onClick={() => setDropDownSection("application-switch")}
                >
                  <SvgIcon name="switch" />
                  <span>
                    {getLocalizedString("switch_applications", "Switch Application")}
                  </span>
                </a>
              </li> */}

              <li className="user-dropdown_link" onClick={() => setDropDownSection("")}>
                <Link className="user-dropdown_link_item" to="/management_portal/client_config">
                  <SvgIcon name="settings" />
                  <span>{getLocalizedString("settings", "Settings")}</span>
                </Link>
              </li>

              <li className="user-dropdown_link" onClick={() => setDropDownSection("")}>
                <Link className="user-dropdown_link_item" to="/management_portal/user-profile">
                  <SvgIcon name="user" />
                  <span>{getLocalizedString("my_profile", "My Profile")}</span>
                </Link>
              </li>

              <li className="user-dropdown_link">
                <a className="user-dropdown_link_item clickable" onClick={logOut}>
                  <SvgIcon name="log-out" />
                  <span>{getLocalizedString("sign_out", "Sign Out")}</span>
                </a>
              </li>
            </ul>
          )}
          {/* {dropDownSection === "application-switch" && (
            <Scrollbars autoHeight autoHeightMax="600px">
              <ul className="application-list">
                <li className="user-dropdown_link" onClick={() => setDropDownSection("")}>
                  <SvgIcon name="back" wrapperClass="p-2" />
                  <strong>
                    {getLocalizedString("switch_applications", "Switch Applications")}
                  </strong>
                </li>
                {user.superadmin && (
                  <li
                    className={`user-dropdown_link ${
                      selectedAppId === "superadmin" ? "active" : ""
                    }`}
                    onClick={() => initiateAppChange("superadmin")}
                  >
                    <div className="application-icon-wrapper">
                      <SvgIcon name="crown" wrapperClass="crown-icon" svgClass="icon-crown" />
                    </div>
                    <span>{getLocalizedString("super_admin", "Superadmin")}</span>
                  </li>
                )}
                {user?.client_apps?.map((app, index) => (
                  <li
                    className={`user-dropdown_link flex ${
                      selectedAppId === app.id ? "active" : ""
                    }`}
                    key={index}
                    onClick={() => {
                      initiateAppChange(app.id);
                    }}
                  >
                    <div className="application-icon-wrapper">
                      {app.image_url ? (
                        <span className="application-icon_wrapper">
                          <img src={app.image_url} className="application-icon" />
                        </span>
                      ) : (
                        <SvgIcon name="app-icon" wrapperClass="application-icon defult" />
                      )}
                    </div>
                    <span>{getAppName(app)}</span>
                  </li>
                ))}
              </ul>
            </Scrollbars>
          )} */}
          {dropDownSection === "language-switch" && (
            <ul>
              <li className="user-dropdown_link" onClick={() => setDropDownSection("")}>
                <SvgIcon name="back" wrapperClass="p-2" />
                <strong>{getLocalizedString("change_language", "Change Language")}</strong>
              </li>
              {languages?.data?.map((language, index) => (
                <li
                  className={`user-dropdown_link ${currentLanguage == language?.code && "active"} `}
                  key={index}
                  onClick={() => switchLanguage(language?.code)}
                >
                  {language.name}
                </li>
              ))}
            </ul>
          )}
        </QIDropdown>
      </div>
      {showConfirmationModal && showModal && (
        <QIModal show={showModal} scrollable={true} className="confirm">
          <QIModalBody>
            <h2 className="page-title mb-4 text-center">
              {getLocalizedString("confirmation_alert", "Confirmation Alert!")}
            </h2>
            <div className="confirmation-message">
              <span>
                <SvgIcon name="alert" className="confirmation-message_icon" />
              </span>
              <p>
                {getLocalizedString(
                  "switch_application_message",
                  "You are about to switch application. Please save your existing work (if any) and confirm."
                )}
              </p>
              <p>{getLocalizedString("are_you_sure", "Are you sure")}?</p>
              {(selectClientAppError || selectClientAppError) && (
                <p className="text-red-600">
                  {getLocalizedString(
                    "switch_application_wrong_message",
                    "Something went wrong, Unable to switch application"
                  )}
                </p>
              )}
            </div>
          </QIModalBody>
          <QIModalFooter>
            <QIButton
              onClick={() => setShowModal(false)}
              className="qi-btn secondary md"
              variant="secondary"
              size="sm"
            >
              {getLocalizedString("no", "No")}
            </QIButton>
            <QIButton
              onClick={() => {
                initiateAppChange(gridSelectedApp);
              }}
              size="sm"
              variant="danger"
              className="qi-btn primary md"
            >
              {getLocalizedString("yes", "Yes")}
            </QIButton>
          </QIModalFooter>
        </QIModal>
      )}
    </header>
  );
};

export const OnNotification = ({ notificationCount, readMore, setReadMore }) => {
  const { data: notificationData, refetch } = useGetNotificationsQuery(
    { page: 0, per_page: readMore, order_by: "id", order_dir: "desc" },
    notificationCount
  );

  const [updateNotificationRead, { data }] = useUpdateNotificationReadMutation();

  const [selectedId, setSelectedId] = useState(null);

  const notificationRows = () => {
    return (
      <ul className="user-dropdown notification-dropdown_list">
        {notificationData?.data?.length > 0 ? (
          notificationData?.data?.map(
            (notificationObj, index) =>
              index < readMore && (
                <li
                  key={index}
                  className={`user-dropdown_link notification-dropdown_list_item ${
                    notificationObj?.is_read ? " read" : " unread"
                  }`}
                  onClick={() => {
                    updateNotificationRead({
                      id: notificationObj?.id,
                      "data-raw": { is_read: true },
                    }).then(() => {
                      refetch();
                      setSelectedId((prevState) => (prevState = notificationObj?.id));
                    });
                  }}
                >
                  <p className="notification-info">
                    {notificationObj?.content.substring(0, notificationObj?.content.indexOf("@"))}
                  </p>

                  <div>
                    <span className="notification-status success">
                      {notificationObj?.content.substring(
                        notificationObj?.content.indexOf("@") + 2,
                        notificationObj?.content.lastIndexOf("@") - 1
                      )}
                    </span>

                    <span className="notification-status failure">
                      {notificationObj?.content.substring(
                        notificationObj?.content.lastIndexOf("@") + 1
                      )}
                    </span>
                  </div>
                </li>
              )
          )
        ) : (
          <p className="no-notification">
            {getLocalizedString(
              "no_notifications_are_available",
              "No notifications are available."
            )}
          </p>
        )}
      </ul>
    );
  };

  return (
    <>
      {notificationRows()}
      {notificationData?.data?.length < notificationData?.total_count && (
        <div className="read-more">
          {notificationData?.total_count > readMore && (
            <a
              className="clickable"
              onClick={() => {
                setReadMore((prevState) => prevState + 10);
              }}
            >
              {getLocalizedString("read_more", "Read More")}
            </a>
          )}
        </div>
      )}
    </>
  );
};
