import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { mergeObjects } from "../../../shared/helper";
import { FormContainer } from "../Shared";
import {
  useCreateVehicleGroupMutation,
  useUpdateVehicleGroupMutation,
  useGetAppsQuery,
  useGetVehicleGroupQuery,
  useGetEventConfigurationPlansQuery,
} from "../services";
import { useForm } from "../../../hooks";
import { vehicleGroupsFormSchema } from "../validations";
import {getLocalizedString} from "../../../shared/translation";

export const VehicleGroupForm = ({ idToEditOrCsvUpload = null, onClose }) => {
  const { data: apps = {} } = useGetAppsQuery({ page: 1, per_page: 1000 });

  const [initialValues, setInitialValues] = useState({
    name: "",
    app_id: "",
    event_handling_plan_id: "",
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: vehicleGroupsFormSchema,
    onSubmit: (value) => handleFormSubmit(value),
  });

  const { data = {} } = useGetVehicleGroupQuery(
    { id: idToEditOrCsvUpload },
    { skip: !idToEditOrCsvUpload }
  );

  // fetch event configuration plans
  const { data: eventConfigurationPlans, isSuccess: isEventConfigurationPlans } =
    useGetEventConfigurationPlansQuery({
      per_page: 600,
    });

  // Set form data in case of edit
  useEffect(() => {
    if (idToEditOrCsvUpload && data) {
      setInitialValues(mergeObjects(initialValues, data));
    }
  }, [data]);

  const { create: createVehicleGroup, update: updateVehicleGroup } = useForm({
    createMutation: useCreateVehicleGroupMutation,
    updateMutation: useUpdateVehicleGroupMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  const handleFormSubmit = (formData) => {
    if (idToEditOrCsvUpload) {
      updateVehicleGroup({ id: idToEditOrCsvUpload, formData });
    } else {
      createVehicleGroup({ formData });
    }
  };

  return (
    <FormContainer
      resourceName={getLocalizedString("vehicle_group", "Vehicle Group")}
      closeForm={() => onClose(false)}
      handleFormSubmit={formik.handleSubmit}
      idToEdit={idToEditOrCsvUpload}
    >
      <Components.QICustomSelect
        label={getLocalizedString("application", "Application")}
        {...formik.getFieldProps("app_id")}
        onChange={(value) => {
          formik.setFieldValue("app_id", value);
        }}
        error={formik.touched.app_id && formik.errors.app_id}
        disabled={idToEditOrCsvUpload}
      >
        <>
          <li value="">All</li>
          {apps?.data?.map((app, index) => (
            <li value={app.id} key={index}>
              {app.name}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QIInput
        label={getLocalizedString("group_name", "Group Name")}
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />

      <Components.QICustomSelect
        label={getLocalizedString("event_ponfiguration_plan", "Event Configuration Plan")}
        {...formik.getFieldProps("event_handling_plan_id")}
        onChange={(value) => {
          formik.setFieldValue("event_handling_plan_id", value);
        }}
        error={formik.touched.event_handling_plan_id && formik.errors.event_handling_plan_id}
        labelClassName="event-configuration-plan"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {eventConfigurationPlans?.data?.map((ecp, index) => (
            <li value={ecp.id} key={index}>
              {ecp.name}
            </li>
          ))}
        </>
      </Components.QICustomSelect>
    </FormContainer>
  );
};
