import { useEffect } from "react";
import { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import sprite from "../../assets/icons.svg";

export const QICustomFieldDropdown = ({
  label,
  value,
  onChange,
  readOnly,
  error,
  resetOnUnmount = false,
  validator = () => ({}),
  presentObjects = {},
  idToEdit = false,
  dataList,
  children,
  labelKey = "Key",
  labelValue = "Value",
}) => {
  const [showNewInput, setShowNewInput] = useState(false);
  const schema = { key: "", value: "" };
  const [pair, setPair] = useState(schema);
  const [pairs, setPairs] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (JSON?.stringify(pairs) !== JSON?.stringify(presentObjects) && idToEdit) {
      setPairs(JSON?.parse(presentObjects));
    }
  }, [presentObjects]);

  const handleFormChange = (key, value) => {
    let tempErrors = validator({ [key]: value }, pairs);
    let tempPair = { ...pair, [key]: value };
    setPair(tempPair);
    setErrors(tempErrors);
  };

  const onCancel = () => {
    setShowNewInput(false);
    setPair(schema);
    setErrors({});
  };

  const addNewPair = () => {
    let tempErrors = validator(pair, pairs);

    if (Object.keys(tempErrors).length === 0) {
      let newValue = pair.value;
      if (newValue === "true" || newValue === "false") {
        newValue = eval(newValue);
      }
      const tempObject = JSON?.parse(value);
      tempObject[pair.key] = newValue;
      setPairs(tempObject);
      onChange(JSON?.stringify(tempObject));
      setPair(schema);
      setShowNewInput(false);
    }
    setErrors(tempErrors);
  };

  const removePair = (key) => {
    const tempValue = { ...JSON?.parse(value) };
    delete pairs[key];
    setPairs(pairs);
    delete tempValue[key];
    onChange(JSON?.stringify(tempValue));
  };

  const headers = [
    { label: labelKey, className: "w-2/3" },
    { label: labelValue, className: "w-2/3" },
    { label: "", className: "action" },
  ];

  useEffect(() => {
    return () => {
      if (resetOnUnmount) {
        onChange("{}");
      }
    };
  }, []);

  return (
    <section
      className={`qi-input qi-form-element items-center ${readOnly && "disabled"} ${
        error && "error"
      }`}
    >
      <label className="qi-input_label">{label}</label>
      <div className="qi-input_wrapper">
        <header className="qi-list-view_header">
          {headers.map((header, index) => (
            <span
              className={`qi-list-view_column ${header.className}`}
              key={index}
              title={header.label}
            >
              {header.label}
            </span>
          ))}
        </header>
        <Scrollbars autoHeight autoHeightMax="170px">
          <ul className="qi-list-view_list custom-field_list">
            {Object?.keys(JSON?.parse(value))?.map((key, index) => (
              <li className="qi-list-view_list_item" key={index}>
                <span className="qi-list-view_column">{key}</span>
                <span className="qi-list-view_column">{`${JSON?.parse(value)[key]}`}</span>
                <span className="qi-list-view_column action">
                  <span className="icon-wrapper icon-wrapper" onClick={() => removePair(key)}>
                    <svg className="icon icon-delete">
                      <use href={`${sprite}#delete`}></use>
                    </svg>
                  </span>
                </span>
              </li>
            ))}
          </ul>
        </Scrollbars>
        {showNewInput && (
          <div className="qi-list-view add-admin-user">
            {showNewInput && (
              <form className="qi-list-view_list_item add-admin-user_form http-header">
                <div className="qi-list-view_column">
                  <input
                    className="w-full"
                    placeholder={labelKey}
                    value={pair.key}
                    onChange={(e) => handleFormChange("key", e.target.value)}
                  />
                  <div className="text-red-600 small-error">{errors.key}</div>
                </div>

                <div className="qi-select_wrapper">
                  <select
                    className="qi-select_text"
                    onChange={(e) => handleFormChange("value", e.target.value)}
                  >
                    {children ? (
                      children
                    ) : (
                      <>
                        <option value="select">select</option>
                        {dataList?.map((data, index) => (
                          <option value={data.name} key={index}>
                            {data.name}
                          </option>
                        ))}
                      </>
                    )}
                  </select>

                  <div className="text-red-600 small-error">{errors.value}</div>
                </div>
                <div className="qi-list-view_column action add-admin-user_button-set">
                  <span className="icon-wrapper tick-icon" onClick={addNewPair}>
                    <svg className="icon icon-tick">
                      <use href={`${sprite}#tick`}></use>
                    </svg>
                  </span>
                  <span className="icon-wrapper cross-icon" onClick={onCancel}>
                    <svg className="icon icon-cross">
                      <use href={`${sprite}#cross`}></use>
                    </svg>
                  </span>
                </div>
              </form>
            )}
          </div>
        )}
        {!readOnly && error && (
          <div className="qi-input_error">
            <span className="icon-wrapper" onClick={addNewPair}>
              <svg className="icon icon-error">
                <use href={`${sprite}#info`}></use>
              </svg>
            </span>
            <p className="qi-input_error_message ">{error}</p>
          </div>
        )}
      </div>

      {!showNewInput && !readOnly && (
        <span className="icon-wrapper add-new" onClick={() => setShowNewInput(true)}>
          <svg className="icon icon-add">
            <use href={`${sprite}#add`}></use>
          </svg>
        </span>
      )}
    </section>
  );
};
