import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { useGetVClassQuery, useCreateVClassMutation, useUpdateVClassMutation } from "../services";
import { mergeObjects } from "../../../shared/helper";
import { FormContainer } from "../Shared";
import { useForm } from "../../../hooks";
import { vClassFormValidation } from "../validations";
import { getLocalizedString } from "../../../shared/translation";

export const VClassForm = ({ idToEdit = null, onClose }) => {
  const [initialValues, setInitialValues] = useState({ name: "" });
  const formik = useFormik({
    initialValues,
    validationSchema: vClassFormValidation,
    enableReinitialize: true,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const { create, update } = useForm({
    createMutation: useCreateVClassMutation,
    updateMutation: useUpdateVClassMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  const { data, error } = useGetVClassQuery({ id: idToEdit }, { skip: !idToEdit });

  // Set form data in case of edit
  useEffect(() => {
    if (idToEdit && data && !error) {
      setInitialValues(mergeObjects(initialValues, data));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      update({ formData: formData, id: idToEdit });
    } else {
      create({ formData: formData });
    }
  };

  return (
    <FormContainer
      resourceName={getLocalizedString("vehicle_class", "Vehicle Class")}
      closeForm={() => onClose(false)}
      handleFormSubmit={formik.handleSubmit}
      idToEdit={idToEdit}
    >
      <Components.QIInput
        label={getLocalizedString("name", "Name")}
        {...formik.getFieldProps("name")}
        error={formik.touched && formik.errors.name}
      />
    </FormContainer>
  );
};
