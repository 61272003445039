import { useEffect, useState } from "react";
import { AdvanceSearchWrapper } from "../Shared/";
import { QICustomSelect, QIInput } from "../../../components";

export const ObdcodeAdvanceSearch = ({ onSearch, activeSearch }) => {
  const initialValues = {
    name_cont: "",
    code_cont: "",
    code_type_eq: "",
  };

  const [formData, setFormData] = useState(initialValues);

  useEffect(() => {
    if (activeSearch) {
      setFormData(activeSearch);
    }
  }, []);

  const handleFormChange = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const clearAdvanceSearch = () => {
    setFormData(initialValues);
    onSearch(null);
  };

  return (
    <AdvanceSearchWrapper
      activeFilters={activeSearch}
      onClear={clearAdvanceSearch}
      onSearch={() => onSearch(formData)}
    >
      <QIInput
        label="Name"
        className=""
        value={formData.name_cont}
        onChange={(e) => handleFormChange("name_cont", e.target.value)}
      />
      <QIInput
        label="Code"
        className=""
        value={formData.code_cont}
        onChange={(e) => handleFormChange("code_cont", e.target.value)}
      />
      <QICustomSelect
        label="Code Type"
        value={formData.code_type_eq}
        onChange={(value) => handleFormChange("code_type_eq", value)}
        labelClassName="obd-type"
      >
        {["Mileage"].map((value) => (
          <li value={value} key={value}>
            {value}
          </li>
        ))}
      </QICustomSelect>
    </AdvanceSearchWrapper>
  );
};
