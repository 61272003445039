import { DateRangePicker } from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import sprite from "../../assets/icons.svg";
import { getLocalizedString } from "../../shared/translation";

export const QICustomDateRangePicker = ({
  selectedRange,
  setSelectedRange,
  resetCount,
  setResetCount,
  label,
  className,
  openCalendar = "right",
  warningMessage = "",
}) => {
  const dateRangeOptions = {};
  dateRangeOptions[getLocalizedString("last_one_hour", "Last 1 Hour")] = [
    moment().subtract(1, "hour"),
    moment(),
  ];

  dateRangeOptions[getLocalizedString("last_two_hours", "Last 2 Hour")] = [
    moment().subtract(2, "hours"),
    moment(),
  ];

  dateRangeOptions[getLocalizedString("last_six_hours", "Last 6 Hour")] = [
    moment().subtract(6, "hours"),
    moment(),
  ];

  dateRangeOptions[getLocalizedString("last_twelve_hours", "Last 12 Hour")] = [
    moment().subtract(12, "hours"),
    moment(),
  ];

  dateRangeOptions[getLocalizedString("last_twentyfour_hours", "Last 24 Hour")] = [
    moment().subtract(24, "hours"),
    moment(),
  ];

  //Date Range Label
  const AbsoluteValue = Math.abs(moment(selectedRange?.startDate).diff(moment(), "hours"));
  const dateLabel = `${
    AbsoluteValue <= 24 && AbsoluteValue >= 0
      ? `Last ${Math.ceil(AbsoluteValue)} Hours`
      : `${moment(selectedRange?.startDate).format("ddd D MMM hh:mm:ss A")} - ${moment(
          selectedRange?.endDate
        ).format("ddd D MMM hh:mm:ss A")}`
  }`;

  const handleDateRangeChange = (event, picker) => {
    setSelectedRange({
      startDate: picker.startDate,
      endDate: picker.endDate,
    });
  };
  const handleReset = () => {
    setResetCount((prev) => prev + 1);
    setSelectedRange({
      startDate: moment().subtract(24, "hours"),
      endDate: moment(),
    });
  };
  // Function to check if a date is in the future
  const isFutureDate = (date) => {
    return date.isAfter(moment().add(24, "hours"));
  };

  return (
    <div className={`track-points-datepicker-wrapper ${className}`}>
      <div className="track-points-timerange qi-form-element ">
        {label && <label className="qi-input_label">{label}</label>}
        <DateRangePicker
          key={resetCount}
          initialSettings={{
            timePicker: true,
            ranges: dateRangeOptions,
            opens: openCalendar,
            drops: "auto",
            isInvalidDate: isFutureDate,
          }}
          onApply={handleDateRangeChange}
        >
          <div className="qi-input_wrapper">
            <div id="reportrange" className="date-range">
              <span>
                <span className="calendar-icon">
                  <svg className={`icon`}>
                    <use href={`${sprite}#calendar`}></use>
                  </svg>
                </span>
                {dateLabel}
              </span>

              {AbsoluteValue > 24 && (
                <span>
                  <span className="icon-wrapper clickable" onClick={handleReset}>
                    <svg className={`icon`}>
                      <use href={`${sprite}#cross`}></use>
                    </svg>
                  </span>
                </span>
              )}
            </div>
            {warningMessage.length > 0 && (
              <div className="qi-input_warning ">
                <p className="qi-input_warning_message">{warningMessage}</p>
              </div>
            )}
          </div>
        </DateRangePicker>
      </div>
    </div>
  );
};
