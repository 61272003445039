import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  AdminSharedHeader,
  AdminSharedTable,
  AdminDeleteButton,
  AdminEditButton,
  AdminSharedDeleteModal,
} from "../Shared/";

import {
  useGetVManQuery,
  useGetVManModelsQuery,
  useDeleteVManModelMutation,
  useGetVManModelVariantsQuery,
  useDeleteVManModelVariantMutation,
  useGetDModelsQuery,
  useDeleteDModelMutation,
} from "../services";

import { VManModelForm } from "../VManModels/Form";
import { VManModelVariantForm } from "../VManModelVariants/Form";
import { utcToLocal } from "../../../shared/utility";
import { QITable } from "../../../components";

export const VManufacturer = () => {
  let params = useParams();

  const {
    data: vManufacturer,
    isSuccess,
    error,
  } = useGetVManQuery({
    id: params.vManId,
    view: "admin_show",
  });

  // vehicle man models
  const [vManModelPage, setVManModelPage] = useState(1);
  const vManModelPagination = {
    page: vManModelPage,
    perPage: 20,
    onPageChange: setVManModelPage,
  };

  const vManModelQueryParams = {
    page: vManModelPagination.page,
    per_page: vManModelPagination.perPage,
    order_by: "created_at",
    order_dir: "desc",
    vehicle_manufacturer_id: params.vManId,
    view: "admin_vman_show",
  };

  const {
    data: vManModelsData = { data: [], count: 0 },
    isLoading: isvManModelsLoading,
    isFetching: isvManModelsFetching,
    isSuccess: isvManModelsSuccess,
    error: isvManModelsError,
  } = useGetVManModelsQuery(vManModelQueryParams);

  const [deleteVManModel, { isSuccess: deleteVManModelSuccess, reset: resetDeleteVManModel }] =
    useDeleteVManModelMutation();

  useEffect(() => {
    if (deleteVManModelSuccess) {
      setShowVManModelDeleteWarning(false);
      resetDeleteVManModel();
    }
    return () => resetDeleteVManModel();
  }, [deleteVManModelSuccess]);

  const [vManModelIdToEdit, setVManModelIdToEdit] = useState(null);
  const [vManModelIdToDelete, setVManModelIdToDelete] = useState(null);
  const [showVManModelForm, setShowVManModelForm] = useState(false);
  const [showvManModelDeleteWarning, setShowVManModelDeleteWarning] = useState(false);

  const createNewVManModelHandler = () => {
    setShowVManModelForm(true);
    setVManModelIdToEdit(null);
  };

  const editVManModelHandler = (datum) => {
    setShowVManModelForm(true);
    setVManModelIdToEdit(datum.id);
  };

  const deleteVManModelHandler = (datum) => {
    setShowVManModelDeleteWarning(true);
    setVManModelIdToDelete(datum.id);
  };

  const vManModelActions = ({ data: datum }) => {
    return (
      <>
        <AdminEditButton onClick={() => editVManModelHandler(datum)} className="me-2" />
        <AdminDeleteButton onClick={() => deleteVManModelHandler(datum)} />
      </>
    );
  };

  const vManModelHeaders = [
    { label: "Name", key: "name" },
    { label: "Created at", key: "created_at" },
    { label: "", type: "component", component: vManModelActions },
  ];

  const getDeleteItemName = (id, collection) => {
    let name = "";
    const index = collection.findIndex((datum) => datum.id === id);

    if (index > -1) {
      name = collection[index].name;
    }

    return name;
  };

  // vehicle man models

  const titleBreadcrumb = (deviceModelName) => {
    return (
      <>
        <Link to={`/superadmin/vehicle/manufacturers`} style={{ textDecoration: "none" }}>
          Vehicle Manufacturers
        </Link>
        &nbsp;&gt;&nbsp;{deviceModelName}
      </>
    );
  };

  const vModelHeaders = [
    "Model ",
    "Capacity",
    "Engine Capacity",
    "Year",
    "Classes",
    "Types",
    "Created on",
  ];

  return (
    <>
      <article className="main-container">
        {isSuccess && (
          <>
            <AdminSharedHeader heading={titleBreadcrumb(vManufacturer.name)} />

            <ul className="details-vie_summary_list">
              <li className="details-vie_summary_list_item">
                <strong className="details-vie_summary_list_label">Name</strong>
                <span className="details-vie_summary_list_value">{vManufacturer.name}</span>
              </li>
              <li className="details-vie_summary_list_item">
                <strong className="details-vie_summary_list_label">Vehicles</strong>
                <span className="details-vie_summary_list_value">
                  {vManufacturer.vehicles_count}
                </span>
              </li>
              <li className="details-vie_summary_list_item">
                <strong className="details-vie_summary_list_label">Client</strong>
                <span className="details-vie_summary_list_value">{vManufacturer.client_name}</span>
              </li>
              <li className="details-vie_summary_list_item">
                <strong className="details-vie_summary_list_label">Created on</strong>
                <span className="details-vie_summary_list_value">
                  {utcToLocal(vManufacturer.created_at)}
                </span>
              </li>
            </ul>

            <div className="qi-list-view">
              <div className="qi-list-view_header">
                {vModelHeaders.map((header, index) => (
                  <div className="qi-list-view_column" key={index}>
                    {header}
                  </div>
                ))}
              </div>
              <ul className="qi-list-view_list">
                {vManModelsData.data.map((vManModel, index) => (
                  <li className="qi-list-view_list_item" key={index}>
                    <div data-testid="td" className="qi-list-view_column">
                      {vManModel.name}
                    </div>
                    <ul>
                      {vManModel.vehicle_manufacturer_model_variants.map((variant, index) => (
                        <li className="qi-list-view_list_item" key={index}>
                          <div data-testid="td" className="qi-list-view_column">
                            {variant.name}
                          </div>
                          <ul>
                            {variant.vehicle_models.map((vehicleModel, index) => (
                              <li className="qi-list-view_list_item" key={index}>
                                <div data-testid="td" className="qi-list-view_column">
                                  {vehicleModel.capacity}
                                </div>
                                <div data-testid="td" className="qi-list-view_column">
                                  {vehicleModel.engine_capacity}
                                </div>
                                <div data-testid="td" className="qi-list-view_column">
                                  {`${variant.name}-${vehicleModel.year}`}
                                </div>
                                <div data-testid="td" className="qi-list-view_column">
                                  {vehicleModel.vehicle_class_names}
                                </div>
                                <div data-testid="td" className="qi-list-view_column">
                                  {vehicleModel.vehicle_type_names}
                                </div>
                                <div data-testid="td" className="qi-list-view_column">
                                  {utcToLocal(vehicleModel.created_at)}
                                </div>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </article>

      {showVManModelForm && (
        <VManModelForm idToEdit={vManModelIdToEdit} closeForm={() => setShowVManModelForm(false)} />
      )}
    </>
  );
};
