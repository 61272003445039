import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { FormContainer } from "../Shared/FormContainer";
import {
  useCreateVManModelVariantMutation,
  useUpdateVManModelVariantMutation,
  useGetVManModelVariantQuery,
  useGetClientsQuery,
  useGetVMansQuery,
  useGetVManModelsQuery,
} from "../services";
import { useForm, useDropDownSearch } from "../../../hooks";
import { vehicleModelVariantFormSchema } from "../validations";
import { mergeObjects } from "../../../shared/helper";

const dropDownParams = { per_page: 1000, order_by: "name" };
export const VManModelVariantForm = ({ idToEdit = null, closeForm }) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    client_id: "",
    vehicle_manufacturer_id: "",
    vehicle_manufacturer_model_id: "",
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: vehicleModelVariantFormSchema,
    onSubmit: (values) => handleFormSubmit(values),
  });

  // Get the clients list using Dropdown Search
  const {
    data: clients,
    setSearchKey: setClientSearch,
    isSuccess: isClientsSuccess,
  } = useDropDownSearch({
    useData: useGetClientsQuery,
    simpleSearchKey: "name_or_email_cont",
    selectedIds: formik.values.client_id,
    additionalParams: { ...dropDownParams },
  });

  // Get the vehicle manufacturers list using Dropdown Search
  const {
    data: vMans = { data: [] },
    setSearchKey: setVManSearch,
    isSuccess: isVmansSuccess,
  } = useDropDownSearch({
    useData: useGetVMansQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_manufacturer_id,
    additionalParams: formik.values.client_id
      ? { ...dropDownParams, client_id: formik.values.client_id }
      : { ...dropDownParams, only_master: true },
  });

  // Get the vehicle manufacturer model list using Dropdown Search
  const {
    data: vManufacturerModels = { data: [] },
    setSearchKey: setVManModelSearch,
    isSuccess: isVManufacturerModelSuccess,
  } = useDropDownSearch({
    useData: useGetVManModelsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.vehicle_manufacturer_model_id,
    additionalParams: {
      ...dropDownParams,
      client_id: formik.values.client_id,
      vehicle_manufacturer_id: formik.values.vehicle_manufacturer_id,
    },
    additionalParamsToHook: { skip: !formik.values.vehicle_manufacturer_id },
  });

  // Get data of current record data in case of edit
  const { data } = useGetVManModelVariantQuery({ id: idToEdit }, { skip: !idToEdit });

  const { create: createVManModelVariant, update: updateVManModelVariant } = useForm({
    createMutation: useCreateVManModelVariantMutation,
    updateMutation: useUpdateVManModelVariantMutation,
    closeForm,
    setError: formik.setErrors,
  });

  useEffect(() => {
    if (idToEdit && data) {
      setInitialValues(mergeObjects(formik.values, data));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      updateVManModelVariant({ id: idToEdit, formData });
    } else {
      createVManModelVariant({ formData });
    }
  };

  return (
    <FormContainer
      resourceName="Variant"
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
    >
      <Components.QICustomSelect
        label="Client"
        value={formik.values.client_id}
        onChange={(value) => {
          formik.setValues({
            ...formik.values,
            client_id: value,
            vehicle_manufacturer_id: "",
            vehicle_manufacturer_model_id: "",
          });
          setClientSearch("");
        }}
        onSearch={setClientSearch}
        error={formik.touched.client_id && formik.errors.client_id}
        labelClassName="client"
      >
        <li value="">All</li>
        {isClientsSuccess &&
          clients.data.map((client) => (
            <li key={client.id} value={client.id}>
              {client.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QICustomSelect
        label="Manufacturer"
        value={formik.values.vehicle_manufacturer_id}
        onChange={(value) => {
          formik.setValues({
            ...formik.values,
            vehicle_manufacturer_id: value,
            vehicle_manufacturer_model_id: "",
          });
          setVManSearch("");
        }}
        onSearch={setVManSearch}
        error={formik.touched.vehicle_manufacturer_id && formik.errors.vehicle_manufacturer_id}
        labelClassName="manufacturer"
      >
        <li value="">Select</li>
        {isVmansSuccess &&
          vMans.data.map((vMan) => (
            <li key={vMan.id} value={vMan.id}>
              {vMan.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QICustomSelect
        label="Model"
        value={formik.values.vehicle_manufacturer_model_id}
        onChange={(value) => {
          formik.setFieldValue("vehicle_manufacturer_model_id", value);
          setVManModelSearch("");
        }}
        onSearch={setVManModelSearch}
        error={
          formik.touched.vehicle_manufacturer_model_id &&
          formik.errors.vehicle_manufacturer_model_id
        }
        disabled={formik.values.vehicle_manufacturer_id === ""}
        labelClassName="model"
      >
        <li value="">Select</li>
        {isVmansSuccess &&
          isVManufacturerModelSuccess &&
          vManufacturerModels.data.map((vManModel) => (
            <li key={vManModel.id} value={vManModel.id}>
              {vManModel.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QIInput
        label="Name"
        placeholder="Variant name"
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />
    </FormContainer>
  );
};
