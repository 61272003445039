import React, { useState, useEffect } from "react";
import { useSearchQuery, usePagination } from "../../../hooks";
import { AdminSharedHeader, AdminSharedTable, AdminSharedDeleteModal } from "../Shared/";
import { useGetVManModelVariantsQuery, useDeleteVManModelVariantMutation } from "../services";
import { useDelete } from "../../../hooks";
import { VManModelVariantForm } from "./Form";

export const VManModelVariantsContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_client_name_cont",
  });

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetVManModelVariantsQuery({
    page: pagination.page,
    per_page: pagination.perPage,
    order_by: "created_at",
    order_dir: "desc",
    q,
  });

  const {
    deleteItem: deleteVManModelVariant,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteVManModelVariantMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  const createNewHandler = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  const deleteClickHandler = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const headers = [
    { label: "Client", key: "client_name", alternate: "All", className: "clients" },
    {
      label: "Manufacturer Model",
      className: "manufacturer-model",
      nestedValue: true,
      getNestedValue: ({ vehicle_manufacturer_name, vehicle_manufacturer_model_name }) =>
        `${vehicle_manufacturer_name} ${vehicle_manufacturer_model_name}`,
    },
    { label: "Name", key: "name", className: "name" },
    { label: "Created on", key: "created_at", className: "date" },
  ];

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((datum) => datum.id === id);

    if (index > -1) {
      name = data?.data[index].name;
    }
    return name;
  };

  return (
    <>
      <article className="main-container">
        <AdminSharedHeader
          createNewHandler={createNewHandler}
          groupName="vehicle"
          heading="Vehicles"
          handleSearchKey={(value) => setSimpleSearch(value)}
          simpleSearchPlaceHolder="Search by Name, Client"
        />

        <AdminSharedTable
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          data={data.data}
          headers={headers}
          pagination={{ ...pagination, count: data.total_count }}
          onEdit={editClickHandler}
          onDelete={deleteClickHandler}
          className="vehicle-variants"
          auditResource="VehicleManufacturerModelVariant"
          auditKey="name"
        />
      </article>

      <AdminSharedDeleteModal
        show={showDeleteWarning}
        error={deleteErrorMsg}
        resourceName="vehicle manufacturer model variant"
        getDeleteItemName={() => getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg("");
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteVManModelVariant({ id: idToDelete })}
      />
      {showForm && (
        <VManModelVariantForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />
      )}
    </>
  );
};
