import React, { useState, useEffect } from "react";
import * as Components from "../../../components";
import { FormContainer } from "../Shared";
import { useGetVTypeQuery, useCreateVTypeMutation, useUpdateVTypeMutation } from "../services";
import { mergeObjects } from "../../../shared/helper";
import { useForm } from "../../../hooks";
import { vTypesFormValidation } from "../validations";
import { useFormik } from "formik";
import { getLocalizedString } from "../../../shared/translation";

export const VTypeForm = ({ idToEdit = null, onClose }) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: vTypesFormValidation,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const { create, update } = useForm({
    createMutation: useCreateVTypeMutation,
    updateMutation: useUpdateVTypeMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  const { data = {}, error } = useGetVTypeQuery({ id: idToEdit }, { skip: !idToEdit });

  // Set form data in case of edit
  useEffect(() => {
    if (idToEdit && data && !error) {
      setInitialValues(mergeObjects(initialValues, data));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      update({ formData: formData, id: idToEdit });
    } else {
      create({ formData: formData });
    }
  };

  return (
    <FormContainer
      resourceName={getLocalizedString("vehicle_type", "Vehicle Type")}
      closeForm={() => onClose(false)}
      handleFormSubmit={formik.handleSubmit}
      idToEdit={idToEdit}
    >
      <Components.QIInput
        label={getLocalizedString("name", "Name")}
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />
    </FormContainer>
  );
};
