import { useEffect, useState } from "react";
import moment from "moment";
import { AdvanceSearchWrapper } from "../Shared/";
import { QIDateRangePicker, QIInput, QICustomSelect } from "../../../components";
import { useGetClientsQuery } from "../services";
import { useDropDownSearch } from "../../../hooks";

export const VehicleAdvanceSearch = ({ onSearch, activeSearch }) => {
  const initialValues = {
    client_id_eq: "",
    name_cont: "",
    plate_number_cont: "",
    vehicle_model_name_cont: "",
    user_filter_full_name_cont: "",
    filter_device_imei_cont: "",
    created_at_gteq: null,
    created_at_lteq: null,
  };

  const [formData, setFormData] = useState(initialValues);
  const [showCalender, setShowCalender] = useState(false);
  const [resetCount, setResetCount] = useState(0);

  const dropmenuQueryParams = { per_page: 1000, order_by: "name" };

  // Get the clients list using Dropdown Search
  const {
    data: clients,
    setSearchKey: setClientSearch,
    isSuccess: clientsSuccess,
  } = useDropDownSearch({
    useData: useGetClientsQuery,
    simpleSearchKey: "name_or_email_cont",
    additionalParams: { ...dropmenuQueryParams },
    selectedIds: formData.client_id_eq,
  });

  useEffect(() => {
    if (activeSearch) {
      setFormData(activeSearch);
    }
  }, []);

  const handleFormChange = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const clearAdvanceSearch = () => {
    setResetCount((i) => i + 1);
    setFormData(initialValues);
    onSearch(null);
    setShowCalender(false);
  };

  const handleDateRange = (startTime, endTime) => {
    setFormData((prevState) => ({
      ...prevState,
      created_at_gteq: startTime,
      created_at_lteq: endTime,
    }));
    setResetCount((i) => i + 1);
  };

  return (
    <AdvanceSearchWrapper
      activeFilters={activeSearch}
      onClear={clearAdvanceSearch}
      onSearch={() => onSearch(formData)}
      showCalender={showCalender}
      setShowCalender={setShowCalender}
    >
      <QICustomSelect
        label="Client"
        value={formData.client_id_eq}
        onChange={(value) => {
          handleFormChange("client_id_eq", parseInt(value));
          setClientSearch("");
        }}
        onSearch={setClientSearch}
        labelClassName="client"
      >
        <li value="">Any</li>
        {clientsSuccess &&
          clients.data.map((client) => (
            <li key={client.id} value={client.id}>
              {client.name}
            </li>
          ))}
      </QICustomSelect>
      <QIInput
        label="Name"
        value={formData.name_cont}
        onChange={(e) => handleFormChange("name_cont", e.target.value)}
      />
      <QIInput
        label="Plate No."
        value={formData.plate_number_cont}
        onChange={(e) => handleFormChange("plate_number_cont", e.target.value)}
      />
      <QIInput
        label="Specification"
        value={formData.vehicle_model_name_cont}
        onChange={(e) => handleFormChange("vehicle_model_name_cont", e.target.value)}
      />
      <QIInput
        label="User"
        value={formData.user_filter_full_name_cont}
        onChange={(e) => handleFormChange("user_filter_full_name_cont", e.target.value)}
      />
      <QIInput
        label="Devices"
        value={formData.filter_device_imei_cont}
        onChange={(e) => handleFormChange("filter_device_imei_cont", e.target.value)}
      />

      <QIDateRangePicker
        start={formData.created_at_gteq}
        end={formData.created_at_lteq}
        label={"Created b/w"}
        maxDate={moment()}
        onChange={handleDateRange}
        showCustomRangeLabel
        setShowCalender={setShowCalender}
        resetCount={resetCount}
      />
    </AdvanceSearchWrapper>
  );
};
