import React from "react";
import { QIModal, QIModalBody, QIButton, QIModalFooter } from "../../../components";
import { SvgIcon } from "../../Shared";

export const AdminSharedDeleteModal = ({
  resourceName,
  getDeleteItemName,
  onHide,
  show,
  onDelete,
  error,
}) => {
  return (
    <QIModal show={show} scrollable={true} className="confirm">
      <QIModalBody className={`${error && "association-error"}`}>
        {error ? (
          <div className="association-error_container">
            <SvgIcon wrapperClass="cross-icon" svgClass="icon-cross" name="cross" />
            <p className="association-error_message">{error}.</p>
          </div>
        ) : (
          <>
            <h2 className="page-title mb-4 text-center">Confirmation Alert!</h2>
            <div className="confirmation-message">
              <span>
                <SvgIcon name="alert" className="confirmation-message_icon" />
              </span>
              <p>
                You are about to delete the {resourceName}
                <br />
                <strong>{getDeleteItemName()}</strong>.
              </p>
              <p>Are you sure?</p>
            </div>
          </>
        )}
      </QIModalBody>
      <QIModalFooter>
        <QIButton onClick={onHide} className="qi-btn secondary md" variant="secondary" size="sm">
          {error ? "close" : "No"}
        </QIButton>
        {!error && (
          <QIButton onClick={onDelete} size="sm" variant="danger" className="qi-btn primary md">
            Yes
          </QIButton>
        )}
      </QIModalFooter>
    </QIModal>
  );
};
