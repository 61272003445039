import React from "react";

import { SvgIcon } from "../../Shared";

export const AdminEditButton = ({ onClick, ...props }) => {
  return (
    <SvgIcon
      name="edit"
      title="Edit"
      wrapperClass="action-btn"
      svgClass="icon-edit"
      onClick={onClick}
    />
  );
};

export const AdminDeleteButton = ({ onClick, ...props }) => {
  return (
    <SvgIcon
      name="delete"
      title="Delete"
      wrapperClass="action-btn"
      svgClass="icon-delete"
      onClick={onClick}
    />
  );
};

export const MPUploadCsvButton = ({ onClick, ...props }) => {
  return (
    <SvgIcon
      name="upload"
      title="Import"
      wrapperClass="action-btn"
      svgClass="icon-upload"
      onClick={onClick}
    />
  );
};
