import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showAuthModal: false,
  ws: null,
  layouts: {
    trackingLayout: {
      a: { top: 20, left: 80, title: "Hello from test" },
    },
    trackingLayoutMinimised: {}, // Add two keywords when you want to have different layout
  },
  readMoreValue: 2,
  source_ids: null,
  flash_screen: false,
  variableDatas: null,
  lastVisitedApp: [],
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    toggleAuthModal: (state) => {
      state.showAuthModal = !state.showAuthModal;
    },
    setWebSocket: (state, action) => {
      state.ws = action.payload;
    },
    setLayout: (state, action) => {
      const { layoutName, value } = action.payload;
      state.layouts[layoutName] = value;
    },
    addToLayout: (state, action) => {
      const { key, layoutName } = action.payload;
      let minimisedComponents = `${layoutName}Minimised`;

      if (state.layouts[minimisedComponents][key]) {
        state.layouts[layoutName][key] = state.layouts[minimisedComponents][key];
        delete state.layouts[minimisedComponents][key];
      } else {
        state.layouts[layoutName][key] = { top: 20, left: 80, ...action.payload };
      }
    },
    minimise: (state, action) => {
      const { key, layoutName } = action.payload;
      let minimisedComponents = `${layoutName}Minimised`;
      state.layouts[minimisedComponents][key] = state.layouts[layoutName][key];
      delete state.layouts[layoutName][key];
    },
    toggleNotificationBar: (state, action) => {
      state.readMoreValue = action.payload;
    },
    setSourceIdsGlobal: (state, action) => {
      state.source_ids = action.payload;
    },
    setFlashScreen: (state, action) => {
      state.flash_screen = action.payload;
    },
    setVariableDatas: (state, action) => {
      state.variableDatas = action.payload;
    },
    setlastVisitedApp: (state, action) => {
      state.lastVisitedApp.push(action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleAuthModal,
  setWebSocket,
  setLayout,
  addToLayout,
  removeFromLayout,
  minimise,
  setSourceIdsGlobal,
  setFlashScreen,
  setVariableDatas,
  setlastVisitedApp,
} = commonSlice.actions;

export const commonReducer = commonSlice.reducer;
