import { useState, useEffect } from "react";

/**
 * This hook helps to handle delete errors and autoclose of modal in case of successfull deletion
 * @param {RtkQuery.Mutation} deleteMutation - A rtk mutation which leads to delete an entity
 * @param {Function} closeModal - A function which is resoponsible to close current delete modal
 * @returns
 */
export const useDelete = ({ deleteMutation, closeModal }) => {
  const [deleteItem, { isSuccess: deleteSuccess, reset, error }] = deleteMutation();
  const [deleteErrorMsg, setDeleteErrorMsg] = useState("");

  // on successfull deletion close the modal and reset the formik state
  useEffect(() => {
    if (deleteSuccess) {
      closeModal();
      reset();
    }
    return () => reset();
  }, [deleteSuccess]);

  // Parse and set error message
  useEffect(() => {
    if (error) {
      if (error.status === 400 && error.data?.base) {
        setDeleteErrorMsg(`Can not delete, ${error.data.base.join(",")}`);
      } else {
        setDeleteErrorMsg("Something went wrong");
      }
    }
  }, [error]);

  return { deleteItem, deleteErrorMsg, setDeleteErrorMsg };
};
