import { QIPagination, QISlideSelect } from "../../../components";
import { getLocalizedString } from "../../../shared/translation";

export const PaginationHeader = ({ pagination, parentName }) => {
  let fromCount = Math.max(pagination.page - 1, 0) * pagination.perPage;
  let toCount = Math.min(pagination.page * pagination.perPage, pagination.count);

  return (
    <div className="perpage-item-and-range">
      {pagination.count > 20 && <QISlideSelect pagination={pagination} />}

      {pagination.count > 0 && (
        <p className="showing-out-of text-sm">
          {getLocalizedString("showing", "Showing")} {fromCount + 1} - {toCount} /{" "}
          {pagination.count}
          {parentName && (
            <>
              &nbsp;of&nbsp;
              <strong>{`${parentName}`}</strong>
            </>
          )}
        </p>
      )}
    </div>
  );
};

export const PaginationFooter = ({ pagination }) => {
  let pageCount = Math.ceil(pagination.count / (pagination.perPage || 10));

  return (
    <nav className="pagination-nav">
      {pagination.count > 20 && (
        <QIPagination
          pageCount={pageCount}
          onPageChange={pagination.onPageChange}
          activePage={pagination.page}
        />
      )}
    </nav>
  );
};
