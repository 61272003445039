import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FormContainer } from "../../ManagementPortal/Shared/FormContainer";
import * as Components from "../../../components";
import {
  useGetVehicleGroupsQuery,
  useGetDeviceGroupsQuery,
  useCreateReportMutation,
  useGetVehiclesQuery,
  useGetDevicesQuery,
  useGetReportConfigurationsQuery,
  useGetTimezoneQuery,
} from "../../ManagementPortal/services";
import moment from "moment";
import { useDropDownSearch, useForm } from "../../../hooks";
import { reportFormValidation } from "../validation";
import {
  selectionType,
  selectionTypeNoGroup,
  assetTypes,
  assetTypesNoDevice,
  eventTypes,
  fileFormats,
  subTypes,
  subTypesNoSummary,
  summaryIntervals,
} from "./static";
import { FMDPStorage } from "../../../shared/helper";
import { reportTypes } from "../../../shared/reportTypes";
import { getLocalizedString } from "../../../shared/translation";
import { lastDayMonth } from "../../../shared/helper";

export const ReportsForm = ({ onClose }) => {
  const [eventType, setEventType] = useState(null);
  const [initialValues, setInitalValues] = useState({
    name: "",
    metadata: {
      selection_criteria: "",
      asset_ids: [],
      asset_type: "",
      device_imeis: [],
      plate_numbers: [],
    },
    start_time: null,
    end_time: null,
    report_configuration_id: "",
    file_format: "csv",
    time_zone_id: 9,
    event_type: null,
    report_type: "trackpoint",
    report_sub_type: "",
    summary_interval: "",
    group_by_asset: false,
  });
  const [resetCount, setResetCount] = useState(0);
  const [selectedRange, setSelectedRange] = useState({
    startDate: moment().subtract(24, "hours"),
    endDate: moment(),
  });

  // initialize Formik
  const formik = useFormik({
    initialValues,
    validationSchema: reportFormValidation,
    enableReinitialize: true,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const currentUser = FMDPStorage.get("current-user");
  const currentAppRoles = currentUser.client_app_roles;
  const currentUserId = currentUser?.id;
  const selectedAppId = FMDPStorage.get("selected-app-id");
  let hasAdminRole = false;
  currentAppRoles?.filter((clientData) => {
    if (clientData?.client_app_id === selectedAppId) {
      clientData?.roles?.filter((role) => {
        if (role?.name === "Admin" || role?.name === "Fleet Admin") {
          hasAdminRole = true;
        }
      });
    }
  });

  const getReportTypeId = (type) => {
    let id = null;
    reportTypes?.map((report, index) => {
      if (report?.id === type) {
        id = index;
      }
    });
    return id;
  };

  //Get Reports Configuration
  const { data: reportsConfiguration } = useGetReportConfigurationsQuery({
    per_page: 1000,
    q: JSON.stringify({ report_type_eq: getReportTypeId(formik?.values?.report_type) }),
  });

  const getUserId = () => {
    if (hasAdminRole === false) {
      return currentUserId;
    } else {
      return "";
    }
  };

  //Get Vehicle Groups
  const { data: vehicleData, setSearchKey: vehicleDataSerch } = useDropDownSearch({
    useData: useGetVehicleGroupsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.metadata.asset_ids,
    additionalParams: { per_page: 600 },
  });

  //Get Device Groups
  const { data: deviceData, setSearchKey: deviceDataSerch } = useDropDownSearch({
    useData: useGetDeviceGroupsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.metadata.asset_ids,
    additionalParams: { per_page: 600 },
  });

  //Get VehicleQuery
  const { data: vehicleListData, setSearchKey: vehicleListDataSerch } = useDropDownSearch({
    useData: useGetVehiclesQuery,
    simpleSearchKey: "name_or_plate_number_cont",
    selectedIds: formik.values.metadata.asset_ids,
    additionalParams: { per_page: 600, filter_user_id: getUserId() },
  });

  //Get DeviceQuery
  const { data: deviceListData, setSearchKey: deviceListDataSearch } = useDropDownSearch({
    useData: useGetDevicesQuery,
    simpleSearchKey: "device_id_cont",
    selectedIds: formik.values.metadata.asset_ids,
    additionalParams: { per_page: 600 },
  });

  const { create: createReportConfiguration, update: updateReportConfiguration } = useForm({
    createMutation: useCreateReportMutation,
    updateMutation: useCreateReportMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  const { data: timeZones } = useGetTimezoneQuery({});

  //Handle Date Change
  useEffect(() => {
    formik.setFieldValue("start_time", moment(selectedRange.startDate).unix());
    formik.setFieldValue("end_time", moment(selectedRange.endDate).unix());
    setResetCount((i) => i + 1);
  }, [selectedRange]);

  const handleFormSubmit = (formdata) => {
    createReportConfiguration({ formData: { ...formdata } });
  };

  const modifiedVehicleData = vehicleListData?.data?.map((item) => {
    return {
      name: item?.plate_number,
      id: item?.id,
      plate_number: item?.plate_number,
    };
  });

  const modifiedDeviceData = deviceListData?.data?.map((item) => {
    return {
      name: item?.device_id,
      id: item?.id,
      plate_number: item?.plate_number,
    };
  });

  // For report type anything other than trackpoint, report configuration is not present hence clearing it
  const handleReportType = (value) => {
    formik.setFieldValue("report_type", value);
    if (value !== "trackpoint") {
      formik.setFieldValue("report_configuration_id", "");
    }
    formik.setFieldValue("report_sub_type", "");
    formik.setFieldValue("summary_interval", "");
  };

  const getWarningMessage = () => {
    const diffInDays =
      (formik?.values?.end_time * 1000 - formik?.values?.start_time * 1000) / 86400000;

    if (formik?.values?.report_sub_type === "summary") {
      if (formik?.values?.summary_interval === "weekly") {
        if (diffInDays < 7) {
          return "You have chosen less than 7 days for weekly report";
        }
      }
      if (formik?.values?.summary_interval === "monthly") {
        if (diffInDays < 28) {
          return "You have chosen less than 28 days for monthly report";
        }
      }
      if (formik?.values?.summary_interval === "daily") {
        if (diffInDays < 1) {
          return "You have chosen less than 24 hours for daily report";
        }
      }
    } else {
      return "";
    }
  };

  return (
    <FormContainer
      customResourceName={getLocalizedString("report", "Report")}
      handleFormSubmit={formik.handleSubmit}
      closeForm={onClose}
      ifTrackingApp={true}
    >
      <Components.QIInput
        label={getLocalizedString("name", "Name")}
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />

      {/* Report Type */}
      <Components.QICustomSelect
        label={getLocalizedString("type", "Type")}
        value={formik?.values?.report_type}
        onChange={(value) => handleReportType(value)}
      >
        {reportTypes?.map((type, index) => (
          <li value={type.id} key={index}>
            {getLocalizedString(type?.localization_key) || type?.name}
          </li>
        ))}
      </Components.QICustomSelect>

      {/* Report SubType */}
      <Components.QICustomSelect
        label={getLocalizedString("subtype", "Sub-Type")}
        value={formik?.values?.report_sub_type}
        onChange={(value) => {
          formik.setFieldValue("report_sub_type", value);
        }}
        error={formik?.touched?.report_sub_type && formik?.errors?.report_sub_type}
      >
        {(formik?.values?.report_type == "trackpoint" || formik?.values?.report_type == "raw"
          ? subTypesNoSummary
          : subTypes
        )?.map((type, index) => (
          <li value={type.id} key={index}>
            {getLocalizedString(type?.localization_key) || type?.name}
          </li>
        ))}
      </Components.QICustomSelect>

      {/* Report Summary Interval */}
      {formik?.values?.report_sub_type === "summary" && (
        <Components.QICustomSelect
          label={getLocalizedString("summary_interval", "Summary Interval")}
          value={formik?.values?.summary_interval}
          onChange={(value) => {
            formik.setFieldValue("summary_interval", value);
          }}
          error={formik?.touched?.summary_interval && formik?.errors?.summary_interval}
        >
          {summaryIntervals?.map((type, index) => (
            <li value={type.id} key={index}>
              {getLocalizedString(type?.localization_key) || type?.name}
            </li>
          ))}
        </Components.QICustomSelect>
      )}

      {/* Group By Asset */}
      {formik?.values?.report_sub_type === "summary" && (
        <Components.QISwitch
          label={getLocalizedString("group_by_asset", "Group By Asset")}
          value={formik.values.group_by_asset}
          onChange={() => formik.setFieldValue("group_by_asset", !formik.values.group_by_asset)}
        />
      )}

      {/* Event Type */}
      {formik?.values?.report_type == "event" && (
        <Components.QICustomSelect
          label={getLocalizedString("event_type", "Event Type")}
          value={formik.values.event_type}
          onChange={(value) => {
            formik.setFieldValue("event_type", value);
          }}
          error={formik?.touched?.event_type && formik?.errors?.event_type}
        >
          {eventTypes?.map((type, index) => (
            <li value={type.id} key={index}>
              {getLocalizedString(type?.localization_key) || type?.name}
            </li>
          ))}
        </Components.QICustomSelect>
      )}

      {/* Report Configuration */}
      {formik?.values?.report_type == "trackpoint" && (
        <Components.QICustomSelect
          label={getLocalizedString("report_configuration", "Report Configuration")}
          value={formik.values.report_configuration_id}
          onChange={(value) => {
            formik.setFieldValue("report_configuration_id", Number(value));
          }}
          error={
            formik?.touched?.report_configuration_id && formik?.errors?.report_configuration_id
          }
          labelClassName="report-configuration"
        >
          {reportsConfiguration?.data?.length ? (
            reportsConfiguration?.data?.map((item, id) => {
              return (
                <li value={item?.id} id={id}>
                  {item?.name}
                </li>
              );
            })
          ) : (
            <li value={null}>No Data Found</li>
          )}
        </Components.QICustomSelect>
      )}

      {/* Selection Criteria */}
      <Components.QICustomSelect
        label={getLocalizedString("selected_criteria", "Selected Criteria")}
        value={formik.values.metadata.selection_criteria}
        error={
          formik?.touched?.metadata?.selection_criteria &&
          formik?.errors?.metadata?.selection_criteria
        }
        onChange={(value) => {
          formik.setFieldValue("metadata.selection_criteria", value);
        }}
      >
        {(!hasAdminRole || formik?.values?.report_sub_type === "summary"
          ? selectionTypeNoGroup
          : selectionType
        )?.map((item, index) => {
          return (
            <li value={item?.name} key={index}>
              {getLocalizedString(item?.localization_key) || item?.name}
            </li>
          );
        })}
      </Components.QICustomSelect>

      {/* Selected Assets Type */}
      <Components.QICustomSelect
        label={getLocalizedString("asset_type", "Asset Type")}
        value={formik.values.metadata.asset_type}
        error={formik?.touched?.metadata?.asset_type && formik?.errors?.metadata?.asset_type}
        onChange={(value) => {
          formik.setFieldValue("metadata.asset_type", value);
        }}
      >
        {(hasAdminRole ? assetTypes : assetTypesNoDevice)?.map((item, index) => {
          return (
            <li value={item?.name} key={index}>
              {getLocalizedString(item?.localization_key) || item?.name}
            </li>
          );
        })}
      </Components.QICustomSelect>

      {/* Selected Assets Report */}
      {formik.values.metadata.asset_type === "Vehicle" &&
        formik.values.metadata.selection_criteria === "Group" && (
          <Components.QIMultiSelectDropDown
            label={getLocalizedString("vehicles_group", "Vehicles Group")}
            data={vehicleData?.data || []}
            selected={formik.values.metadata.asset_ids}
            error={formik?.touched?.metadata?.asset_ids && formik?.errors?.metadata?.asset_ids}
            onChange={(value) => formik.setFieldValue("metadata.asset_ids", value)}
            onSearch={(value) => vehicleDataSerch(value)}
          />
        )}

      {formik.values.metadata.asset_type === "Device" &&
        formik.values.metadata.selection_criteria === "Group" && (
          <Components.QIMultiSelectDropDown
            label={getLocalizedString("devices_group", "Devices Group")}
            data={deviceData?.data || []}
            selected={formik.values.metadata.asset_ids}
            error={formik?.touched?.metadata?.asset_ids && formik?.errors?.metadata?.asset_ids}
            onChange={(value) => formik.setFieldValue("metadata.asset_ids", value)}
            onSearch={(value) => deviceDataSerch(value)}
          />
        )}

      {/* Vehicle List Reports */}
      {formik.values.metadata.selection_criteria === "Asset" &&
        formik.values.metadata.asset_type === "Vehicle" && (
          <Components.QIMultiSelectDropDown
            label="Vehicles"
            data={modifiedVehicleData || []}
            selected={formik.values.metadata.asset_ids}
            error={formik?.touched?.metadata?.asset_ids && formik?.errors?.metadata?.asset_ids}
            onChange={(value) => {
              formik.setFieldValue("metadata.asset_ids", value);
              const newData = modifiedVehicleData
                ?.filter((val) => {
                  return value?.includes(val?.id);
                })
                .map((val) => {
                  return val?.plate_number;
                });
              formik.setFieldValue("metadata.plate_numbers", newData);
            }}
            onSearch={(value) => vehicleListDataSerch(value)}
          />
        )}

      {/* Device List Reports */}
      {formik.values.metadata.selection_criteria === "Asset" &&
        formik.values.metadata.asset_type === "Device" && (
          <Components.QIMultiSelectDropDown
            label="Devices"
            data={modifiedDeviceData || []}
            selected={formik.values.metadata.asset_ids}
            error={formik?.touched?.metadata?.asset_ids && formik?.errors?.metadata?.asset_ids}
            onChange={(value) => {
              formik.setFieldValue("metadata.asset_ids", value);
              const newData = modifiedDeviceData
                ?.filter((val) => {
                  return value?.includes(val?.id);
                })
                .map((val) => {
                  return val?.plate_number;
                });
              formik.setFieldValue("metadata.plate_numbers", newData);
            }}
            onSearch={(value) => deviceListDataSearch(value)}
          />
        )}

      {/* File Format */}
      <Components.QICustomSelect
        label={getLocalizedString("file_format", "File Format")}
        value={formik.values.file_format}
        onChange={(value) => {
          formik.setFieldValue("file_format", value);
        }}
      >
        {fileFormats?.map((item, index) => {
          return (
            <li value={item?.id} key={index}>
              {getLocalizedString(item?.localization_key) || item?.name}
            </li>
          );
        })}
      </Components.QICustomSelect>

      {/* Date Range */}
      <Components.QICustomDateRangePicker
        label="Date & Time"
        resetCount={resetCount}
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
        setResetCount={setResetCount}
        className={"report-form-datepicker"}
        warningMessage={getWarningMessage()}
      />

      {/* Time Zone */}
      <Components.QICustomSelect
        label={getLocalizedString("timezone", "Timezone")}
        value={formik.values.time_zone_id}
        onChange={(value) => {
          formik.setFieldValue("time_zone_id", Number(value));
        }}
        className="timezone-dropdown"
      >
        {timeZones?.data?.map((item) => {
          return <li value={item?.id}>{item?.name}</li>;
        })}
      </Components.QICustomSelect>
    </FormContainer>
  );
};
