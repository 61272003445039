import { useRef, useState } from "react";
import sprite from "../../assets/icons.svg";
import {getLocalizedString} from "../../shared/translation";

export const QIPhotoUploader = ({
  label,
  value,
  className = "",
  error,
  onChange,
  readOnly,
  src,
  photoGuide = "",
  placeHolderImg = "",
  showJusImg,
  base64 = true,
  initalImageUrl = "",
  ...props
}) => {
  const inputRef = useRef(null);
  const [previewImg, setPreviewImg] = useState(null);
  const [isImgChanged, setIsImgChanged] = useState(false);

  const handleChange = (e) => {
    const image = { image_extension: "", image_data: "" };

    try {
      const file = e.target.files[0];
      if (!base64) {
        onChange(e.target.files);
      }
      // Get file extenstion

      var file_extension = file.name.split(".").pop().toLowerCase();

      const reader = new FileReader();

      reader.onloadend = () => {
        const stringArray = reader.result.split(",");
        const dataString = stringArray[1];
        if (base64) {
          onChange({ image_extension: file_extension, image_data: dataString });
        } else {
          setPreviewImg({ image_extension: file_extension, image_data: dataString });
          setIsImgChanged(true);
        }
      };

      reader.onerror = () => {
        if (base64) {
          onChange(image);
        } else {
          onChange(null);
        }
      };

      reader.readAsDataURL(file);
    } catch {
      if (base64) {
        onChange(image);
      } else {
        setPreviewImg(null);
        setIsImgChanged(true);
        onChange(null);
      }
    }
  };

  return (
    <div
      className={`qi-input qi-form-element flex-wrap ${error ? "error" : ""} ${
        readOnly && "disabled"
      } ${className || ""}`}
      onClick={() => inputRef.current.focus()}
    >
      <div className="qi-input_wrapper flex">
        {!showJusImg && (
          <label className="qi-input_label" data-testid="input-label">
            {label}
          </label>
        )}

        <div className="customization_block_header_brand">
          <div className="qi-photo-uploader">
            <div className="qi-photo-uploader_container">
              {((src?.image_extension && src?.image_data) ||
                (initalImageUrl && !isImgChanged) ||
                (isImgChanged && previewImg)) &&
                !readOnly && (
                  <span className="icon-wrapper" onClick={handleChange}>
                    <svg className="icon">
                      <use href={`${sprite}#cross`}></use>
                    </svg>
                  </span>
                )}

              {base64 && (
                <img
                  className="qi-photo-uploader_image"
                  src={
                    src?.image_extension && src?.image_data
                      ? `data:image/${src.image_extension};base64,${src.image_data}`
                      : placeHolderImg
                  }
                />
              )}

              {!base64 && (
                <>
                  {isImgChanged ? (
                    <img
                      className="qi-photo-uploader_image"
                      src={
                        previewImg?.image_extension && previewImg?.image_data
                          ? `data:image/${previewImg.image_extension};base64,${previewImg.image_data}`
                          : placeHolderImg
                      }
                    />
                  ) : (
                    <img
                      className="qi-photo-uploader_image"
                      src={initalImageUrl || placeHolderImg}
                    />
                  )}
                </>
              )}
            </div>

            {!readOnly && (
              <div className="qi-photo-uploader_action">
                <div
                  className="qi-photo-uploader_custome-button upload-button"
                  onClick={() => inputRef.current.click()}
                >
                  {getLocalizedString("upload", "Upload")}
                </div>

                <input
                  className="qi-photo-uploader_file-field"
                  type="file"
                  ref={inputRef}
                  onChange={handleChange}
                />
              </div>
            )}
          </div>
        </div>

        {!readOnly && error && (
          <div className="qi-input_error">
            <span className="icon-wrapper">
              <svg className="icon">
                <use href={`${sprite}#info`}></use>
              </svg>
            </span>
            <p
              className="qi-input_error_message "
              dangerouslySetInnerHTML={{ __html: `${error}` }}
            ></p>
          </div>
        )}
      </div>

      {photoGuide && (
        <div
          className="qi-photo-uploader_guide-text flex customization-photo-upload-guide"
          dangerouslySetInnerHTML={{ __html: photoGuide }}
        ></div>
      )}
    </div>
  );
};
