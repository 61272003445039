import { useEffect, useState } from "react";
import moment from "moment";
import { AdvanceSearchWrapper } from "../Shared/";
import { QIDateRangePicker, QIInput, QICustomSelect } from "../../../components";
import { useGetClientsQuery } from "../services";
import { useDropDownSearch } from "../../../hooks";

export const VehicleModelAdvanceSearch = ({ onSearch, activeSearch }) => {
  const initialValues = {
    client_id_eq: "",
    name_cont: "",
    vehicle_manufacturer_model_variant_vehicle_manufacturer_model_vehicle_manufacturer_name_cont:
      "",
    vehicle_manufacturer_model_variant_vehicle_manufacturer_model_name_cont: "",
    vehicle_manufacturer_model_variant_name_cont: "",
    capacity_cont: "",
    engine_capacity_cont: "",
    year_eq: "",
    filter_vehicle_class_ids: [],
    filter_vehicle_type_ids: [],
    created_at_gteq: null,
    created_at_lteq: null,
  };
  const dropmenuQueryParams = { per_page: 1000, order_by: "name" };

  const [formData, setFormData] = useState(initialValues);
  const [showCalender, setShowCalender] = useState(false);
  const [resetCount, setResetCount] = useState(0);

  // Get the clients list using Dropdown Search
  const {
    data: clients,
    setSearchKey: setClientSearch,
    isSuccess: clientsSuccess,
  } = useDropDownSearch({
    useData: useGetClientsQuery,
    simpleSearchKey: "name_or_email_cont",
    additionalParams: { ...dropmenuQueryParams },
    selectedIds: formData.client_id_eq,
  });

  useEffect(() => {
    if (activeSearch) {
      setFormData(activeSearch);
    }
  }, []);

  const handleFormChange = (key, value, type) => {
    let tempValue = value;
    if (type === "int") {
      tempValue = parseInt(value) || "";
    }
    setFormData((prevState) => ({ ...prevState, [key]: tempValue }));
  };

  const clearAdvanceSearch = () => {
    setResetCount((i) => i + 1);
    setFormData(initialValues);
    onSearch(null);
    setShowCalender(false);
  };

  const handleDateRange = (startTime, endTime) => {
    setFormData((prevState) => ({
      ...prevState,
      created_at_gteq: startTime,
      created_at_lteq: endTime,
    }));
    setResetCount((i) => i + 1);
  };

  return (
    <AdvanceSearchWrapper
      activeFilters={activeSearch}
      onClear={clearAdvanceSearch}
      onSearch={() => onSearch(formData)}
      showCalender={showCalender}
      setShowCalender={setShowCalender}
    >
      <QICustomSelect
        label="Client"
        value={formData.client_id_eq}
        onChange={(value) => {
          handleFormChange("client_id_eq", parseInt(value));
          setClientSearch("");
        }}
        onSearch={setClientSearch}
        labelClassName="client"
      >
        <li value="">Any</li>
        {clientsSuccess &&
          clients.data.map((client) => (
            <li key={client.id} value={client.id}>
              {client.name}
            </li>
          ))}
      </QICustomSelect>
      <QIInput
        label="Name"
        value={formData.name_cont}
        onChange={(e) => handleFormChange("name_cont", e.target.value)}
      />
      <QIInput
        label="Manufacturer"
        value={
          formData.vehicle_manufacturer_model_variant_vehicle_manufacturer_model_vehicle_manufacturer_name_cont
        }
        onChange={(e) =>
          handleFormChange(
            "vehicle_manufacturer_model_variant_vehicle_manufacturer_model_vehicle_manufacturer_name_cont",
            e.target.value
          )
        }
      />
      <QIInput
        label="Model"
        value={formData.vehicle_manufacturer_model_variant_vehicle_manufacturer_model_name_cont}
        onChange={(e) =>
          handleFormChange(
            "vehicle_manufacturer_model_variant_vehicle_manufacturer_model_name_cont",
            e.target.value
          )
        }
      />
      <QIInput
        label="Variant"
        value={formData.vehicle_manufacturer_model_variant_name_cont}
        onChange={(e) =>
          handleFormChange("vehicle_manufacturer_model_variant_name_cont", e.target.value)
        }
      />
      <QIInput
        label="Capacity"
        value={formData.capacity_cont}
        type="number"
        onChange={(e) => handleFormChange("capacity_cont", e.target.value)}
      />
      <QIInput
        label="Engine Capacity"
        value={formData.engine_capacity_cont}
        onChange={(e) => handleFormChange("engine_capacity_cont", e.target.value)}
      />
      <QIInput
        label="Year"
        value={formData.year_eq}
        onChange={(e) => handleFormChange("year_eq", e.target.value, "int")}
      />

      <QIDateRangePicker
        start={formData.created_at_gteq}
        end={formData.created_at_lteq}
        label={"Created b/w"}
        maxDate={moment()}
        onChange={handleDateRange}
        showCustomRangeLabel
        setShowCalender={setShowCalender}
        resetCount={resetCount}
      />
    </AdvanceSearchWrapper>
  );
};
