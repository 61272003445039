import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { FormContainer } from "../Shared";
import {
  useCreateEventConfigurationPlanMutation,
  useUpdateEventConfigurationPlanMutation,
  useGetEventConfigurationPlanQuery,
  useGetEventConfigurationsQuery,
} from "../services";
import { useForm } from "../../../hooks";
import { EventConfigurationsPlanFormValidation } from "../validations";
import { mergeObjects } from "../../../shared/helper";
import { events } from "../../../shared/dropdownLists";
import { getLocalizedString } from "../../../shared/translation";

export const EventConfigurationsPlanForm = ({ idToEdit = null, onClose }) => {
  const dropdownParams = { per_page: 1000 };
  const [initialValues, setInitialValues] = useState({
    name: "",
    active: true,
    idle_event_ids: [],
    speed_event_ids: [],
    geofence_event_ids: [],
    low_battery_event_ids: [],
    ignition_onchange_event_ids: [],
    vehicle_group_ids: [],
    device_group_ids: [],
    custom_event_ids: [],
    offline_event_ids: [],
  });

  //Set select option for all event types at the beginning
  const [multiSelect, setMultiSelect] = useState({
    Idling: true,
    Speeding: true,
    Geofence: true,
    Battery: true,
    Ignition: true,
    Custom: true,
    Offline: true,
  });

  const [configurationError, setConfigurationError] = useState("");
  const [warningMessageActiveToggle, setWarningMessageActiveToggle] = useState(false);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: EventConfigurationsPlanFormValidation,
    onSubmit: (value) => handleFormSubmit(value),
  });

  const { data = {} } = useGetEventConfigurationPlanQuery({ id: idToEdit }, { skip: !idToEdit });

  const queryParamsForIdling = {
    q: JSON.stringify({ event_type_id_eq: events.Idling }),
  };

  const queryParamsForSpeeding = {
    q: JSON.stringify({ event_type_id_eq: events.Speeding }),
  };

  const queryParamsForGeofence = {
    q: JSON.stringify({ event_type_id_eq: events.Geofence }),
  };

  const queryParamsForBattery = {
    q: JSON.stringify({ event_type_id_eq: events.Battery }),
  };

  const queryParamsForIgnition = {
    q: JSON.stringify({ event_type_id_eq: events.Ignition }),
  };

  const queryParamsForCustom = {
    q: JSON.stringify({ event_type_id_eq: events.Custom }),
  };

  const queryParamsForOffline = {
    q: JSON.stringify({ event_type_id_eq: events.Offline }),
  };

  //Fetch data for the created idle events
  const { data: idleCreated } = useGetEventConfigurationsQuery({
    q: encodeURIComponent(queryParamsForIdling.q),
    per_page: 600,
  });

  //Fetch data for the created speeding events
  const { data: speedingCreated } = useGetEventConfigurationsQuery({
    q: encodeURIComponent(queryParamsForSpeeding.q),
    per_page: 600,
  });

  //Fetch data for the created geofence events
  const { data: geofenceCreated } = useGetEventConfigurationsQuery({
    q: encodeURIComponent(queryParamsForGeofence.q),
    per_page: 600,
  });

  //Fetch data for the created low battery events
  const { data: batteryCreated } = useGetEventConfigurationsQuery({
    q: encodeURIComponent(queryParamsForBattery.q),
  });

  //Fetch data for the created ignition onchange events
  const { data: ignitionCreated } = useGetEventConfigurationsQuery({
    q: encodeURIComponent(queryParamsForIgnition.q),
  });

  //Fetch data for the created custom events
  const { data: customCreated } = useGetEventConfigurationsQuery({
    q: encodeURIComponent(queryParamsForCustom.q),
  });

  //Fetch data for the created offline events
  const { data: offlineCreated } = useGetEventConfigurationsQuery({
    q: encodeURIComponent(queryParamsForOffline.q),
  });

  // Set form data in case of edit
  useEffect(() => {
    if (idToEdit && data) {
      setInitialValues(mergeObjects(initialValues, data));
    }
  }, [data]);

  useEffect(() => {
    //Remove add option for idle event after one is already added
    if (initialValues.idle_event_ids.length) {
      setMultiSelect((prevState) => ({ ...prevState, Idling: false }));
    }
    //Remove add option for ignition onchange event after one is already added
    if (initialValues.ignition_onchange_event_ids.length) {
      setMultiSelect((prevState) => ({ ...prevState, Ignition: false }));
    }
    //Remove add option for offline event after one is already added
    if (initialValues.offline_event_ids.length) {
      setMultiSelect((prevState) => ({ ...prevState, Offline: false }));
    }
  }, [initialValues]);

  const { create: createEventConfigurationPlan, update: updateEventConfigurationPlan } = useForm({
    createMutation: useCreateEventConfigurationPlanMutation,
    updateMutation: useUpdateEventConfigurationPlanMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  const handleFormSubmit = (formData) => {
    //Set error that at least one event is required
    if (
      formData.idle_event_ids.length ||
      formData.speed_event_ids.length ||
      formData.geofence_event_ids.length ||
      formData.low_battery_event_ids.length ||
      formData.ignition_onchange_event_ids.length ||
      formData.offline_event_ids.length ||
      formData.custom_event_ids.length
    ) {
      setConfigurationError("");
      if (idToEdit) {
        updateEventConfigurationPlan({ id: idToEdit, formData });
      } else {
        createEventConfigurationPlan({ formData });
      }
    } else {
      setConfigurationError(
        getLocalizedString(
          "at_least_1_configuration_is_required",
          "At least 1 configuration is required"
        )
      );
    }
  };

  const handleChangeConfigs = (value, type, multiSelectType) => {
    formik.setFieldValue(type, value);
    if (
      value.length >= 1 &&
      (multiSelectType === "Idling" ||
        multiSelectType === "Ignition" ||
        multiSelectType === "Offline")
    ) {
      setMultiSelect((prevState) => ({ ...prevState, [multiSelectType]: false }));
    } else {
      setMultiSelect((prevState) => ({ ...prevState, [multiSelectType]: true }));
    }
  };

  return (
    <FormContainer
      resourceName={getLocalizedString("event_plan", "Event Plan")}
      closeForm={() => onClose(false)}
      handleFormSubmit={formik.handleSubmit}
      idToEdit={idToEdit}
    >
      <Components.QIInput
        label={getLocalizedString("name", "Name")}
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />

      <div className="qi-input credentials">
        <label className="qi-input_label">
          {getLocalizedString("event_configurations", "Event Configurations")}
        </label>
        <div className="w-9/12">
          <Components.QIMultiSelectDropDown
            label="Idling"
            selected={formik.values.idle_event_ids}
            data={idleCreated?.data || []}
            onChange={(value) => handleChangeConfigs(value, "idle_event_ids", "Idling")}
            className="narrow-multiselect-dropdown"
            multiSelect={multiSelect.Idling}
            labelKey={(data) => `${data?.name}`}
          />

          <Components.QIMultiSelectDropDown
            label="Speeding"
            selected={formik.values.speed_event_ids}
            data={speedingCreated?.data || []}
            onChange={(value) => handleChangeConfigs(value, "speed_event_ids", "Speeding")}
            className="narrow-multiselect-dropdown"
            multiSelect={multiSelect.Speeding}
            labelKey={(data) => `${data?.name}`}
          />

          <Components.QIMultiSelectDropDown
            label="Geofence"
            selected={formik.values.geofence_event_ids}
            data={geofenceCreated?.data || []}
            onChange={(value) => handleChangeConfigs(value, "geofence_event_ids", "Geofence")}
            className="narrow-multiselect-dropdown"
            labelKey={(data) => `${data?.name}`}
            multiSelect={multiSelect.Geofence}
          />

          <Components.QIMultiSelectDropDown
            label="Low Battery"
            selected={formik.values.low_battery_event_ids}
            data={batteryCreated?.data || []}
            onChange={(value) => handleChangeConfigs(value, "low_battery_event_ids", "Battery")}
            className="narrow-multiselect-dropdown"
            multiSelect={multiSelect.Battery}
            labelKey={(data) => `${data?.name}`}
          />

          <Components.QIMultiSelectDropDown
            label="Ignition Status Change"
            selected={formik.values.ignition_onchange_event_ids}
            data={ignitionCreated?.data || []}
            onChange={(value) =>
              handleChangeConfigs(value, "ignition_onchange_event_ids", "Ignition")
            }
            className="narrow-multiselect-dropdown"
            multiSelect={multiSelect.Ignition}
            labelKey={(data) => `${data?.name}`}
          />

          <Components.QIMultiSelectDropDown
            label="Offline"
            selected={formik.values.offline_event_ids}
            data={offlineCreated?.data || []}
            onChange={(value) => handleChangeConfigs(value, "offline_event_ids", "Offline")}
            className="narrow-multiselect-dropdown"
            multiSelect={multiSelect.Offline}
          />

          <Components.QIMultiSelectDropDown
            label="Custom"
            selected={formik.values.custom_event_ids}
            data={customCreated?.data || []}
            onChange={(value) => handleChangeConfigs(value, "custom_event_ids", "Custom")}
            className="narrow-multiselect-dropdown"
            multiSelect={multiSelect.Custom}
            labelKey={(data) => `${data?.name}`}
          />
          {configurationError && <p className="configurations-error">{configurationError}</p>}
        </div>
      </div>

      <Components.QISwitch
        label={getLocalizedString("active", "Active")}
        {...formik.getFieldProps("active")}
        error={formik.touched.active && formik.errors.active}
        onChange={() => {
          setWarningMessageActiveToggle(formik.values.active);
          formik.setFieldValue("active", !formik.values.active);
        }}
        warningMessage={`${
          warningMessageActiveToggle
            ? getLocalizedString(
                "alert_associated_group(s)_will_get_deactivated",
                "Alert! Associated group(s) will get deactivated."
              )
            : ""
        }`}
      />
    </FormContainer>
  );
};
