import "./style.scss";

export const QIErrorBoundary = ({ message, children }) => {
  return (
    <>
      <div className="no-data">
        <div className="no-data_message">
          <h1>{message.header}</h1>
          <p>{message.content}</p>
        </div>
      </div>
    </>
  );
};
