import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { QIInput, QIButton } from "../../../components";
import { useResetPasswordMutation } from "../service";
import { resetPasswordSchema } from "../validations";
import { ErrorModal } from "../Shared/ErrorModal";

export const ResetPasswordOtp = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [initialValues] = useState({
    password: "",
    password_confirmation: "",
    id,
  });

  const [errorModal, setShowErrorModal] = useState({
    msg: "",
    show: false,
    type: "",
  });

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const [create, { isSuccess, isError }] = useResetPasswordMutation();

  const onSubmit = (formData) => {
    create({ formData });
  };

  useEffect(() => {
    if (isSuccess) {
      setShowErrorModal({
        msg: "Successfully updated password",
        show: true,
        type: "success",
      });
    }
    if (isError) {
      setShowErrorModal({
        msg: "Token expired or Something went wrong. Try again.",
        show: true,
        type: "error",
      });
    }
  }, [isSuccess, isError]);

  const closeWarningModal = () => {
    if (errorModal.type === "success") {
      navigate("/");
    }
    setShowErrorModal({
      msg: "",
      show: false,
      type: "",
    });
  };

  return (
    <div className="login-layout">
      <div className="login-form-container">
        <h1>Set New Password</h1>
        <QIInput
          label="Password"
          {...formik.getFieldProps("password")}
          error={formik.touched.password && formik.errors.password}
          type="password"
        />
        <QIInput
          label="Confirm Password"
          {...formik.getFieldProps("password_confirmation")}
          type="password"
          error={formik.touched.password_confirmation && formik.errors.password_confirmation}
        />
        <QIButton className="btn sm primary text-uppercase mx-auto" onClick={formik.handleSubmit}>
          send
        </QIButton>
        <p className="text-center">
          <a href="/login">Back to login</a>
        </p>
      </div>
      {errorModal.show && <ErrorModal onClose={closeWarningModal} msg={errorModal.msg} />}
    </div>
  );
};
