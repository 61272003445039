import { FMDPStorage } from "./helper";

export class CurrentUser {
  constructor(resourceName) {
    const { name, app_features, email_id } = FMDPStorage.get("current_user") || {};
    this.appFeatures = FMDPStorage.get("app_features");
    this.name = name;
    this.email = email_id;
    this.resourceName = resourceName;
    this.canCreate = this.can("create");
    this.canDelete = this.can("delete");
    this.canUpdate = this.can("update");
  }

  can = (action) => {
    return this.appFeatures[this.resourceName]?.[action];
  };
}
