import React, { useState, useEffect, useMemo } from "react";
import { useSearchQuery, usePagination } from "../../../hooks";
import { RoleForm } from "./form";
import { MPSharedHeader, MPSharedTable, MPSharedDeleteModal } from "../Shared/";
import { useGetRolesQuery, useDeleteRoleMutation } from "../services";
import { getLocalizedString } from "../../../shared/translation";

export const RolesContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_client_app_app_name_cont",
  });

  const {
    data = { data: [], total_count: 0 },
    error,
    isFetching,
    isSuccess,
  } = useGetRolesQuery({
    page,
    per_page: perPage,
    order_by: "client_app_roles.created_at",
    order_dir: "desc",
    q,
  });

  const [deleteRole, { isSuccess: deleteSuccess, reset: resetDeleteRole }] =
    useDeleteRoleMutation();

  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [idToEdit, setIdToEdit] = useState(null);

  const onEdit = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  const onDelete = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const onCreate = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const headers = [
    {
      label: getLocalizedString("name", "Name"),
      key: "name",
      className: "name",
    },
    {
      label: getLocalizedString("application", "Application"),
      key: "app_name",
      className: "application",
    },
    {
      label: getLocalizedString("no_of_users", "No. of users"),
      key: "user_count",
      className: "w-25 text-center",
    },
    {
      label: getLocalizedString("added_on", "Added On"),
      key: "created_at",
      className: "date",
    },
  ];

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((item) => item.id === id);
    if (index >= 0) {
      name = data?.data[index]?.name;
    }
    return name;
  };

  useEffect(() => {
    if (deleteSuccess) {
      resetDeleteRole();
      setShowDeleteWarning(false);
    }
    return () => resetDeleteRole();
  }, [deleteSuccess]);

  return (
    <>
      <article className="main-container">
        <MPSharedHeader
          groupName="users_role"
          heading={getLocalizedString("users_and_roles", "Users And Roles")}
          resourceName="Client App Roles"
          createNewHandler={onCreate}
          handleSearchKey={(value) => setSimpleSearch(value)}
          simpleSearchPlaceholder={getLocalizedString("search_by_name_app", "Search by Name, App")}
        />

        <MPSharedTable
          resourceName="Client App Roles"
          data={data.data || []}
          error={error}
          isLoading={isFetching}
          isSuccess={isSuccess}
          headers={headers}
          onEdit={onEdit}
          pagination={{ ...pagination, count: data.total_count }}
          skipClientId
          className="roles"
          // onDelete={onDelete}
          auditResource="ClientAppRole"
          auditKey="name"
        />
      </article>

      {showForm && <RoleForm idToEdit={idToEdit} onClose={setShowForm} />}

      <MPSharedDeleteModal
        show={showDeleteWarning}
        entityName={getLocalizedString("role", "role")}
        onHide={() => setShowDeleteWarning(false)}
        itemName={getDeleteItemName(idToDelete)}
        onDelete={() => deleteRole({ id: idToDelete })}
      />
    </>
  );
};
