import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { QIButton, QIModal, QIModalBody } from "../../../components";
import { toggleAuthModal } from "../../../reduxStore/features";
import { FMDPStorage } from "../../../shared/helper";

export const AuthError = () => {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(toggleAuthModal());
    FMDPStorage.clear();
    window.location.reload();
  };

  // This effect helps to auto close the warning modal after sometime
  useEffect(() => {
    const timeOut = setTimeout(logout, 3000);
    return () => clearTimeout(timeOut);
  }, []);

  return (
    <QIModal show size="small">
      <QIModalBody className="p-2">
        <h2>There is some problem with your account. Logging you out...</h2>
        <QIButton className="secondary sm" onClick={logout}>
          Ok
        </QIButton>
      </QIModalBody>
    </QIModal>
  );
};
