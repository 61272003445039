import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AdminSharedHeader, AdminSharedTable, TableDropDownView } from "../Shared/";
import { TickOrCross } from "../../Shared";
import { useGetClientsQuery } from "../services";
import { useSearchQuery, usePagination } from "../../../hooks";
import { ClientForm } from "./Form";

export const ClientSection = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_cont",
  });

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetClientsQuery({ page, per_page: perPage, order_by: "created_at", order_dir: "desc", q });

  const [idToEdit, setIdToEdit] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const createNewHandler = () => {
    setIdToEdit(null);
    setShowForm(true);
  };

  const editClickHandler = (datum) => {
    setIdToEdit(datum.id);
    setShowForm(true);
  };

  const headers = [
    { label: "Id", key: "id", className: "id" },
    {
      label: "Name",
      key: "name",
      className: "name",
      nestedValue: true,
      getNestedValue: ({ name }) => {
        name = name.replace(/\s\s+/g, " ");
        return name;
      },
    },
    {
      label: "Applications",
      className: "apps applications more",
      type: "component",
      component: ({ data }) => {
        const appNames = data?.app_names || [""];
        return <TableDropDownView data={appNames} />;
      },
    },
    // {
    //   label: "Additional Features",
    //   type: "component",
    //   className: "additional-features more",
    //   component: ({ data }) => {
    //     return <TableDropDownView data={data?.additional_feature_names} />;
    //   },
    // },
    {
      label: "Devices",
      nestedValue: true,
      getNestedValue: ({ id, devices_count, name }) => {
        return (
          <>
            {devices_count > 0 ? (
              <Link
                to={`/superadmin/devices/list?client_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {devices_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
      className: "text-center device",
    },
    {
      label: "Vehicles",
      nestedValue: true,
      getNestedValue: ({ id, vehicles_count, name }) => {
        return (
          <>
            {vehicles_count > 0 ? (
              <Link
                to={`/superadmin/vehicles/list?client_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {vehicles_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
      className: "text-center vehicle",
    },
    {
      label: "Backends",
      nestedValue: true,
      getNestedValue: ({ id, backends_count, name }) => {
        return (
          <>
            {backends_count > 0 ? (
              <Link
                to={`/superadmin/others/backends?client_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {backends_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
      className: "backends text-center",
    },
    {
      label: "Geolocation Plan",
      key: "geolocation_plan_name",
      className: "geolocation-plan text-center more",
    },
    {
      label: "Retention Period (Days)",
      key: "retention_period",
      className: "text-center",
    },
    { label: "Created on", key: "created_at", className: "date" },
    {
      label: "Co-Branding Screen",
      nestedValue: true,
      getNestedValue: ({ co_branding_screen }) => <TickOrCross flag={co_branding_screen} />,
      className: "text-center",
    },
    {
      label: "Reseller",
      nestedValue: true,
      getNestedValue: ({ is_reseller }) => <TickOrCross flag={is_reseller} />,
      className: "text-center",
    },
    {
      label: "Created by Reseller",
      nestedValue: true,
      getNestedValue: ({ created_by_reseller }) => <TickOrCross flag={created_by_reseller} />,
      className: "text-center",
    },
  ];

  return (
    <>
      <AdminSharedHeader
        createNewHandler={createNewHandler}
        heading="Clients"
        count={data.total_count}
        handleSearchKey={(value) => setSimpleSearch(value)}
        simpleSearchPlaceHolder="Search by Name"
      />
      <AdminSharedTable
        isLoading={isFetching}
        error={error}
        isSuccess={isSuccess}
        data={data.data}
        headers={headers}
        pagination={{ ...pagination, count: data.total_count }}
        onEdit={editClickHandler}
        className="clients no-tabs"
        auditResource="Client"
        auditKey="name"
      />
      {showForm && <ClientForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}
    </>
  );
};
