import { QIButton, QIModal, QIModalBody } from "../../../components";

export const ErrorModal = ({ onClose, msg }) => {
  return (
    <QIModal show size="small">
      <QIModalBody className="p-2">
        <p>{msg}</p>
        <QIButton className="secondary sm mx-auto" onClick={onClose}>
          Ok
        </QIButton>
      </QIModalBody>
    </QIModal>
  );
};
