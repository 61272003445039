import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { ChangeLog } from "./show.js";
import { usePagination, useSearchQuery } from "../../../hooks";
import { QICustomSelect } from "../../../components";
import { MPSharedTable, MPSharedHeader } from "../Shared/";
import { AdvanceSearch } from "./advanceSearch.js";
import { SvgIcon } from "../../Shared";
import { useGetAuditableResourceTypeQuery, useGetAuditableEventsQuery } from "../services";
import {getLocalizedString} from "../../../shared/translation";

export const AuditTrail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const resourceType = searchParams.get("resource_type");
  const resourceId = searchParams.get("resource_id");
  const resourceName = searchParams.get("resource_name");
  const { page, setPage, perPage, pagination } = usePagination();
  const [changes, setChanges] = useState(null);

  const { setAdvanceSearch, advanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "event_type_or_user_email_cont",
    additionalSimpleSearchKeys: ["resource_value_cont"],
  });

  const [initialValues] = useState({
    resource_type: resourceType || "",
  });

  const formik = useFormik({
    initialValues,
  });

  const queryParams = {
    page: page,
    per_page: perPage,
    resource_type: formik.values.resource_type || resourceType,
    resource_id: resourceId || "",
  };

  //This lists the audit trail
  const {
    data: detailedData = { data: [], total_count: 0 },
    error: detailedDataError,
    isFetching: detailedDataIsFetching,
    isSuccess: detailedDataSuccess,
  } = useGetAuditableEventsQuery(
    {
      ...queryParams,
      q,
    },
    { skip: resourceId ? !resourceId : !resourceType || resourceId }
  );

  // List of all the resources which user can access
  const { data: resources = { data: [], count: 0 } } = useGetAuditableResourceTypeQuery({
    page: page,
    per_page: perPage,
  });

  // If there is any change in resource type, sync that in FE queryParams
  useEffect(() => {
    if (formik.values.resource_type) {
      let queryParams = {};

      // Filter out queryParam in object form
      for (let entry of searchParams.entries()) {
        queryParams[entry[0]] = entry[1];
      }

      if (queryParams.resource_type != formik.values.resource_type) {
        let qp = { resource_type: formik.values.resource_type };
        setSearchParams(qp);
      }
    }
  }, [formik.values.resource_type]);

  //When page loads for first time and no resource type is selected
  // Select first resource type as soon as resources list is available
  useEffect(() => {
    if (resources.data.length > 0) {
      if (!resources.data.includes(formik.values.resource_type) || !resourceType) {
        formik.setFieldValue("resource_type", resources.data[0]);
        if (!resourceType) {
          let qp = { resource_type: formik.values.resource_type };
          setSearchParams(qp);
        }
      }
    }
  }, [resources, searchParams]);

  const headers = [
    {
      label: getLocalizedString("entity", "Entity"),
      key: "resource_value",
      className: "name",
    },
    { label: getLocalizedString("event", "Event"), key: "event_type", className: "event" },
    {
      label: getLocalizedString("updated_by", "Updated By"),
      key: "user_email",
      className: "email",
    },
    {
      label: getLocalizedString("added_on", "Added On"),
      key: "created_at",
      className: "date",
    },
    {
      label: getLocalizedString("change_log", "Change Log"),
      type: "component",
      className: "text-center",
      component: ({ data }) => (
        <SvgIcon
          wrapperClass="clickable"
          name="audit-log"
          onClick={() => setChanges(data)}
          title="View Log"
        >
          {getLocalizedString("view_log", "View Log")}
        </SvgIcon>
      ),
    },
  ];

  return (
    <article className="main-container">
      <MPSharedHeader
        heading={getLocalizedString("audit_trail", "Audit Trail")}
        defaultSearchType="advance"
        handleAdvanceSearchKeys={() => {}}
        className="audit-trail-search"
        beforeSearchDom={
          <QICustomSelect
            label={getLocalizedString("resource_type", "Resource Type")}
            value={resourceType}
            onChange={(value) => formik.setFieldValue("resource_type", value)}
            className=""
            labelClassName="resource-type"
          >
            {resources.data.map((res) => (
              <li key={res} value={res}>
                {res}
              </li>
            ))}
          </QICustomSelect>
        }
      >
        <div className="audit-filter">
          <AdvanceSearch
            onSearch={(value) => setAdvanceSearch(value)}
            activeSearch={advanceSearch}
            hasResourceId={resourceId}
          />
        </div>
      </MPSharedHeader>

      {resourceId && (
        <div className="main-container_secondary-header secondary-header-wrapper showing-only-shortlisted audit-log-shortlisted">
          <p className="showing-shortlisted">
            {resourceType &&
              resourceName &&
              `${
                getLocalizedString("filtered_by", "Filtered by")
              } ${resourceType} (${resourceName})`}
          </p>
        </div>
      )}
      <MPSharedTable
        pagination={{ ...pagination, count: detailedData.total_count }}
        isLoading={detailedDataIsFetching}
        error={detailedDataError}
        isSuccess={detailedDataSuccess}
        data={detailedData.data || []}
        headers={headers}
        className="audit-trail no-tabs"
      />

      {changes && <ChangeLog idToAudit={changes.id} onClose={() => setChanges(null)} />}
    </article>
  );
};
