import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { QIDropdown } from "../QIDropdown";
import { SvgIcon } from "../../containers/Shared";

export const QINotification = ({ OnNotification, initialValue }) => {
  const ws = useSelector((state) => state.common.ws);

  const [dropDownSection, setDropDownSection] = useState("");
  const [notificationCount, setNotificationCount] = useState(0);
  const [readMore, setReadMore] = useState(null);

  useMemo(() => {
    setNotificationCount(initialValue?.data);
  }, [initialValue?.data]);

  useEffect(() => {
    if (ws) {
      const { message } = ws;
      if (message?.id && message?.notification_count >= 0) {
        setNotificationCount(() => message.notification_count);
      }
    }
  }, [ws]);

  return (
    <QIDropdown
      onHide={() => setDropDownSection("")}
      className="user-action-dropdown notification-dropdown "
      toggleComponent={
        <div onClick={() => setReadMore(10)} className="notification">
          <SvgIcon name="bell" />
          {notificationCount > 0 && <span className="notification_alert">{notificationCount}</span>}
        </div>
      }
    >
      {
        <OnNotification
          notificationCount={notificationCount}
          readMore={readMore}
          setReadMore={setReadMore}
        />
      }
    </QIDropdown>
  );
};
