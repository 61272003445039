import * as Yup from "yup";

export const loginFromValidationSchema = Yup.object({
  email: Yup.string().email("Not a valid mail").required("Required"),
  password: Yup.string().required("Required").min(3, "Minimum 3 characters required"),
});

export const forgetPasswordSchema = Yup.object({
  email: Yup.string().email("Not a valid mail").required("Required"),
});

export const resetPasswordSchema = Yup.object({
  password: Yup.string()
    .required("Required")
    .matches(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
      `Password should meet the following criteria:
  <br>
  1. Must be at least 8 characters.
  <br>
  2. Must contain at least 1 Uppercase letter.
  <br>
  3. Must contain at least 1 Lowercase letter.
  <br>
  4. Must contain at least 1 Number.
  <br>
  5. Must contain at least 1 special character (@#$%^&*)`
    ),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords don't match!")
    .required("Required"),
});
