const commonConfig = {
  dateTimeFormat: "D MMM, YYYY HH:mm:ss",
  dateFormat: "D MMM, YYYY ",
  trackInfo: {
    verySlow: { color: "#ED1C24", width: 4 },
    slow: { color: "#330000", width: 4 },
    medium: { color: "#FF7814", width: 4 },
    fast: { color: "#800000", width: 4 },
  },
  getTrackType: (speed) => {
    if (speed < 25) {
      return "verySlow";
    } else if (speed < 35) {
      return "slow";
    } else if (speed < 65) {
      return "medium";
    }
    return "fast";
  },
  defaultClient: {
    id: "2",
    name: "carro",
  },
};

export default commonConfig;
