import { QIMapLoader } from "../../../components";

export const VehicleMap = ({
  setMapRef,
  dockAlign,
  mapExpand,
  mapRef,
  deviceId,
  selectedRowId,
  maximizeMinimize,
}) => {
  return (
    <div className={`map-container ${dockAlign && "qiMap-split"}`}>
      <QIMapLoader
        dockAlign={dockAlign}
        containerId="device-map-container"
        setMapRef={setMapRef}
        mapExpand={mapExpand}
        mapRef={mapRef}
        deviceId={deviceId}
        selectedRowId={selectedRowId}
        maximizeMinimize={maximizeMinimize}
      ></QIMapLoader>
    </div>
  );
};
