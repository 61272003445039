import React, { useState, useRef, useEffect, useMemo } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useOutsideAlerter } from "../../hooks";
import { QIInput } from "../QIInput";
import sprite from "../../assets/icons.svg";
import { SvgIcon } from "../../containers/Shared/SvgIcon";

function debounce(func, delay = 500) {
  let tempVar;

  return function () {
    clearTimeout(tempVar);
    tempVar = setTimeout(() => {
      func.apply(this, arguments);
    }, delay);
  };
}

export const QICustomSelect = ({
  label,
  value = "",
  onChange,
  icon = {},
  children,
  className = "",
  disabled = false,
  readOnly,
  onSearch = null,
  onSearchPlaceHolder = "",
  error,
  autoHeightMax = "100%",
  warningMessage = "",
  edit = false,
  labelClassName,
  setExpandForm = () => {},
}) => {
  const [innerValue, setInnerValue] = useState("");
  const [show, setShow] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [disable, setDisable] = useState(false);
  const [editable, setEditable] = useState(false);
  const dropDownRef = useRef(null);
  const listRef = useRef(null);
  useOutsideAlerter(dropDownRef, () => {
    try {
      setShow(false);
      setExpandForm(false);
    } catch (e) {
      console.error(e);
    }
  });
  const { className: iconClass, src } = icon;

  //To set the disabled value
  useEffect(() => {
    setDisable(disabled);
  }, [disabled]);

  //To set the editable value and disable field if editable option is added
  useEffect(() => {
    if (edit) {
      setEditable(true);
      setDisable(true);
    }
  }, [edit]);

  useEffect(() => {
    let tempInnerValue = "";
    try {
      const restNode = listRef.current.querySelectorAll("li");
      restNode.forEach((node) => node.classList.remove("active"));
      const node = listRef.current.querySelector(`li[value="${value}"]`);
      tempInnerValue = node.outerHTML;
      node.classList.add("active");
    } catch (e) {}

    setInnerValue(tempInnerValue);
  });

  //Clear search value
  useEffect(() => {
    if (onSearch) {
      onSearch("");
    }
  }, [searchKey]);

  const handleClick = (e) => {
    let value = "";
    try {
      value = e.target.attributes.value.value;
    } catch {}
    onChange(value);
    setShow((prevState) => !prevState);
    setSearchKey("");
  };

  const showDropdown = () => {
    if (showDropdown) {
      setSearchKey("");
    }
    if (!disable) {
      setShow((prevState) => !prevState);
    }
  };

  const callApi = useMemo(() => {
    return onSearch
      ? debounce((value) => {
          onSearch(value);
        })
      : () => {};
  }, []);

  const handleSearch = (value) => {
    setSearchKey(value);
    callApi(value);
  };

  return (
    <div
      className={`qi-custom-select qi-form-element ${error ? "error": ""} ${
        disable ? "disabled": ""
      } ${className}`}
    >
      <div
        className={`qi-custom-select_inputwrapper  ${show ? "focus" : ""}`}
        onClick={showDropdown}
      >
        <label className="qi-input_label">{label}</label>
        <ul
          className="qi-custom-select_inputwrapper_text"
          dangerouslySetInnerHTML={{ __html: innerValue }}
        ></ul>
        {src && (
          <span className="icon-wrapper">
            <svg className={`icon ${iconClass || ""}`}>
              <use href={src} />
            </svg>
          </span>
        )}
        {editable && (
          <SvgIcon
            name="edit"
            title="Edit"
            wrapperClass="action-btn"
            svgClass="icon-edit"
            onClick={() => {
              setDisable(false);
              setEditable(false);
            }}
          />
        )}
        {!readOnly && error && (
          <div className="qi-input_error">
            <span className="icon-wrapper">
              <svg className="icon">
                <use href={`${sprite}#info`}></use>
              </svg>
            </span>
            <p className="qi-input_error_message">{error}</p>
          </div>
        )}
        {warningMessage.length > 0 && (
          <div className="qi-input_warning">
            <p className="qi-input_warning_message">{warningMessage}</p>
          </div>
        )}
      </div>
      <div
        className={`qi-custom-select_dropdown ${labelClassName} ${show ? "show" : ""}`}
        ref={dropDownRef}
      >
        {onSearch && (
          <QIInput
            className="qi-custom-select_search"
            value={searchKey}
            placeholder={onSearchPlaceHolder}
            onChange={(e) => handleSearch(e.target.value)}
            icon={{ src: `${sprite}#search`, className: "search-icon" }}
            focus={show ? true : false}
          />
        )}
        <Scrollbars autoHeight autoHeightMax={autoHeightMax}>
          <ul onClick={handleClick} ref={listRef} className="qi-custom-select_dropdown_list">
            {children}
          </ul>
        </Scrollbars>
      </div>
    </div>
  );
};
