import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { commandFormSchema } from "../validations";
import * as Components from "../../../components";
import { mergeObjects } from "../../../shared/helper";
import {
  useCreateCommandMutation,
  useUpdateCommandMutation,
  useGetCommandQuery,
  useGetDModelsQuery,
  useGetDMansQuery,
} from "../services";
import { FormContainer } from "../Shared/FormContainer";
import { useForm, useDropDownSearch } from "../../../hooks";
import { commandTypes, commandModes } from "../../../shared/dropdownLists";

export const CommandsForm = ({ idToEdit = null, closeForm }) => {
  const dropmenuQueryParams = { per_page: 1000, order_by: "name" };

  const [initialValues, setInitialValues] = useState({
    device_model_ids: [],
    device_manufacturer_id: "",
    name: "",
    command_type: "",
    command_text: "",
    mode: "",
  });

  const formik = useFormik({
    initialValues,
    validationSchema: commandFormSchema,
    onSubmit: (values) => handleFormSubmit(values),
    enableReinitialize: true,
  });

  // Get the device manufacturers list using Dropdown Search
  const {
    data: dmans,
    setSearchKey: setDManSearch,
    isSuccess: dmansSuccess,
  } = useDropDownSearch({
    useData: useGetDMansQuery,
    simpleSearchKey: "name_or_clients_name_cont",
    selectedIds: formik.values.device_manufacturer_id,
    additionalParams: { ...dropmenuQueryParams },
  });

  // Get the device models list using Dropdown Search, against selected devices manufacturers
  const {
    data: deviceModels,
    setSearchKey: setDeviceModelSearch,
    isSuccess: deviceModelsSuccess,
  } = useDropDownSearch({
    useData: useGetDModelsQuery,
    simpleSearchKey: "name_or_device_manufacturer_name_cont",
    additionalParams: { device_manufacturer_id: formik.values.device_manufacturer_id },
    selectedIds: formik.values.device_model_ids,
  });

  const { create: createCommand, update: updateCommand } = useForm({
    createMutation: useCreateCommandMutation,
    updateMutation: useUpdateCommandMutation,
    closeForm,
    setError: formik.setErrors,
  });

  const { data } = useGetCommandQuery({ id: idToEdit }, { skip: !idToEdit });

  // Set the values for edit form
  useEffect(() => {
    if (idToEdit && data) {
      let tempData = { ...data };
      setInitialValues(mergeObjects(formik.initialValues, tempData));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      updateCommand({ id: idToEdit, formData: formData });
    } else {
      createCommand({ formData: formData });
    }
  };

  return (
    <FormContainer
      resourceName="Command"
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
    >
      <Components.QIInput
        label="Name"
        placeholder="Name"
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />

      <Components.QIInput
        label="Command"
        placeholder="Command Text"
        {...formik.getFieldProps("command_text")}
        error={formik.touched.command_text && formik.errors.command_text}
      />

      <Components.QICustomSelect
        label="Device Manufacturer"
        {...formik.getFieldProps("device_manufacturer_id")}
        error={formik.touched.device_manufacturer_id && formik.errors.device_manufacturer_id}
        onChange={(value) => {
          formik.setValues({
            ...formik.values,
            device_manufacturer_id: value,
            device_model_ids: [],
          });
          setDManSearch("");
        }}
        onSearch={setDManSearch}
        labelClassName="device-manufacturer"
      >
        {dmans &&
          dmans.data.map((dman) => (
            <li key={dman.id} value={dman.id}>
              {dman.name}
            </li>
          ))}
      </Components.QICustomSelect>

      <Components.QIMultiSelectDropDown
        label="Device Model"
        data={deviceModels?.data || []}
        className="device-model"
        selected={formik.values.device_model_ids}
        onChange={(updatedList) => {
          formik.setFieldValue("device_model_ids", updatedList);
          setDeviceModelSearch("");
        }}
        onSearch={(value) => setDeviceModelSearch(value)}
        readOnly={formik.values.device_manufacturer_id === ""}
        error={formik.touched.device_model_ids && formik.errors.device_model_ids}
      />

      <Components.QICustomSelect
        label="Type"
        {...formik.getFieldProps("command_type")}
        error={formik.touched.command_type && formik.errors.command_type}
        onChange={(value) => formik.setFieldValue("command_type", value)}
        labelClassName="command-type"
      >
        {commandTypes?.map((type) => (
          <li key={type.id} value={type.id}>
            {type.name}
          </li>
        ))}
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label="Mode"
        {...formik.getFieldProps("mode")}
        error={formik.touched.mode && formik.errors.mode}
        onChange={(value) => formik.setFieldValue("mode", value)}
        labelClassName="command-mode"
      >
        <li value="">Select</li>
        {commandModes?.map((type) => (
          <li key={type.id} value={type.id}>
            {type.name}
          </li>
        ))}
      </Components.QICustomSelect>
    </FormContainer>
  );
};
