import { event } from "jquery";
import React, { useEffect } from "react";
/**
 * This hook helps to attached a callback when their is click outside any DOM node.
 *
 * @param {React.Ref} ref- Accepts the ref(useRef) of the node outside which we want to add callback
 * @param {Function} callback- A callback function which gets a call when there is click outside ref provided
 */
export function useOutsideAlerter(ref, callBack, showCalender) {
  useEffect(() => {
    function handleClickOutside(event) {
      // if clicked element is not the ref dom
      if (ref.current && !ref.current.contains(event.target) && !showCalender) {
        callBack();
      }
    }

    // Attach the event when for click event
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove the event when the component is
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, showCalender]);
}
