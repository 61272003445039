import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { geocodersFormSchema } from "../validations";
import * as Components from "../../../components";
import {
  useCreateGeoCoderMutation,
  useGetGeoCoderQuery,
  useUpdateGeoCoderMutation,
} from "../services";
import { FormContainer } from "../Shared/FormContainer";
import { mergeObjects } from "../../../shared/helper";
import { useForm } from "../../../hooks";

export const GeocodersForm = ({ idToEdit = null, closeForm }) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: geocodersFormSchema,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const { create: createGeoCoder, update: updateGeoCoder } = useForm({
    createMutation: useCreateGeoCoderMutation,
    updateMutation: useUpdateGeoCoderMutation,
    closeForm,
    setError: formik.setErrors,
  });

  const { data } = useGetGeoCoderQuery({ id: idToEdit }, { skip: !idToEdit });

  // Set form data in case of edit
  useEffect(() => {
    if (idToEdit && data) {
      setInitialValues(mergeObjects(formik.initialValues, data));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      updateGeoCoder({ formData, id: idToEdit });
    } else {
      createGeoCoder({ formData });
    }
  };

  return (
    <FormContainer
      resourceName="Geocoder"
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
    >
      <Components.QIInput
        label="Name"
        placeholder="Name"
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />
      <Components.QIInput
        label="Description"
        placeholder="Description"
        {...formik.getFieldProps("description")}
        error={formik.touched.description && formik.errors.description}
      />
    </FormContainer>
  );
};
