import React, { useState, useEffect } from "react";
import * as Components from "../../../components";
import { mergeObjects } from "../../../shared/helper";
import {
  useCreateDModelVariableMutation,
  useUpdateDModelVariableMutation,
  useGetDModelVariableQuery,
  useGetVariableTypesQuery,
} from "../services";
import { FormContainer } from "../Shared/FormContainer";
import { useParams } from "react-router-dom";
import { useForm } from "../../../hooks";
import { useFormik } from "formik";
import { DModelVariableFormSchema } from "../validations";

export const DModelVariableForm = ({ idToEdit = null, closeForm }) => {
  const dropdownParams = { per_page: 600 };

  const [initialValues, setInitialValues] = useState({
    device_model_id: useParams().deviceModelId,
    name: "",
    section: "",
    description: "",
    variable_type_id: "",
  });

  const formik = useFormik({
    initialValues,
    validationSchema: DModelVariableFormSchema,
    onSubmit: (values) => handleFormSubmit(values),
    enableReinitialize: true,
  });

  // Get data for edit form
  const { data } = useGetDModelVariableQuery({ id: idToEdit }, { skip: !idToEdit });
  // Get the variable type list
  const { data: variableTypes = { data: [] } } = useGetVariableTypesQuery(dropdownParams);

  const { create: createVariable, update: updateVariable } = useForm({
    createMutation: useCreateDModelVariableMutation,
    updateMutation: useUpdateDModelVariableMutation,
    closeForm,
    setError: formik.setErrors,
  });

  // Set the data in the edit form
  useEffect(() => {
    if (idToEdit && data) {
      setInitialValues(mergeObjects(formik.initialValues, data));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      updateVariable({ id: idToEdit, formData });
    } else {
      createVariable({ formData });
    }
  };

  return (
    <FormContainer
      resourceName="Variable"
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
    >
      <Components.QIInput
        label="Name"
        placeholder="Name"
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />

      <Components.QIInput
        label="Section"
        placeholder="Section"
        {...formik.getFieldProps("section")}
        error={formik.touched.section && formik.errors.section}
      />

      <Components.QIInput
        label="Description"
        placeholder="Description"
        textarea={true}
        {...formik.getFieldProps("description")}
        error={formik.touched.description && formik.errors.description}
      />

      <Components.QICustomSelect
        label="Class"
        {...formik.getFieldProps("variable_type_id")}
        onChange={(value) => {
          formik.setFieldValue("variable_type_id", value);
        }}
        error={formik.touched.variable_type_id && formik.errors.variable_type_id}
        labelClassName="variable-type"
      >
        <li value="">Select</li>
        {variableTypes.data.map((variableType) => {
          return (
            <li key={variableType.id} value={variableType.id}>
              {variableType.name}
            </li>
          );
        })}
      </Components.QICustomSelect>
    </FormContainer>
  );
};
