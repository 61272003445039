import React, { useState } from "react";
import { usePagination } from "../../../hooks";
import { AdminSharedHeader, AdminSharedTable } from "../Shared/";
import { useGetBatchCommandsQuery } from "../services";
import { BatchCommandsForm } from "./Form";

export const BatchCommandContainer = () => {
  const { page, perPage, pagination } = usePagination();

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetBatchCommandsQuery({
    page,
    per_page: perPage,
    order_by: "created_at",
    order_dir: "desc",
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const createNewHandler = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  const headers = [
    { label: "Device Manufacturer", key: "device_manufacturer_name", className: "name more" },
    { label: "Device Model", key: "device_model_name", className: "name" },
    { label: "Vehicle Manufacturer", key: "vehicle_manufacturer_name", className: "name more" },
    { label: "Vehicle Model", key: "vehicle_manufacturer_model_name", className: "name more" },
    {
      label: "Vehicle Specification",
      nestedValue: true,
      getNestedValue: ({
        engine_capacity,
        capacity,
        vehicle_manufacturer_model_variant_name,
        vehicle_model_name,
        year,
      }) =>
        `Specification: ${vehicle_model_name},
        Variant: ${vehicle_manufacturer_model_variant_name},
         Engine Capacity: ${engine_capacity},
         Capacity: ${capacity},
         Year: ${year}
        `,
      className: "phone-number",
    },
    { label: "Created on", key: "created_at" },
  ];

  return (
    <>
      <article className="main-container">
        <AdminSharedHeader
          createNewHandler={createNewHandler}
          groupName="other"
          heading="Configurations"
        />
        <AdminSharedTable
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          data={data.data}
          headers={headers}
          pagination={{ ...pagination, count: data.total_count }}
          onEdit={editClickHandler}
          className="batch-commands"
          auditResource="BatchCommand"
          auditKey="batch_command"
        />
      </article>

      {showForm && <BatchCommandsForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}
    </>
  );
};
