import moment from "moment";
import commonConfig from "../../../shared/commonConfig";

export const filterTrackingData = (data) => {
  const result = { coordinates: [], linecoordinates: [], data: [] };

  for (let i = 0; i < data.length; i++) {
    try {
      const { lat, lng } = data[i].gps_location;

      // Escape if not valid lat lon
      if (lat === 0 || lng === 0) continue;

      const { speed, direction } = data[i].velocity;
      const trackInfo = commonConfig.trackInfo[commonConfig.getTrackType(speed)];
      const pointInfo = {
        lat: lat,
        lon: lng,
        speed: speed.toFixed(2) || "zero",
        bearing: direction,
        time: moment(data[i].position_time).format(commonConfig.dateTimeFormat),
      };
      // coordinates is formatted for point type track
      result.coordinates.push({
        coordinates: [pointInfo],
        style: {
          hasBearing: true,
          fill: { color: trackInfo.color },
          stroke: { color: trackInfo.color },
        },
      });
      // linecoordinates is formatted for lint type track
      result.linecoordinates.push({
        coordinates: [pointInfo],
        style: {
          ...trackInfo,
          zIndex: 10,
        },
      });
      if (result.linecoordinates.length > 1) {
        const arrayLength = result.linecoordinates.length;
        result.linecoordinates[arrayLength - 2].coordinates.push(pointInfo);
      }
      const updatedFormat = {
        positionTime: moment(data[i].position_time).format(commonConfig.dateTimeFormat),
        lat: lat,
        lon: lng,
        location: null,
        speed: speed.toFixed(2),
        ...data[i],
      };
      result.data.push(updatedFormat);
    } catch (e) {}
  }
  return result;
};
