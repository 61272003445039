import { QISpinner } from "../../../components";

export const Loading = () => {
  return (
    <div className="no-data">
      <QISpinner size="lg" type="grow" className="text-primary text-center" />
      <p>Loading...</p>
    </div>
  );
};
