import { useFormik } from "formik";
import { useState, useEffect } from "react";
import {
  QIInput,
  QIPhotoUploader,
  QIButton,
  QIModalBody,
  QIModal,
  QIModalFooter,
} from "../../../components";
import { appConfigSchema } from "../validations";
import { useGetClientAppConfigQuery, useUpdateClientAppConfigMutation } from "../services";
import { parseServerError, mergeObjects } from "../../../shared/helper";
import { SvgIcon } from "../../Shared";
import { userImg } from "../../../assets";
import {getLocalizedString} from "../../../shared/translation";

export const AppConfig = ({ appName, appId }) => {
  const [editMode, setEditMode] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [initialValues, setInitialValues] = useState({
    custom_name: "",
    app_id: appId,
    image: "",
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: appConfigSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const { data } = useGetClientAppConfigQuery({ appId });
  const [
    update,
    {
      error: updateError,
      isLoading: isLoadingUpdate,
      isSuccess: updateSuccess,
      reset: resetUpdate,
    },
  ] = useUpdateClientAppConfigMutation();

  const handleSubmit = (formData) => {
    let fObj = new FormData();

    Object.keys(formData).forEach((key) => {
      if (key == "image" && formData.image == null) {
        fObj.append("remove_image", true);
      } else if (!(key == "image" && formData.image == "") && formData[key] != null) {
        fObj.append(key, formData[key]);
      }
    });

    update({ formData: fObj });
  };

  useEffect(() => {
    if (updateError) {
      formik.setErrors(parseServerError(updateError));
    }
  }, [updateError]);

  const cancelUpdate = () => {
    formik.setValues(mergeObjects(formik.initialValues, data));
    setEditMode(false);
  };

  const toggleEditMode = () => {
    if (editMode) {
      cancelUpdate();
    } else {
      setEditMode(true);
    }
  };

  // Set the values for edit form
  useEffect(() => {
    if (data) {
      setInitialValues(mergeObjects(formik.initialValues, data));
    }
  }, [data]);

  useEffect(() => {
    if (updateSuccess) {
      setShowSuccessModal(true);
      setEditMode(false);
    }
  }, [updateSuccess]);

  return (
    <>
      <div className="customization_block">
        <div className="profile-form">
          <div className="profile-form_right">
            <header className="customization_block_header">
              <strong>
                {appName} {getLocalizedString("customization", "Customization")}
              </strong>
              <span className="edit">
                <SvgIcon name="edit" wrapperClass="mx-2" onClick={toggleEditMode} />
              </span>
            </header>
            <div>
              <div>
                <QIPhotoUploader
                  label={getLocalizedString("application_logo", "Application Logo")}
                  placeHolderImg={userImg}
                  src={formik.values.picture}
                  onChange={(files) =>
                    formik.setFieldValue("image", files?.length > 0 ? files[0] : null)
                  }
                  readOnly={!editMode}
                  disabled={!editMode}
                  showJustImage
                  base64={false}
                  initalImageUrl={formik.values.image_url}
                  key={editMode}
                  error={formik.touched.image && formik.errors.image}
                  photoGuide={
                    editMode &&
                    `
                    <h4 className="font-semibold">${
                      getLocalizedString("image_upload_guide", "Image Upload guide")
                    } - </h4>
                    <div className="ml-2">
                      <p>${getLocalizedString("max_size", "Max size")} 40kb</p>
                      <p>${
                        getLocalizedString("file_format", "File format")
                      } .jpg/ .jpeg/ .png/ .gif</p>
                      <p>${
                        getLocalizedString("preferred_image_aspect_ratio", "")
                      } 1:1</p>
                    </div>
                `
                  }
                />
              </div>
              <div>
                <QIInput
                  label={getLocalizedString("display_name", "Display Name")}
                  {...formik.getFieldProps("custom_name")}
                  value={formik.values.custom_name.replace(/\s\s+/g, " ")}
                  readOnly={!editMode}
                  disabled={!editMode}
                />

                {editMode && (
                  <div className="user-profile-details_footer pt-4">
                    <QIButton className="secondary sm mr-2" onClick={cancelUpdate}>
                      {getLocalizedString("cancel", "Cancel")}
                    </QIButton>
                    <QIButton className="primary sm" onClick={formik.handleSubmit}>
                      {getLocalizedString("update", "Update")}
                    </QIButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <QIModal show={showSuccessModal} className="confirm">
        <QIModalBody>
          <p className="text-center">
            {" "}
            {getLocalizedString("app_customization_updated_successfully", "App Customization Updated Successfully")}
          </p>
        </QIModalBody>
        <QIModalFooter>
          <QIButton className="qi-btn primary sm" onClick={() => window.location.reload()}>
            {getLocalizedString("okay", "Okay")}
          </QIButton>
        </QIModalFooter>
      </QIModal>
    </>
  );
};
