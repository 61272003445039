import React from "react";
import * as Components from "../../../components";
import sprite from "../../../assets/icons.svg";
import { loader } from "../../../assets";

export const FormContainer = ({
  resourceName = "",
  closeForm,
  handleFormSubmit,
  idToEdit,
  error,
  children,
  isLoading,
  disabled = false,
  customResourceName,
}) => {
  // To scroll up to the first field which has error
  async function handleSubmit() {
    handleFormSubmit();

    // Delay for a short time to ensure handleFormSubmit() execution
    setTimeout(() => {
      // Scroll to the first field with an error
      const firstErrorField = document.querySelector(".error");

      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 500);
  }

  return (
    <Components.QIModal show onHide={closeForm} size="large" backdrop={false}>
      <Components.QIModalHeader onHide={closeForm}>
        {customResourceName ? (
          <h5>{customResourceName}</h5>
        ) : (
          <h5>{idToEdit ? `Edit ${resourceName}` : `Add ${resourceName}`}</h5>
        )}
      </Components.QIModalHeader>
      <Components.QIModalBody>{children}</Components.QIModalBody>
      <Components.QIModalFooter>
        <div>
          {error?.serverError ? <p className="text-danger text-center">{error.serverError}</p> : ""}
        </div>
        <div className="flex justify-center">
          <Components.QIButton
            variant="secondary"
            className="qi-btn secondary md"
            onClick={closeForm}
          >
            Cancel
          </Components.QIButton>

          <Components.QIButton
            onClick={() => handleSubmit()}
            icon={{
              src: isLoading ? <img src={loader} height="10px" width="20px" /> : `${sprite}#save`,
            }}
            className="qi-btn primary md"
            disabled={disabled}
          >
            {idToEdit ? "Update" : "Save"}
          </Components.QIButton>
        </div>
      </Components.QIModalFooter>
    </Components.QIModal>
  );
};
