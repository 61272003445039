import moment from "moment";

// eslint-disable-next-line no-useless-escape
export const emailPattern = /^[A-Za-z0-9._+!#$%^&'"`~{}\-=?\/]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

/**
 * Returns type of property passed through the argument
 */
export const getObjType = (obj) => {
  const prop = {
    "[object Object]": "object",
    "[object Array]": "array",
    "[object String]": "string",
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object Null]": "null",
    "[object Undefined]": "undefined",
    "[object Function]": "function",
    "[object Date]": "date",
    "[object HTMLDivElement]": "htmlDivElement",
    "[object HTMLCollection]": "htmlCollection",
    "[object Blob]": "blob",
    "[object File]": "file",
    "[object MouseEvent]": "mouse_event",
    "[object PointerEvent]": "pointer_event",
    "[object KeyboardEvent]": "keyboard_event",
  };
  return prop[Object.prototype.toString.call(obj)];
};

/**
 * This checks if the value is present
 */
export const isObjPresent = (obj) => {
  const type = getObjType(obj);
  if (type === "object") {
    return Object.keys(obj).length > 0;
  }
  if (type === "array") {
    if (obj.length > 0) {
      return true;
    }
    if (obj.length === 0) {
      return false;
    }
    throw new Error(`${obj} comes to array if-else but the length is neither 0 or more than 0`);
  }
  if (type === "string") {
    if (obj.length > 0 && obj !== "undefined" && obj !== "null") {
      return true;
    }
    if (obj.length === 0) {
      return false;
    }
    if (obj === "undefined" || obj === "null") {
      return false;
    }
    throw new Error(`${obj} comes to string if-else but the length its not 0 or more than 0`);
  }
  if (type === "boolean") {
    if (obj === true) {
      return true;
    }
    if (obj === false) {
      return false;
    }
    throw new Error(`${obj} comes to boolean if-else but is neither true or false`);
  }
  if (type === "number") {
    return true;
  }
  if (type === "null") {
    return false;
  }
  if (type === "undefined") {
    return false;
  }
  if (type === "function") {
    const tmpStr = obj.toString();
    const tmpStr2 = tmpStr
      .substring(tmpStr.indexOf("{") + 1, tmpStr.indexOf("}"))
      .trim()
      .replace(/\r?\n|\r/g, ""); // The RegEx here is to check for: arriage Return (CR, \r, on older Macs), Line Feed (LF, \n, on Unices incl. Linux) or CR followed by LF (\r\n, on WinDOS)
    if (isObjPresent(tmpStr2)) {
      return true;
    }
    return false;
  }
  if (type === "date") {
    if (Number.isNaN(obj.getTime())) {
      return false; // Date is invalid
    }
    return true; // Date is valid
  }
  if (type === "htmlDivElement") {
    if (type.toString().length > 0) {
      return true;
    }
    return false;
  }
  if (type === "htmlCollection") {
    if (obj.length > 0) {
      return true;
    }
    if (obj.length === 0) {
      return false;
    }
    throw new Error(
      `${obj} comes to htmlCollection if-else but the length is neither 0 or more than 0`
    );
  }
  if (type === "blob") {
    if (obj.size > 0) {
      return true;
    }
    return false;
  }
  // if (type === "file") {
  //   Object.keys(obj).forEach(key => {
  //     if (Object.prototype.hasOwnProperty.call(obj, key)) {
  //       return true;
  //     }
  //   });
  //   return false;
  // }
  if (type === "mouse_event" || type === "pointer_event") {
    return true;
  }
  if (type === "keyboard_event") {
    return true;
  }
  throw new Error(`${Object.prototype.toString.call(obj)} type is not supported yet`);
};

export const utcToLocal = (epochtime) => {
  let date = "";
  try {
    date = moment.unix(epochtime).format(" MMM DD, YYYY - h:mm A");
  } catch {}

  return date;
};

export const utcToLocalGlobal = (epochtime) => {
  let date = "";
  try {
    date = moment.unix(epochtime).format(" DD/MM/YYYY - hh:mm:ss A");
  } catch {}

  return date;
};

export const utcToLocalDate = (epochtime) => {
  let date = "";
  try {
    date = moment.unix(epochtime).format(" MMM DD, YYYY ");
  } catch {}

  return date;
};

export const setUpdatedBy = (email) => {
  if (email === null || email === "") {
    email = "System";
  }

  return email;
};

//Collect data recursively from nested objects
export const collectDataRecursively = (obj) => {
  let result = {};

  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      result = { ...result, ...collectDataRecursively(obj[key]) };
    } else {
      result[key] = obj[key];
    }
  }

  return result;
};

export const convertToEpoch = (dateString) => {
  // Create a Date object from the date string
  const date = new Date(dateString);

  // Get the epoch time (in milliseconds)
  const epochTime = date.getTime();

  return epochTime;
};
