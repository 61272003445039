export const reportTypes = [
  {
    id: "trackpoint",
    localization_key: "trackpoint",
    name: "Trackpoint",
  },
  {
    id: "trip",
    localization_key: "trip",
    name: "Trip",
  },
  {
    id: "event",
    localization_key: "event",
    name: "Event",
  },
  {
    id: "raw",
    localization_key: "raw",
    name: "Raw",
  },
  {
    id: "activities",
    localization_key: "activity",
    name: "Activity",
  },
  {
    id: "trip_idle_parked",
    localization_key: "trip_idle_parked",
    name: "Trip Idle Parked",
  },
];
