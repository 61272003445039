import React from "react";
import { QIButton } from "../../../components";
import { SvgIcon } from "../../Shared";

export const MPEditButton = ({ onClick, ...props }) => {
  return (
    <QIButton className="me-2" onClick={onClick}>
      <SvgIcon name="edit" wrapperClass="icon-edit" />
    </QIButton>
  );
};

export const MPDeleteButton = ({ onClick, ...props }) => {
  return (
    <QIButton variant="danger" size="sm" onClick={onClick}>
      <SvgIcon name="delete" wrapperClass="icon-delete" />
    </QIButton>
  );
};
