import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ObdcodeAdvanceSearch } from "./advanceSearch";
import { useSearchQuery, usePagination } from "../../../hooks";
import { AdminSharedHeader, AdminSharedTable, AdminSharedDeleteModal } from "../Shared/";
import { useGetObdCodesQuery, useDeleteObdCodeMutation } from "../services";
import { ObdCodeForm } from "./Form";
import { useDelete, useQuery } from "../../../hooks";
import { TickOrCross } from "../../Shared";

export const ObdCodesContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, advanceSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "code_or_name_cont",
  });

  const queryParams = {
    page: page,
    per_page: perPage,
    order_by: "created_at",
    order_dir: "desc",
    q,
  };

  const query = useQuery();
  let filterHeader = "";
  const filters = [
    { label: "Target Section ", key: "target_section_id" },
    { label: "Target Variable ", key: "target_variable_id" },
  ];

  filters.map((filter) => {
    if (query.get(filter.key)) {
      queryParams[filter.key] = query.get(filter.key);
      filterHeader = `${filter.label}: ${query.get("name")}`;
    }
  });

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetObdCodesQuery({
    ...queryParams,
  });

  const {
    deleteItem: deleteObdCode,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteObdCodeMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  const createNewHandler = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  const deleteClickHandler = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const headers = [
    { label: "Name", key: "name", className: "name" },
    {
      label: "Vehicles Specification",
      nestedValue: true,
      className: "name",
      getNestedValue: ({ id, vehicle_models_count, name }) => {
        return (
          <>
            {vehicle_models_count > 0 ? (
              <Link
                to={`/superadmin/vehicles/specifications?obd_code_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {vehicle_models_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
    },
    {
      label: "Vehicles",
      nestedValue: true,
      className: "name",
      getNestedValue: ({ id, vehicles_count, name }) => {
        return (
          <>
            {vehicles_count > 0 ? (
              <Link
                to={`/superadmin/vehicles/list?obd_code_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {vehicles_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
    },
    { label: "Code", key: "code", className: "code" },
    { label: "Size", key: "size", className: "size text-center" },
    { label: "Start Index", key: "start_index", className: "start-index" },
    { label: "Offset", key: "offset", className: "offset" },
    {
      label: "Reverse Array",
      nestedValue: true,
      getNestedValue: ({ reverse_array }) => <TickOrCross flag={reverse_array} />,
      className: "reverse-array text-center",
    },
    {
      label: "Mul. Factor",
      key: "multiplication_factor",
    },
    { label: "Created on", key: "created_at" },
  ];

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((datum) => datum.id === id);

    if (index > -1) {
      name = data?.data[index].name;
    }
    return name;
  };

  return (
    <>
      <article className="main-container">
        <AdminSharedHeader
          createNewHandler={createNewHandler}
          groupName="other"
          heading="Configurations"
          handleSearchKey={(value) => setSimpleSearch(value)}
          handleAdvanceSearchKeys={(value) => setAdvanceSearch(null)}
          simpleSearchPlaceHolder="Search by Name, Code"
          filterText={filterHeader && `Filtered by ${filterHeader}`}
        >
          <ObdcodeAdvanceSearch
            onSearch={(value) => setAdvanceSearch(value)}
            activeSearch={advanceSearch}
          />
        </AdminSharedHeader>

        <AdminSharedTable
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          data={data.data}
          headers={headers}
          pagination={{ ...pagination, count: data.total_count }}
          onEdit={editClickHandler}
          onDelete={deleteClickHandler}
          className="obd-codes"
          auditResource="ObdCode"
          auditKey="name"
        />
      </article>

      {showForm && <ObdCodeForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}

      <AdminSharedDeleteModal
        error={deleteErrorMsg}
        show={showDeleteWarning}
        resourceName="OBD codes"
        getDeleteItemName={() => getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg("");
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteObdCode({ id: idToDelete })}
      />
    </>
  );
};
