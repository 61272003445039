import { FMDPStorage } from "../../shared/helper";
export const FMDP_APPLICATIONS = ["ManagementPortal", "SuperAdmin", "Tracking"];

export const groupedUserFeatures = (app_features) => {
  const groupedData = {};
  for (let i = 0; i < app_features?.length; i++) {
    const { name, id, label_name } = app_features[i];

    if (groupedData[name]) {
      groupedData[name][label_name] = id;
    } else {
      groupedData[name] = { [label_name]: id };
    }
  }
  return groupedData;
};

export const toggleAppInfo = (user, selectedAppId, clientAppData) => {
  // Find index of selectAppId in user.clients app
  const index = user?.client_apps
    ? user.client_apps.findIndex((app) => app.id == selectedAppId)
    : -1;

  // If user has access to selectedApp
  if (index >= 0) {
    // Expected format of name is Client Name - App Name e.g HMS - Management Portal
    const clientName = user.client_apps[index].name.substring(
      0,
      user.client_apps[index].name.lastIndexOf("-") - 1
    );
    const applicationName = user.client_apps[index].name.substring(
      user.client_apps[index].name.lastIndexOf("-") + 2
    );
    let applicationNameCorrectFormat = "";
    applicationName?.split(" ")?.map((word) => {
      applicationNameCorrectFormat += word;
    });

    // Check if FMDP has selected application
    if (FMDP_APPLICATIONS.includes(applicationNameCorrectFormat)) {
      FMDPStorage.set("app_features", groupedUserFeatures(clientAppData?.app_features));
      FMDPStorage.set("created-by-client-id", clientAppData?.created_by_client_id);
      FMDPStorage.set("created-by-reseller", clientAppData?.created_by_reseller);
      FMDPStorage.set("reseller", clientAppData?.reseller);
      FMDPStorage.set("selected-client", clientName);
      FMDPStorage.set("selected-app", applicationNameCorrectFormat);
      FMDPStorage.set("selected-app-id", selectedAppId);
      FMDPStorage.set("selected-client-id", clientAppData?.user_sessions?.client_app?.client?.id);

      return true;
    }
  }

  return false;
};
