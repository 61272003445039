import { useEffect, useState } from "react";
import qiLogo from "./qi-logo.png";
import { useSelector } from "react-redux";
import { removeConsecutiveDuplicates } from "../../containers/Tracking/utils";
import { FMDPStorage } from "../../shared/helper";

export const QIFlashScreen = ({ flashScreenStatus, currentAppId, children }) => {
  const [flashTimer, setFlashTimer] = useState(true);
  const [clientState, setClientState] = useState(null);
  let lastVisitedApps = useSelector((state) => state.common.lastVisitedApp);
  lastVisitedApps = removeConsecutiveDuplicates(lastVisitedApps);

  useEffect(() => {
    setTimeout(() => {
      setFlashTimer(false);
    }, 2000);
  }, []);

  function findClient(appId) {
    const currentClientApps = FMDPStorage.get("current-user")?.client_apps;
    const clientId = currentClientApps?.find((item) => {
      return item?.id === appId;
    });
    return clientId?.client?.id;
  }

  useEffect(() => {
    // Check if the last and second to last elements are the same
    if (
      findClient(lastVisitedApps[lastVisitedApps?.length - 1]) ===
      findClient(lastVisitedApps[lastVisitedApps?.length - 2])
    ) {
      setClientState("same");
    } else {
      setClientState("diff");
    }
  }, [lastVisitedApps]);

  const flashMessage = () => {
    return (
      <>
        <div className="qi-flash-screen">
          <div className="logo">
            <img src={qiLogo} alt="qi-logo" />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        {flashTimer && clientState === "diff" && flashScreenStatus ? flashMessage() : children}
      </div>
    </>
  );
};
