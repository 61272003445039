import { useMemo, useState } from "react";

/**
 * This hook can be used search is needed in dropdown, will do all the calls based on searchKey and generate query
 * @param {Function} useData - The rtk query hook to fetch the data
 * @param {String} simpleSearchKey - ransack key to do simple search
 * @param {Object} additionalParams - An object which will passed as query params to data hook
 * @param {Function} additionalParamsToHook - An object which will be passed to useData hook as second params. e.g. {skipId:true}
 * @param {Function} selectedIds - A function which return by createMutation to create objects
 * @returns  data - Api Response data
 * @returns  setSearchKey - function to update the simpleSearchKey value
 * @returns  isSuccess - flag denotes the status of api call
 * @returns  isLoading - True when api call is ongoing
 */
export const useDropDownSearch = ({
  useData,
  simpleSearchKey,
  additionalParams = {},
  additionalParamsToHook = {},
  selectedIds = [],
}) => {
  const [searchKey, setSearchKey] = useState("");

  // Generate the query whenever search Key is generated
  const q = useMemo(() => {
    const tempObj = {
      [simpleSearchKey]: searchKey,
    };

    if (searchKey) {
      tempObj.id_in = selectedIds;
      tempObj.m = "or";
    } else {
      tempObj.id_gteq_any_or_lteq_any = selectedIds;
      tempObj.m = "or";
    }
    return JSON.stringify(tempObj);
  }, [searchKey]);

  const {
    data = { data: [] },
    isSuccess,
    isFetching,
  } = useData(
    {
      per_page: 1000,
      page: 1, // As dropdown search it will always show the page 1 data in list
      ...additionalParams,
      q,
    },
    additionalParamsToHook
  );

  return { setSearchKey, data, isSuccess, isLoading: isFetching };
};

export const useStaticDropDownSearch = ({
  dataSet,
  dataType = "Country Codes",
  searchKeyName = "name",
  selectedIds = [],
  key = "dial_code",
}) => {
  const [searchKey, setSearchKey] = useState("");
  let data = dataSet;

  // Generate the filtered list
  useMemo(() => {
    if (searchKey) {
      let tempDataSet = [];
      let selectedItem = {};

      dataSet.map((datum) => {
        //Search by name and counry codes and for other data search by the key provided
        if (
          datum[searchKeyName]?.toUpperCase()?.includes(searchKey?.toUpperCase()) ||
          (dataType === "Country Codes" &&
            (datum[key]?.substring(1)?.includes(searchKey) || datum[key]?.includes(searchKey)))
        ) {
          tempDataSet.push(datum);
        } else if (datum[key] === selectedIds) {
          selectedItem = datum;
        }
      });

      if (Object.keys(selectedItem).length) {
        tempDataSet.push(selectedItem);
      }
      data = tempDataSet;
    }
  }, [searchKey]);

  return { setSearchKey, data };
};
