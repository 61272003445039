import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Container } from "./Container";
import "./dnd.scss";

export const Dnd = ({ isItemsDragging, handleDragChange, className = "", children }) => {
  return (
    <div className={`dnd-wrapper ${className}`}>
      <DndProvider backend={HTML5Backend}>
        <Container isItemsDragging={isItemsDragging} handleDragChange={handleDragChange}>
          {children}
        </Container>
      </DndProvider>
    </div>
  );
};
