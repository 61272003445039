import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const modalSlice = createSlice({
  name: "MPmodal",
  initialState,
  reducers: {},
});

export const {} = modalSlice.actions;
export const modalReducer = modalSlice.reducer;
