import React, { useState } from "react";
import { AdminSharedHeader, AdminSharedTable, TableDropDownView } from "../Shared/";
import { UserForm } from "./Form";
import { UsersAdvanceSearch } from "./advanceSearch";
import { useGetUsersQuery } from "../services";
import { useSearchQuery, usePagination } from "../../../hooks";
import { SvgIcon } from "../../Shared";

export const UsersContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, advanceSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "filter_full_name_or_email_or_filter_ph_with_country_code_cont",
  });

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetUsersQuery({
    page,
    per_page: perPage,
    order_by: "created_at",
    order_dir: "desc",
    q,
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const createNewHandler = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  const headers = [
    {
      label: "Name",
      nestedValue: true,
      getNestedValue: ({ first_name, last_name }) => `${first_name} ${last_name}`,
      className: "name",
    },
    { label: "Email", key: "email", className: "email" },
    {
      label: "Phone No",
      nestedValue: true,
      getNestedValue: (element) =>
        `${element.country_code ? `${element.country_code}-` : ""}${element.phone_no || ""}`,
      className: "phone-number",
    },
    {
      label: "Clients",
      type: "component",
      component: ({ data }) => {
        const clientNames = data?.client_names?.map((name) => {
          return name.replace(/\s\s+/g, " ");
        });
        return <TableDropDownView data={clientNames || [""]} />;
      },
      className: "clients more",
    },
    {
      label: "Active",
      key: "is_enabled",
      nestedValue: true,
      getNestedValue: ({ is_enabled }) => (
        <>
          {is_enabled ? (
            <SvgIcon name="tick" wrapperClass="status-icon active" svgClass="=icon-tick" />
          ) : (
            <SvgIcon name="cross" wrapperClass="status-icon inactive" svgClass="=icon-cross" />
          )}
        </>
      ),
      className: "w-25",
    },
    {
      label: "Superadmin",
      key: "superadmin",
      nestedValue: true,
      className: "role text-center",
      getNestedValue: ({ superadmin }) => (
        <>
          {superadmin && (
            <SvgIcon name="crown" wrapperClass="crown-icon mr-2" svgClass="icon-crown" />
          )}
        </>
      ),
    },
    { label: "Created on", key: "created_at", className: "date" },
  ];

  return (
    <>
      <article className="main-container">
        <AdminSharedHeader
          createNewHandler={createNewHandler}
          heading="Admin Users"
          count={data.total_count}
          handleSearchKey={(value) => setSimpleSearch(value)}
          handleAdvanceSearchKeys={() => setAdvanceSearch(null)}
          simpleSearchPlaceHolder="Search by Name, Email"
        >
          <UsersAdvanceSearch
            onSearch={(value) => setAdvanceSearch(value)}
            activeSearch={advanceSearch}
          />
        </AdminSharedHeader>

        <AdminSharedTable
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          data={data.data}
          headers={headers}
          pagination={{ ...pagination, count: data.total_count }}
          onEdit={editClickHandler}
          className="users no-tabs"
          auditResource="User"
          auditKey="email"
        />
      </article>
      {showForm && <UserForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}
    </>
  );
};
