import React from "react";
import "./style.scss";

export const QIMapLegend = () => {
  return (
    <div className="mapLegend">
      <div className="legend">
        <div className="legendPoint legendPoint--very_slow"></div>
        <div className="legendText">Very Slow</div>
      </div>
      <div className="legend">
        <div className="legendPoint legendPoint--slow" />
        <div className="legendText">Slow</div>
      </div>
      <div className="legend">
        <div className="legendPoint legendPoint--medium" />
        <div className="legendText">Medium</div>
      </div>
      <div className="legend">
        <div className="legendPoint legendPoint--fast" />
        <div className="legendText">Fast</div>
      </div>
    </div>
  );
};
