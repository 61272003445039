import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useGetDashBoardQuery } from "../services";
import { NativeSvgIcon } from "./../Shared/";

export const AdminDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const deviceSegmentLinks = [
    {
      label: "Devices",
      key: "devices_count",
      url: "devices/list",
    },
    {
      label: "Models",
      key: "device_models_count",
      url: "devices/models",
    },
    {
      label: "Manufacturers",
      key: "device_manufacturers_count",
      url: "devices/manufacturers",
    },
  ];

  const vehicleSegmentLinks = [
    {
      label: "Vehicles",
      key: "vehicles_count",
      url: "vehicles/list",
    },
    {
      label: "Specifications",
      key: "vehicle_models_count",
      url: "vehicles/specifications",
    },
    {
      label: "Variants",
      key: "vehicle_manufacturer_model_variants_count",
      url: "vehicles/variants",
    },
    {
      label: "Models",
      key: "vehicle_manufacturer_models_count",
      url: "vehicles/manufacturer_models",
    },
    {
      label: "Manufacturers",
      key: "vehicle_manufacturers_count",
      url: "vehicles/manufacturers",
    },
    {
      label: "Classes",
      key: "vehicle_classes_count",
      url: "vehicles/classes",
    },
    {
      label: "Types",
      key: "vehicle_types_count",
      url: "vehicles/types",
    },
  ];

  const otherLinks = [
    {
      label: "Variable Mappings",
      key: "variable_mappings_count",
      url: "others/variable_mappings",
    },
    {
      label: "Backends",
      key: "backends_count",
      url: "others/backends",
    },
    {
      label: "Geocoders",
      key: "geocoders_count",
      url: "others/geocoders",
    },
    {
      label: "Geolocation Plans",
      key: "geolocation_plans_count",
      url: "others/geolocation_plan",
    },
    {
      label: "Obd Codes",
      key: "obd_codes_count",
      url: "others/obd_codes",
    },
    {
      label: "Batch Commands",
      key: "batch_commands_count",
      url: "others/batch_command",
    },
  ];

  let segmentIcons = {
    devices: "device",
    vehicles: "vehicle",
    other: "other",
  };

  // Get dashboard counts
  const { data = {} } = useGetDashBoardQuery();

  const segment = (className, title, path, links) => {
    return (
      <section className={className}>
        <h3
          className="applications-oparations_title clickable"
          onClick={() => navigate(`/superadmin/${path}`)}
        >
          <NativeSvgIcon name={`${segmentIcons[path]}`} />
          {title}
        </h3>

        <ul className="applications-oparations_list">
          {links.map((item, index) => (
            <li className="applications-oparations_list_item" key={index}>
              <NavLink
                className="applications-oparations_list_link"
                to={`/superadmin/${item.url || ""}`}
              >
                <span className="applications-oparations_list_link_label">{item.label}</span>
                <strong className="applications-oparations_list_link_count">
                  {data[item.key] || 0}
                </strong>
              </NavLink>
            </li>
          ))}
        </ul>
      </section>
    );
  };

  return (
    <article className="application-summary">
      <header className="application-summary_header">
        <h4 className="application-summary_header_title">
          <NativeSvgIcon name="dashboard" />
          <span>Dashboard</span>
        </h4>
      </header>

      <section className="applications-oparations">
        {segment("devices", "Devices", "devices", deviceSegmentLinks)}
        {segment("vehicles", "Vehicles", "vehicles", vehicleSegmentLinks)}
        {segment("others", "Configurations", "others", otherLinks)}
      </section>
    </article>
  );
};
