import React from "react";
import { SvgIcon } from "../../Shared";
import {getLocalizedString} from "../../../shared/translation";

export const MPEditButton = ({ onClick, ...props }) => {
  return (
    <SvgIcon
      name="edit"
      title={getLocalizedString("edit", "Edit")}
      wrapperClass="action-btn"
      svgClass="icon-edit"
      onClick={onClick}
    />
  );
};

export const MPDeleteButton = ({ onClick, ...props }) => {
  return (
    <SvgIcon
      name="delete"
      title={getLocalizedString("delete", "Delete")}
      wrapperClass="action-btn"
      svgClass="icon-delete"
      onClick={onClick}
    />
  );
};

export const MPAuditButton = ({ onClick, ...props }) => {
  return (
    <SvgIcon
      name="audit-log"
      title={getLocalizedString("audit_log", "Audit log")}
      wrapperClass="action-btn"
      svgClass="icon-delete"
      onClick={onClick}
    />
  );
};

export const MPUploadCsvButton = ({ onClick, ...props }) => {
  return (
    <SvgIcon
      name="upload"
      title={getLocalizedString("upload_csv", "Upload CSV")}
      wrapperClass="action-btn"
      svgClass="icon-upload"
      onClick={onClick}
    />
  );
};
