import { useEffect, useState } from "react";
import moment from "moment";
import { AdvanceSearchWrapper } from "../Shared/";
import { QIDateRangePicker, QIInput } from "../../../components";
import {getLocalizedString} from "../../../shared/translation";

export const AdvanceSearch = ({ onSearch, activeSearch, hasResourceId }) => {
  const initialValues = {
    event_type_cont: "",
    resource_value_cont: "",
    user_email_cont: "",
    created_at_gteq: null,
    created_at_lteq: null,
  };

  const [formData, setFormData] = useState(initialValues);
  const [showCalender, setShowCalender] = useState(false);
  const [resetCount, setResetCount] = useState(0);

  const handleFormChange = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const clearAdvanceSearch = () => {
    setResetCount((i) => i + 1);
    setFormData(initialValues);
    onSearch(null);
    setShowCalender(false);
  };

  const handleDateRange = (startTime, endTime) => {
    setFormData((prevState) => ({
      ...prevState,
      created_at_gteq: startTime,
      created_at_lteq: endTime,
    }));
    setResetCount((i) => i + 1);
  };

  return (
    <AdvanceSearchWrapper
      activeFilters={activeSearch}
      onClear={clearAdvanceSearch}
      onSearch={() => onSearch(formData)}
      className="audit-advance-search"
      defaultOpen={false}
      showCalender={showCalender}
      setShowCalender={setShowCalender}
    >
      {!hasResourceId && (
        <QIInput
          label={getLocalizedString("entity", "Entity")}
          className=""
          value={formData.resource_value_cont}
          onChange={(e) => handleFormChange("resource_value_cont", e.target.value)}
        />
      )}
      <QIInput
        label={getLocalizedString("event", "Event")}
        className=""
        value={formData.event_type_cont}
        onChange={(e) => handleFormChange("event_type_cont", e.target.value)}
      />
      <QIInput
        label={getLocalizedString("done_by", "Done By")}
        className=""
        value={formData.user_email_cont}
        onChange={(e) => handleFormChange("user_email_cont", e.target.value)}
      />
      <QIDateRangePicker
        start={formData.created_at_gteq}
        end={formData.created_at_lteq}
        label={getLocalizedString("created_between", "Created b/w")}
        maxDate={moment()}
        onChange={handleDateRange}
        className=""
        setShowCalender={setShowCalender}
        resetCount={resetCount}
      />
    </AdvanceSearchWrapper>
  );
};
