import { QITable } from "../../../components";
import { Loading, FetchError, PaginationHeader, PaginationFooter, NoData } from "../../Shared";

export const EventsTableWrapper = ({
  data,
  pagination,
  header,
  isLoading,
  isSuccess,
  tableHandlers = {},
  highlight,
  setHighlight,
  listType,
}) => {
  return (
    <>
      <section>
        <QITable
          headers={header}
          data={data || []}
          handler={tableHandlers}
          highlight={highlight}
          setHighlight={setHighlight}
          listType={listType}
        />
        {data.length > 0 && isSuccess && pagination && <PaginationFooter pagination={pagination} />}
      </section>
    </>
  );
};
