export const selectionType = [
  {
    id: "group",
    localization_key: "group",
    name: "Group",
  },
  {
    id: "asset",
    localization_key: "asset",
    name: "Asset",
  },
];

export const selectionTypeNoGroup = [
  {
    id: "asset",
    localization_key: "asset",
    name: "Asset",
  },
];

export const assetTypes = [
  {
    id: "vehicle",
    localization_key: "vehicle",
    name: "Vehicle",
  },
  {
    id: "device",
    localization_key: "device",
    name: "Device",
  },
];

export const assetTypesNoDevice = [
  {
    id: "vehicle",
    localization_key: "vehicle",
    name: "Vehicle",
  },
];

export const fileFormats = [
  {
    id: "csv",
    localization_key: "csv",
    name: "CSV",
  },
  // {
  //   id: "pdf",
  //   localization_key: "pdf",
  //   name: "pdf",
  // },
];

export const eventTypes = [
  {
    id: "idling",
    localization_key: "idling",
    name: "Idling",
  },
  {
    id: "speeding",
    localization_key: "speeding",
    name: "Speeding",
  },
  {
    id: "geofence",
    localization_key: "geofence",
    name: "Geofence",
  },
  {
    id: "low_battery",
    localization_key: "low_battery",
    name: "Low Battery",
  },
  {
    id: "ignition_onchange",
    localization_key: "ignition_status_change",
    name: "Ignition Status Change",
  },
];

export const subTypes = [
  {
    id: "detailed",
    localization_key: "details",
    name: "Detailed",
  },
  {
    id: "summary",
    localization_key: "summary",
    name: "Summary",
  },
];

export const subTypesNoSummary = [
  {
    id: "detailed",
    localization_key: "details",
    name: "Detailed",
  },
];

export const summaryIntervals = [
  {
    id: "na",
    localization_key: "na",
    name: "NA",
  },
  {
    id: "daily",
    localization_key: "daily",
    name: "Daily",
  },
  {
    id: "weekly",
    localization_key: "weekly",
    name: "Weekly",
  },
  {
    id: "monthly",
    localization_key: "monthly",
    name: "Monthly",
  },
];
