import React from "react";
import PropTypes, { any } from "prop-types";

/**
 * This is the Select Component
 *
 * @param {string} label- Accepts a string as label
 * @param {string} labelclass- Accepts class names
 * @param {string} inputclass- Accepts class name
 * @param {string} error - Accepts a string to be shown below the input field (optional)
 *
 * @example
 *
 * <QISelect label="Select :" labelclass="labelstyle" selectclass="selectstyle" error="Required" />
 */

export const QISelect = ({
  label,
  children,
  value,
  className = "",
  error,
  onChange,
  readOnly,
  ...props
}) => {
  return (
    <div className={`qi-select qi-form-element ${className || ""}`}>
      <label className="qi-select_label">{label}</label>
      <div className="qi-select_wrapper">
        <select
          value={value || ""}
          className={`qi-select_text ${readOnly ? "qi-select_text_readonly" : ""}`}
          onChange={onChange}
          readOnly={readOnly}
          {...props}
        >
          {children}
        </select>
        {!readOnly && <small className="qi-select_error">{error}</small>}
      </div>
    </div>
  );
};

QISelect.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.arrayOf(any).isRequired,
};

QISelect.defaultProps = {
  label: "",
  onChange: () => {},
  error: "",
};
