import React from "react";
import { QICollapsible } from "../../../components";
import PropTypes from "prop-types";

const ListView = ({ data }) => {
  return (
    <>
      {data.map((info, index) => (
        <div key={index} className="flex justify-between border">
          <span>{info.label}</span>
          <span>{info.value}</span>
        </div>
      ))}
    </>
  );
};

const GroupBody = ({ group, label }) => {
  const childData = [];
  group.forEach((item, index) => {
    childData.push({
      key: index,
      header: item.label,
      body: <ListView data={item.value} parentLabel={label} />,
    });
  });
  return (
    <QICollapsible
      data={childData}
      className={{ header: "internalHeader", body: "internalStatusBody" }}
    />
  );
};

const GroupCollapsible = ({ group, label }) => {
  const ParentData = [
    { key: `group${label}1`, header: label, body: <GroupBody group={group} label={label} /> },
  ];
  return (
    <div className="w-full">
      <QICollapsible
        data={ParentData}
        className={{ header: "internalHeader", body: "internalStatusBody" }}
      />
    </div>
  );
};

export const StatusBody = ({ data = [] }) => {
  return (
    <ul className="statusBody vehicle-status-summary_list">
      {data.map((info, index) => (
        <li className="flex border justify-between" key={index}>
          {info.type === "group" ? (
            <GroupCollapsible group={info.value} label={info.label} key={index} />
          ) : (
            <>
              <strong className="vehicle-status-summary_list_item_label">{info.label}</strong>
              <span className="vehicle-status-summary_list_item_value">{info.value}</span>
            </>
          )}
        </li>
      ))}
    </ul>
  );
};

StatusBody.propTypes = {
  data: PropTypes.arrayOf(Object).isRequired,
};
