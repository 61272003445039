import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { geolocationFormSchema } from "../validations";
import * as Components from "../../../components";
import {
  useCreateGeolocationMutation,
  useGetGeolocationQuery,
  useUpdateGeolocationMutation,
  useGetGeoCodersQuery,
} from "../services";
import { FormContainer } from "../Shared/FormContainer";
import { mergeObjects } from "../../../shared/helper";
import { useForm, useDropDownSearch } from "../../../hooks";

export const GeolocationForm = ({ idToEdit = null, closeForm }) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    geocoder_ids: [],
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: geolocationFormSchema,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const { create: createGeoCoder, update: updateGeoCoder } = useForm({
    createMutation: useCreateGeolocationMutation,
    updateMutation: useUpdateGeolocationMutation,
    closeForm,
    setError: formik.setErrors,
  });

  const { data } = useGetGeolocationQuery({ id: idToEdit }, { skip: !idToEdit });

  // Set form data in case of edit
  useEffect(() => {
    if (idToEdit && data) {
      setInitialValues(mergeObjects(formik.initialValues, data));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      updateGeoCoder({ formData, id: idToEdit });
    } else {
      createGeoCoder({ formData });
    }
  };

  // Get the geocoders list using Dropdown Search
  const {
    data: geocoders,
    setSearchKey: setGeocoderSearchKey,
    isSuccess: isGeocodersSuccess,
  } = useDropDownSearch({
    useData: useGetGeoCodersQuery,
    simpleSearchKey: "name_or_description_cont",
    selectedIds: formik.values.geocoder_ids,
  });

  return (
    <FormContainer
      resourceName="Geolocation Plan"
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
    >
      <Components.QIInput
        label="Name"
        placeholder="Name"
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />

      <Components.QIMultiSelectDropDown
        label="Geocoders"
        selected={formik.values.geocoder_ids}
        data={geocoders?.data || []}
        onChange={(updatedList) => {
          formik.setFieldValue("geocoder_ids", updatedList);
          setGeocoderSearchKey("");
        }}
        onSearch={(value) => setGeocoderSearchKey(value)}
        error={formik.touched.geocoder_ids && formik.errors.geocoder_ids}
        className="narrow-multiselect-dropdown"
      />
    </FormContainer>
  );
};
