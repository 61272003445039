import React, { useState } from "react";
import { QIDropdown } from "../../../components";
import {getLocalizedString} from "../../../shared/translation";

export const DetailsDropDownView = ({ data }) => {
  const [copyStatus, setCopyStatus] = useState("Copy");

  const handleCopyToClipboard = () => {
    const textToCopy = data;

    // Create a hidden input element to hold the text
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);

    // Select the text in the input element
    textArea.select();
    textArea.setSelectionRange(0, textToCopy.length);

    // Copy the selected text to the clipboard using execCommand
    document.execCommand("copy");

    // Remove the temporary input element
    document.body.removeChild(textArea);

    // Provide user feedback (you can still use your 'setCopyStatus' here)
    setCopyStatus("Copied");
    setTimeout(() => {
      setCopyStatus("Copy");
    }, 2000);
  };

  return (
    <>
      {data && data.length > 0 && (
        <>
          <QIDropdown
            className="custom-tooltip"
            toggleComponent={
              <span>
                {data?.length > 1 && (
                  <a className="associated-devices_more rounded">
                    {getLocalizedString("show", "Show")}
                  </a>
                )}
              </span>
            }
          >
            <code className={`custom-tooltip_data ${copyStatus === "Copied" && "copied"}`}>
              {data}
            </code>
            <span className="custom-tooltip_data_copy" onClick={handleCopyToClipboard}>
              {copyStatus == "Copy"
                ? getLocalizedString("copy", "Copy")
                : getLocalizedString("copied", "Copied")}
            </span>
          </QIDropdown>
        </>
      )}
    </>
  );
};
