import { useEffect, useState, useRef } from "react";
import { useOutsideAlerter } from "../../../hooks";
import { QIButton } from "../../../components";
import { Scrollbars } from "react-custom-scrollbars";
import { SvgIcon } from "../../Shared";

export const AdvanceSearchWrapper = ({
  activeFilters,
  onSearch,
  onClear,
  children,
  className = "",
  defaultOpen = true,
  showCalender,
}) => {
  const [collapseAdvanceSearch, setCollapseAdvanceSearch] = useState(!defaultOpen);
  const [searchText, setSearchText] = useState("");
  const dropdownRef = useRef(null);

  const handleSearch = () => {
    onSearch();
    setCollapseAdvanceSearch(true);
  };

  useEffect(() => {
    let filterText = "";
    if (activeFilters) {
      Object.keys(activeFilters).forEach((key) => {
        if (
          activeFilters[key] === false ||
          (Array.isArray(activeFilters[key]) && activeFilters[key].length > 0) ||
          (activeFilters[key] && !Array.isArray(activeFilters[key]))
        ) {
          filterText = `${filterText}${filterText ? "," : ""} ${key}:${activeFilters[key]}`;
        }
      });
    }
    setSearchText(filterText);
  }, [activeFilters]);

  useOutsideAlerter(
    dropdownRef,
    () => {
      setCollapseAdvanceSearch(true);
    },
    showCalender
  );

  return (
    <form className={`advanced-search ${className}`} ref={dropdownRef}>
      <header className="advanced-search_header">
        <div className="advanced-search_header_title">
          <h5>Filter</h5>
          {searchText && (
            <div className="search-by-filter" title={searchText}>
              {searchText}
            </div>
          )}
        </div>

        <SvgIcon
          wrapperClass={`collapsible-icon ${collapseAdvanceSearch && "rotate-180"}`}
          svgClass="icon-collapsible"
          name="up-arrow"
          onClick={() => setCollapseAdvanceSearch(!collapseAdvanceSearch)}
        />
      </header>

      <Scrollbars autoHide autoHeight autoHeightMax="68vh">
        <section className={`advanced-search_container ${collapseAdvanceSearch && "hidden"}`}>
          {children}
        </section>
      </Scrollbars>

      <footer className={`advanced-search_button-group ${collapseAdvanceSearch && "hidden"}`}>
        <QIButton className="qi-btn secondary md" onClick={onClear}>
          Clear
        </QIButton>
        <QIButton className="qi-btn primary md" onClick={handleSearch}>
          Search
        </QIButton>
      </footer>
    </form>
  );
};
