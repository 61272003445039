import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toggleAuthModal } from "../reduxStore/features";
import { FMDPStorage } from "../shared/helper";

const extractId = (path, queryParams, idKey) => {
  const qP = { ...queryParams };
  delete qP["formData"];
  delete qP[idKey];
  return pathWithQuery(`${path}/${queryParams[idKey]}`, qP);
};

const pathWithQuery = (path, queryParams) => {
  let finalPath = `${path}`;
  if (queryParams) {
    finalPath = `${finalPath}?`;
    for (const property in queryParams) {
      finalPath = `${finalPath}${property}=${queryParams[property]}&`;
    }
  }
  return finalPath;
};

// check https://redux-toolkit.js.org/rtk-query/usage/mutations
export const getCollectionQuery = (builder, path) => {
  return builder.query({
    query: (queryParams) => ({
      url: pathWithQuery(path, queryParams),
      method: "get",
    }),
    keepUnusedDataFor: 2,
    providesTags: [path],
  });
};

export const getQuery = (builder, path, idKey = null) => {
  return builder.query({
    query: (queryParams) => ({
      url: idKey ? extractId(path, queryParams, idKey) : path,
      method: "get",
    }),
    providesTags: (result, error, id) => {
      if (!error) {
        return [path];
      }
    },
    keepUnusedDataFor: 2,
  });
};

export const createMutation = (builder, path) => {
  return builder.mutation({
    query: (queryParams) => ({
      url: path,
      method: "post",
      body: queryParams.formData,
    }),
    invalidatesTags: (result, error, id) => {
      if (!error) {
        return [path];
      }
    },
  });
};

// TODO - handle invalidatesTags tagname from path
export const updateMutation = (builder, path, idKey = "id") => {
  return builder.mutation({
    query: (queryParams) => ({
      url: extractId(path, queryParams, idKey),
      method: "put",
      body: queryParams.formData,
    }),
    invalidatesTags: (result, error, { id }) => {
      if (!error) {
        return [path];
      }
    },
  });
};

export const deleteMutation = (builder, path, idKey = "id") => {
  return builder.mutation({
    query: (queryParams) => ({
      url: extractId(path, queryParams, idKey),
      method: "delete",
    }),
    invalidatesTags: (result, error, id) => {
      if (!error) {
        return [path];
      }
    },
  });
};

export const baseQueryAuth = (baseUrl) => {
  const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const authToken = sessionStorage.getItem("fmdp-access-token");
      if (authToken) {
        headers.set("Authorization", authToken);
      }
      return headers;
    },
  });

  return async (args, api, extraOptions) => {
    /*
      BE pagination starts with 'zero' while FE from 'one'.
      Check if query has pagination in query params reduce it by -1 befor API call
    */
    if (args?.url?.includes("?")) {
      let [firstPart, endPart] = args.url.split("?");
      const searchParams = new URLSearchParams(endPart);

      let page = parseInt(searchParams.get("page"));

      if (page) {
        searchParams.set("page", page - 1);
        args.url = `${firstPart}?${searchParams.toString()}`;
      }
    }

    const { error, data } = await baseQuery(args, api, extraOptions);
    if (error) {
      if (error.status === 401) {
        if (args?.url !== "sso") {
          FMDPStorage.clear();
          api.dispatch(toggleAuthModal());
        }
      }
      return { error };
    }

    return { data };
  };
};
