import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { useForm, useDropDownSearch } from "../../../hooks";
import { FormContainer } from "../Shared/FormContainer";
import {
  useCreateVariablesMutation,
  useUpdateVariablesMutation,
  useGetVariableQuery,
  useGetSectionsQuery,
  useGetUnitsQuery,
} from "../services";
import { mergeObjects } from "../../../shared/helper";
import { variableTypeFormValidation } from "../validations";
import { variableTypes, classifications } from "../../../shared/dropdownLists";

export const VariableTypeForm = ({ idToEdit = null, closeForm }) => {
  const dropDownParams = { per_page: 1000, order_by: "name" };

  const [initialValues, setInitialValues] = useState({
    name: "",
    display_name: "",
    variable_type: "",
    section_id: "",
    unit_id: "",
    classification_type: "",
    precision: "",
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: variableTypeFormValidation,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const { create: createVariableType, update: updateVariableType } = useForm({
    createMutation: useCreateVariablesMutation,
    updateMutation: useUpdateVariablesMutation,
    closeForm,
    setError: formik.setErrors,
  });

  // Get the sections list using Dropdown Search
  const {
    data: sections,
    setSearchKey: setSectionsSearch,
    isSuccess: sectionsSuccess,
  } = useDropDownSearch({
    useData: useGetSectionsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.section_id,
  });

  // Get the units list using Dropdown Search
  const {
    data: units,
    setSearchKey: setUnitsSearch,
    isSuccess: unitsSuccess,
  } = useDropDownSearch({
    useData: useGetUnitsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.unit_id,
  });

  const { data } = useGetVariableQuery({ id: idToEdit }, { skip: !idToEdit });

  useEffect(() => {
    if (idToEdit && data) {
      setInitialValues(mergeObjects(initialValues, data));
    }
  }, [data]);

  //Set section to Other Data as default value
  useEffect(() => {
    if (sections && formik?.values?.section_id === "") {
      sections?.data?.map((section) => {
        if (section?.value === "other_data") {
          formik.setFieldValue("section_id", section?.id);
        }
      });
    }
  }, [sections]);

  //Set unit to not applicable as default value
  useEffect(() => {
    if (units && formik?.values?.unit_id === "") {
      units?.data?.map((unit) => {
        if (unit?.name === "Not Applicable") {
          formik.setFieldValue("unit_id", unit?.id);
        }
      });
    }
  }, [units]);

  //Set classification to Tracking Data - Device as default value
  useEffect(() => {
    if (classifications && formik?.values?.classification_type === "") {
      classifications?.map((classification) => {
        if (classification?.name === "Tracking Data - Device") {
          formik.setFieldValue("classification_type", classification?.id);
        }
      });
    }
  }, [classifications]);

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      updateVariableType({ id: idToEdit, formData });
    } else {
      createVariableType({ formData });
    }
  };

  const handleTypeChange = (value) => {
    formik.setFieldValue("variable_type", value);
    if (value !== "number") {
      formik.setFieldValue("precision", "");
    }
  };

  return (
    <FormContainer
      resourceName="Variable"
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
    >
      <Components.QICustomSelect
        label="Classification"
        {...formik.getFieldProps("classification_type")}
        onChange={(value) => {
          formik.setFieldValue("classification_type", value);
        }}
        error={formik?.touched?.classification_type && formik?.errors?.classification_type}
        labelClassName="classification"
      >
        {classifications?.map((classification) => {
          return (
            <li key={classification?.id} value={classification?.id}>
              {classification?.name}
            </li>
          );
        })}
      </Components.QICustomSelect>
      <Components.QIInput
        label="Display Name"
        placeholder="Display Name"
        {...formik.getFieldProps("display_name")}
        error={formik?.touched?.display_name && formik?.errors?.display_name}
      />
      <Components.QICustomSelect
        label="Section"
        {...formik.getFieldProps("section_id")}
        onChange={(value) => {
          formik.setFieldValue("section_id", value);
          setSectionsSearch("");
        }}
        onSearch={setSectionsSearch}
        error={formik?.touched?.section_id && formik?.errors?.section_id}
        labelClassName="section"
      >
        {sectionsSuccess &&
          sections?.data?.map((section) => {
            return (
              <li key={section?.id} value={section?.id}>
                {section?.name}
                {section?.value ? ` (${section.value})` : ""}
              </li>
            );
          })}
      </Components.QICustomSelect>
      <Components.QIInput
        label="Name"
        placeholder="Name"
        {...formik.getFieldProps("name")}
        error={formik?.touched?.name && formik?.errors?.name}
      />
      <Components.QICustomSelect
        label="Type"
        {...formik.getFieldProps("variable_type")}
        onChange={(value) => handleTypeChange(value)}
        error={formik?.touched?.variable_type && formik?.errors?.variable_type}
        labelClassName="type"
      >
        {variableTypes?.map((type) => {
          return (
            <li key={type?.id} value={type?.id}>
              {type?.name}
            </li>
          );
        })}
      </Components.QICustomSelect>
      {formik?.values?.variable_type === "number" && (
        <Components.QIInput
          label="Display Precision"
          placeholder="Display Precision"
          type="number"
          {...formik.getFieldProps("precision")}
          error={formik?.touched?.precision && formik?.errors?.precision}
        />
      )}
      <Components.QICustomSelect
        label="Unit"
        {...formik.getFieldProps("unit_id")}
        onChange={(value) => {
          formik.setFieldValue("unit_id", value);
          setUnitsSearch("");
        }}
        onSearch={setUnitsSearch}
        error={formik?.touched?.unit_id && formik?.errors?.unit_id}
        labelClassName="unit"
      >
        {unitsSuccess &&
          units?.data?.map((unit) => {
            return (
              <li key={unit?.id} value={unit?.id}>
                {unit?.name}
              </li>
            );
          })}
      </Components.QICustomSelect>
    </FormContainer>
  );
};
