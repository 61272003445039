// Each Application route will start with Application name
// e.g 'http://localhost:3000/Tracking/'  'http://localhost:3000/DeviceManagement'

export const getCurrentAppFromPath = (location) => {
  if (location && location.pathname) {
    const paths = location.pathname.split("/");

    if (paths.length > 1) {
      return paths[1];
    }
  }
  return "";
};

export const parseServerError = (error) => {
  if (error.status === 500 || error.status === 404) {
    return { serverError: "Something went wrong. We are checking this soon!" };
  } else {
    let parsedError = {};

    for (let key in error?.data) {
      try {
        parsedError[key] = error?.data[key]?.join(", ");
      } catch (e) {
        console.log(e);
      }
    }

    return parsedError;
  }
};

export class FMDPStorage {
  static set(key, value) {
    let encryptedValue = value;

    if (typeof encryptedValue === "object") {
      encryptedValue = JSON.stringify(encryptedValue);
    }
    localStorage.setItem(key, encryptedValue);
  }
  static get(key) {
    let value = localStorage.getItem(key);

    try {
      value = JSON.parse(value);
    } catch {}
    return value;
  }
  static clear() {
    localStorage.clear();
    sessionStorage.clear();
  }
}

// assign default new object attr to the object fetched from db if undefined
export const mergeObjects = (newObj, dbObject) => {
  let obj3 = {};

  for (let attr in newObj) {
    if (dbObject[attr] === 0 || dbObject[attr] === false) {
      obj3[attr] = dbObject[attr];
    } else {
      obj3[attr] = dbObject[attr] || newObj[attr];
    }
  }

  obj3 = { ...dbObject, ...obj3 };
  return obj3;
};

//Reads the csv file and finds out the headers of the file and the total number of rows present in that file
export const getCsvDetails = (csvFile, onSuccess) => {
  const reader = new FileReader();

  reader.onload = function (e) {
    const text = e.target.result;
    //Extracts the first line of the csv
    let headers = text.slice(0, text.indexOf("\n"));
    //If the carriage return character exists then the headers are extracted excluding that character
    headers = headers.indexOf("\r") === -1 ? headers : headers.substring(0, headers.indexOf("\r"));
    //Converted to an array of headers
    headers = headers.split(",");
    //If a null character is generated at the end, it is removed
    if (headers[headers.length - 1] === "") {
      headers.splice(headers.length - 1, 1);
    }
    //The rows exluding the header is extracted
    const rows = text.slice(text.indexOf("\n") + 1).split("\n");
    //The total number of non-empty rows are calculated
    const rowLength = rows[rows.length - 1] === "" ? rows.length - 1 : rows.length;

    onSuccess(headers, rowLength);
  };

  reader.readAsText(csvFile);
};

// Takes the year and month name/number (eg: Jan/0) and returns the last day of that month. For any invalid date, returns NA
export const lastDayMonth = (y, m) => {
  switch (m) {
    case "Jan":
      m = 0;
      break;
    case "Feb":
      m = 1;
      break;
    case "Mar":
      m = 2;
      break;
    case "Apr":
      m = 3;
      break;
    case "May":
      m = 4;
      break;
    case "Jun":
      m = 5;
      break;
    case "Jul":
      m = 6;
      break;
    case "Aug":
      m = 7;
      break;
    case "Sep":
      m = 8;
      break;
    case "Oct":
      m = 9;
      break;
    case "Nov":
      m = 10;
      break;
    case "Dec":
      m = 11;
      break;
    default:
      m = "NA";
      break;
  }

  if (m >= 0 && m < 12) return new Date(y, m + 1, 0).getDate();
  else return "NA";
};
