import { useState } from "react";

export const useDndContainer = () => {
  const [isItemsDragging, setIsItemsDragging] = useState(false);
  const [activeBoxes, setActiveBoxes] = useState({});
  const [minimisedItem, setMinimisedItems] = useState({});

  const handleDragChange = (key, val) => {
    const tempNew = {
      ...activeBoxes,
      [key]: { ...activeBoxes[key], props: { ...activeBoxes[key].props, ...val } },
    };
    setActiveBoxes(tempNew);
  };

  const removeBox = (key) => {
    if (activeBoxes[key]) {
      let tempActiveBoxes = { ...activeBoxes };
      delete tempActiveBoxes[key];
      setActiveBoxes(tempActiveBoxes);
    }
  };

  const revertBox = (key, setShowDetails) => {
    if (activeBoxes[key]) {
      let tempActiveBoxes = { ...activeBoxes };
      delete tempActiveBoxes[key];
      setShowDetails(true)
      setActiveBoxes(tempActiveBoxes);
    }
  };

  const addNewBox = (key) => {
    if (!activeBoxes[key]) {
      let tempActiveBoxes = {
        ...activeBoxes,
        [key]: {
          props: { top: 20, left: 80, onDragging: setIsItemsDragging },
        },
      };
      setActiveBoxes(tempActiveBoxes);
    }
  };

  return { activeBoxes, addNewBox, removeBox, revertBox, isItemsDragging, handleDragChange };
};
