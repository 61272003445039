import { useEffect, useState } from "react";
import moment from "moment";
import { AdvanceSearchWrapper } from "../Shared/";
import { QIDateRangePicker, QIInput } from "../../../components";
import {getLocalizedString} from "../../../shared/translation";

export const VehicleAdvanceSearch = ({ onSearch, activeSearch }) => {
  const initialValues = {
    name_cont: "",
    plate_number_cont: "",
    vehicle_model_name_cont: "",
    user_filter_full_name_cont: "",
    filter_device_imei_cont: "",
    created_at_gteq: null,
    created_at_lteq: null,
  };

  const [formData, setFormData] = useState(initialValues);
  const [showCalender, setShowCalender] = useState(false);
  const [resetCount, setResetCount] = useState(0);

  useEffect(() => {
    if (activeSearch) {
      setFormData(activeSearch);
    }
  }, []);

  const handleFormChange = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const clearAdvanceSearch = () => {
    setFormData(initialValues);
    onSearch(null);
    setResetCount((i) => i + 1);
    setShowCalender(false);
  };

  const handleDateRange = (startTime, endTime) => {
    setFormData((prevState) => ({
      ...prevState,
      created_at_gteq: startTime,
      created_at_lteq: endTime,
    }));
    setResetCount((i) => i + 1);
  };

  return (
    <AdvanceSearchWrapper
      activeFilters={activeSearch}
      onClear={clearAdvanceSearch}
      onSearch={() => onSearch(formData)}
      showCalender={showCalender}
      setShowCalender={setShowCalender}
    >
      <QIInput
        label={getLocalizedString("name", "Name")}
        value={formData.name_cont}
        onChange={(e) => handleFormChange("name_cont", e.target.value)}
      />
      <QIInput
        label={getLocalizedString("plate_no", "Plate No.")}
        value={formData.plate_number_cont}
        onChange={(e) => handleFormChange("plate_number_cont", e.target.value)}
      />
      <QIInput
        label={getLocalizedString("specification", "Specification")}
        value={formData.vehicle_model_name_cont}
        onChange={(e) => handleFormChange("vehicle_model_name_cont", e.target.value)}
      />
      <QIInput
        label={getLocalizedString("user", "User")}
        value={formData.user_filter_full_name_cont}
        onChange={(e) => handleFormChange("user_filter_full_name_cont", e.target.value)}
      />
      <QIInput
        label={getLocalizedString("devices", "Devices")}
        value={formData.filter_device_imei_cont}
        onChange={(e) => handleFormChange("filter_device_imei_cont", e.target.value)}
      />

      <QIDateRangePicker
        start={formData.created_at_gteq}
        end={formData.created_at_lteq}
        label={getLocalizedString("created_between", "Created b/w")}
        maxDate={moment()}
        onChange={handleDateRange}
        showCustomRangeLabel
        setShowCalender={setShowCalender}
        resetCount={resetCount}
      />
    </AdvanceSearchWrapper>
  );
};
