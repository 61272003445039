import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * This hook help to keep track of pagination params and required routings
 * Also this hook help to set page and perPage info at single file
 * @returns {Integer} page - current active page state
 * @returns {Integer} perPage - current perPage state
 * @returns {Function} setPage
 * @returns {Function} setPerPage
 * @returns {Object} pagination - an object which consist of page,setPage,perPage,setPerPage
 */
export const usePagination = () => {
  // const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchCurrentPage = parseInt(searchParams.get("page"));
  const searchCurrentPerPage = parseInt(searchParams.get("perPage"));

  // Check if valid page number or not, else set to page 1
  const currentPage = searchCurrentPage > 1 ? searchCurrentPage : 1;
  // Check if current perPage value is currect or not else set to 20
  const currentPerPage = [20, 50, 100].includes(searchCurrentPerPage) ? searchCurrentPerPage : 20;

  const [pageObj, setPageObj] = useState({ page: currentPage, perPage: currentPerPage });

  const setPage = (value) => {
    setPageObj((prevState) => ({ ...prevState, page: value }));
  };

  const setPerPage = (value) => {
    setPageObj((prevState) => ({ ...prevState, perPage: value }));
  };

  useEffect(() => {
    let queryParams = {};

    // Filter out queryParam in object form
    for (let entry of searchParams.entries()) {
      if (["page", "perPage"].includes(entry[0])) {
        queryParams[entry[0]] = parseInt(entry[1]); // Keep page, perPage in Int format always
      } else {
        queryParams[entry[0]] = entry[1];
      }
    }

    // Remove pagination queryparams when page number is 1
    if (pageObj.page === 1) {
      if (queryParams.page != 1) {
        delete queryParams.page;
        delete queryParams.perPage;
        setSearchParams(queryParams);
      }
    }
    // If change in page or perPage then append this information to queryParams
    else if (queryParams.page !== pageObj.page || queryParams.perPage !== pageObj.perPage) {
      setSearchParams({ ...queryParams, ...pageObj });
    }
  }, [pageObj]);

  // Keep watch when there is change in searchParams
  useEffect(() => {
    const currentPage = parseInt(searchParams.get("page"));
    const currentPerPage = parseInt(searchParams.get("perPage"));

    if (!currentPage && pageObj.page !== 1) {
      setPage(1);
    }
    // When page is hard refreshed read the value of page from queryParams and set the value
    if (
      (currentPage && currentPage !== pageObj.page) ||
      (currentPerPage && currentPerPage !== pageObj.perPage)
    ) {
      setPageObj({ page: currentPage, perPage: currentPerPage });
    }
  }, [searchParams]);

  const pagination = {
    page: pageObj.page,
    perPage: pageObj.perPage,
    onPageChange: setPage,
    onPerPageChange: setPerPage,
  };

  return { page: pageObj.page, perPage: pageObj.perPage, setPage, setPerPage, pagination };
};
