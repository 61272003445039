import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AdminSharedHeader, AdminSharedTable, AdminSharedDeleteModal } from "../Shared/";
import { usePagination, useDropDownSearch } from "../../../hooks";

import {
  useGetDModelControlSignalsQuery,
  useDeleteDModelControlSignalMutation,
  useDeleteVariablesDeviceModelsMutation,
  useGetVariablesQuery,
  useUpdateVariablesDeviceModelsMutation,
  useUpdateCommandsDeviceModelsMutation,
  useDeleteCommandsDeviceModelsMutation,
  useGetCommandsQuery,
} from "../services";
import * as Components from "../../../components";
import { DModelControlSignalForm } from "../DModelControlSignals/Form";
import { DModelVariableForm } from "../DModelVariables/Form";
import { useFormik } from "formik";

export const DeviceModel = ({ ControlSignalVariableDetails, closeForm }) => {
  const formatMapping = (mappingHash) => {
    return (
      <code>
        <pre className="code-format">{JSON.stringify(mappingHash, null, 2)}</pre>
      </code>
    );
  };

  // control signals
  const [controlSignalPage, setControlSignalPage] = useState(1);
  const [controlSignalPerPage, setControlSignalPerPage] = useState(10);
  const controlSignalPagination = {
    page: controlSignalPage,
    perPage: controlSignalPerPage,
    onPageChange: setControlSignalPage,
    onPerPageChage: setControlSignalPerPage,
  };

  const controlSignalQueryParams = {
    page: controlSignalPagination.page,
    per_page: controlSignalPagination.perPage,
    order_by: "created_at",
    order_dir: "desc",
    device_model_id: ControlSignalVariableDetails?.id,
  };

  const {
    data: controlSignalsData = { data: [], count: 0 },
    isLoading: isControlSignalsLoading,
    isFetching: isControlSignalsFetching,
    isSuccess: isControlSignalsSuccess,
    error: isControlSignalsError,
  } = useGetDModelControlSignalsQuery(controlSignalQueryParams);

  const [
    deleteControlSignal,
    { isSuccess: deleteControlSignalSuccess, reset: resetDeleteControlSignal },
  ] = useDeleteDModelControlSignalMutation();

  const [controlSignalIdToEdit, setControlSignalIdToEdit] = useState(null);
  const [controlSignalIdToDelete, setControlSignalIdToDelete] = useState(null);
  const [showControlSignalForm, setShowControlSignalForm] = useState(false);
  const [showControlSignalDeleteWarning, setShowControlSignalDeleteWarning] = useState(false);

  useEffect(() => {
    if (deleteControlSignalSuccess) {
      setShowControlSignalDeleteWarning(false);
      resetDeleteControlSignal();
    }
    return () => resetDeleteControlSignal();
  }, [deleteControlSignalSuccess]);

  const createNewControlSignalHandler = () => {
    setShowControlSignalForm(true);
    setControlSignalIdToEdit(null);
  };

  const editControlSignalHandler = (datum) => {
    setShowControlSignalForm(true);
    setControlSignalIdToEdit(datum.id);
  };

  const deleteControlSignalHandler = (datum) => {
    setShowControlSignalDeleteWarning(true);
    setControlSignalIdToDelete(datum.id);
  };

  const controlSignalHeaders = [
    { label: "Name", key: "name", className: "name" },
    {
      label: "Mapping",
      className: "maping",
      nestedValue: true,
      getNestedValue: ({ mapping }) => formatMapping(mapping),
    },
    { label: "Created on", key: "created_at", className: "date" },
  ];

  const getDeleteItemName = (id, collection) => {
    let name = "";
    const index = collection.findIndex((datum) => datum.id === id);

    if (index > -1) {
      name = collection[index].name;
    }

    return name;
  };

  // control signals end

  // device model variables
  const [variablePage, setVariablePage] = useState(1);
  const [variablePerPage, setVariablePerPage] = useState(10);

  const { page, setPage, perPage, pagination } = usePagination();

  const variableQueryParams = {
    per_page: 2000,
    order_by: "variables.created_at",
    order_dir: "desc",
    device_model_id: ControlSignalVariableDetails?.id,
  };

  // Get the list of variables for list view
  const {
    data: variablesModelData = { data: [], count: 0 },
    isLoading: isVariablesModelLoading,
    isFetching: isVariablesModelFetching,
    isSuccess: isVariablesModelSuccess,
    error: isVariablesModelError,
    refetch: refetchVariables,
  } = useGetVariablesQuery(variableQueryParams);

  const [initialValues, setInitialValues] = useState({
    variable_ids: [],
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
  });

  // Get the variables list for multiselect using Dropdown Search
  const {
    data: variablesData = { data: [], count: 0 },
    setSearchKey: setVariablesSearch,
    isSuccess: isVariablesSuccess,
  } = useDropDownSearch({
    useData: useGetVariablesQuery,
    simpleSearchKey: "name_or_display_name_or_section_name_or_unit_name_cont",
    selectedIds: formik.values.variable_ids,
    additionalParams: {
      per_page: 2000,
      order_by: "variables.created_at",
      order_dir: "desc",
      classification_type:
        ControlSignalVariableDetails?.device_manufacturer_name === "Mobile"
          ? "tracking_data_api"
          : "tracking_data_device",
    },
  });

  //API to add a variable
  const [addVariable, { isSuccess: isSuccessUpdateVariables, isLoading: isAddVariablesLoading }] =
    useUpdateVariablesDeviceModelsMutation();

  //API to delete a variable
  const [deleteVariable, { isSuccess: deleteVariableSucccess, reset: resetDeleteVariable }] =
    useDeleteVariablesDeviceModelsMutation();

  useEffect(() => {
    if (deleteVariableSucccess) {
      setShowVariableDeleteWarning(false);
      resetDeleteVariable();
    }
    return () => resetDeleteVariable();
  }, [deleteVariableSucccess]);

  const [variableIdToEdit, setVariableIdToEdit] = useState(null);
  const [variableIdToDelete, setVariableIdToDelete] = useState(null);
  const [showVariableForm, setShowVariableForm] = useState(false);
  const [showVariableDeleteWarning, setShowVariableDeleteWarning] = useState(false);

  const deleteVariableHandler = (datum) => {
    setShowVariableDeleteWarning(true);
    setVariableIdToDelete(datum.id);
  };

  const variableHeaders = [
    { label: "Display Name", key: "display_name", className: "display-name" },
    { label: "Section", key: "section_name", className: "section-name" },
    { label: "Name", key: "name", className: "name" },
    { label: "Type", key: "variable_type", className: "variable-type" },
    { label: "Display Precision", key: "precision", className: "precision" },
    { label: "Unit", key: "unit_name", className: "unit-name" },
    {
      label: "Device Model",
      key: "device_model_count",
      className: "device-model-count text-center",
    },
    { label: "Created on", key: "created_at", className: "date" },
  ];

  const titleBreadcrumb = (deviceModelName) => {
    return (
      <>
        <Link
          to={`/applications/management_portal/device_models`}
          style={{ textDecoration: "none" }}
        >
          Device Models
        </Link>
        &nbsp;&gt;&nbsp;
        <span className="text-base text-slate-600">{deviceModelName}</span>
      </>
    );
  };

  // To maintain whether variables list has been updated
  const [variablesListUpdate, setVariablesListUpdate] = useState(false);

  // To call the add variables API when there is a change in the variables list
  useEffect(() => {
    // Execute only when formik.values.variable_ids change
    if (variablesListUpdate) {
      const formData = formik?.values;

      // Call the add API of variables
      addVariable({ device_model_id: ControlSignalVariableDetails?.id, formData });
      setVariablesListUpdate(false);
    }
  }, [formik?.values?.variable_ids, ControlSignalVariableDetails?.id, variablesListUpdate]);

  // Set the selected variables in the multiselect dropdown after the list of variables is received
  useEffect(() => {
    if (variablesModelData?.data && isVariablesModelSuccess) {
      const selectedVariables = [];
      variablesModelData?.data?.map((variable) => {
        selectedVariables.push(variable?.id);
      });
      formik.setFieldValue("variable_ids", selectedVariables);
    }
  }, [variablesModelData]);

  // If any variable is added/ deleted, call the list API of variables to get the added variables
  useEffect(() => {
    if (isSuccessUpdateVariables || deleteVariableSucccess) {
      // Refetch the variables data
      refetchVariables();
    }
  }, [isSuccessUpdateVariables, deleteVariableSucccess]);

  const handleVariablesChange = (updatedList) => {
    formik.setFieldValue("variable_ids", updatedList);
    setVariablesListUpdate(true);
  };

  // end of device model variables

  // commands

  const commandsQueryParams = {
    per_page: 2000,
    order_by: "created_at",
    order_dir: "desc",
    device_model_id: ControlSignalVariableDetails?.id,
  };

  // Get the list of commands for list view
  const {
    data: commandsModelData = { data: [], count: 0 },
    isLoading: isCommandsModelLoading,
    isFetching: isCommandsModelFetching,
    isSuccess: isCommandsModelSuccess,
    error: isCommandsModelError,
    refetch: refetchCommands,
  } = useGetCommandsQuery(commandsQueryParams);

  const [initialCommandValues, setInitialCommandValues] = useState({
    command_id: [],
  });

  const formikCommand = useFormik({
    initialValues: initialCommandValues,
    enableReinitialize: true,
  });

  // Get the commands list for multiselect using Dropdown Search
  const {
    data: commandsData = { data: [], count: 0 },
    setSearchKey: setCommandsSearch,
    isSuccess: isCommandsSuccess,
  } = useDropDownSearch({
    useData: useGetCommandsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formikCommand?.values?.command_id,
    additionalParams: {
      per_page: 2000,
      order_by: "created_at",
      order_dir: "desc",
      device_manufacturer_id: ControlSignalVariableDetails?.device_manufacturer_id,
    },
  });

  //API to add a command
  const [addCommand, { isSuccess: isSuccessUpdateCommands, isLoading: isAddCommandsLoading }] =
    useUpdateCommandsDeviceModelsMutation();

  //API to delete a command
  const [deleteCommand, { isSuccess: deleteCommandSucccess, reset: resetDeleteCommand }] =
    useDeleteCommandsDeviceModelsMutation();

  useEffect(() => {
    if (deleteCommandSucccess) {
      setShowCommandDeleteWarning(false);
      resetDeleteCommand();
    }
    return () => resetDeleteCommand();
  }, [deleteCommandSucccess]);

  const [commandIdToDelete, setCommandIdToDelete] = useState(null);
  const [showCommandDeleteWarning, setShowCommandDeleteWarning] = useState(false);

  const commandsHeaders = [
    { label: "Name", key: "name", className: "name" },
    { label: "Command", key: "command_text", className: "command" },
    { label: "Type", key: "command_type", className: "type" },
    { label: "Mode", key: "mode", className: "mode" },
    {
      label: "Device Model",
      key: "device_model_count",
      className: "device-models text-center",
    },
    { label: "Manufacturer", key: "device_manufacturer_name" },
    { label: "Created on", key: "created_at", className: "date" },
  ];

  // To maintain whether commands list has been updated
  const [commandsListUpdate, setCommandsListUpdate] = useState(false);

  // To call the add commands API when there is a change in the commands list
  useEffect(() => {
    // Execute only when  formikCommand.values.command_id change
    if (commandsListUpdate) {
      const formData = {
        command_id:
          formikCommand?.values?.command_id[formikCommand?.values?.command_id?.length - 1],
      };

      // Call the add API of commands
      addCommand({ device_model_id: ControlSignalVariableDetails?.id, formData });
      setCommandsListUpdate(false);
    }
  }, [formikCommand?.values?.command_id, ControlSignalVariableDetails?.id, commandsListUpdate]);

  // Set the selected commands in the multiselect dropdown after the list of commands is received
  useEffect(() => {
    if (commandsModelData?.data && isCommandsModelSuccess) {
      const selectedCommands = [];
      commandsModelData?.data?.map((command) => {
        selectedCommands.push(command?.id);
      });
      formikCommand.setFieldValue("command_id", selectedCommands);
    }
  }, [commandsModelData]);

  const handleCommandsChange = (updatedList) => {
    formikCommand.setFieldValue("command_id", updatedList);
    setCommandsListUpdate(true);
  };

  const deleteCommandHandler = (datum) => {
    setShowCommandDeleteWarning(true);
    setCommandIdToDelete(datum.id);
  };

  // If any command is added/ deleted, call the list API of commands to get the added commands
  useEffect(() => {
    if (isSuccessUpdateCommands || deleteCommandSucccess) {
      // Refetch the commands data
      refetchCommands();
    }
  }, [isSuccessUpdateCommands, deleteCommandSucccess]);

  // end of commands
  const [showConfiguationTab, setShowConfiguationTab] = useState("variables");

  return (
    <Components.QIModal show onHide={closeForm} size="xl-large" backdrop={false}>
      <Components.QIModalHeader onHide={closeForm}>
        <h5>
          Configurations For {ControlSignalVariableDetails?.device_manufacturer_name}{" "}
          {ControlSignalVariableDetails?.name}
        </h5>
      </Components.QIModalHeader>
      <Components.QIModalBody className={`${showConfiguationTab}`}>
        <div>
          <section>
            <div className="flex">
              <span
                className={`modal_tab ${showConfiguationTab === "variables" && "active"}`}
                onClick={() => setShowConfiguationTab("variables")}
              >
                Variables
              </span>

              <span
                className={`modal_tab ${showConfiguationTab === "control-signals" && "active"}`}
                onClick={() => setShowConfiguationTab("control-signals")}
              >
                Control Signals
              </span>

              <span
                className={`modal_tab ${showConfiguationTab === "commands" && "active"}`}
                onClick={() => setShowConfiguationTab("commands")}
              >
                Commands
              </span>
            </div>

            {showConfiguationTab === "control-signals" && (
              <div className="modal_tab-content">
                <AdminSharedHeader headingPresent={false} />

                <AdminSharedTable
                  isLoading={isControlSignalsLoading}
                  isFetching={isControlSignalsFetching}
                  error={isControlSignalsError}
                  isSuccess={isControlSignalsSuccess}
                  data={controlSignalsData.data}
                  headers={controlSignalHeaders}
                  pagination={{ ...controlSignalPagination, count: controlSignalsData.count }}
                  onEdit={editControlSignalHandler}
                  idToEdit={controlSignalIdToEdit}
                  onDelete={deleteControlSignalHandler}
                  className="control-signals"
                />
              </div>
            )}

            {showConfiguationTab === "variables" && (
              <div className="modal_tab-content">
                <AdminSharedHeader headingPresent={false} />
                <Components.QIMultiSelectDropDown
                  data={variablesData?.data}
                  selected={formik?.values?.variable_ids}
                  onChange={(updatedList) => handleVariablesChange(updatedList)}
                  onSearch={(value) => setVariablesSearch(value)}
                  multiSelectWithoutChip={true}
                  addNewTitle="Add"
                  labelKey={(data) =>
                    `${data?.display_name} (${data?.section_name}${
                      data?.unit_name ? ` - ${data.unit_name}` : ""
                    })`
                  }
                />

                {isVariablesModelFetching && (
                  <div className="no-data">
                    <Components.QISpinner size="50px" />
                    <p>Loading...</p>
                  </div>
                )}
                {!isVariablesModelFetching && isVariablesModelError && (
                  <h2>Something went wrong! Try again ...</h2>
                )}

                {!isVariablesModelFetching && isVariablesModelSuccess && (
                  <AdminSharedTable
                    isLoading={isVariablesModelLoading}
                    isFetching={isVariablesModelFetching}
                    error={isVariablesModelError}
                    isSuccess={isVariablesModelSuccess}
                    data={variablesModelData.data}
                    headers={variableHeaders}
                    onDelete={deleteVariableHandler}
                    className="variables"
                    systemDefault={false}
                  />
                )}
              </div>
            )}

            {showConfiguationTab === "commands" && (
              <div className="modal_tab-content">
                <AdminSharedHeader headingPresent={false} />
                <Components.QIMultiSelectDropDown
                  data={commandsData?.data}
                  selected={formikCommand?.values?.command_id}
                  onChange={(updatedList) => handleCommandsChange(updatedList)}
                  onSearch={(value) => setCommandsSearch(value)}
                  multiSelectWithoutChip={true}
                  addNewTitle="Add"
                  labelKey={(data) => `${data?.name}`}
                />

                {isCommandsModelFetching && (
                  <div className="no-data">
                    <Components.QISpinner size="50px" />
                    <p>Loading...</p>
                  </div>
                )}
                {!isCommandsModelFetching && isCommandsModelError && (
                  <h2>Something went wrong! Try again ...</h2>
                )}

                {!isCommandsModelFetching && isCommandsModelSuccess && (
                  <AdminSharedTable
                    isLoading={isCommandsModelLoading}
                    isFetching={isCommandsModelFetching}
                    error={isCommandsModelError}
                    isSuccess={isCommandsModelSuccess}
                    data={commandsModelData.data}
                    headers={commandsHeaders}
                    onDelete={deleteCommandHandler}
                    className="commands"
                    systemDefault={false}
                  />
                )}
              </div>
            )}
          </section>
        </div>

        {showControlSignalForm && (
          <DModelControlSignalForm
            idToEdit={controlSignalIdToEdit}
            closeForm={() => setShowControlSignalForm(false)}
          />
        )}

        <AdminSharedDeleteModal
          show={showControlSignalDeleteWarning}
          resourceName="control signal"
          getDeleteItemName={() =>
            getDeleteItemName(controlSignalIdToDelete, controlSignalsData.data)
          }
          onHide={() => setShowControlSignalDeleteWarning(false)}
          onDelete={() => deleteControlSignal({ id: controlSignalIdToDelete })}
        />

        {showVariableForm && (
          <DModelVariableForm
            idToEdit={variableIdToEdit}
            closeForm={() => setShowVariableForm(false)}
          />
        )}
        <AdminSharedDeleteModal
          show={showVariableDeleteWarning}
          resourceName="variable"
          getDeleteItemName={() => getDeleteItemName(variableIdToDelete, variablesModelData.data)}
          onHide={() => setShowVariableDeleteWarning(false)}
          onDelete={() => {
            const formData = { variable_ids: variableIdToDelete };
            deleteVariable({ device_model_id: ControlSignalVariableDetails?.id, formData });
          }}
        />

        <AdminSharedDeleteModal
          show={showCommandDeleteWarning}
          resourceName="association of command"
          getDeleteItemName={() => getDeleteItemName(commandIdToDelete, commandsModelData.data)}
          onHide={() => setShowCommandDeleteWarning(false)}
          onDelete={() => {
            const formData = { command_id: commandIdToDelete };
            deleteCommand({ device_model_id: ControlSignalVariableDetails?.id, formData });
          }}
        />
      </Components.QIModalBody>
    </Components.QIModal>
  );
};
