import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  AdminRoutes,
  MPRoutes,
  AuthenticationRoutes,
  TrackingRoutes,
  UnAuthenticatedRoutes,
} from "./containers/routes";
import { useCreateAuthMutation } from "./containers/Authentication/service";
import { FMDPStorage } from "./shared/helper";
import { useQuery } from "./hooks";
import { toggleAuthModal } from "./reduxStore/features";
import { FMDPWebSocket } from "./containers/Shared/WebSocket";
import { AuthError } from "./containers/Shared";
import { setWebSocket } from "./reduxStore/features";

let firstLocation = "";
let queryParams = "";

// Add all your unauthenticated routes here to
let UN_AUTHETICATED_ROUTES = ["documentation", "clients"];

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showAuthModal = useSelector((state) => state.common.showAuthModal);

  const query = useQuery();

  const userApp = FMDPStorage.get("selected-app");
  let paths = location.pathname.split("/");
  let subRoute = paths.length > 1 ? paths[1] : "";

  // Cache the location path
  useEffect(() => {
    firstLocation = location.pathname;

    // Filter out query Params
    for (let entry of query.entries()) {
      if (!queryParams) {
        queryParams = `${entry[0]}=${entry[1]}`;
      } else {
        queryParams = `${queryParams}&${entry[0]}=${entry[1]}`;
      }
    }
  }, []);

  const [, { data: userData }] = useCreateAuthMutation({
    fixedCacheKey: "shared-sso-data",
  });

  useEffect(() => {
    if (userData) {
      const ws = new FMDPWebSocket(userData.id);

      ws.subscribe("WebNotificationChannel", (response) => {
        if (response) {
          dispatch(setWebSocket(response));
        }
      });

      ws.subscribe("ForceLogoutNotificationChannel", (response) => {
        if (response.message === "force_logout") {
          dispatch(toggleAuthModal());
        }
      });
    }
  }, [userData]);
  // This effect will help to keep route persistence if page is force re-loaded
  // firstLocation will only change if application get loaded for first time(or when hard refresh)
  useEffect(() => {
    if (userData && userApp) {
      let redirectTo = firstLocation;
      if (queryParams) {
        redirectTo = `${firstLocation}?${queryParams}`;
      }
      navigate(redirectTo);
    }
  }, [userData]);

  return (
    <div className="App">
      {!UN_AUTHETICATED_ROUTES.includes(subRoute) && (
        <>
          {userData && userApp === "SuperAdmin" && <AdminRoutes />}
          {userData && userApp === "ManagementPortal" && <MPRoutes />}
          {userData && userApp == "Tracking" && <TrackingRoutes />}
          {(!userData || !userApp) && <AuthenticationRoutes />}
          {showAuthModal && <AuthError />}
        </>
      )}
      <UnAuthenticatedRoutes />
    </div>
  );
}

export default App;
