import PropTypes from "prop-types";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import sprite from "../../assets/icons.svg";
import { getLocalizedString } from "../../shared/translation";

export const QIDateRangePicker = ({
  label,
  dateFormat,
  start,
  end,
  maxDate,
  minDate,
  onChange,
  dateRangeOptions,
  showCustomRangeLabel,
  disabled = false,
  className,
  setShowCalender,
  resetCount,
}) => {
  const dateLabel = `${start?.format(dateFormat) || "Start "}   -   ${
    end?.format(dateFormat) || "End"
  }`;

  const handleFocus = () => {
    setShowCalender(true);
  };

  const handleBlur = () => {
    setShowCalender(false);
  };

  const handleReset = () => {
    onChange(null, null);
    setShowCalender(false);
  };

  return (
    <div className={`qi-input ${disabled && "disabled"} ${className || ""}`}>
      {label && (
        <label className="qi-input_label" data-testid="input-label">
          {label}
        </label>
      )}
      <div className="qi-input_wrapper date-range-picker">
        <DateRangePicker
          key={resetCount}
          initialSettings={{
            timePicker: true,
            startDate: start?.toDate() || moment().toDate(),
            endDate: end?.toDate() || moment().toDate(),
            ranges: dateRangeOptions,
            maxDate: maxDate,
            minDate: minDate,
            showCustomRangeLabel: showCustomRangeLabel,
            drops: "auto",
          }}
          onCallback={onChange}
          onShow={handleFocus}
          onHide={handleBlur}
        >
          <div id="reportrange" className="date-range">
            <span>{dateLabel}</span>

            <span>
              {start && end && (
                <span className="icon-wrapper clickable" onClick={handleReset}>
                  <svg className={`icon`}>
                    <use href={`${sprite}#cross`}></use>
                  </svg>
                </span>
              )}
            </span>
          </div>
        </DateRangePicker>
      </div>
    </div>
  );
};

QIDateRangePicker.propTypes = {
  label: PropTypes.string,
  start: PropTypes.instanceOf(moment),
  end: PropTypes.instanceOf(moment),
  maxDate: PropTypes.instanceOf(moment),
  minDate: PropTypes.instanceOf(moment),
  onChange: PropTypes.func,
  showCustomRangeLabel: PropTypes.bool,
  dateFormat: PropTypes.string,
};

QIDateRangePicker.defaultProps = {
  label: "",
  maxDate: null,
  minDate: null,
  onChange: () => {},
  dateRangeOptions: {
    "Last 1 hour": [moment().subtract(1, "hour"), moment()],
    "Last 2 hour": [moment().subtract(2, "hour"), moment()],
    "Last 4 hour": [moment().subtract(4, "hour"), moment()],
    "Last 8 hour": [moment().subtract(8, "hour"), moment()],
    "Last 24 hour": [moment().subtract(24, "hour"), moment()],
  },
  showCustomRangeLabel: false,
  dateFormat: "D MMM, YYYY HH:mm",
};
QIDateRangePicker.defaultProps.dateRangeOptions[getLocalizedString("last_one_hour", "")] = [
  moment().subtract(1, "hour"),
  moment(),
];

QIDateRangePicker.defaultProps.dateRangeOptions[getLocalizedString("last_two_hours", "")] = [
  moment().subtract(2, "hours"),
  moment(),
];

QIDateRangePicker.defaultProps.dateRangeOptions[getLocalizedString("last_six_hours", "")] = [
  moment().subtract(6, "hours"),
  moment(),
];

QIDateRangePicker.defaultProps.dateRangeOptions[getLocalizedString("last_twelve_hours", "")] = [
  moment().subtract(12, "hours"),
  moment(),
];

QIDateRangePicker.defaultProps.dateRangeOptions[getLocalizedString("last_twentyfour_hours", "")] = [
  moment().subtract(24, "hours"),
  moment(),
];
