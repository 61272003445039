import { useEffect, useState } from "react";
import { AdvanceSearchWrapper } from "../Shared/";
import { QIButton, QIInput } from "../../../components";

export const VaribaleMappingAdvanceSearch = ({ onSearch, activeSearch }) => {
  const initialValues = {
    name_cont: "",
    device_model_name_cont: "",
    device_model_device_manufacturer_name_cont: "",
  };

  const [formData, setFormData] = useState(initialValues);

  useEffect(() => {
    if (activeSearch) {
      setFormData(activeSearch);
    }
  }, []);

  const clearAdvanceSearch = () => {
    setFormData(initialValues);
    onSearch(null);
  };

  const handleFormChange = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleDateRange = (startTime, endTime) => {
    setFormData((prevState) => ({
      ...prevState,
      created_at_gteq: startTime,
      created_at_lteq: endTime,
    }));
  };

  return (
    <AdvanceSearchWrapper
      activeFilters={activeSearch}
      onClear={clearAdvanceSearch}
      onSearch={() => onSearch(formData)}
    >
      <QIInput
        label="Name"
        value={formData.name_cont}
        onChange={(e) => handleFormChange("name_cont", e.target.value)}
      />
      <QIInput
        label="Device Model"
        value={formData.device_model_name_cont}
        onChange={(e) => handleFormChange("device_model_name_cont", e.target.value)}
      />
      <QIInput
        label="Manufacturer"
        value={formData.device_model_device_manufacturer_name_cont}
        onChange={(e) =>
          handleFormChange("device_model_device_manufacturer_name_cont", e.target.value)
        }
      />
    </AdvanceSearchWrapper>
  );
};
