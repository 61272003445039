import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { MobileServiceProviderFormSchema } from "../validations";
import * as Components from "../../../components";
import { useForm, useDropDownSearch } from "../../../hooks";
import { mergeObjects } from "../../../shared/helper";
import {
  useCreateMSPMutation,
  useGetMSPQuery,
  useGetClientsQuery,
  useGetCountriesQuery,
  useUpdateMSPMutation,
} from "../services";
import { FormContainer } from "../Shared/FormContainer";

export const MSPForm = ({ idToEdit = null, closeForm }) => {
  const [initialValues, setInitialValues] = useState({
    client_id: "",
    name: "",
    country_id: "",
  });

  const [clientDisabled, setClientDisabled] = useState(false);
  const [filteredClients, setFilteredClients] = useState([]);

  const formik = useFormik({
    initialValues,
    validationSchema: MobileServiceProviderFormSchema,
    enableReinitialize: true,
    onSubmit: (value) => handleFormSubmit(value),
  });

  // Get the clients list using Dropdown Search
  const {
    data: clients,
    setSearchKey: setClientSearch,
    isSuccess: isClientsSuccess,
  } = useDropDownSearch({
    useData: useGetClientsQuery,
    simpleSearchKey: "name_or_email_cont",
    selectedIds: formik.values.client_id,
  });

  // Get the countries list using Dropdown Search
  const {
    data: countries,
    setSearchKey: setCountrySearch,
    isSuccess: isCountriesSuccess,
  } = useDropDownSearch({
    useData: useGetCountriesQuery,
    simpleSearchKey: "name_or_dial_code_cont",
    selectedIds: formik.values.country_id,
  });

  const {
    create: createMSP,
    update: updateMSP,
    isLoading,
  } = useForm({
    createMutation: useCreateMSPMutation,
    updateMutation: useUpdateMSPMutation,
    closeForm,
    setError: formik.setErrors,
  });

  // Fetch the data if edit form
  const { data } = useGetMSPQuery({ id: idToEdit }, { skip: !idToEdit });

  // Set the values for edit form
  useEffect(() => {
    if (idToEdit && data) {
      let tempData = { ...data };
      setInitialValues(mergeObjects(formik.initialValues, tempData));
      //If selected client is All then the client field should be disabled
      if (data?.client_id == null || data?.client_id == "") {
        setClientDisabled(true);
      }
    }
  }, [data]);

  // For edit form, if any particular client is chosen then the client dropdown will have only ALL option
  useEffect(() => {
    if (idToEdit && data?.client_id && clients?.data) {
      let tempClient = [];
      clients?.data?.map((client) => {
        if (client?.id === data?.client_id) {
          tempClient.push(client);
        }
      });

      setFilteredClients(tempClient);
    }
  }, [data, clients?.data]);

  const handleFormSubmit = (formData) => {
    if (idToEdit) {
      updateMSP({ id: idToEdit, formData: formData });
    } else {
      createMSP({ formData: formData });
    }
  };

  return (
    <FormContainer
      resourceName="Mobile Service Provider"
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
      error={formik.errors}
      isLoading={isLoading}
    >
      <Components.QICustomSelect
        label="Client"
        {...formik.getFieldProps("client_id")}
        onChange={(value) => {
          formik.setFieldValue("client_id", value);
          setClientSearch("");
        }}
        onSearch={setClientSearch}
        error={formik.touched.client_id && formik.errors.client_id}
        labelClassName="client"
        disabled={clientDisabled}
      >
        <li value="">All</li>
        {filteredClients.length > 0
          ? filteredClients.map((client) => (
              <li key={client.id} value={client.id}>
                {client.name}
              </li>
            ))
          : isClientsSuccess &&
            clients.data.map((client) => (
              <li key={client.id} value={client.id}>
                {client.name}
              </li>
            ))}
      </Components.QICustomSelect>
      <Components.QIInput
        label="Name"
        placeholder="MSP name"
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />

      <Components.QICustomSelect
        label="Country"
        value={formik.values.country_id}
        onChange={(value) => {
          formik.setFieldValue("country_id", value);
          value && formik.setErrors((prevState) => ({ ...prevState, country_id: "" }));
          setCountrySearch("");
        }}
        onSearch={setCountrySearch}
        error={formik.touched.country_id && formik.errors.country_id}
        labelClassName="country"
      >
        <li value="">Select</li>
        {isCountriesSuccess &&
          countries?.data?.map((country) => (
            <li
              key={country?.id}
              value={country?.id}
              title={`${country?.name} (${country?.dial_code})`}
            >
              {country?.name} ({country?.dial_code})
            </li>
          ))}
      </Components.QICustomSelect>
    </FormContainer>
  );
};
