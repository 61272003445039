import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useForm, useDropDownSearch } from "../../../hooks";
import { obdCodeFormValidationSchema } from "../validations";
import * as Components from "../../../components";
import { mergeObjects } from "../../../shared/helper";
import {
  useCreateObdCodeMutation,
  useGetObdCodeQuery,
  useUpdateObdCodeMutation,
  useGetVariablesQuery,
  useGetObdCodesDataTypesQuery,
} from "../services";
import { FormContainer } from "../Shared/FormContainer";

/**
* This is an add/edit form of obd codes
*
* @param {number} [idToEdit:"null"]- A number for the id of the selected edit form and null for the add form
* @param {func} [closeForm] - A function to close the add/edit form

* @example
* <ObdCodeForm
    idToEdit=45
    closeForm={() => setShowForm(false)}
  />
 */

export const ObdCodeForm = ({ idToEdit = null, closeForm }) => {
  //To get the data for the selected obd code in edit form
  const { data } = useGetObdCodeQuery({ id: idToEdit }, { skip: !idToEdit });

  const [initialValues, setInitalValues] = useState({
    name: "",
    code_type: "",
    code: "",
    data_type: "number",
    signed: true,
    size: "",
    start_index: "",
    offset: 0,
    reverse_array: false,
    multiplication_factor: 1,
    target_variable_id: "",
    target_section_id: "",
    mask_text: "",
    boolean_conversion: "",
    compare_value: "",
    use_raw_value: false,
    encoding: "",
    prefix: "",
    sufix: "",
    variable_id: "",
  });

  //To maintain the data type value which can be number/switch/text
  const [dataTypeValue, setDataTypeValue] = useState("number");

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: obdCodeFormValidationSchema,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const { create: createObdCode, update: updateObdCode } = useForm({
    createMutation: useCreateObdCodeMutation,
    updateMutation: useUpdateObdCodeMutation,
    closeForm,
    setError: formik.setErrors,
  });

  // Set form data in case of edit
  useEffect(() => {
    if (idToEdit && data) {
      // Changing initial values will reset the formik
      setDataTypeValue(data?.data_type);
      setInitalValues(mergeObjects(formik.initialValues, data));
    }
  }, [data]);

  const handleFormSubmit = (formData) => {
    const finalFormData = {
      ...formData,
      offset: formData.offset || 0, // By default set offset = 0
      multiplication_factor:
        formData.multiplication_factor === "" ? 1 : formData.multiplication_factor, // By default = 1
    };

    // If idToEdit then it's a edit form
    if (idToEdit) {
      updateObdCode({ formData: finalFormData, id: idToEdit });
    } else {
      createObdCode({ formData: finalFormData });
    }
  };

  // Get the variables list using Dropdown Search
  const {
    data: variables,
    setSearchKey: setVariableSearch,
    isSuccess: isVariableSuccess,
  } = useDropDownSearch({
    useData: useGetVariablesQuery,
    simpleSearchKey: "name_or_display_name_or_section_name_or_unit_name_cont",
    selectedIds: formik.values.target_section_id,
    additionalParams: {
      per_page: 2000,
      order_by: "variables.created_at",
      order_dir: "desc",
      classification_type: ["tracking_data_device", "tracking_data_custom"],
      variable_type: "Number",
    },
  });

  // Get Data Types List in Dropdown
  const { data: dataTypes } = useGetObdCodesDataTypesQuery();

  //Capitalize
  function capitalize(str) {
    return str && str[0].toUpperCase() + str.slice(1);
  }

  return (
    <FormContainer
      resourceName="OBD Code"
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={formik.handleSubmit}
    >
      <Components.QIInput
        label="Name"
        placeholder="Name"
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />
      <Components.QIInput
        label="Code"
        placeholder="0F0005B0"
        {...formik.getFieldProps("code")}
        error={formik.touched.code && formik.errors.code}
      />
      <Components.QICustomSelect
        label="Data Type"
        value={formik.values.data_type}
        onChange={(value) => {
          formik.setFieldValue("data_type", value);
          setDataTypeValue(value);
        }}
        error={formik.touched.data_type && formik.errors.data_type}
      >
        {Object.keys(dataTypes?.data || {}).map((dataType, id) => {
          return (
            <>
              <li value={dataType} key={id}>
                {capitalize(dataType)}
              </li>
            </>
          );
        })}
      </Components.QICustomSelect>
      <Components.QISwitch
        label="Signed"
        {...formik.getFieldProps("signed")}
        onChange={() => formik.setFieldValue("signed", !formik.values.signed)}
        error={formik.touched.signed && formik.errors.signed}
      />
      {/* Data Type Switch and Number */}
      {dataTypeValue !== "text" && (
        <>
          <Components.QIInput
            label="Size in Bit(s)"
            placeholder="3"
            {...formik.getFieldProps("size")}
            error={formik.touched.size && formik.errors.size}
          />
          <Components.QIInput
            label="Start Bit"
            placeholder="3"
            {...formik.getFieldProps("start_index")}
            error={formik.touched.start_index && formik.errors.start_index}
          />
          <Components.QIInput
            label="Offset"
            placeholder="10000"
            {...formik.getFieldProps("offset")}
            error={formik.touched.offset && formik.errors.offset}
          />
          <Components.QIInput
            label="Mul. Factor"
            placeholder="1"
            {...formik.getFieldProps("multiplication_factor")}
            error={formik.touched.multiplication_factor && formik.errors.multiplication_factor}
          />
          <Components.QISwitch
            label="Reverse Array"
            {...formik.getFieldProps("reverse_array")}
            onChange={() => formik.setFieldValue("reverse_array", !formik.values.reverse_array)}
            error={formik.touched.reverse_array && formik.errors.reverse_array}
          />
          <Components.QIInput
            label="Mask"
            placeholder="7B316"
            {...formik.getFieldProps("mask_text")}
            error={formik.touched.mask_text && formik.errors.mask_text}
          />
        </>
      )}
      {/* Data Type Switch and Number End */}
      {/* Data Type Text */}
      {dataTypeValue === "text" && (
        <>
          <Components.QIInput
            label="Size"
            placeholder="3"
            {...formik.getFieldProps("size")}
            error={formik.touched.size && formik.errors.size}
          />
          <Components.QIInput
            label="Start Index"
            placeholder="0/1"
            {...formik.getFieldProps("start_index")}
            error={formik.touched.start_index && formik.errors.start_index}
          />
          <Components.QICustomSelect
            label="Encoding"
            value={formik.values.encoding}
            onChange={(value) => formik.setFieldValue("encoding", value)}
            error={formik.touched.encoding && formik.errors.encoding}
          >
            {["UTF-8", "ASCII", "Unicode"].map((items, id) => (
              <li value={items} key={id}>
                {items}
              </li>
            ))}
          </Components.QICustomSelect>
          <Components.QIInput
            label="Prefix"
            placeholder="7B316"
            {...formik.getFieldProps("prefix")}
          />
          <Components.QIInput
            label="Sufix"
            placeholder="7B316"
            {...formik.getFieldProps("sufix")}
          />
        </>
      )}
      {/* Data Type Text End */}
      {/* Data Type Switch */}
      {dataTypeValue === "switch" && (
        <>
          <Components.QICustomSelect
            label="Boolean Conversion"
            value={formik.values.boolean_conversion}
            onChange={(value) => formik.setFieldValue("boolean_conversion", value)}
            error={formik.touched.boolean_conversion && formik.errors.boolean_conversion}
          >
            {[
              "Equal to",
              "Greater than",
              "Less than",
              "Greater than/equal to",
              "Less than/equal to",
              "Not equal to",
              "None",
            ].map((items, id) => (
              <li value={items} key={id}>
                {items}
              </li>
            ))}
          </Components.QICustomSelect>
          <Components.QIInput
            label="Compare Value"
            placeholder="7B316"
            {...formik.getFieldProps("compare_value")}
            error={formik.touched.compare_value && formik.errors.compare_value}
          />
        </>
      )}
      {/* Data Type Switch End */}
      {/* Data Type Switch and Text */}
      {dataTypeValue !== "number" && (
        <Components.QISwitch
          label="Use Raw Value"
          {...formik.getFieldProps("use_raw_value")}
          onChange={() => formik.setFieldValue("use_raw_value", !formik.values.use_raw_value)}
          error={formik.touched.use_raw_value && formik.errors.use_raw_value}
        />
      )}
      {/* Data Type Switch and Text End */}
      <Components.QICustomSelect
        label="Variable"
        value={formik.values.variable_id}
        onChange={(value) => {
          formik.setFieldValue("variable_id", value);
          setVariableSearch("");
        }}
        onSearch={setVariableSearch}
        labelClassName="variable"
      >
        {variables?.data.map((variable) => (
          <li value={variable.id} key={variable.id} title={variable.name}>
            {variable.display_name} ({variable.section_name}{" "}
            {variable?.unit_name ? ` - ${variable.unit_name}` : ""})
          </li>
        ))}
      </Components.QICustomSelect>
    </FormContainer>
  );
};
