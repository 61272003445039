import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "../../../hooks";
import { useGetDeviceDetailsQuery, useGetAddressQuery } from "../services";
import { filterTrackingData } from "./helper";
import { QIButton, QIExpand, QIMapLoader } from "../../../components";
import { SvgIcon } from "../../Shared";
import { CollapsibleSection } from "./CollapsibleSection";
import "./style.scss";

export const VehicleDetails = () => {
  const { imei } = useParams();
  const navigate = useNavigate();
  const query = useQuery();

  const [vehicleData, setVehicleData] = useState(null);

  const { data: exactVehicleData, isLoading } = useGetDeviceDetailsQuery({
    device_ids: imei,
    manufacturerAndModelName: "Ulbotech",
    fields: "imei,gps_location,car_plate_number,ignition,velocity,position_time",
    q: JSON.stringify({ gps_location: { $not: null } }),
  });

  const { data: location = {}, isLoading: isLoadingLocation } = useGetAddressQuery(
    {
      format: "json",
      lat: vehicleData?.location?.lat,
      lon: vehicleData?.location?.lng,
    },
    {
      skip: !vehicleData?.location?.lat,
    }
  );

  useEffect(() => {
    if (exactVehicleData) {
      const res = filterTrackingData(exactVehicleData.tracking_data, "Ulbotech");
      setVehicleData(res);
    }
  }, [exactVehicleData]);

  const showTrips = () => {
    navigate(`/tracking/track/${imei}?plate_no=${vehicleData.carPlateNumber}`);
  };

  return (
    <QIMapLoader containerId="vehicle-details-map">
      <QIExpand title="Device Details">
        {vehicleData && (
          <div className="t-vehicle-details">
            <div className="vehicle-details_summary">
              <section className="flex items-start">
                <SvgIcon
                  name="back"
                  onClick={() => navigate(-1)}
                  wrapperClass="clickable"
                  svgClass="icon_back"
                />

                <SvgIcon name="vehicle" wrapperClass="clickable" svgClass="icon_vehicle" />

                <div className="w-full">
                  <div className="">
                    <span className="status-icon active"></span>
                    <span className="text-uppercase font-weight-bold">
                      {vehicleData.carPlateNumber || "N/A"}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <SvgIcon name="route" wrapperClass="icon-container p-2" />
                    <span className="font-weight-bold m-0 font-size">
                      {isLoadingLocation && "Loading..."}
                      {!isLoadingLocation && (location.display_name || "N/A")}
                    </span>
                  </div>
                  <div className="flex items-center time-stamp">
                    <SvgIcon name="time" />
                    <small className="p-2">
                      {(vehicleData && vehicleData.positionTime) || (!isLoading && "N/A")}
                    </small>
                  </div>
                </div>
              </section>

              <div className="w-full flex items-center justify-center">
                <QIButton className="btn primary md" onClick={showTrips}>
                  Trips
                </QIButton>
              </div>
            </div>

            <section className="t-vehicle-details_summary">
              {vehicleData && <CollapsibleSection vehicleData={vehicleData} />}
            </section>
          </div>
        )}
      </QIExpand>
    </QIMapLoader>
  );
};
