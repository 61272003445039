import React, { useState } from "react";
import { useSearchQuery, usePagination, useDelete } from "../../../hooks";
import { MPSharedHeader, MPSharedTable, MPSharedDeleteModal } from "../Shared";
import { GeofenceForm } from "./Form";
import { useGetGeofencesQuery, useDeleteGeofenceMutation } from "../services";
import { Link } from "react-router-dom";
import {getLocalizedString} from "../../../shared/translation";

export const GeofenceContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, q} = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_cont",
  });

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetGeofencesQuery({
    page,
    per_page: perPage,
    order_by: "geofences.created_at",
    order_dir: "desc",
    q,
  });

  const {
    deleteItem: deleteBackend,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteGeofenceMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const [idToEdit, setIdToEdit] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  const createNewHandler = () => {
    setShowForm(true);
    setIsNew(true);
    setIdToEdit(null);
  };

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIsNew(false);
    setIdToEdit(datum.id);
  };

  const deleteClickHandler = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const headers = [
    { label: getLocalizedString("name", "Name"), key: "name", className: "name" },
    {
      label: getLocalizedString("events", "Events"),
      nestedValue: true,
      className: "more event-configuration-plans text-center justify-center",

      getNestedValue: ({ event_configuration_count, id, name }) => {
        return (
          <>
            {event_configuration_count >= 0 ? (
              <Link
                to={`/management_portal/configurations/events?geofence_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {event_configuration_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
    },

    // { label: "Added by", key: "created_by", className: "added-by" },
    {
      label: getLocalizedString("added_on", "Added on"),
      key: "created_at",
      className: "date",
    },
  ];

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((datum) => datum.id === id);

    if (index > -1) {
      name = data?.data[index].name;
    }
    return name;
  };

  return (
    <>
      <article className="main-container">
        <MPSharedHeader
          groupName="configurations"
          resourceName="Geofences"
          createNewHandler={createNewHandler}
          heading={getLocalizedString("configurations", "Configurations")}
          handleSearchKey={setSimpleSearch}
          simpleSearchPlaceholder={getLocalizedString("search_by_name", "Search by Name")}
          // heading="Geofences"
          onEdit={editClickHandler}
        />
        <MPSharedTable
          resourceName="Geofences"
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          headers={headers}
          data={data.data}
          pagination={{ ...pagination, count: data.total_count }}
          onEdit={editClickHandler}
          onDelete={deleteClickHandler}
          className="geofence"
          auditResource="Geofence"
          auditKey="name"
          skipClientId
        />
      </article>

      {showForm && (
        <GeofenceForm isNew={isNew} idToEdit={idToEdit} closeForm={() => setShowForm(false)} />
      )}

      <MPSharedDeleteModal
        show={showDeleteWarning}
        entityName={getLocalizedString("geofence_lowercase", "geofence")}
        error={deleteErrorMsg}
        itemName={getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg();
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteBackend({ id: idToDelete })}
      />
    </>
  );
};
