import { useEffect, useState } from "react";
import moment from "moment";
import { AdvanceSearchWrapper } from "../Shared/";
import {
  QIDateRangePicker,
  QIInput,
  QISwitch,
  QIMultiSelectDropDown,
  QICustomSelect,
} from "../../../components";
import { useGetBackendsQuery, useGetGeolocationsQuery } from "../services";
import { useDropDownSearch } from "../../../hooks";
import { getLocalizedString } from "../../../shared/translation";

export const DeviceAdvanceSearch = ({ onSearch, activeSearch }) => {
  const initialValues = {
    name_cont: "",
    device_id_cont: "",
    filter_device_ph_with_country_code_cont: "",
    simcard_no_eq: "",
    firmware_version_cont: "",
    created_at_gteq: null,
    created_at_lteq: null,
    enabled_eq: "",
    backend_ids_contains: [],
    geocoder_ids: [],
  };

  let searchLabels = {
    name_cont: "Name",
    device_id_cont: "ID/IMEI",
    filter_device_ph_with_country_code_cont: "Simcard/Phone",
    firmware_version_cont: "Firmware",
    created_at_gteq: "Start date",
    created_at_lteq: "End date",
    enabled_eq: "Enabled",
    backend_ids_contains: null,
    geolocation_plan_id_eq: null,
  };

  const dropmenuQueryParamsBackends = { per_page: 1000, order_by: "backends.name" };
  const dropmenuQueryParamsGeolocations = { per_page: 1000, order_by: "geolocation_plans.name" };

  const [formData, setFormData] = useState(initialValues);
  const [labelData, setLabelData] = useState(searchLabels);
  const [showCalender, setShowCalender] = useState(false);
  const [resetCount, setResetCount] = useState(0);

  // Get the geolocation plan list using Dropdown Search
  const {
    data: geolocations,
    setSearchKey: setGeolocationPlanSearchKey,
    isSuccess: geolocationsSuccess,
  } = useDropDownSearch({
    useData: useGetGeolocationsQuery,
    simpleSearchKey: "name_cont",
    additionalParamsToHook: dropmenuQueryParamsGeolocations,
    selectedIds: formData.geolocation_plan_id_eq,
  });

  // Get the backends list using Dropdown Search
  const {
    data: backends,
    setSearchKey: setBackendsSearchKey,
    isSuccess: backendsSuccess,
  } = useDropDownSearch({
    useData: useGetBackendsQuery,
    simpleSearchKey: "name_or_backend_type_cont",
    selectedIds: formData.backend_ids_contains,
    additionalParams: { ...dropmenuQueryParamsBackends },
  });

  useEffect(() => {
    if (activeSearch) {
      setFormData(activeSearch);
    }
  }, []);

  const handleFormChange = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const clearAdvanceSearch = () => {
    setResetCount((i) => i + 1);
    setFormData(initialValues);
    onSearch(null);
    setShowCalender(false);
  };

  const handleDateRange = (startTime, endTime) => {
    setFormData((prevState) => ({
      ...prevState,
      created_at_gteq: startTime,
      created_at_lteq: endTime,
    }));
    setResetCount((prevCount) => prevCount + 1);
  };

  //To add the dropdowns selected
  const handleDropdownChange = (dropdownData, chosenId, key, labelName) => {
    dropdownData.map((datum) => {
      //Here we wont strictly type check as the updatedList is a string and the other is an integer
      if (datum.id === chosenId) {
        setLabelData((prevState) => ({ ...prevState, [key]: [labelName, datum.name] }));
      }
    });
  };

  return (
    <AdvanceSearchWrapper
      activeFilters={activeSearch}
      // searchLabels={labelData}
      onClear={clearAdvanceSearch}
      onSearch={() => onSearch(formData)}
      showCalender={showCalender}
      setShowCalender={setShowCalender}
    >
      <QIInput
        label={getLocalizedString("name", "Name")}
        className=""
        value={formData.name_cont}
        onChange={(e) => handleFormChange("name_cont", e.target.value)}
      />
      <QIInput
        label="ID/IMEI"
        className=""
        value={formData.device_id_cont}
        onChange={(e) => handleFormChange("device_id_cont", e.target.value)}
      />
      <QIInput
        label={getLocalizedString("primary_sim_card", "Primary SIM Card")}
        className=""
        value={formData.simcard_no_eq}
        onChange={(e) => handleFormChange("simcard_no_eq", e.target.value)}
      />
      <QIInput
        label={getLocalizedString("phone_no", "Phone No")}
        className=""
        value={formData.filter_device_ph_with_country_code_cont}
        onChange={(e) =>
          handleFormChange("filter_device_ph_with_country_code_cont", e.target.value)
        }
      />
      <QIInput
        label={getLocalizedString("firmware", "Firmware")}
        className=""
        value={formData.firmware_version_cont}
        onChange={(e) => handleFormChange("firmware_version_cont", e.target.value)}
      />
      <QISwitch
        label={getLocalizedString("enabled", "Enabled")}
        value={formData.enabled_eq}
        onChange={() => handleFormChange("enabled_eq", !formData.enabled_eq)}
      />
      <QIMultiSelectDropDown
        label={getLocalizedString("backends", "Backends")}
        className=""
        data={backends?.data || []}
        selected={formData.backend_ids_contains}
        onChange={(updatedList) => {
          // handleDropdownChange(backends.data, updatedList, "backend_ids_contains", "Backends")
          handleFormChange("backend_ids_contains", updatedList);
          setBackendsSearchKey("");
        }}
        disabled={!backendsSuccess}
        onSearch={(value) => setBackendsSearchKey(value)}
      />

      <QICustomSelect
        label={getLocalizedString("geolocation_plan", "Geolocation Plan")}
        value={formData.geolocation_plan_id_eq}
        onChange={(updatedList) => {
          handleFormChange("geolocation_plan_id_eq", updatedList);
          setGeolocationPlanSearchKey("");
        }}
        onSearch={(value) => setGeolocationPlanSearchKey(value)}
        labelClassName="geolocation-plan"
      >
        <li value="">{getLocalizedString("select", "Select")}</li>
        {geolocationsSuccess &&
          (geolocations?.data || []).map((g) => (
            <li value={g.id} key={g.id}>
              {g.name}
            </li>
          ))}
      </QICustomSelect>

      <QIDateRangePicker
        start={formData.created_at_gteq}
        end={formData.created_at_lteq}
        label={getLocalizedString("created_between", "Created b/w")}
        maxDate={moment()}
        onChange={handleDateRange}
        setShowCalender={setShowCalender}
        resetCount={resetCount}
      />
    </AdvanceSearchWrapper>
  );
};
