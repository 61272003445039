import { Map, View, Overlay, Feature } from 'ol';
import * as Proj from 'ol/proj';
import Projection from 'ol/proj/Projection';
import { KML, Polyline } from 'ol/format';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import Circle from 'ol/geom/Circle';
import Polygon from 'ol/geom/Polygon';
import { defaults as DefaultControl, ScaleLine, FullScreen } from 'ol/control';
import { defaults as DefaultsInteraction, DragPan, MouseWheelZoom } from 'ol/interaction';
import { Style, Stroke, Fill, Circle as circleStyle, Icon, Text } from 'ol/style';
import { Tile as TileLayer, Vector as VectorLayer, Group as GroupLayer } from 'ol/layer';
import { OSM as OSMSource, TileWMS as TileWMSSource, Vector as VectorSource, Cluster as ClusterSource, XYZ } from 'ol/source';
import { unByKey } from 'ol/Observable';
import LayerSwitcher from 'ol-layerswitcher';
import 'ol/ol.css';
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import './../css/ol5.css';
import './../css/map.css';

export default class qiMap {
  constructor(mapObj) {
    this.baseLayers = {
      OSM: new TileLayer({
        name: 'OSM_BASE',
        title: 'OSM',
        type: 'base',
        visible: true,
        source: new OSMSource(),
      }),
      GOOGLE: new VectorLayer({
        name: 'GOOGLE_BASE',
        title: 'Google',
        type: 'base',
        visible: true,
        source: new VectorSource(),
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.6)',
          }),
          stroke: new Stroke({
            color: '#319FD3',
            width: 1,
          }),
        }),
      }),
    };
    this.mapBoxLibraryConfig = {
      js: 'https://api.mapbox.com/mapbox-gl-js/v1.9.1/mapbox-gl.js',
      css: 'https://api.mapbox.com/mapbox-gl-js/v1.9.1/mapbox-gl.css',
    };
    this.iconBasePath = 'http://sg.trip-staging.mapsynq.com/assets/mapicons_v2/';
    this.layer = {};
    this.liveOverlay = {};
    this.liveInterval = {};
    this.layerList = [];
    this.liveTrackCheckDurationInMS = mapObj.liveTrackCheckDurationInMS || 10 * 60 * 1000;
    this._validateInitialMapObject(mapObj);
    this._validateAndIncludeGoogleMapKey(mapObj);
    this._validateAndIncludeMapbox(mapObj);
    this.container = document.getElementById(mapObj.containerId);
    this.container.style.width = '100%';
    this.container.style.height = '100%';
    this.currentCountry = mapObj.currentCountry;
    this.mapBaseLayer = mapObj.baseLayer;
    this.defaultBaseLayer = mapObj.defaultBaseLayer || 'OSM';
    this.fullScreenController = typeof mapObj.fullScreenController == 'boolean' ? mapObj.fullScreenController : true;
    this.liveTrackInterval = {
      maxRefreshCount: 5,
    };

    this.dataFormat = {
      lat: (mapObj.dataFormat && mapObj.dataFormat.lat) || 'lat',
      lon: (mapObj.dataFormat && mapObj.dataFormat.lon) || 'lon',
      time: (mapObj.dataFormat && mapObj.dataFormat.time) || 'time',
    };

    this.googleMapStyleObj = mapObj.googleStyle;

    this.classList = {
      layer: {
        Tile: TileLayer,
        Vector: VectorLayer,
      },
      source: {
        OSM: OSMSource,
        TileWMS: TileWMSSource,
        Vector: VectorSource,
        XYZ,
      },
    };
    this.gridViewData = {};
    this.registeredEvents = {};
  }

  /**
   * Validate initial map object
   * @param {Object} mapObj - Mandatory mapObj need to add
   * @param {string} mapObj.containerId - An unique container id is mandatory to provide
   * @param {Object} mapObj.currentCountry - Mandatory current country config need to provide
   * @param {string} mapObj.currentCountry.center - Country config need to have center
   * @param {string} mapObj.currentCountry.minZoom - Country config need to have minZoom
   * @param {string} mapObj.currentCountry.zoom - Country config need to have zoom
   * @param {string} mapObj.currentCountry.maxZoom - Country config need to have maxZoom
   * @param {array} mapObj.baseLayer - Mandatory base layer need to provide e.g ['OSM']
   */
  _validateInitialMapObject(mapObj) {
    if (!mapObj.containerId) {
      throw new Error('Invalid containerId');
    }

    if (!mapObj.currentCountry) {
      throw new Error('Invalid currentCountry info');
    }

    if (
      mapObj.currentCountry &&
      (!mapObj.currentCountry.center || !mapObj.currentCountry.minZoom || !mapObj.currentCountry.zoom || !mapObj.currentCountry.maxZoom)
    ) {
      throw new Error('Invalid current country configuration data, please make sure center, minZoom, zoom, maxZoom properties are present');
    }

    if (!mapObj.baseLayer) {
      throw new Error("Invalid base layer, you need to pass base layers e.g ['OSM']");
    }
  }

  /**
   * If baselayer google google key will be added
   * @param {Object} mapObj - Mandatory mapObj need to add
   * @param {array} mapObj.baseLayer - Mandatory base layer should be GOOGLE e.g ['GOOGLE']
   * @param {array} mapObj.googleApiKey - Valid API key need to be provided
   */
  _validateAndIncludeGoogleMapKey(mapObj) {
    const isGoogleMapABaseLayer = mapObj.baseLayer.indexOf('GOOGLE') > -1;
    const googleApiScript = document.querySelector('script[src^="https://maps.googleapis.com/maps/api/js"]');

    if (googleApiScript == null) {
      if (mapObj.googleApiKey && isGoogleMapABaseLayer) {
        const newScript = document.createElement('script');
        newScript.src = `https://maps.googleapis.com/maps/api/js?v=3.37.2&key=${mapObj.googleApiKey}`;
        document.head.appendChild(newScript);
      } else {
        if (isGoogleMapABaseLayer) {
          throw new Error('Google map api key not found');
        }
      }
    }
  }

  /**
   * If baselayer Mapbox mapbox key will be added
   * @param {Object} mapObj - Mandatory mapObj need to add
   * @param {array} mapObj.baseLayer - Mandatory base layer should be MAPBOX e.g ['MAPBOX']
   * @param {String} mapObj.mapBoxStyleUrl - Valid API style url need to be provided
   */
  _validateAndIncludeMapbox(mapObj) {
    const { baseLayer, mapBoxStyleUrl: styleUrl } = mapObj;
    const isMapboxABaseLayer = baseLayer.indexOf('MAPBOX') > -1;

    if (isMapboxABaseLayer && styleUrl) {
      if (styleUrl.indexOf('api.mapbox.com/styles') > -1) {
        /* Add Script tag to load Mapbox */
        const mapBoxScript = document.createElement('script');
        mapBoxScript.src = this.mapBoxLibraryConfig.js;
        document.head.appendChild(mapBoxScript);
        const mapBoxCSS = document.createElement('link');
        mapBoxCSS.href = this.mapBoxLibraryConfig.css;
        mapBoxCSS.rel = 'stylesheet';
        document.head.appendChild(mapBoxCSS);
        /* Add required source properties to load Mapbox styles with OL */
        this.baseLayers.MAPBOX = new TileLayer({
          name: 'MAPBOX',
          title: 'Mapbox',
          type: 'base',
          visible: true,
          source: new XYZ({
            url: styleUrl,
            attributions:
              '© <a href="https://www.mapbox.com/map-feedback/">Mapbox</a> © <a href="https://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>',
            attributionsCollapsible: false,
          }),
        });
      } else {
        throw new Error(
          'Invalid mapbox style url. It should have format like: api.mapbox.com/styles. More Info: https://docs.mapbox.com/help/glossary/styles-api/'
        );
      }
    } else {
      if (isMapboxABaseLayer) {
        throw new Error('Mapbox style url key not found');
      }
    }
  }

  /**
   * Validate default base layer
   * @param {string} defaultBaseLayer - Base layer should be OSM, MAPBOX, GOOGLE
   */
  _validateDefaultBaseLayer(defaultBaseLayer) {
    let defaultBaseLayerValidate = false;

    this.mapBaseLayer.forEach((baseLayerItem) => {
      if (typeof baseLayerItem == 'string') {
        if (baseLayerItem == defaultBaseLayer) {
          defaultBaseLayerValidate = true;
        }
      } else if (typeof baseLayerItem == 'object') {
        if (baseLayerItem['title'] == defaultBaseLayer) {
          defaultBaseLayerValidate = true;
        }
      }
    });

    if (!defaultBaseLayerValidate) {
      throw new Error('OSM,GOOGLE,MAPBOX or custom baseLayer can be selected as default baselayers');
    }
  }

  /**
   * Provided baseLayer will be added if not default base Layer will be added
   * @param {array} baseLayer - Mandatory base layer should be GOOGLE e.g ['GOOGLE', 'OSM']
   */
  _addDefaultBaseLayers(baseLayer) {
    if (baseLayer) {
      if (Array.isArray(baseLayer) && baseLayer.length > 0) {
        let baseLayerArray = [];
        baseLayer.forEach((baseLayerItem) => {
          if (typeof baseLayerItem == 'string') {
            Object.keys(this.baseLayers).includes(baseLayerItem)
              ? baseLayerArray.push(this.baseLayers[baseLayerItem])
              : console.warn('OSM,GOOGLE,MAPBOX or custom baseLayer can be selected as default baselayers');
          }
        });
        return baseLayerArray;
      } else if (Object.keys(this.baseLayers).includes(baseLayer)) {
        return [this.baseLayers[baseLayer]];
      } else {
        console.warn('OSM,GOOGLE,MAPBOX or custom baseLayer can be selected as default baselayers');
      }
    }
  }

  /**
   * Add new layer
   * @param {Object} layer - layer object
   * @param {string} layer.name - layer name
   * @param {string} layer.title - layer title
   * @param {string} layer.type - layer type
   * @param {string} layer.layerType - layer type
   * @param {boolean} layer.visible - layer visibility
   * @param {string} layer.source - layer source
   * @param {string} layer.sourceType - layer source type
   */
  _newLayer(layer) {
    const { name, title, type, layerType, visible, source, sourceType } = layer;

    return new this.classList['layer'][layerType]({
      name: name || '',
      title: title || '',
      type: type,
      visible: visible || false,
      source: new this.classList['source'][sourceType](source),
    });
  }

  /**
   * Add new base layer
   * @param {Object} layer - layer object
   * @param {string} layer.name - layer name
   * @param {string} layer.title - layer title
   * @param {string} layer.type - layer type
   * @param {boolean} layer.visible - layer visibility
   * @param {string} layer.source - layer source
   */
  _newBaseLayer(layer) {
    this.map.getLayerGroup().getLayers().item(0).getLayers().push(layer);
  }

  /**
   * Add vector layer
   * @param {Object} layer - layer object
   * @param {string} layer.name - layer name
   */
  _addVectorLayer(layer) {
    let newLayer = new VectorLayer({
      name: layer.name,
      source: layer.source || new VectorSource(),
    });

    this.layer[layer.name] = newLayer;
    this.map.addLayer(newLayer);
  }

  /**
   * Add to layer list
   * @param {Object} layer - layer object
   * @param {string} layer.name - layer name
   */
  _addToLayerList(layer) {
    this._checkUniqueLayer(layer);
    if (layer.name) {
      this.layerList.push(layer.name);
    } else {
      throw new Error('Layer object should have an unique name');
    }
  }

  /**
   * Remove layer from layer list
   * @param {Object} layer - layer object
   */
  _removeLayerList(layer) {
    this.layerList = this.layerList.filter((l) => l !== layer.get('name'));
  }

  /**
   * Unique layer should be added in layer list
   * @param {Object} layer - layer object
   */
  _checkUniqueLayer(layer) {
    if (this.layerList.indexOf(layer.name) > -1) {
      throw new Error('Each layer should have a unique name');
    }
  }

  /**
   * Add new layer
   * @param {Object} layer - layer object
   */
  _addNewLayer(layer) {
    if (this.layer[layer.name]) {
      return false;
    }
    this._addToLayerList(layer);
    this._newBaseLayer(this._newLayer(layer));
    LayerSwitcher.ensureTopVisibleBaseLayerShown_(this.map);
  }

  /**
   * Add new vector layer
   * @param {Object} layer - layer object
   */
  _addNewVectorLayer(layer) {
    if (this.layer[layer.name]) {
      return false;
    }
    this._addToLayerList(layer);
    this._addVectorLayer(layer);
  }

  /**
   * Add layer
   * @param {Object} layer - layer object
   * @param {Object} option - Some other option
   * @param {boolean} option.addInLayerSwitcher - Check if need to add in layer switcher
   */
  addLayer(layers, option) {
    if (option && option.addInLayerSwitcher) {
      if (Array.isArray(layers) && layers.length > 0) {
        layers.forEach((layer) => {
          this._addNewLayer(layer);
        });
      } else {
        this._addNewLayer(layers);
      }
    } else {
      if (Array.isArray(layers) && layers.length > 0) {
        layers.forEach((layer) => {
          this._addNewVectorLayer(layer);
        });
      } else {
        this._addNewVectorLayer(layers);
      }
    }
  }

  /**
   * show layer by name
   * @param {string} layerName - layer name
   */
  showLayer(layerName) {
    this.layer[layerName].setVisible(true);
  }

  /**
   * hide layer by name
   * @param {string} layerName - layer name
   */
  hideLayer(layerName) {
    this.layer[layerName].setVisible(false);
  }

  /**
   * Delete layer
   * @param {Object} layer - layer object
   * @param {string} layerToRemove - layer name
   */
  deleteLayer(layer, layerToRemove) {
    this._removeLayerList(layer);
    this.map.removeLayer(layer);
    this._removeOverlay(layerToRemove);
    document.querySelectorAll('.end-marker-' + layerToRemove).forEach((elem) => {
      elem.remove();
    });
  }

  /**
   * Remove layer
   * @param {string} removedBy - layer name
   * @param {string/array} layerToRemove - layer name or array
   */
  removeLayer(layersToRemove, removedBy = 'name') {
    let hasLayerNameMatched = false;
    if (layersToRemove) {
      this.map.getLayers().forEach((layer) => {
        if (layer) {
          if (Array.isArray(layersToRemove) && layersToRemove.length > 0) {
            layersToRemove.forEach((layersToRemoveItem) => {
              if (layer.get(removedBy) && layer.get(removedBy) === layersToRemoveItem) {
                this.deleteLayer(layer, layersToRemoveItem);
                const clusterLayer = removedBy + 'Cluster';
                if (this.layer[clusterLayer]) {
                  this.deleteLayer(clusterLayer, layersToRemoveItem);
                }
              }
            });
          } else if (layer.get(removedBy) && layer.get(removedBy) === layersToRemove) {
            this.deleteLayer(layer, layersToRemove);
            const clusterLayer = removedBy + 'Cluster';
            if (this.layer[clusterLayer]) {
              this.deleteLayer(clusterLayer, layersToRemoveItem);
            }
          }
        }
      });
    } else if (!hasLayerNameMatched) {
      console.warn('Invalid layer name passed or you are trying to remove base layer!');
    } else {
      throw new Error('No layer name passed as function argument');
    }
  }

  /**
   * Show tooltip
   * @param {Object} e - event
   */
  _showTooltip(e) {
    this.map.forEachFeatureAtPixel(
      e.pixel,
      (featureAtPixel) => {
        if (featureAtPixel) {
          const featureArray = featureAtPixel.get('features');
          if (featureArray && Array.isArray(featureArray)) {
            for (var i = 0; i < featureArray.length; i++) {
              const tooltip = featureArray[i].get('tooltip');
              if (tooltip) {
                let popupContent;
                if (tooltip.content) {
                  popupContent = tooltip.content(featureArray, Proj.transform(e.coordinate, 'EPSG:3857', 'EPSG:4326'));
                }
                popupContent && this._showOverlay(popupContent, e.coordinate, tooltip.style);
                break;
              } else {
                this._hideOverlay();
              }
            }
          } else if (featureAtPixel.get('tooltip') || (Array.isArray(featureArray) && featureArray[0] && featureArray[0].get('tooltip'))) {
            const singleFeature = featureAtPixel.get('tooltip') ? featureAtPixel : featureArray[0];
            const tooltip = singleFeature.get('tooltip');
            if (tooltip) {
              let popupContent;
              if (tooltip.content) {
                popupContent = tooltip.content(singleFeature, Proj.transform(e.coordinate, 'EPSG:3857', 'EPSG:4326'));
              }
              popupContent && this._showOverlay(popupContent, e.coordinate, tooltip.style);
            } else {
              this._hideOverlay();
            }
          }
        } else {
          this._hideOverlay();
        }
      },
      { checkWrapped: false }
    );
  }

  /**
   * Create map
   * @param {Object} baseLayerInfo - base layer
   */
  createMap(baseLayerInfo) {
    const baseLayer = baseLayerInfo || this.mapBaseLayer;
    let userSelectedBaseLayer = localStorage.getItem('qi_map_base_layer');
    if (!userSelectedBaseLayer) {
      userSelectedBaseLayer = this.defaultBaseLayer;
      localStorage.setItem('qi_map_base_layer', this.defaultBaseLayer);
    }

    if (!baseLayer) {
      throw new Error("You need to pass base layers e.g ['OSM']");
      return;
    }

    if (!document.getElementById('olmap')) {
      this.container.insertAdjacentHTML('beforeend', '<div id="olmap" class="fill"></div>');
    }

    const isValidUserSelection = baseLayer.findIndex((el) => {
      if (typeof el == 'string') {
        return el.toLowerCase() == userSelectedBaseLayer.toLowerCase();
      } else if (typeof el == 'object') {
        return el['title'].toLowerCase() == userSelectedBaseLayer.toLowerCase();
      } else {
        return -1;
      }
    });

    if (isValidUserSelection == -1) {
      localStorage.setItem('qi_map_base_layer', this.defaultBaseLayer);
    }

    this.view = new View({
      maxZoom: this.currentCountry.maxZoom && this.currentCountry.maxZoom <= 21 ? this.currentCountry.maxZoom : 21,
      minZoom: this.currentCountry.minZoom || 0,
      zoom: this.currentCountry.zoom,
      constrainResolution: true,
    });

    this.map = new Map({
      target: 'olmap',
      controls: DefaultControl({
        attributionOptions: /** @type {olx.control.AttributionOptions} */ ({
          collapsible: false,
        }),
      }).extend([new ScaleLine()]),
      interactions: DefaultsInteraction({
        altShiftDragRotate: false,
        dragPan: false,
        pinchRotate: false,
        pinchZoom: false,
        zoomDuration: 0,
        mouseWheelZoom: false,
      }).extend([
        new DragPan({ kinetic: null }),
        new MouseWheelZoom({
          constrainResolution: true,
        }),
      ]),
      layers: [
        new GroupLayer({
          title: 'Base maps',
          layers: this._addDefaultBaseLayers(baseLayer),
        }),
      ],
      view: this.view,
    });

    if (this.fullScreenController) {
      this.map.controls.extend([new FullScreen()]);
    }

    this.map.on('pointermove', (e) => {
      this.pointerLocation = e;
    });

    this.view.setCenter(this.currentCountry.center);
    this.view.setZoom(this.currentCountry.zoom);
    if (!baseLayer || (Array.isArray(baseLayer) && baseLayer.length > 1)) {
      this._addLayerSwitcher();
      this._activateLayerSwitcherCheck();
      LayerSwitcher.ensureTopVisibleBaseLayerShown_(this.map);
    }
    // add speed layer
    if (this.currentCountry.speed_layer) {
      this._addSpeedLayer();
    }

    // add overlay
    if (baseLayer.indexOf('GOOGLE') > -1 && localStorage.getItem('qi_map_base_layer').toLowerCase() === 'google') {
      this.view.on('change:center', () => this._changeGMapCenter());
      this.view.on('change:resolution', () => this._changeGMapResolution());
      const googleMapReference = document.querySelector('gmap');
      if (googleMapReference == null) {
        this._createGoogleMap();
      }
    }
    this._validateAndIncludeBaseLayerObject();
    this._validateDefaultBaseLayer(this.defaultBaseLayer);
    this._setDefaultLayer(this.map);
  }

  /**
   * Set default base layer
   * @param {Object} map - reference of this.map
   */
  _setDefaultLayer(map) {
    let lastVisibleBaseLyr;
    let qiMapBaseLayer = this.defaultBaseLayer;

    LayerSwitcher.forEachRecursive(map, function (l) {
      if (l.get('type') === 'base' && l.get('title').toUpperCase() === qiMapBaseLayer) {
        lastVisibleBaseLyr = l;
      }
    });

    if (lastVisibleBaseLyr) {
      LayerSwitcher.setVisible_(map, lastVisibleBaseLyr, true);
    }
  }

  /**
   * Set base layer from object
   */
  _validateAndIncludeBaseLayerObject() {
    this.mapBaseLayer.forEach((baseLayerItem) => {
      if (typeof baseLayerItem == 'object') {
        if (baseLayerItem['type'] == 'base') {
          this._activateLayerSwitcherCheck();
          LayerSwitcher.ensureTopVisibleBaseLayerShown_(this.map);
          this._addNewLayer(baseLayerItem);
        } else {
          throw new Error('Invalid layertype. For custom layer layer type should be base');
        }
      }
    });
  }

  /**
   * Create google map
   */
  _createGoogleMap() {
    if (!document.getElementById('gmap')) {
      this.container.insertAdjacentHTML('beforeend', '<div id="gmap" class="fill"></div>');
    }

    this.gmap = new google.maps.Map(document.getElementById('gmap'), {
      disableDefaultUI: true,
      keyboardShortcuts: false,
      disableDoubleClickZoom: true,
      panControl: false,
      streetViewControl: false,
      gestureHandling: 'none',
      rotateControl: false,
      scaleControl: false,
      zoomControl: false,
      styles: this.googleMapStyleObj || [],
    });

    this.gmap.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('olmap'));
    // add google traffic layer
    this._addGoogleTrafficLayer();
    // // Google street view on/off event
    this._addGoogleStreetView();
    // // call the change:center, and change:resolution manually
    this._changeGMapCenter();
    this._changeGMapResolution();
  }

  /**
   * add google street view
   */
  _addGoogleStreetView() {
    var thePanorama = this.gmap.getStreetView();
    google.maps.event.addListener(thePanorama, 'visible_changed', function () {
      if (thePanorama.getVisible()) {
        document.getElementsByClassName('google_street_toggle').style.display = 'block';
      } else {
        document.getElementsByClassName('google_street_toggle').style.display = 'none';
      }
    });
  }

  /**
   * add google traffic layer
   */
  _addGoogleTrafficLayer() {
    this.container.insertAdjacentHTML(
      'beforeend',
      `<a id="trafficToggle" class="google_traffic">
        <img src="${this.iconBasePath}traffic.png" />
      </a>`
    );

    this.trafficLayer = new google.maps.TrafficLayer();

    google.maps.event.addDomListener(document.getElementById('trafficToggle'), 'click', this._toggleTraffic.bind(this));
  }

  /**
   * show hide google traffic layer
   */
  _toggleTraffic() {
    if (this.trafficLayer.getMap() == null) {
      this.trafficLayer.setMap(this.gmap);
    } else {
      this.trafficLayer.setMap(null);
    }
  }

  /**
   * change google map zoom
   */
  _changeGMapResolution() {
    if (this.gmap) {
      this.gmap.setZoom(this.view.getZoom());
    }
  }

  /**
   * change google map center
   */
  _changeGMapCenter() {
    if (this.gmap) {
      const center = Proj.transform(this.view.getCenter(), 'EPSG:3857', 'EPSG:4326');
      this.gmap.setCenter(new google.maps.LatLng(center[1], center[0]));
    }
  }

  /**
   * add layer switcher over map
   */
  _addLayerSwitcher() {
    this.map.addControl(
      new LayerSwitcher({
        tipLabel: 'Layer Switcher',
      })
    );
  }

  _activateLayerSwitcherCheck() {
    LayerSwitcher.ensureTopVisibleBaseLayerShown_ = (map) => {
      let lastVisibleBaseLyr;
      const qiMapBaseLayer = localStorage.getItem('qi_map_base_layer');
      LayerSwitcher.forEachRecursive(map, function (l, idx, a) {
        if (l.get('type') === 'base' && l.get('title') === qiMapBaseLayer) {
          lastVisibleBaseLyr = l;
        }
      });
      if (lastVisibleBaseLyr) {
        LayerSwitcher.setVisible_(map, lastVisibleBaseLyr, true);
      }
    };

    LayerSwitcher.setVisible_ = (map, lyr, visible) => {
      lyr.setVisible(visible);
      if (visible && lyr.get('type') === 'base') {
        const oldBaseLayer = localStorage.getItem('qi_map_base_layer');
        const currentBaseLayer = lyr.get('title');
        if (oldBaseLayer != currentBaseLayer) {
          localStorage.setItem('qi_map_base_layer', currentBaseLayer);
          this.switchMap(oldBaseLayer, currentBaseLayer);
        }
        // Hide all other base layers regardless of grouping
        LayerSwitcher.forEachRecursive(map, function (l, idx, a) {
          if (l != lyr && l.get('type') === 'base') {
            l.setVisible(false);
          }
        });
      }
    };
  }

  /**
   * Add speed layer
   */
  _addSpeedLayer() {
    this.layer.speed = new VectorLayer({
      source: new VectorSource({
        format: new KML({
          extractStyles: true,
          extractAttributes: true,
        }),
      }),
      name: 'trafficSpeed',
    });
    this.layer.speed.setVisible(false);
    this.map.addLayer(this.layer.speed);
  }

  /**
   * Show overlay
   * @param {string} content - overlay content
   * @param {array} coordinate - coordinate
   * @param {Object} style - custom style
   */
  _showOverlay(content, coordinate, style = {}) {
    this._setOverlay();
    this.overlayContent.innerHTML = content;
    this.overlay.setPosition(coordinate);
    this.overlay.get('element').style.cssText = style;
    this.overlay.get('element').style.display = 'block';
  }

  /**
   * hide overlay
   */
  _hideOverlay() {
    this.overlay.setPosition(undefined);
    return false;
  }

  /**
   * Remove overlay
   * @param {Object} layer - layer object
   */
  _removeOverlay(layer) {
    this.liveOverlay[layer] && this.map.removeOverlay(this.liveOverlay[layer]);
  }

  /**
   * Init overlay handler
   */
  _initOverlayHandler() {
    // marked location popup, edit link sends ajax request
    $('#popup a[data-remote]')
      .not('a[data-confirm]')
      .on('click', (event) => {
        event.preventDefault();
        if (this.dataset.method == 'put') {
          $.get(this.href, null, null, 'script');
          this.closePopup();
          return false;
        }
      });

    $('#popup a[data-remote-modal]').on('click', function (event) {
      event.preventDefault();
      qi.Modal.showRemoteModal(this.href, this.dataset.title, this.dataset.modalClass);
    });

    $('#popup a[data-confirm]').on('click', function (event) {
      event.preventDefault();
      qi.Modal.showCustomConfirm(this);
    });

    $('#popup .bookmark').on('click', function (event) {
      event.preventDefault();
      qi.MarkedDrive.showForm(this);
    });
  }

  /**
   * Set overlay
   */
  _setOverlay() {
    /**
     * Elements that make up the popup.
     */
    if (!document.getElementById('popup')) {
      this.container.parentNode.insertAdjacentHTML(
        'beforeend',
        `<div id="popup" class="ol-popup">
            <a href="#" id="popup-closer" class="ol-popup-closer"></a>
            <div id="popup-content"></div>
          </div>`
      );
    }

    this.overlayContainer = document.getElementById('popup');
    this.overlayCloser = document.getElementById('popup-closer');
    this.overlayContent = document.getElementById('popup-content');

    this.overlayCloser.onclick = () => {
      this.overlay.setPosition(undefined);
      return false;
    };

    this.overlay = new Overlay({
      element: this.overlayContainer,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    this.map.addOverlay(this.overlay);
  }

  /**
   * Close pop up
   */
  closePopup() {
    this.overlay && this.overlay.setPosition(undefined);
  }

  /**
   * remove google map
   */
  _removeGoogleMap() {
    if (document.getElementById('gmap')) {
      document.getElementById('gmap').remove();
      document.getElementsByClassName('google_traffic')[0].remove();
    }
    if (document.getElementsByClassName('google_street_toggle') && document.getElementsByClassName('google_street_toggle').length > 0) {
      document.getElementsByClassName('google_street_toggle')[0].style.display = 'none';
    }
  }

  /**
   * Switch map
   * @param {Object} oldLayer - old layer object
   * @param {Object} newLayer - new layer object
   */
  switchMap(oldLayer, newLayer) {
    if (oldLayer == 'Google') {
      this.gmap = null;
      this.container.appendChild(document.getElementById('olmap'));
      this._removeGoogleMap();
    } else if (newLayer == 'Google') {
      this._createGoogleMap();
    }
  }

  /**
   * Add point feature
   * @param {Object} props - param object
   * @param {Array} props.data - Coordinates object array
   * @param {Object} props.style - Custom style
   * @param {Object} props.tooltip - tooltip object
   */
  _addFeaturePoint(props) {
    const {
      data = [
        {
          coordinates: [],
        },
      ],
      style,
      tooltip,
    } = props;

    return (
      data &&
      data.map(
        (dataItem) =>
          dataItem.coordinates &&
          dataItem.coordinates.map((coord) => {
            if (coord) {
              let pointStyle = dataItem.style || style;
              let olFeature;
              olFeature = new Feature({
                geometry: new Point(this.getGeometry(coord)),
                data: coord,
                tooltip,
              });

              if (pointStyle && pointStyle.style) {
                olFeature.setStyle(pointStyle.style(coord));
              } else {
                if (pointStyle && pointStyle.image && !pointStyle.hasBearing) {
                  olFeature.setStyle(
                    new Style({
                      image: new Icon({
                        src: pointStyle.image.src,
                        anchor: pointStyle.image.anchor || [0.5, 0.5],
                        imgSize: pointStyle.image.imgSize || [24, 24],
                        opacity: pointStyle.image.opacity || 1,
                      }),
                    })
                  );
                } else if (pointStyle && pointStyle.image && pointStyle.hasBearing) {
                  olFeature.setStyle(
                    new Style({
                      image: new Icon({
                        src: pointStyle.image.src || null,
                        anchor: pointStyle.image.anchor || [0.5, 0.5],
                        imgSize: pointStyle.image.imgSize || [24, 24],
                        opacity: pointStyle.image.opacity || 1,
                        rotation: pointStyle.image.rotation || ((coord[pointStyle.bearingProp] + 270) * Math.PI) / 180.0,
                        rotateWithView: pointStyle.image.rotateWithView || false,
                      }),
                    })
                  );
                } else if (pointStyle && pointStyle.hasBearing) {
                  olFeature.setStyle(
                    new Style({
                      text: new Text({
                        font: '18px Calibri,sans-serif',
                        text: '➤',
                        placement: 'point',
                        fill: new Fill({
                          color: (pointStyle.fill && pointStyle.fill.color) || '#2fb22f',
                        }),
                        stroke: new Stroke({
                          color: (pointStyle.stroke && pointStyle.stroke.color) || '#228b22',
                          width: 2,
                        }),
                        rotation: pointStyle.rotation || ((coord[pointStyle.bearingProp || 'bearing'] + 270) * Math.PI) / 180.0,
                        rotateWithView: pointStyle.rotateWithView || false,
                      }),
                    })
                  );
                } else {
                  olFeature.setStyle(
                    new Style({
                      image: new circleStyle({
                        radius: (pointStyle && pointStyle.circle && pointStyle.circle.radius) || 7,
                        fill: new Fill({
                          color: (pointStyle && pointStyle.circle && pointStyle.circle.fill && pointStyle.circle.fill.color) || '#228b22',
                        }),
                      }),
                    })
                  );
                }
              }

              return olFeature;
            }
          })
      )
    );
  }

  /**
   * Add line feature
   * @param {Object} props - param object
   * @param {Array} props.data - Coordinates object array
   * @param {Object} props.style - Custom style
   * @param {Object} props.tooltip - tooltip object
   */
  _addFeatureLine(props) {
    const { data, style, tooltip } = props;
    return data.map((dataPoints) => {
      let coord = dataPoints.coordinates;
      let featureList = [];
      let trackStyle;

      if (style && style.style) {
        trackStyle = style.style(coord);
      } else {
        trackStyle = new Style({
          stroke: new Stroke(
            (typeof dataPoints.style === 'string' && style && style[dataPoints.style]) ||
              dataPoints.style ||
              style || {
                color: '#666',
                width: 3,
              }
          ),
        });
      }

      if (typeof coord === 'string') {
        var route = new Polyline().readGeometry(coord, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        });

        var feature = new Feature({
          geometry: route,
          data: { coordinate: coord, content: dataPoints.content || '' },
          tooltip,
        });
        feature.setStyle(trackStyle);
        featureList.push(feature);
      } else if (Array.isArray(coord) && coord.length > 0) {
        let i = 0;
        featureList = coord.map((coordItem) => {
          let trackFeature = new Feature({
            geometry: new LineString([this.getGeometry(coordItem), this.getGeometry(coord[i + 1] || coord[i])]),
            data: { coordinate: coordItem, content: dataPoints.content || '' },
            tooltip,
          });
          trackFeature.setStyle(trackStyle);
          i++;
          return trackFeature;
        });
      }
      return featureList;
    });
  }

  /**
   * Add features
   * @param {Object} props - param object
   * @param {String} props.layerName - Layer layer name
   * @param {String} props.trackType - Track type
   * @param {Object} props.tooltip - tooltip object
   * @param {Object} props.features - pre-built list of feature
   */
  addFeatures(props) {
    const { layerName, trackType, tooltip, features } = props;

    const vectorLayer = this.layer[layerName];
    let featureList = features || [];
    // ToDo: Check if we can iterate data over here
    if (trackType && trackType.toLowerCase() === 'point') {
      featureList = this._addFeaturePoint(props);
    } else if (featureList.length == 0) {
      featureList = this._addFeatureLine(props);
    }

    if (tooltip) {
      this._activateTooltip(tooltip);
    }

    if (features && features.length > 0) {
      vectorLayer.getSource().addFeatures(features);
    } else if (Array.isArray(featureList) && featureList.length > 0) {
      featureList.map((featureItem) => vectorLayer.getSource().addFeatures(featureItem));
    } else {
      vectorLayer.getSource().addFeatures(featureList);
    }
    return vectorLayer;
  }

  /**
   * Add cluster layer
   * @param {Object} props - param object
   * @param {String} props.layerName - Layer layer name
   * @param {Object} props.style - Custom style object
   * @param {String} props.nonCenterCount - non center count
   */
  addClusterLayer(props) {
    const {
      layerName,
      style: { cluster, point },
      cluster: clusterOption,
    } = props;

    const clusterImage = (cluster && cluster.image) || {};
    const clusterText = (cluster && cluster.text) || {};
    const style = cluster && cluster.style;

    let clusterLayerName = layerName + 'Cluster';
    if (this.layer[clusterLayerName]) {
      return false;
    }

    let vectorSource;
    if (this.layer[layerName]) {
      vectorSource = this.layer[layerName].getSource();
    } else {
      vectorSource = new VectorSource();
    }

    let clusterSource = new ClusterSource({
      distance: (clusterOption && clusterOption.distance) || 40,
      source: vectorSource,
    });

    const clusterLayer = new VectorLayer({
      name: clusterLayerName,
      visible: true,
      source: clusterSource,
      style: (feature) => {
        if (style) {
          return style(feature);
        } else {
          var features = feature.get('features');
          var size = features.length;
          if (size == 1) {
            if (point && point.style) {
              return [point.style(features[0].get('data'))];
            } else {
              return [
                new Style({
                  image: new Icon({
                    opacity: (point && point.image && point.image.opacity) || 0.8,
                    anchor: (point && point.image && point.image.anchor) || [0.5, 1.0],
                    src: (point && point.image && point.image.src) || this.iconBasePath + 'start_marker.png',
                  }),
                }),
              ];
            }
          } else {
            return [
              new Style({
                image: new Icon({
                  opacity: (clusterImage && clusterImage.opacity) || 0.8,
                  anchor: (clusterImage && clusterImage.anchor) || [0.5, 1.0],
                  src: (clusterImage && clusterImage.src) || this.iconBasePath + 'csi_cluster.png',
                }),
                text: new Text({
                  ...clusterText,
                  text: size.toString(),
                  fill: new Fill({
                    color: (clusterText && clusterText.fill) || '#fff',
                  }),
                  backgroundFill:
                    clusterText &&
                    clusterText.backgroundFill &&
                    new Fill({
                      color: clusterText && clusterText.backgroundFill,
                    }),
                  offsetX: (clusterText && clusterText.offsetX) || 0,
                  offsetY: (clusterText && clusterText.offsetY) || -12,
                }),
              }),
            ];
          }
        }
      },
    });

    this.map.addLayer(clusterLayer);
    this.layer[clusterLayerName] = clusterLayer;
  }

  /**
   * Remove Features
   * @param {String} layerName - Layer layer name
   */
  removeFeatures(layerName) {
    this.closePopup();
    var vectorLayer = this.layer[layerName];
    var clusterLayer = this.layer[layerName + 'Cluster'];

    if (vectorLayer) {
      vectorLayer.getSource().clear();
    }
    if (clusterLayer) {
      clusterLayer.getSource().getSource().clear();
    }
  }

  /**
   * Get geometry value from coordinates
   * @param {Object} props - param object
   */
  getGeometry(props) {
    let lat, lon;
    if (Array.isArray(props)) {
      lon = props[0];
      lat = props[1];
    } else {
      lon = props[this.dataFormat.lon];
      lat = props[this.dataFormat.lat];
    }

    if (lat && lon) {
      var coord = Proj.transform([lon, lat], 'EPSG:4326', 'EPSG:3857');
      if (!(isNaN(coord[0]) || isNaN(coord[1]))) {
        return coord;
      }
    }
  }

  /**
   * Add cluster layer
   * @param {Object} props - param object
   * @param {String} props.layerName - Layer layer name
   * @param {Object} props.style - Custom style object
   */
  _drawCluster(props) {
    const { layerName, style = { cluster: {} } } = props;
    this.addClusterLayer(props);
    this.layer[layerName].setVisible(false);
  }

  /**
   * @desc draw track
   * @param object props = {
   *     data: array, ( passs data points to plot )
   *     layerName: string, ( exisiting layer name )
   *     trackStyle: string, ( line/point, default: line )
   *     style = {
   *       point: {},
   *       start: {},
   *       end: {},
   *       line: {},
   *       cluster: {}
   *     },
   *     cluster: boolean/object, e,g: { distance: 70 }
   *     startPoint: boolean,
   *     endPoint: boolean,
   *     clickHandler: function, ( user define function )
   *     options: object,
   *     tooltip: {
   *       showOn: string, ( hover/click )
   *       content: function(feature, attr[]) ( lat: attr[0], long: attr[1] )
   *     },
   *     liveTrack: {
   *       maxLiveCheck: int, (maximum number of time scheduler will check for new data if no new data received)
   *       interval: int, (in which inrerval data will refresh)
   *       intervalHandler: function, (callback method for handling callback)
   *     },
   *   }
   *
   * @return void
   */
  drawTrack(props) {
    const { data, trackType, layerName, style, cluster, startPoint, endPoint, liveStartPoint, liveTrack, maxLiveCheck, clickHandler, tooltip } = props;

    const fitWithinView = typeof props.fitWithinView == 'boolean' ? props.fitWithinView : false;
    // ToDo : Need to check empty coordinates as well
    if (data.length > 0) {
      if (this.layerList.indexOf(layerName) <= -1) {
        if (!layerName) {
          this.addLayer({ name: 'track' });
          props.layerName = 'track';
        } else {
          this.addLayer({ name: layerName });
        }
      }

      if (!trackType) {
        props.trackType = 'line';
      }

      this.addFeatures({
        ...props,
        style: style && style[props.trackType],
      });

      if (cluster && trackType.toLowerCase() == 'point') {
        this._drawCluster(props);
      }

      if (!liveStartPoint && startPoint) {
        this.drawStartPointMarker({ layerName, style: style && style.startPoint, coordinates: data[0].coordinates[0] });
      }

      if (this._checkIfLivetrack(props) && liveTrack.intervalHandler) {
        props.maxLiveCheck = maxLiveCheck || 10;
        props.interval = liveTrack.interval || 5000;
        this._addLiveEndMarker(props);
        setTimeout(() => this._registerLiveTrackInterval(props), liveTrack.interval);
      } else if (endPoint) {
        this.drawEndPointMarker({
          layerName,
          style: style && style.endPoint,
          coordinates: data[data.length - 1].coordinates[data[data.length - 1].coordinates.length - 1],
        });
      }

      if (clickHandler) {
        this.map.on('singleclick', clickHandler);
      }

      this.map.on('moveend', (evt) => {
        this.currentZoom = evt.map.getView().getZoom();
      });

      if (tooltip) {
        this._activateTooltip(tooltip);
      }

      if (fitWithinView) {
        this.performFit(props.layerName);
      }
    }
  }

  /**
   * Fit the coordinates within viewport on map
   * @param {String} layerName - layer name
   */
  performFit(layerName) {
    if (layerName) {
      if (this.layerList.indexOf(layerName) > -1) {
        const extent = this.layer[layerName].getSource().getExtent();
        this.map.getView().fit(extent, this.map.getSize());
      } else {
        throw new Error('Invalid layer name to perform fit operation');
      }
    } else {
      throw new Error('No layer name passed to perform fit operation');
    }
  }

  /**
   * Check if live track
   * @param {Object} props - param object
   * @param {String} props.data - track data
   */
  _checkIfLivetrack({ data }) {
    let isLiveTrack = false;
    const endPoint = data[data.length - 1].coordinates[data[data.length - 1].coordinates.length - 1];
    if (endPoint && endPoint[this.dataFormat.time]) {
      const currentTime = new Date().getTime();
      const lastTime = new Date(endPoint[this.dataFormat.time]).getTime();
      if (currentTime - lastTime <= this.liveTrackCheckDurationInMS) {
        isLiveTrack = true;
      }
    }
    return isLiveTrack;
  }

  /**
   * Activate tooltip
   * @param {Object} tooltip - param object
   */
  _activateTooltip(tooltip) {
    if (tooltip && tooltip.showOn === 'hover') {
      this.map.on('pointermove', (e) => {
        this._showTooltip(e);
      });
    }

    if (tooltip && tooltip.showOn === 'click') {
      this.map.on('singleclick', (e) => {
        this._showTooltip(e);
      });
    }
  }

  /**
   * Activate tooltip
   * @param {String} layerName - Layer name
   * @param {Boolean} visible - set visibility
   */
  toggleLayer(layerName, visible) {
    const vectorLayer = this.layer[layerName];
    const clusterLayer = this.layer[layerName + 'Cluster'];

    if (vectorLayer) {
      vectorLayer.setVisible(visible);
    }
    if (clusterLayer) {
      clusterLayer.setVisible(visible);
    }
  }

  /**
   * draw start point marker
   * @param {Object} props - param object
   * @param {Array} props.coordinates - Data to plot
   * @param {Object} props.style - Custom style object
   */
  drawStartPointMarker(props) {
    const { style, coordinates, layerName } = props;

    coordinates &&
      this.addFeatures({
        layerName,
        trackType: 'point',
        style: style || {
          image: {
            src: this.iconBasePath + 'start_marker.png',
            anchor: [0.5, 1.0],
            imgSize: [26, 35],
          },
        },
        data: [
          {
            coordinates: [coordinates],
          },
        ],
      });
  }

  /**
   * draw end point marker
   * @param {Object} props - param object
   * @param {Array} props.coordinates - Data to plot
   * @param {Object} props.style - Custom style object
   */
  drawEndPointMarker(props) {
    const { style, coordinates, layerName } = props;

    coordinates &&
      this.addFeatures({
        layerName,
        trackType: 'point',
        style: style || {
          image: {
            src: this.iconBasePath + 'end_marker.png',
            anchor: [0.5, 1.0],
            imgSize: [26, 35],
          },
        },
        data: [
          {
            coordinates: [coordinates],
          },
        ],
      });
  }

  /**
   * Remove live end marker
   * @param {Object} props - param object
   * @param {Array} props.layerName - layer name
   */
  _removeLiveEndMarker({ layerName }) {
    document.querySelectorAll('.end-marker-' + layerName).forEach((elem) => {
      elem.remove();
    });
  }

  /**
   * check if track is live
   * @param {Object} props - param object
   */
  _isTrackLive(props) {
    let interval = setInterval(() => {
      props.liveTrack.liveCheckCount = props.liveTrack.liveCheckCount ? props.liveTrack.liveCheckCount + 1 : 1;
      if (props.liveTrack.maxLiveCheck >= props.liveTrack.liveCheckCount) {
        this._registerLiveTrackInterval(props);
      } else {
        this._removeLiveEndMarker(props);
        this._drawEndPointMarker(props);
        clearInterval(interval);
      }
    }, props.liveTrack.interval);
  }

  /**
   * Add live track check interval
   * @param {Object} props - param object
   * @param {Array} props.data - Data to plot
   */
  _registerLiveTrackInterval(props) {
    props.liveTrack.intervalHandler().then((data) => {
      if (data.length > 0) {
        this._removeLiveEndMarker(props);
        props.data = [
          {
            coordinates: data,
          },
        ];
        props.liveStartPoint = true;
        this.drawTrack(props);
      } else {
        this._isTrackLive(props);
      }
    });
  }

  /**
   * zoom to particular coordinate
   * @param {Object} props - param object
   * @param {Array} props.coordinate - coordinate
   * @param {Object} props.options - additional option
   */
  zoomToCoordinate({ coordinate, isCoordinate, overlayContent, style }) {
    if (Array.isArray(coordinate) && coordinate.length > 0) {
      if (isCoordinate) {
        coordinate = this.getGeometry(coordinate);
      }
      overlayContent && this._showOverlay(overlayContent, coordinate, style);
    } else {
      return new Error('Expecting an array with lon, lat');
    }
  }

  /**
   * Calculate current zoom
   */
  calculateZoom() {
    return this.currentCountry.maxZoom - 4;
  }

  /**
   * Center the map to the passed coordinate
   */
  centerToCoordinate(coordinate, options = {}) {
    if (Array.isArray(coordinate) && coordinate.length > 0) {
      if (options.isCoordinate) {
        this.map.getView().setCenter(this.getGeometry(coordinate));
      } else {
        this.map.getView().setCenter(coordinate);
      }
    } else {
      return new Error('Expecting an array with lon, lat');
    }
  }

  /**
   * zoom to particular coordinate
   * @param {Object} props - param object
   * @param {Array} props.data - data
   * @param {String} props.layerName - layer name
   */
  _addLiveEndMarker({ data = [], layerName }) {
    let markerId = 'end-marker-' + layerName;

    const endPoint = data[data.length - 1].coordinates[data[data.length - 1].coordinates.length - 1];

    if (!document.getElementById(markerId)) {
      this.container.insertAdjacentHTML('beforeend', '<div id="' + markerId + '" class="circleOut end-marker-' + layerName + '"></div>');
    }

    let container = document.getElementById(markerId);
    const liveEndMarker = new Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });
    this.map.addOverlay(liveEndMarker);
    this.liveOverlay[layerName] = liveEndMarker;
    liveEndMarker.setPosition(new Point(this.getGeometry(endPoint)).getCoordinates());
  }

  /**
   * Draw KML layer on top of map
   */
  drawFromKML(props) {
    this.map.addLayer(
      new VectorLayer({
        source: new VectorSource({
          url: props.url,
          format: new KML({
            extractStyles: true,
            extractAttributes: true,
          }),
        }),
      })
    );
  }

  /**
   * Returns map bounding value
   */
  getBoundingLatLng(fromProjection = 'EPSG:3414', toProjecton = 'EPSG:4326') {
    var fromProj = new Projection({ code: fromProjection });
    var toProj = new Projection({ code: toProjecton });
    return Proj.transformExtent(this.map.getView().calculateExtent(this.map.getSize()), fromProj, toProj);
  }

  /**
   * Draws cluster in shape of grid. It requires an API handler to be provided.
   */
  drawGridCluster(props) {
    const { layerName, fromProjection, toProjecton, gridApiHandler, resetZoomLevel = 17 } = props;
    if (!layerName && !gridApiHandler && !gridColorHandler) {
      throw new Error('layerName or gridApiHandler or gridColorHandler attributes not found.');
    }

    this.gridViewData = props;
    this._fetchGridData(props);

    this.registeredEvents['GridCluster'] = this.map.on('moveend', () => {
      this._fetchGridData(props);
    });
  }

  /**
   * Calls the API handler to fetch the grid data
   */
  _fetchGridData({ gridApiHandler: callback, layerName, fromProjection, toProjecton }) {
    const mapBoundInfo = this.getBoundingLatLng(fromProjection, toProjecton).join('|');
    callback(mapBoundInfo).then((data) => {
      this._drawGridFeatures(layerName, data);
    });
  }

  /**
   * Check the current zoom level and decides if the grids or points to be shown
   */
  _drawGridFeatures(layerName, data) {
    const currentZoom = this.map.getView().getZoom();
    if (currentZoom < this.gridViewData.resetZoomLevel) {
      this._drawBoxes(layerName, data.coordinates);
    } else {
      this._drawGridPointsFeatures(data, layerName);
    }
  }

  /**
   * Draws points of grids
   */
  _drawGridPointsFeatures(data, boxLayerName) {
    const featureList = [];
    for (let i = 0, len = data.coordinates.length; i < len; i++) {
      const gridDetails = data.coordinates[i];
      featureList.push({
        coordinates: [
          {
            lon: gridDetails.x0,
            lat: gridDetails.y0,
          },
        ],
      });
    }

    this.removeFeatures(boxLayerName);
    this.addLayer({ name: boxLayerName });
    this.addFeatures({
      layerName: boxLayerName,
      trackType: 'point',
      data: featureList,
    });
  }

  /**
   * Draws grid boxes
   */
  _drawBoxes(layerName, boxInfos) {
    const boxFeatures = [];
    const thisRef = this;

    boxInfos.forEach(function (box, index) {
      const convertedCoordinates = [];
      let unformattedCoordinates = box.coordinates;
      for (let i = 1; i < unformattedCoordinates.length; i++) {
        let dY = unformattedCoordinates[i][0] - unformattedCoordinates[i - 1][0];
        if (Math.abs(dY) > 180) unformattedCoordinates[i][0] += 360;
      }

      unformattedCoordinates.forEach(function (coordinate, index) {
        const lat = coordinate[0];
        const lon = coordinate[1];

        const circle = new Circle(Proj.transform([lat, lon], 'EPSG:4326', 'EPSG:900913'));
        convertedCoordinates.push(circle.getCenter());
      });

      const polygonGeometry = new Polygon([convertedCoordinates]);
      const polygonFeature = new Feature({ geometry: polygonGeometry });
      const style = thisRef._boxStyle(box);
      polygonFeature.setStyle(style);
      boxFeatures.push(polygonFeature);
    });

    this.removeFeatures(layerName);
    this.addLayer({ name: layerName });
    this.addFeatures({ layerName, features: boxFeatures });
  }

  /**
   * Decides the length of grid boxes
   */
  _boxStyle(box) {
    const count = box.count;
    const min_to_increase = box.min_to_increase;
    const currentZoom = this.map.getView().getZoom();

    if (currentZoom < this.gridViewData.resetZoomLevel) {
      const offset = {
        13: [10, -15],
        14: [40, -45],
        15: [100, -100],
      };

      let offsetX = 0;
      let offsetY = 0;
      if (offset[currentZoom]) {
        offsetX = offset[currentZoom][0];
        offsetY = offset[currentZoom][1];
      }

      let strokeColor = '#fff';
      let strokeWidth = 0.5;
      if (min_to_increase) {
        strokeColor = '#000';
        strokeWidth = 3;
      }

      const boxStyleWithNumber = new Style({
        fill: new Fill({
          color: this._boxFillColor(count),
        }),
        stroke: new Stroke({
          color: strokeColor,
          width: strokeWidth,
        }),
      });
      return [boxStyleWithNumber];
    }
  }

  /**
   * Calls handler to colour the boxes.
   */
  _boxFillColor(dataPointCount) {
    const { gridColorHandler } = this.gridViewData;
    return (gridColorHandler && gridColorHandler(dataPointCount)) || 'rgba(169, 169, 169, 0.5)';
  }

  /**
   * Unregisters the event registered on the openlayer e.g moveend, click etc
   */
  unregisterEvent(eventName) {
    const eventNames = Object.keys(this.registeredEvents) || ['--'];
    if (eventNames.length > 0 && eventNames.indexOf(eventName) == -1) {
      console.warn(`Nothing to unregister. The event name should be one of the ${eventNames.join()}`);
      return;
    }
    unByKey(this.registeredEvents[eventName]);
  }

  getMapReference() {
    return this.map;
  }
}
