import React, { useEffect, useState } from "react";
import { useSearchQuery, usePagination } from "../../../hooks";
import { DeviceAdvanceSearch } from "./advanceSearch";
import { DeviceForm } from "./form";
import { CommandSending } from "./commandSending";
import { MPSharedTable, MPSharedHeader, MPSharedDeleteModal, TableDropDownView } from "../Shared/";
import { useGetDevicesQuery, useDeleteDeviceMutation } from "../services";
import { useQuery, useDelete } from "../../../hooks";
import { TickOrCross, SvgIcon } from "../../Shared";
import { DeviceGroupCsvUploadForm } from "../DeviceGroups/csvUploadForm";
import { useGetDevicesTrackingQuery } from "../../Tracking/services";
import moment from "moment";
import { getLocalizedString } from "../../../shared/translation";
import { FMDPStorage } from "../../../shared/helper";

export const DeviceContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, advanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_device_id_or_filter_device_ph_with_country_code_cont",
  });

  const [selectedDevice, setSelectedDevice] = useState("");
  const [sourceIds, setSourceIds] = useState([]);
  const [combinedData, setCombinedData] = useState([]);

  const isCreatedByReseller = FMDPStorage?.get("created-by-reseller");
  const isReseller = FMDPStorage?.get("reseller");
  const appFeatures = FMDPStorage?.get("app_features");

  const isCreateDeviceEnabled = appFeatures?.Devices && appFeatures?.Devices?.create !== undefined;

  const queryParams = isReseller
    ? {
        page: page,
        per_page: perPage,
        order_by: "devices.created_at",
        order_dir: "desc",
        reseller_view: true,
      }
    : {
        page: page,
        per_page: perPage,
        order_by: "devices.created_at",
        order_dir: "desc",
      };

  const query = useQuery();
  let filterHeader = "";
  const filters = [
    {
      label: " " + getLocalizedString("manufacturer_lowercase", " manufacturer") + ":",
      key: "device_manufacturer_id",
    },
    {
      label: " " + getLocalizedString("model_lowercase", " model") + ":",
      key: "device_model_id",
    },
    {
      label: " " + getLocalizedString("client_lowercase", " client") + ":",
      key: "client_id",
    },
    {
      label: " " + getLocalizedString("device_group_lowercase", " device group") + ":",
      key: "device_group_id",
    },
    {
      label: " " + getLocalizedString("backend_lowercase", " backend") + ":",
      key: "backend_id",
    },
    {
      label:
        " " + getLocalizedString("trip_event_configuration", " Trip Event Configuration") + ":",
      key: "trip_event_configuration_id",
    },
  ];

  filters.map((filter) => {
    if (query.get(filter.key)) {
      queryParams[filter.key] = query.get(filter.key);
      filterHeader = `${filter.label} ${query.get("name")}`;
    }
  });

  const {
    data = { data: [], total_count: 0 },
    error,
    isFetching,
    isSuccess,
  } = useGetDevicesQuery({ ...queryParams, q });

  //Collect Source Ids
  useEffect(() => {
    if (data?.data?.length > 0) {
      setSourceIds(data?.data?.map((devices) => devices?.device_id));
    }
  }, [data]);

  const {
    data: trackingData = { Tdata: [] },
    error: trackingError,
    isLoading: isLoadingTracking,
  } = useGetDevicesTrackingQuery(
    sourceIds && {
      device_ids: sourceIds,
      // fields: "device_data.source_id,device_data.gps,device_data.velocity,device_data.ignition",
    },
    { refetchOnMountOrArgChange: true }
  );

  /**
   * This effect updates the combined data with the latest tracking information,
   * if both device data and tracking data are available.
   */
  useEffect(() => {
    // Check if there is device data and tracking data available
    if (data?.data?.length > 0 && trackingData?.latest_data?.length > 0) {
      // Map through each device to find matching tracking info
      const newData = data?.data?.map((device) => {
        const matchingTrackingInfo = trackingData?.latest_data?.find(
          (tracking) => tracking?.device_data?.source_id === device?.device_id
        );

        // If matching tracking info is found, update server_time in device data
        if (matchingTrackingInfo) {
          return {
            ...device,
            server_time:
              matchingTrackingInfo.heart_beat?.server_time ||
              matchingTrackingInfo.device_data?.server_time ||
              matchingTrackingInfo.device_reply?.server_time,
          };
        } else {
          // If no matching tracking info, return the original device data
          return device;
        }
      });

      // Update the combined data with the new information
      setCombinedData(newData);
    } else {
      //If no tracking data, then use the previous data
      setCombinedData(data?.data);
    }
  }, [data, trackingData]);

  const {
    deleteItem: deleteDevice,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteDeviceMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const [idToDelete, setIdToDelete] = useState(null);
  const [idToEdit, setIdToEdit] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showForm, setShowForm] = useState(false);

  //For the csv upload form for device asset creation
  const [showCsvUploadForm, setShowCsvUploadForm] = useState(false);

  const onEdit = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  const onDelete = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const onCreate = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const headers = isReseller
    ? [
        {
          label: getLocalizedString("client_name", "Client Name"),
          key: "client_name",
          className: "client-name",
        },
        {
          label: getLocalizedString("name", "Name"),
          nestedValue: true,
          getNestedValue: ({ device_manufacturer_name, device_model_name, name }) =>
            `${name} - ${device_manufacturer_name} - ${device_model_name}`,
          className: "name",
        },
        { label: "ID/IMEI", key: "device_id", className: "id" },
        {
          label: getLocalizedString("last_received", "Last Received"),
          className: "id",
          nestedValue: true,
          getNestedValue: ({ server_time }) => {
            if (!trackingError) {
              return `${server_time ? moment(server_time).format("DD/MM/YYYY - hh:mm:ss A") : ""}`;
            } else {
              return "Unable to Fetch - Error occurred";
            }
          },
        },
        // { label: t.table_headers.sim_card_no, key: "simcard_no", className: "sim-number" },
        {
          label: getLocalizedString("phone_no", "Phone No"),
          nestedValue: true,
          getNestedValue: (element) =>
            `${element.country_code ? `${element.country_code}-` : ""}${
              element.simcard_phone_no || ""
            }`,
          className: "phone-number",
        },
        {
          label: getLocalizedString("vehicle_plate_no", "Vehicle Plate No."),
          key: "plate_number",
          className: "plate-number-number",
        },
        {
          label: getLocalizedString("enabled", "Enabled"),
          nestedValue: true,
          getNestedValue: (value) => <TickOrCross flag={value?.enabled} />,
          className: "status",
        },
        {
          label: getLocalizedString("groups", "Groups"),
          type: "component",
          className: "group more",
          component: ({ data }) => {
            const group_names = data?.device_group_names || [""];
            return <TableDropDownView data={group_names} />;
          },
        },
        {
          label: getLocalizedString("backends", "Backends"),
          type: "component",
          className: "backends more",
          component: ({ data }) => {
            const backends = data?.backend_name_list?.split(",") || [""];
            return <TableDropDownView data={backends} />;
          },
        },
        {
          label: getLocalizedString("geolocation_plan", "Geolocation Plan"),
          key: "geolocation_plan_name",
          className: "text more",
        },
        {
          label: getLocalizedString("firmware_version", "Firmware Version"),
          key: "firmware_version",
          className: "firmware",
        },
        // {
        //   label: "Applications",
        //   type: "component",
        //   className: "applications more",
        //   component: ({ data }) => <TableDropDownView data={data?.app_names} />,
        // },
        {
          label: getLocalizedString("added_on", "Added on"),
          key: "created_at",
          className: "date",
        },
      ]
    : [
        {
          label: getLocalizedString("name", "Name"),
          nestedValue: true,
          getNestedValue: ({ device_manufacturer_name, device_model_name, name }) =>
            `${name} - ${device_manufacturer_name} - ${device_model_name}`,
          className: "name",
        },
        { label: "ID/IMEI", key: "device_id", className: "id" },
        {
          label: getLocalizedString("last_received", "Last Received"),
          className: "id",
          nestedValue: true,
          getNestedValue: ({ server_time }) => {
            if (!trackingError) {
              return `${server_time ? moment(server_time).format("DD/MM/YYYY - hh:mm:ss A") : ""}`;
            } else {
              return "Unable to Fetch - Error occurred";
            }
          },
        },
        // { label: t.table_headers.sim_card_no, key: "simcard_no", className: "sim-number" },
        {
          label: getLocalizedString("phone_no", "Phone No"),
          nestedValue: true,
          getNestedValue: (element) =>
            `${element.country_code ? `${element.country_code}-` : ""}${
              element.simcard_phone_no || ""
            }`,
          className: "phone-number",
        },
        {
          label: getLocalizedString("vehicle_plate_no", "Vehicle Plate No."),
          key: "plate_number",
          className: "plate-number-number",
        },
        {
          label: getLocalizedString("enabled", "Enabled"),
          nestedValue: true,
          getNestedValue: (value) => <TickOrCross flag={value?.enabled} />,
          className: "status",
        },
        {
          label: getLocalizedString("groups", "Groups"),
          type: "component",
          className: "group more",
          component: ({ data }) => {
            const group_names = data?.device_group_names || [""];
            return <TableDropDownView data={group_names} />;
          },
        },
        {
          label: getLocalizedString("backends", "Backends"),
          type: "component",
          className: "backends more",
          component: ({ data }) => {
            const backends = data?.backend_name_list?.split(",") || [""];
            return <TableDropDownView data={backends} />;
          },
        },
        {
          label: getLocalizedString("geolocation_plan", "Geolocation Plan"),
          key: "geolocation_plan_name",
          className: "text more",
        },
        {
          label: getLocalizedString("firmware_version", "Firmware Version"),
          key: "firmware_version",
          className: "firmware",
        },
        // {
        //   label: "Applications",
        //   type: "component",
        //   className: "applications more",
        //   component: ({ data }) => <TableDropDownView data={data?.app_names} />,
        // },
        {
          label: getLocalizedString("added_on", "Added on"),
          key: "created_at",
          className: "date",
        },
      ];

  const additionalActions = [
    {
      component: (data) => (
        <SvgIcon
          wrapperClass="clickable"
          name="console"
          onClick={() => setSelectedDevice(data)}
          title={getLocalizedString("terminal", "Terminal")}
        />
      ),
    },
  ];

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data.data.findIndex((item) => item.id === id);

    if (index >= 0) {
      name = data.data[index].name;
    }
    return name;
  };

  //Handler to set the csv upload form open
  const uploadCsvHandler = () => {
    setShowCsvUploadForm(true);
  };

  const isUploadCsv = () => {
    if (!isCreateDeviceEnabled && isCreatedByReseller) {
      return false;
    }
    return true;
  };

  return (
    <>
      <article className="main-container grid-view">
        <MPSharedHeader
          heading={getLocalizedString("devices", "Devices")}
          createNewHandler={onCreate}
          resourceName="Devices"
          handleSearchKey={(value) => setSimpleSearch(value)}
          handleAdvanceSearchKeys={(value) => setAdvanceSearch(null)}
          activeSearch={advanceSearch}
          simpleSearchPlaceholder={getLocalizedString(
            "search_by_name_imei_phone_no",
            "Search by Name, IMEI, Phone No."
          )}
          groupName="device"
          filterText={
            filterHeader && `${getLocalizedString("filtered_by", "Filtered by")} ${filterHeader}`
          }
          uploadCsvHandler={isUploadCsv() && uploadCsvHandler}
        >
          <DeviceAdvanceSearch
            onSearch={(value) => setAdvanceSearch(value)}
            activeSearch={advanceSearch}
          />
        </MPSharedHeader>

        <MPSharedTable
          resourceName="Devices"
          pagination={{ ...pagination, count: data.total_count }}
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          data={combinedData || []}
          headers={headers}
          onEdit={onEdit}
          onDelete={onDelete}
          className="devices"
          additionalActions={additionalActions}
          auditResource="Device"
          auditKey="device_id"
        />
      </article>

      {showForm && <DeviceForm idToEdit={idToEdit} onClose={() => setShowForm(false)} />}

      {showCsvUploadForm && (
        <DeviceGroupCsvUploadForm onClose={() => setShowCsvUploadForm(false)} />
      )}

      {selectedDevice && (
        <CommandSending deviceData={selectedDevice} onClose={() => setSelectedDevice(null)} />
      )}

      <MPSharedDeleteModal
        show={showDeleteWarning}
        error={deleteErrorMsg}
        entityName={getLocalizedString("device", "device")}
        itemName={getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg("");
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteDevice({ id: idToDelete })}
      />
    </>
  );
};
