import { useEffect, useState } from "react";
import { useGetVariablesDataQuery } from "../../containers/Tracking/services";

export function useVariableDataQuery() {
  const [variablePage, setVariablePage] = useState(0);
  const [variableData, setVariableData] = useState({});

  const { data: mappingData } = useGetVariablesDataQuery({ per_page: 2000, page: variablePage });

  /* Variable Data Mapping */
  useEffect(() => {
    if (mappingData?.data?.length) {
      mappingData?.data?.map((item, id) => {
        const format = item?.section_value;
        setVariableData((prev) => ({
          ...prev,
          [`${format ? format + "." : ""}${item?.name}`]: { ...item },
        }));
        return null;
      });
      setVariablePage((prev) => prev + 1);
    }
  }, [mappingData]);

  /* Dispatch Variable Data to Global Store */
  return { variableData };
}
