import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QIButton, QICustomSelect, QISimpleSearch, QISpinner } from "../../../components";
import { SvgIcon } from "../../Shared";
import { FMDPStorage } from "../../../shared/helper";
import { toggleAppInfo } from "../helper";
import {
  useCreateAuthMutation,
  useSelectClientAppMutation,
  useSelectSuperAdminMutation,
  useLogoutAppMutation,
} from "../service";
import { useDispatch } from "react-redux";
import { setFlashScreen } from "../../../reduxStore/features";
import Scrollbars from "react-custom-scrollbars";
import { toast, ToastContainer } from "react-toastify";
import { getLocalizedString } from "../../../shared/translation";

export const RoleSelection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clientsData, setClientsData] = useState(null);
  const [appSearch, setAppSearch] = useState("");
  // Variable to hold the filtered data
  const [filteredData, setFilteredData] = useState([]);
  const [appSelectionError, setAppSelectionError] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);

  const [, { data: userData }] = useCreateAuthMutation({
    fixedCacheKey: "shared-sso-data",
  });

  const [
    selectClientApp,
    { isSuccess: selectClientAppSuccess, error: selectClientAppError, data: clientAppData },
  ] = useSelectClientAppMutation({
    fixedCacheKey: "shared-client-app-data",
  });

  const [selectSuperAdmin, { isSuccess: selectSuperAdminSuccess, data: superAdminData }] =
    useSelectSuperAdminMutation({
      fixedCacheKey: "shared-superadmin-app-data",
    });

  const [logoutApp, { isSuccess: logoutAppSuccess, reset: resetlogoutApp, error: logoutAppError }] =
    useLogoutAppMutation();

  const [selectedAppId, setSelectedAppId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (selectedAppId) {
      handleGo();
    }
  }, [selectedAppId]);

  const handleGo = () => {
    if (selectedAppId === "superadmin") {
      selectSuperAdmin();
    } else {
      selectClientApp({ client_app_id: selectedAppId });
    }
  };

  //Call select client app Api when clicked on Try Again
  useEffect(() => {
    selectClientApp({ client_app_id: selectedAppId });
  }, [tryAgain]);

  useEffect(() => {
    // Redirect to login page, If lost userData or access-token
    if (!userData || !sessionStorage.getItem("fmdp-access-token")) {
      navigate("login");
    }
    // If user is superAdmin only, Redirect to super admin dashboard
    else if (userData.superadmin && (!userData.client_apps || userData.client_apps.length === 0)) {
      selectSuperAdmin();
    }
    // If user is not a super admin and have only one app to select
    // Redirect to that one Application
    else if (
      !userData.superadmin &&
      Array.isArray(userData.client_apps) &&
      userData.client_apps.length === 1
    ) {
      selectClientApp({ client_app_id: userData.client_apps[0].id });
      setSelectedAppId(userData?.client_apps[0].id);
    } else {
      setIsLoading(false);
    }
  }, []);

  // Navigate to Management Portal in case of success
  useEffect(() => {
    if (selectClientAppSuccess) {
      if (toggleAppInfo(userData, selectedAppId, clientAppData)) {
        navigate("/");
      } else {
        console.log("Error in app selection");
      }
    }
  }, [selectClientAppSuccess]);

  // To handle if select client app error occurs
  useEffect(() => {
    if (selectClientAppError) {
      setAppSelectionError(true);
    }
  }, [selectClientAppError]);

  // Navigate to admin dashboard if selected as super admin
  useEffect(() => {
    if (selectSuperAdminSuccess) {
      FMDPStorage.set("selected-app", "SuperAdmin");
      FMDPStorage.set("selected-app-id", "superadmin");
      navigate("/");
    }
  }, [selectSuperAdminSuccess]);

  const extractClientName = (app) => {
    const tempAppName = app?.name?.substring(0, app?.name?.lastIndexOf("-"))?.trim() || "";
    return tempAppName;
  };

  const extractAppName = (name) => {
    const tempAppName = name?.substring(name?.lastIndexOf("-") + 1, name?.length)?.trim() || "";
    return tempAppName;
  };

  //Set flash screen status to global store
  useEffect(() => {
    dispatch(setFlashScreen(clientAppData?.user_sessions?.client_app?.client?.co_branding_screen));
  }, [clientAppData, dispatch]);

  useEffect(() => {
    let a = combineObjects(userData?.client_apps);
    setClientsData(a);
  }, [userData?.client_apps]);

  function combineObjects(array) {
    const combinedObject = {};

    array?.forEach((obj) => {
      const clientId = obj?.client.id;
      if (!combinedObject[clientId]) {
        combinedObject[clientId] = {
          client: obj?.client,
          client_name: extractClientName(obj),
          client_image_url: obj?.client?.image_url,
          items: [],
        };
      }
      combinedObject[clientId].items.push({
        id: obj?.id,
        custom_name: obj?.custom_name,
        image_url: obj?.image_url,
        name: obj?.name,
      });
    });

    return Object.values(combinedObject);
  }

  const allAppsPresent = ["Management Portal", "Tracking", "TCO"];
  //Seacrh by client
  useEffect(() => {
    if (appSearch) {
      // Filter the original data and update the filteredData state
      const filteredSearch = clientsData?.filter((item) => {
        return item?.client_name?.toLowerCase().includes(appSearch?.toLowerCase());
      });
      setFilteredData(filteredSearch);
    } else {
      // If appSearch is empty, set filteredData to the original array
      setFilteredData(clientsData);
    }
  }, [appSearch, clientsData]);

  const logOut = () => {
    logoutApp();
  };

  useEffect(() => {
    if (logoutAppSuccess) {
      FMDPStorage.clear();
      window.location.reload();
    }
  }, [logoutAppSuccess]);

  const [liveView, setLiveView] = useState(true);

  //For live view directly redirect to the tracking portal and skip the role selection
  useEffect(() => {
    setLiveView(FMDPStorage?.get("live-view") || false);

    if (FMDPStorage?.get("live-view")) {
      setSelectedAppId(FMDPStorage?.get("selected-app-id"));
    }
  }, []);

  return (
    <>
      {liveView ? (
        selectClientAppError ? (
          <main className="login-layout authentication">
            <div className="login-form-container">
              <p className="login-form-container_error text-red-500 text-sm mb-4">
                Invalid Application!
                <QIButton
                  onClick={() => setTryAgain((prevState) => !prevState)}
                  className="qi-btn primary sm "
                >
                  Try Again
                </QIButton>
              </p>
            </div>
          </main>
        ) : (
          <main className="global-dashboard">
            <section className="text-center happenings">
              <QISpinner />
            </section>
          </main>
        )
      ) : userData?.client_apps?.length >= 1 ? (
        <>
          <header className="qi-header global-dashboard-header">
            <h1 className="page-title xl">FMDP</h1>

            <a className="user-dropdown_link_item clickable" onClick={logOut}>
              <SvgIcon name="log-out" />
              <span>{getLocalizedString("sign_out", "Sign Out")}</span>
            </a>
          </header>

          <main className="global-dashboard">
            {isLoading ? (
              <section className="text-center happenings">
                <QISpinner />
              </section>
            ) : (
              <section className="available-application">
                <h1 className="available-application_title">
                  Welcome to Fleet Mobility Data Platform
                </h1>

                {userData?.superadmin && (
                  <div
                    className="available-application_list_item super-admin"
                    onClick={async () => {
                      setSelectedAppId("superadmin");
                    }}
                    value="superadmin"
                  >
                    <SvgIcon name="crown" wrapperClass="crown-icon mr-2" svgClass="icon-crown" />
                    <strong>SuperAdmin</strong>
                  </div>
                )}

                <div className="clients-and-applications-wrapper">
                  <QISimpleSearch
                    handleSearchChange={setAppSearch}
                    simpleSearchPlaceholder="Search By Clients"
                    searchKey={appSearch}
                  />

                  <section className="clients-and-applications">
                    {filteredData?.map((client, id) => {
                      return (
                        <div className="available-application_block">
                          <div className="available-application_block_client">
                            <div className="application-icon-wrapper">
                              {client?.client_image_url ? (
                                <span className="application-icon_wrapper">
                                  <img
                                    alt=""
                                    src={client?.client_image_url}
                                    className="client-icon"
                                  />
                                </span>
                              ) : (
                                <SvgIcon name="app-icon" wrapperClass="client-icon defult" />
                              )}
                            </div>
                            <strong>{client?.client_name}</strong>
                          </div>

                          <ul className="available-application_list">
                            {client?.items?.map((item, id) => {
                              let name = extractAppName(item?.name) || "";
                              let selectedIcon =
                                name === "Management Portal"
                                  ? "management"
                                  : name === "Tracking"
                                  ? "tracking"
                                  : name === "TCO"
                                  ? "truck"
                                  : null;

                              return (
                                <li
                                  onClick={() => {
                                    setSelectedAppId(item?.id);
                                  }}
                                  className="available-application_list_item"
                                >
                                  <SvgIcon
                                    name={selectedIcon}
                                    wrapperClass="application-icon"
                                    svgClass={`icon-${selectedIcon}`}
                                  />
                                  <strong>{name}</strong>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    })}
                  </section>
                </div>
              </section>
            )}
          </main>
          <ToastContainer />
        </>
      ) : null}
    </>
  );
};
