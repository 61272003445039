import { useEffect, useState } from "react";
import moment from "moment";
import { AdvanceSearchWrapper } from "../Shared/";
import {
  QICustomSelect,
  QIDateRangePicker,
  QIInput,
  QISwitch,
  QIMultiSelectDropDown,
} from "../../../components";
import { useGetBackendsQuery, useGetClientsQuery, useGetGeolocationsQuery } from "../services";
import { useDropDownSearch } from "../../../hooks";

export const DeviceAdvanceSearch = ({ onSearch, activeSearch }) => {
  const initialValues = {
    client_id_eq: "",
    name_cont: "",
    device_id_cont: "",
    filter_device_ph_with_country_code_cont: "",
    simcard_no_eq: "",
    firmware_version_cont: "",
    created_at_gteq: null,
    created_at_lteq: null,
    geocoder_ids_contains: [],
    backend_ids_contains: [],
    enabled_eq: "",
  };

  const [formData, setFormData] = useState(initialValues);
  const [showCalender, setShowCalender] = useState(false);
  const [resetCount, setResetCount] = useState(0);

  useEffect(() => {
    if (activeSearch) {
      const tempSearchObj = { activeSearch };
      if (tempSearchObj.geocoder_ids_contains) {
        tempSearchObj.backend_ids_contains.subString(
          1,
          tempSearchObj.backend_ids_contains.length - 1
        );
        tempSearchObj.geocoder_ids_contains = tempSearchObj.geocoder_ids_contains.split(",");
      }
      if (tempSearchObj.geocoder_ids_contains) {
        tempSearchObj.geocoder_ids_contains.subString(
          1,
          tempSearchObj.geocoder_ids_contains.length - 1
        );
        tempSearchObj.geocoder_ids_contains = tempSearchObj.geocoder_ids_contains.split(",");
      }
      setFormData(tempSearchObj);
    }
  }, []);

  const dropmenuQueryParams = { per_page: 1000, order_by: "name" };

  // Get the clients list using Dropdown Search
  const {
    data: clients,
    setSearchKey: setClientSearch,
    isSuccess: clientsSuccess,
  } = useDropDownSearch({
    useData: useGetClientsQuery,
    simpleSearchKey: "name_or_email_cont",
    additionalParams: { ...dropmenuQueryParams },
    selectedIds: formData.client_id_eq,
  });

  // Get the backends list using Dropdown Search
  const {
    data: backends,
    setSearchKey: setBackendSearchKey,
    isSuccess: backendsSuccess,
  } = useDropDownSearch({
    useData: useGetBackendsQuery,
    simpleSearchKey: "name_or_backend_type_cont",
    selectedIds: formData.backend_ids_contains,
    additionalParams: { ...dropmenuQueryParams },
  });

  // Get the geolocation plans list using dropdown search
  const {
    data: geolocation_plans,
    setSearchKey: setGeolocationPlanSearchKey,
    isSuccess: isGeolocationPlansSuccess,
  } = useDropDownSearch({
    useData: useGetGeolocationsQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formData.geolocation_plan_id_eq,
  });

  const handleFormChange = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const clearAdvanceSearch = () => {
    setResetCount((i) => i + 1);
    setFormData(initialValues);
    onSearch(null);
    setShowCalender(false);
  };

  const handleDateRange = (startTime, endTime) => {
    setFormData((prevState) => ({
      ...prevState,
      created_at_gteq: startTime,
      created_at_lteq: endTime,
    }));
    setResetCount((i) => i + 1);
  };

  return (
    <AdvanceSearchWrapper
      activeFilters={activeSearch}
      onClear={clearAdvanceSearch}
      onSearch={() => onSearch(formData)}
      showCalender={showCalender}
      setShowCalender={setShowCalender}
    >
      <QICustomSelect
        label="Client"
        value={formData.client_id_eq}
        onChange={(value) => {
          handleFormChange("client_id_eq", parseInt(value));
          setClientSearch("");
        }}
        className=""
        labelClassName="client"
        onSearch={setClientSearch}
      >
        <li value="">Any</li>
        {clientsSuccess &&
          clients.data.map((client) => (
            <li key={client.id} value={client.id}>
              {client.name}
            </li>
          ))}
      </QICustomSelect>
      <QIInput
        label="Name"
        className=""
        value={formData.name_cont}
        onChange={(e) => handleFormChange("name_cont", e.target.value)}
      />
      <QIInput
        label="ID/IMEI"
        className=""
        value={formData.device_id_cont}
        onChange={(e) => handleFormChange("device_id_cont", e.target.value)}
      />
      <QIInput
        label="Primary SIM Card"
        className=""
        value={formData.simcard_no_eq}
        onChange={(e) => handleFormChange("simcard_no_eq", e.target.value)}
      />
      <QIInput
        label="Phone No"
        className=""
        value={formData.filter_device_ph_with_country_code_cont}
        onChange={(e) =>
          handleFormChange("filter_device_ph_with_country_code_cont", e.target.value)
        }
      />
      <QIInput
        label="Firmware"
        className=""
        value={formData.firmware_version_cont}
        onChange={(e) => handleFormChange("firmware_version_cont", e.target.value)}
      />
      <QISwitch
        label="Enabled"
        className=""
        value={formData.enabled_eq}
        onChange={() => handleFormChange("enabled_eq", !formData.enabled_eq)}
      />

      <QIMultiSelectDropDown
        label="Backends"
        className=""
        data={backends?.data || []}
        selected={formData.backend_ids_contains}
        onChange={(updatedList) => {
          handleFormChange("backend_ids_contains", updatedList);
          setBackendSearchKey("");
        }}
        disabled={!backendsSuccess}
        onSearch={(value) => setBackendSearchKey(value)}
      />

      <QICustomSelect
        label="Geolocation Plan"
        className=""
        value={formData.geolocation_plan_id_eq}
        onChange={(updatedList) => {
          handleFormChange("geolocation_plan_id_eq", updatedList);
          setGeolocationPlanSearchKey("");
        }}
        onSearch={setGeolocationPlanSearchKey}
        labelClassName="geolocation-plan"
      >
        {isGeolocationPlansSuccess &&
          geolocation_plans.data.map((gl) => (
            <li key={gl.id} value={gl.id}>
              {gl.name}
            </li>
          ))}
      </QICustomSelect>

      <QIDateRangePicker
        start={formData.created_at_gteq}
        end={formData.created_at_lteq}
        label={"Created b/w"}
        maxDate={moment()}
        onChange={handleDateRange}
        className=""
        setShowCalender={setShowCalender}
        resetCount={resetCount}
      />
    </AdvanceSearchWrapper>
  );
};
