import React, { useState, useEffect } from "react";
import * as Components from "../../../components";
import { mergeObjects } from "../../../shared/helper";
import {
  useCreateDModelMutation,
  useUpdateDModelMutation,
  useGetDModelQuery,
  useGetDMansQuery,
} from "../services";
import { FormContainer } from "../Shared/FormContainer";
import { useForm } from "../../../hooks";

export const DModelForm = ({ idToEdit = null, closeForm }) => {
  const { data: deviceMans = { data: [] }, isSuccess: isdeviceMansSuccess } = useGetDMansQuery({
    per_page: 1000,
    order_by: "name",
  });

  // new and edit forms
  const [formData, setFormData] = useState({
    name: "",
    device_manufacturer_id: "",
    year: "",
    version: "",
  });

  // error handling
  const [error, setError] = useState({});

  const { create: createBackend, update: updateBackend } = useForm({
    createMutation: useCreateDModelMutation,
    updateMutation: useUpdateDModelMutation,
    closeForm,
    setError,
  });

  const { data = {} } = useGetDModelQuery({ id: idToEdit }, { skip: !idToEdit });

  useEffect(() => {
    if (idToEdit && data) {
      setFormData(mergeObjects(formData, data));
    }
  }, [data]);

  const handleFormChange = (field, value) => {
    setFormData((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleFormSubmit = () => {
    if (idToEdit) {
      updateBackend({ id: idToEdit, formData });
    } else {
      createBackend({ formData });
    }
  };

  return (
    <FormContainer
      resourceName="Device Model"
      closeForm={closeForm}
      idToEdit={idToEdit}
      handleFormSubmit={handleFormSubmit}
    >
      <Components.QICustomSelect
        label="Device Manufacturer"
        value={formData.device_manufacturer_id}
        onChange={(value) => handleFormChange("device_manufacturer_id", value)}
        error={error.device_manufacturer}
        labelClassName="device-manufacturer"
      >
        <li value="">Select</li>
        {isdeviceMansSuccess &&
          deviceMans.data.map((deviceMan) => (
            <li key={deviceMan.id} value={deviceMan.id}>
              {deviceMan.name}
            </li>
          ))}
      </Components.QICustomSelect>
      <Components.QIInput
        label="Name"
        placeholder="LTE Vehicle Terminal"
        value={formData.name}
        onChange={(e) => handleFormChange("name", e.target.value)}
        error={error.name}
      />
      <Components.QIInput
        label="Version"
        placeholder="JM-VL01"
        value={formData.version}
        onChange={(e) => handleFormChange("version", e.target.value)}
        error={error.version}
      />
      <Components.QIInput
        label="Year"
        placeholder={new Date().getFullYear()}
        value={formData.year}
        onChange={(e) => handleFormChange("year", e.target.value)}
        error={error.year}
      />
    </FormContainer>
  );
};
