import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { FormContainer } from "../Shared";
import {
  useCreateUserGroupMutation,
  useUpdateUserGroupMutation,
  useGetAppsQuery,
  useGetUserGroupQuery,
} from "../services";
import { useForm } from "../../../hooks";
import { userGroupsFormSchema } from "../validations";
import { mergeObjects } from "../../../shared/helper";
import {getLocalizedString} from "../../../shared/translation";

export const UserGroupForm = ({ idToEditOrCsvUpload = null, onClose }) => {
  const { data: apps = {} } = useGetAppsQuery({ page: 1, per_page: 1000 });

  const [initialValues, setInitialValues] = useState({
    name: "",
    app_id: "",
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: userGroupsFormSchema,
    onSubmit: (value) => handleFormSubmit(value),
  });

  const { data = {} } = useGetUserGroupQuery(
    { id: idToEditOrCsvUpload },
    { skip: !idToEditOrCsvUpload }
  );

  // Set form data in case of edit
  useEffect(() => {
    if (idToEditOrCsvUpload && data) {
      setInitialValues(mergeObjects(initialValues, data));
    }
  }, [data]);

  const { create: createUserGroup, update: updateUserGroup } = useForm({
    createMutation: useCreateUserGroupMutation,
    updateMutation: useUpdateUserGroupMutation,
    closeForm: onClose,
    setError: formik.setErrors,
  });

  const handleFormSubmit = (formData) => {
    if (idToEditOrCsvUpload) {
      updateUserGroup({ id: idToEditOrCsvUpload, formData });
    } else {
      createUserGroup({ formData });
    }
  };

  return (
    <FormContainer
      resourceName={getLocalizedString("user_group", "User Group")}
      closeForm={() => onClose(false)}
      handleFormSubmit={formik.handleSubmit}
      idToEdit={idToEditOrCsvUpload}
    >
      <Components.QICustomSelect
        label={getLocalizedString("application", "Application")}
        {...formik.getFieldProps("app_id")}
        onChange={(value) => formik.setFieldValue("app_id", value)}
        error={formik.touched.app_id && formik.errors.app_id}
        disabled={idToEditOrCsvUpload}
        labelClassName="applications"
      >
        <>
          <li value="">All</li>
          {apps?.data?.map((app, index) => (
            <li value={app.id} key={index}>
              {app.name}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QIInput
        label={getLocalizedString("group_name", "Group Name")}
        {...formik.getFieldProps("name")}
        error={formik.touched.name && formik.errors.name}
      />
    </FormContainer>
  );
};
