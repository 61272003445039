import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { QICheckBox } from "../../components";
import PropTypes, { func, object } from "prop-types";

/**
 * This is a Table with dynamic number of headers.
 *
 * @param {object} header - An array of objects having list of headers with key
 * header : [
 * {checkbox:true , statusKey: 'id'} // checkbox: if want to render checkbox, statusKey: an key in data-item
  { label: 'Name', key: 'name', sortable: true, defaultSort: true },
  { label: 'Email', key: 'email' },
  { label: 'Age', key: age, sortable: true },
  { label: 'action', type: component, component: ReactComponent } // Will pass row data as data props to React component
  { label: 'Address', nestedValue:true, getNestedValue:()=>getNestedValue(element)} // pass a function to parse nested value
];
 * @param {object}  handler - An object containing functions with key same as header key
 * handler: {
    @param allSelectHandler: A function within handler to select all row. // will get call back when all the rows are selected
    @param checkboxHandler:  A function within handler to select individual  row having parameter to select the row data,
    @param rowHandler: A callback function to onClick event of any row,
  },
 * @param {string}  className - A string to set class to root node
 * @param {array} status - An array which consist the main key to denote a item is selected or not
 * @param {array}  data - An array for data (first priority over fetchData)
 * data: data = [
  { key: 1, name: 'Rahul', email: 'rajsrivastvaa@gmail.com',age: '22' },
  { key: 2, name: 'Rohit', email: 'rajsrivastvaa@gmail.com',age: '21' },
  { key: 3, name: 'Rama', email: 'rajsrivastvaa@gmail.com', age: '23' },
  { key: 4, name: 'Abhishek', email: 'rajsrivastvaa@gmail.com', age: '24' },
  { key: 5, name: 'Abhinav', email: 'rajsrivastvaa@gmail.com', age: '25' },
];
 * @example
 *  <QITrackingTable
 *    data={data}
 *    fetchData={() => fetchData}
 *    handler={handler}
 *    headers={headers} />
 *
 */
let sortingStatus = {};
let currentSortKey = false;
let sortType = "asc";

export const QITrackingTable = ({
  headers,
  striped,
  className,
  handler,
  data = [],
  status = [],
  tableKey,
  maxHeight = "100%",
  setHighlight,
  highlight,
  ...props
}) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    for (let i = 0; i < headers.length; i += 1) {
      if (!currentSortKey && headers[i].defaultSort) {
        currentSortKey = headers[i].key;
      }
      sortingStatus = { ...sortingStatus, [headers[i].key]: false };
    }
    sortingStatus[currentSortKey] = true;
  }, [headers]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const sort = (sortingKey) => {
    if (currentSortKey === sortingKey) {
      sortType = sortType === "asc" ? "desc" : "asc";
    } else {
      sortingStatus[currentSortKey] = false;
      currentSortKey = sortingKey;
      sortingStatus[currentSortKey] = true;
      sortType = "asc";
    }
    // Have to add API CALL SUCH THAT COMPONENT WILL BE RERENDERED
    handler.sortingHandler(sortingKey, sortType);
  };

  const getDisplayText = (text) => {
    if (text === 0) {
      return "0";
    }
    if (!text) {
      return "";
    }
    return text;
  };

  const getTooltip = (header, rowData) => {
    let title = "";

    if (header.nestedValue) {
      const nestedValue = header.getNestedValue(rowData);
      const typeofNestedValue = typeof nestedValue;
      if (typeofNestedValue === "string" || typeofNestedValue == "number") {
        title = nestedValue;
      } else {
        title = rowData[header.key] || header.alternate;
      }
    } else {
      title = rowData[header.key] || header.alternate;
    }
    if (title === undefined || title === null) {
      return ``;
    } else return `${title}`;
  };

  return (
    <section className={`qi-map-view-left-panel ${className || ""}`}>
      <Scrollbars autoHeight autoHeightMax={maxHeight}>
        <ul className="qi-map-view-left-panel_list">
          {tableData.map((element, index) => {
            return (
              <li
                data-testid="tr"
                className={`qi-map-view-left-panel_list_item ${element.className || ""} ${
                  element?.device_name_list && element?.device_name_list === highlight && "selected"
                }`}
                key={index}
                id={element?.device_name_list}
                onClick={() => {
                  handler.rowHandler && handler.rowHandler(element);
                  try {
                    setHighlight(element?.device_name_list && element?.device_name_list);
                  } catch (e) {
                    console.log(e);
                  }
                }}
              >
                {headers.map((header, index) => (
                  <React.Fragment key={index}>
                    {header.checkbox && handler.checkboxHandler && (
                      <div key={header.key} className="qi-map-view-left-panel_list_item_element">
                        <QICheckBox
                          changeHandler={() => handler.checkboxHandler(element)}
                          checked={status.includes(element[header.statusKey])}
                        />
                      </div>
                    )}
                    {!header.checkbox && header.type === "component" && header.component && (
                      <div key={header.key} className="qi-map-view-left-panel_list_item_element">
                        <header.component data={element} />
                      </div>
                    )}
                    {!header.checkbox && !header.type && (
                      <div
                        key={header.key}
                        className="qi-map-view-left-panel_list_item_element"
                        title={getTooltip(header, element)}
                        onClick={handler[header.key]}
                      >
                        {header.nestedValue
                          ? header.getNestedValue(element)
                          : getDisplayText(element[header.key]) || header.alternate}
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </li>
            );
          })}
        </ul>
      </Scrollbars>
    </section>
  );
};

QITrackingTable.defaultProps = {
  handler: {},
  hover: true,
  striped: true,
  responsive: "lg",
  className: "",
  fetchData: null,
  data: [],
  status: [],
};

QITrackingTable.propTypes = {
  headers: PropTypes.arrayOf(Object).isRequired,
  fetchData: PropTypes.func,
  hover: PropTypes.bool,
  striped: PropTypes.bool,
  responsive: PropTypes.oneOf([true, false, "lg", "sm", "md", "xl"]),
  className: PropTypes.string,
  handler: PropTypes.objectOf(func),
  data: PropTypes.arrayOf(object),
  status: PropTypes.array,
  tableKey: PropTypes.objectOf(String),
};
