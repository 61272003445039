import { useState, useEffect, useCallback } from "react";
import { VehicleMap } from "../Vehicles/VehicleMap";
import { FMDPStorage } from "../../../shared/helper";
import { useGetDevicesQuery, useGetVehiclesQuery } from "../../ManagementPortal/services";
import { useGetDevicesTrackingQuery } from "../services";
import { useWebSocket } from "../../../hooks";
import { Icon, Style, Fill, Stroke, Text } from "ol/style.js";
import { locationImg } from "../assets/mapIcons";

// Custom hook to get client configuration
const useClientConfig = () => {
  const [clientId, setClientId] = useState(null);
  const [clientConfig, setClientConfig] = useState({});
  const currentUser = FMDPStorage.get("current-user");
  const selectedAppId = FMDPStorage.get("selected-app-id");

  useEffect(() => {
    const selectedClientId = JSON.parse(localStorage.getItem("selected-app-id"));
    const currentUser = JSON.parse(localStorage.getItem("current-user"))?.client_apps?.find(
      (item) => item.id === selectedClientId
    );
    setClientId(currentUser?.client?.id);
    if (currentUser?.client?.config_json) {
      try {
        setClientConfig(JSON.parse(currentUser?.client?.config_json));
      } catch (e) {
        console.error(e);
      }
    }

    return () => setClientId(null);
  }, []);

  return { clientId, clientConfig, currentUser, selectedAppId };
};

// Custom hook to get user ID
const useUserId = (currentUser, selectedAppId) => {
  return useCallback(() => {
    const currentAppRoles = currentUser?.client_app_roles;
    const currentUserId = currentUser?.id;
    let hasAdminRole = false;

    currentAppRoles?.forEach((clientData) => {
      if (clientData?.client_app_id === selectedAppId) {
        clientData?.roles?.forEach((role) => {
          if (role?.name === "Admin" || role?.name === "Fleet Admin") {
            hasAdminRole = true;
          }
        });
      }
    });

    return hasAdminRole ? "" : currentUserId;
  }, [currentUser, selectedAppId]);
};

// Custom hook to manage tracking data
const useTrackingData = (sourceIds, deviceId, realTimedata) => {
  const { data: trackingData = { data: [] } } = useGetDevicesTrackingQuery({
    device_ids: sourceIds,
  });
  const [mapData, setMapData] = useState({});
  const [trackingPoints, setTrackingPoints] = useState([]);
  const [deviceData, setDeviceData] = useState(null);

  useEffect(() => {
    let device;
    if (trackingData && trackingData?.latest_data?.length) {
      device =
        (trackingData?.latest_data || []).find(
          ({ device_data }) => device_data?.source_id === deviceId
        ) || {};
      setDeviceData(device?.device_data || {});
    } else {
      setDeviceData({});
    }
  }, [trackingData, deviceId]);

  useEffect(() => {
    if (!trackingData?.latest_data) return;

    const filteredPosition = {};
    const points = [];

    trackingData.latest_data.forEach(({ device_data }) => {
      const sourceId = device_data?.source_id;
      const realTimeDataForSource = realTimedata[sourceId];
      const position = realTimeDataForSource?.gps?.position || device_data?.gps?.v?.position;
      const direction =
        realTimeDataForSource?.velocity?.direction || device_data?.velocity?.v?.direction;
      let speed = realTimeDataForSource?.velocity?.speed || device_data?.velocity?.v?.speed;
      const sourceTime =
        realTimeDataForSource?.source_time ||
        device_data?.source_time?.v ||
        device_data?.source_time;
      const serverTime =
        realTimeDataForSource?.server_time ||
        device_data?.server_time?.v ||
        device_data?.server_time;
      const plate_number = device_data?.plate_number?.v;
      speed = speed == 0 || speed ? `${(speed * 3.6).toFixed(1)} km/h` : "Not Available";
      const ignition = realTimeDataForSource?.ignition ?? device_data?.ignition?.v ?? "Unknown";

      if (sourceId && position && position.lat !== 0 && position.lng !== 0) {
        filteredPosition[sourceId] = {
          speed,
          direction,
          ignition,
          sourceTime,
          plate_number,
          serverTime,
        };
        points.push({ ...position, imei: sourceId, direction, speed, plate_number });
      }
    });

    setMapData(filteredPosition);
    setTrackingPoints(points.filter((device) => device.lat !== 0 && device.lng !== 0));
  }, [trackingData, realTimedata]);

  return { mapData, trackingPoints, deviceData };
};

export const DashBoardContainer = () => {
  const [mapRef, setMapRef] = useState(null);
  const [mapExpand, setMapExpand] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(1000);
  const [q, setQ] = useState("");
  const [sourceIds, setSourceIds] = useState([]);
  const [deviceId, setDeviceId] = useState(null);
  const [count, setCount] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true); // Flag to check if initial load

  const { clientId, currentUser, selectedAppId } = useClientConfig();
  const getUserId = useUserId(currentUser, selectedAppId);

  const pagination = { page, perPage, onPageChange: setPage, onPerPageChange: setPerPage };
  const { data: coreDevices = { data: [], total_count: 0 } } = useGetVehiclesQuery({
    page,
    per_page: perPage,
    q,
    filter_user_id: getUserId(),
  });

  const queryParams = getUserId()
    ? { page, per_page: perPage, device_ids: sourceIds, q }
    : { page, per_page: perPage, q };

  const { data: devicesData = { data: [], total_count: 0 } } = useGetDevicesQuery(queryParams);

  let list_arr;

  useEffect(() => {
    try {
      list_arr = [];
      coreDevices?.data.forEach((element, index) => {
        let x = { className: element.device_name_list + "_cls" };
        list_arr.push({ ...element, ...x });
      });

      let tempDevices = [];

      coreDevices?.data?.map((device) => {
        if (device?.device_name_list) {
          let temp = device?.device_name_list?.split(",");
          tempDevices = temp.concat(tempDevices);
        }
      });

      devicesData?.data?.map((device) => {
        if (device?.device_id) {
          if (!tempDevices?.includes(device?.device_id)) {
            tempDevices.push(device?.device_id);
          }
        }
      });

      setSourceIds((prev) => tempDevices);
    } catch (e) {}
  }, [coreDevices.data, devicesData?.data]);

  const { realTimedata } = useWebSocket(String(clientId), "sub_all");
  const { mapData, trackingPoints, deviceData } = useTrackingData(
    sourceIds,
    deviceId,
    realTimedata
  );

  useEffect(() => {
    if (mapRef && trackingPoints.length) {
      const layerName = `test_${count}`;
      if (count) mapRef.removeLayer(`test_${count - 1}`);
      mapRef.addLayer({ name: layerName });
      mapRef.drawTrack({
        layerName,
        fitWithinView: true,
        trackType: "point",
        data: [{ coordinates: trackingPoints }],
        style: {
          point: {
            style: (feature) => {
              return new Style({
                image: new Icon({
                  color: "#fff",
                  crossOrigin: "anonymous",
                  src: locationImg,
                  imgSize: [26, 26],
                  rotation: feature.direction,
                }),
                text: new Text({
                  text: feature.plate_number || feature.imei,
                  font: "14px Calibri,sans-serif",
                  fill: new Fill({
                    color: "#000", // Black font
                  }),
                  backgroundFill: new Fill({
                    color: "#fff", // White background for the box
                  }),
                  padding: [5, 5, 5, 5], // Padding inside the box
                  offsetY: -30, // Moves the text box above the marker
                  textAlign: "center", // Aligns the text in the center of the box
                  backgroundStroke: new Stroke({
                    color: "#000", // Border color around the box
                    width: 1, // Border width
                  }),
                }),
              });
            },
          },
        },
      });
      // Only fit the map on initial load
      if (initialLoad) {
        mapRef.performFit(layerName);
        setInitialLoad(false); // Disable initial load after first fit
      }
      //mapRef.performFit(layerName);
    } else {
      mapRef?.removeLayer(`test_${count - 1}`);
    }
    setCount((c) => c + 1);
  }, [mapRef, trackingPoints, deviceId]);

  return (
    <>
      <VehicleMap mapExpand={mapExpand} mapRef={mapRef} setMapRef={setMapRef} />
    </>
  );
};
