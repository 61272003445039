import Scrollbars from "react-custom-scrollbars";
import { QITracksListCard } from "../../../../components";

const TripsListWrapper = ({ tracksData }) => {
  const renderCardList = tracksData?.map((data, index) => {
    return <QITracksListCard key={index} data={data} />;
  });

  return <ul className="trips-list-wrapper">{renderCardList}</ul>;
};

export default TripsListWrapper;
