import moment from "moment";
import convertUnit from "convert-units";
import commonConfig from "../../../shared/commonConfig";
import mappings from "./mappings.json";

/**
 * This method returns parsed keys with type
 *
 * @param {string} section - A string value which has to be parsed
 * @return {array} - Returns an array with keyName name and type
 *
 * @example
 *    Supported section type : "Root->temp->xyz[]"
 *    Return value : [{key:"Root",type:"object"},{key:"temp",type:"object"},{key:"xyz",type:"array"}]
 */
const parseSection = (section) => {
  const path = [];
  const sectionElements = section.split("->");
  sectionElements.forEach((item) => {
    let key = item;
    let type = "object";
    if (item.slice(-2) === "[]") {
      key = key.slice(0, -2);
      type = "array";
    }
    path.push({
      key,
      type,
    });
  });
  return path;
};

/**
 * This method returns concated units if conversions are possible otherwise returns same value with base unit.
 *
 * @param {any} value - Accepts value in base unit
 * @param {object} unit - Accepts unit in format {input:"m/s",output:"km/hr"}
 *                        where input in base unit of value and output is desired unit to show in UI.
 * @return {string} - Returns a string with concated unit(if conversion supported else with base unit)
 */

const addUnit = (value, unit) => {
  let valueWithUnit = value;
  const { input, output } = unit;
  if (!input) {
    return value;
  }
  if (input !== output) {
    try {
      valueWithUnit = convertUnit(value).from(input).to(output);
    } catch (e) {
      valueWithUnit = `${value} ${input}`;
    }
  } else {
    valueWithUnit = `${value} ${input}`;
  }
  return valueWithUnit;
};

/**
 * This method finalConverted values after adding unit and other tranformation.s
 *
 * @param {any} value - Accepts value for respective level
 * @param {string} type - Accepts type in string format mentioned in mapper object
 * @param {object} unit - Accepts unit in format {input:"m/s",output:"km/hr"}
 *                        where input in base unit of value and output is desired unit to show in
 * @return {string} - Returns a string with all required formatting
 * @note - New comparision and conversion based on type can be added in this method
 */
const convertValues = (value, type, unit) => {
  let convertedValue = value;
  if (value === null || value === "" || typeof value === "undefined") {
    convertedValue = "NA";
  } else if (type === "timestamp") {
    convertedValue = moment(value).format(commonConfig.dateTimeFormat);
  } else if (unit) {
    convertedValue = addUnit(value, unit);
  }
  return convertedValue;
};

const groupMapper = (data, value, parentLable) => {
  const tempValue = [];
  const validMapperItems = [];
  data.forEach((item) => {
    if (isProcessble(item, "group")) {
      validMapperItems.push(item);
    }
  });

  value.forEach((item, index) => {
    const tempData = { label: `${parentLable.eng} ${index + 1}`, value: [] };
    validMapperItems.forEach((mapperItem) => {
      let { type, unit, name } = mapperItem;
      let value = convertValues(item[name], type, unit);
      tempData.value.push({ label: mapperItem.label.eng, value });
    });
    tempValue.push(tempData);
  });
  return tempValue;
};

const getMappedField = (device, section, name, type, label, data) => {
  const path = parseSection(section);
  let value = "";
  path.forEach((item) => {
    const { key, type } = item;
    if (key === "Root") {
      value = device;
    } else if (type === "object") {
      value = value[key];
    } else if (type === "array") {
      value = value[key];
    }
  });

  if (Array.isArray(value) && type !== "group") {
    const tempValue = [];
    value.forEach((item) => tempValue.push(item[name]));
    value = tempValue;
  } else if (Array.isArray(value) && type === "group") {
    value = groupMapper(data, value, label);
  } else {
    value = value[name];
  }
  return value;
};

const isProcessble = (mapperItem, itemType) => {
  const { name, show, supported, label, type, section } = mapperItem;
  if (name && show && label && type && supported) {
    if (itemType === "group" || section) {
      return true;
    }
  }
  return false;
};

const formattMappingData = (data, mapper, manufacturerAndModelName) => {
  const formattedData = [];
  const mapperObject = mapper[manufacturerAndModelName];
  if (mapperObject) {
    mapperObject.forEach((item) => {
      try {
        const { name, label, type, section, unit, data: innerData } = item;
        if (isProcessble(item)) {
          let value = getMappedField(data, section, name, type, label, innerData);
          value = convertValues(value, type, unit);
          const finalLabel = label.eng;
          formattedData.push({
            label: finalLabel,
            value,
            type,
          });
        }
      } catch (e) {}
    });
  }

  return formattedData;
};

export const filterTrackingData = (data, manufacturerAndModelName) => {
  const result = { otherData: {}, linecoordinates: [] };
  data = data[0];
  try {
    result.positionTime = moment(data.position_time).format(commonConfig.dateTimeFormat);
    result.otherData = formattMappingData(data, mappings, manufacturerAndModelName);
    result.carPlateNumber = data.car_plate_number;
    result.location = {};
    const { lat, lng } = data.gps_location;

    if (lat !== 0 || lng !== 0) {
      result.location.lat = lat;
      result.location.lng = lng;
    }
    return result;
  } catch {
    return result;
  }
};

// export function* getReverseGeoCodedAddress(action) {
//   try {
//     const { lat, lon } = action.payload;
//     const address = yield call(httpProvider.call, {
//       requestName: "getAddress",
//       requestObject: { format: "json", lat, lon },
//     });
//     yield put({ type: actions.location.SET.SUCCESS, payload: address.display_name });
//   } catch {
//     yield put({ type: actions.location.SET.SUCCESS, payload: "N/A" });
//   }
// }

// export function* getVehicleData(action) {
//   const source = axios.CancelToken.source();
//   const { imei, manufacturerAndModelName } = action.payload;
//   const clientId = localStorage.getItem("client_id");

//   yield apiSaga({
//     apiObj: {
//       requestName: "getVehiclesDetails",
//       requestObject: { device_ids: imei },
//       idOnUrl: clientId,
//       secondIdOnUrl: "trackingdata/latest",
//       cancelToken: source,
//     },
//     statusHandler: {
//       200: [
//         {
//           action: actions.vehicleDetails.GET.SUCCESS,
//           modifier: function (response) {
//             let data = [];
//             if (Array.isArray(response.tracking_data) && response.tracking_data.length > 0) {
//               const latestData = response.tracking_data[0];
//               if (latestData && latestData.gps_location) {
//                 const { lat, lng: lon } = latestData.gps_location;
//               }
//               data = filterTrackingData(response.tracking_data, manufacturerAndModelName);
//             }
//             return data;
//           },
//         },
//       ],
//       onError: {
//         action: actions.vehicleDetails.GET.FAIL,
//       },
//     },
//   });
// }

// export function* VehicleDetailsSaga() {
//   yield takeEvery(actions.vehicleDetails.GET.REQUEST, getVehicleData);
//   yield takeEvery(actions.location.GET.REQUEST, getReverseGeoCodedAddress);
// }
