import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { getTimeGap } from "../../containers/Tracking/utils";
import { smallLogo } from "../../assets";
import sprite from "../../assets/icons.svg";
import spriteNative from "../../containers/Tracking/assets/nativeIcons/icons.svg";
import { getLocalizedString } from "../../shared/translation";

export const QIDragAndDropDetails = ({
  setDetails,
  setPopup,
  deviceData,
  removeLayerOnClose,
  selectedVehiclePlate,
  removeRowSelection,
  setDockAlign,
  deviceId,
  realTimedata,
  dockAlign,
  setDeviceId,
  setShowTrack,
  headerItems = ["collapse", "split-down", "split-left", "split-right", "pop-out", "cross"],
  headerName,
  headerDetails,
  listDetails,
  setSelectedRowId,
  currentTab,
  setmaximizeMinimize,
  maximizeMinimize,
}) => {
  useEffect(() => {
    if (dockAlign !== "split-down") {
      if (maximizeMinimize) {
        setDockAlign("split-down");
      }
    }
  }, [maximizeMinimize, dockAlign, setDockAlign]);

  const closeDeviceDetails = () => {
    removeLayerOnClose && removeLayerOnClose();
    setDetails(false);
    removeRowSelection();
    setDeviceId && setDeviceId(null);
    //setShowTrack(false);
  };

  const { status, description } = getTimeGap(
    realTimedata[deviceId]?.source_time || deviceData?.source_time?.v || deviceData?.report_time
  );

  //Header Items
  const renderHeaderItems = headerItems.map((item, index) => {
    return (
      item !== dockAlign && (
        <>
          <div
            onClick={() => {
              item === "collapse" && setmaximizeMinimize((prev) => !prev);
              item === "cross" && closeDeviceDetails();
              item === "pop-out" && setPopup();
              if (item !== "collapse") {
                setDockAlign(item);
                if (item !== "split-down") {
                  setmaximizeMinimize(false);
                }
              }
            }}
            className="details-block_actions_item"
            key={index}
          >
            <svg
              className={`icon ${
                item === "collapse" && (maximizeMinimize ? "rotate-90" : "rotate-270")
              }`}
            >
              <use
                href={`${item === "cross" || item === "collapse" ? sprite : spriteNative}#${item}`}
              ></use>
            </svg>
          </div>
        </>
      )
    );
  });
  //Header Items End
  return (
    <>
      <header className="details-block_header">
        <h2 className="details-block_header_title">
          {headerName}: {selectedVehiclePlate}
        </h2>
        <div className="details-block_actions">{renderHeaderItems}</div>
      </header>
      <section
        className={`details-block_section ${currentTab && `details-block_section_${currentTab}`}`}
      >
        {Object.keys(deviceData).length !== 0 && deviceData.constructor === Object ? (
          <>
            {headerDetails && (
              <aside className="details-block_summary">
                <div className="details-block_summary_item-info">
                  <div className="item-photo">
                    <img src={smallLogo} alt="logo" />
                    <span className={`status ${status}`} title={description}></span>
                  </div>
                  <ul className="details-block_summary_list">{headerDetails}</ul>
                </div>
              </aside>
            )}
            <div className="details-block_data-container-wrapper">
              <Scrollbars>{listDetails}</Scrollbars>
            </div>
          </>
        ) : (
          <p>{getLocalizedString("no_data_available", "No Data Available")}</p>
        )}
      </section>
    </>
  );
};
